import React, { useEffect, useState } from "react";
import * as types from "../redux/actions/types";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import LinearProgress from "../components/LineProgress";
import Axios from "connection/axios";
const {REACT_APP_GET_INVITED_TEAMS} = process.env;
export default function RouteWrapper({ component: Component, isPrivate, ...rest }) {
    const dispatch = useDispatch();
    const {inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const customerId = useSelector((state) => state.userDetailsReducer.id);
    const teamId = localStorage.getItem("team");
    const role = localStorage.getItem("role");

const handleSwitchTeam = (data) => {
    const live = data.invitedTeam.find((item) => item.customerId === teamId);
    if (live != undefined) {
        dispatch({ type: types.SWITCH_TEAM, payload: { id: teamId, role: role } });
    } else {
        dispatch({ type: types.SWITCH_TEAM, payload: { id: null, role: null } });
    }
};
   
    useEffect(async () => {
        if (teamId != null && role != null) {
            if (customerId) {
                const addedTeams = await Axios.get(REACT_APP_GET_INVITED_TEAMS, {
                    params: { customerId },
                });
                if (addedTeams && addedTeams.data.statusCode == 200) {
                    let val = addedTeams.data.data;
                    handleSwitchTeam(val);
                }
            }
        }
    }, [customerId, teamId, role]);

    if (inProgress === "none") {
        if (!isAuthenticated && !isPrivate) {
            return <Redirect to="/" />;
        }

        if (isAuthenticated && isPrivate) {
            return <Redirect to="/dashboard/charts" />;
        }
        return <Route {...rest} component={Component} />;
    } else {
        return (
            <Switch>
                <Route to="/redirect" component={LinearProgress} />
            </Switch>
        );
    }
}
RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
};
RouteWrapper.defaultProps = {
    isPrivate: false,
};
