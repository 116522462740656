import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
// import {similarObject} from "../listS3bucket/ConversionFunc";
import RecommendationsTable from "./RecommendationTable";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { LinearProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import DynamoDBIcon from "../../images/DynamoDB.svg";
import S3Icon from "../../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import Button from "@material-ui/core/Button";
import recommendation_icon from "../../images/recommended.png";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
import AwsLogo from "../../images/aws_dark.svg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import Color from "../../styles/color";
import Box from "@material-ui/core/Box";
import {bytesToSize}  from "../../util/Miscellaneous";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function AWSS3Recommendations({ title, data, view, subscriptionId, lastUpdated, currency, cards, loading, collapseRef }) {
    const classes = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [progress, setProgress] = useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                // f?.bucketSizeBytes,
                // f?.linkedAWSAccountId,
                // f?.monthlySavings,
                
            ], search);
        }
        return true;
    }

    
    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange
                someref={collapseRef}
                title={title}
                titleIcon={AwsLogo}
                showDateRange={false}
                collapsible={true}
                HeaderTitleComponent={()=>(
                    <div>
                        <img src={S3Icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                        <span>
                            {title}
                        </span>
                    </div>
                )}
                ExtraHeaderComponent={({ period }) =>
                    data != null && Object.keys(data)?.length ? (
                        <div style={{ marginTop: 4 }}>
                            <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                        </div>
                    ) : null
                }
                CenterComponent={()=>(
                    <div>
                     {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name}</h6>:null}
                  </div>
                    )}
                 >
                {({ period }) => (
                    <>
                        {cards && <RecommendationsSummaryCards summary={cards} />}

                        <div className={classes.root}>
                            <Collapse in={totalExpand}>
                                {data != null && data?.data?.length ? (
                                    <Paper className={classes.paper}>
                                      <TableSearch onSearch={setSearch} />

                                        <TableContainer style={{maxHeight:450}}>
                                            <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                <AwsS3Head view={view} subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={data?.data?.length} />
                                                <TableBody>
                                                    {data?.data
                                                     ?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                        return <MyTableRow date={data} view={view} subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10, 25, 50, 75]}
                                            component="div"
                                            count={data?.data?.filter(filteredTable)?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                            </Collapse>
                        </div>
                    </>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        // fontWeight: "bold",
        backgroundColor: Color.tableHeader,
        whiteSpace:'nowrap',

    },
}));

function AwsS3Head({ view }) {

    const classes = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>Bucket Name</TableCell>                     
                {view == "all" ? <TableCell className={classes.tableRow}>AWS Account</TableCell> : null}
                <TableCell className={classes.tableRow}>Size</TableCell>
                <TableCell className={classes.tableRow}>#Objects</TableCell>
                <TableCell className={classes.tableRow}>Cost</TableCell>
                <TableCell className={classes.tableRow}>Current Storage</TableCell>
                <TableCell className={classes.tableRow}>Recommended Storage</TableCell>
                <TableCell className={classes.tableRow}>Potential Savings</TableCell>
                <TableCell className={classes.tableRow}>Action</TableCell>

            </TableRow>
        </TableHead>
    );
}

function generateURL(data){
    if(data){
    window.open(data, "_blank");
}
}

function MyTableRow({date, row, view, index, list, setList, currency }) {
    const [open, setOpen] = useState(true);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <IconButton
                            style={{ cursor: "pointer",marginBottom:8 }}
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                setOpen(!open);
                                setList(index);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>{" "}
                        <span>
                            {row?.resourceId?.length>30 ? 
                             <OverlayTrigger
                             placement="right"
                             overlay={
                                 <Popover id="popover-contained">
                                     <Popover.Content style={{ fontSize: 11 }}>{row?.resourceId}</Popover.Content>
                                 </Popover>
                             }
                         >
                             <div>{row?.resourceId?.substring(0,30)+'...'}</div>
                         </OverlayTrigger>
                            : row?.resourceId}
                        </span> 
                    </div>
                </TableCell>
                {view == "all" ? <TableCell>{row?.linkedAWSAccountId}</TableCell> : null}
                <TableCell>{row?.bucketSizeBytes=='NaN undefined'?'-':row?.bucketSizeBytes}</TableCell>
                <TableCell>{Format.formatNumberInt(row?.numberOfObjects)}</TableCell>
                {/* <TableCell>{row?.getRequests||'-'}</TableCell> */}
                <TableCell>
                {(row?.currentCost>0.01 || row?.currentCost == 0)? Format.formatCurrencySymbol(currency)+Format.formatNumber(row?.currentCost):
                "<"+Format.formatCurrencySymbol(currency)+"0.01"}
                </TableCell>
                <TableCell>{row?.current||'-'}</TableCell>
                <TableCell>{typeof row?.recommendations == "object"?row?.recommendations?.new:'-'}</TableCell>

                <TableCell>
                {typeof row?.recommendations == "object"?
                     <div style={{border: "1px solid #338307",padding:2}}>                  
                    {(row?.monthlySavings>0.01 || row?.monthlySavings == 0)? Format.formatCurrencySymbol(currency)+Format.formatNumber(row?.monthlySavings)+" /mon":
                    "<"+Format.formatCurrencySymbol(currency)+"0.01 /mon"}
                     <br />
                    {(row?.monthlySavings*12>0.01 || row?.monthlySavings*12 == 0)? Format.formatCurrencySymbol(currency)+Format.formatNumber(row?.monthlySavings*12)+" /yr":
                    "<"+Format.formatCurrencySymbol(currency)+"0.12 /yr"}
                      </div>
                    :'-'}
                 </TableCell>
                 <TableCell>
                 {typeof row?.recommendations == "object" ?
                 <Button 
                  onClick={()=>{generateURL(row?.recommendations?.fixIn)}}
                  variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                     Fix in AWS
                 </Button>
                 : <Button 
                 onClick={()=>{generateURL(row?.createFilter?.url)}}
                 variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                    Create Filter
                </Button>}
                 </TableCell>
               
            </TableRow>
            <ExpandLavelData date={date} data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ date, data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable date={date} data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({date, data, currency }) {
    return (
        <>
            <div>
            {typeof data?.recommendations == "object" ?
                "recommendations" in data && Object.keys(data?.recommendations)?.length ? (
                    <>
                       <div style={{display: "flex", flexdirection:'row',marginTop:4}}>
                        <b>Recommendations</b> 
                        <td style={{marginLeft:4}}><span style={{border: "1px solid #338307",margin:2,padding:2}}>{data?.recommendations?.new} </span>
                        </td>
                       </div>
                       
                    </>
                ) : null
                : <div style={{display: "flex", flexdirection:'row',marginTop:4}}>
                {/* <b>Recommendations</b>   */}
                <td style={{marginLeft:4}}><span style={{border: "1px solid #338307",margin:2,padding:2}}>{data?.createFilter?.action} </span>
                </td>
                </div>}
            </div>
        </>
    );
}
