import React, { useState } from "react";
import AWS_LOGO from "../../../images/AWS_LOGO";
import ConnectionHeader from "../../../components/ConnectionHeader";
import axios from "../../../connection/axios";
import LeftArrow from "../../../images/left-arrow.png";
import { Button, Form, InputGroup, Card } from "react-bootstrap";
import { useDispatch,useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import history from "../../../services/history";
import RoutesList from "../../../routes/RoutesList";
import ClipboardJS from "clipboard/dist/clipboard";
import Color from "../../../styles/color";
import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
// import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ReportIcon from '@material-ui/icons/Report';
import "./styles.css";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Paper, CircularProgress, makeStyles, Stepper, Step, StepLabel, StepContent, Typography, StepButton, colors } from "@material-ui/core";
import Copy from "../../../images/copy.svg";
import EditAWSAccount from "./EditAWSAccount";
const { REACT_APP_AWS_ACCOUNT_INSTRUCTIONS, REACT_APP_AWS_CONNECTION,REACT_APP_EDIT_AWS_CONNECTION, REACT_APP_AWS_CLOUD_FORMATION } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        "& > * + *": {
            marginLeft: theme.spacing(2),
            justifyContent: "center",
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
        customLabelStyle: {
            fontSize: "44px",
        },
    },
}));

export default function ConnectAWSAccount(props) {
    const [isArnGenerated, setIsArnGenerated] = useState(false);
    const [connectionLoading, setConnectionLoading] = useState(false);
    const [cloudFormationLoading, setCloudFormationLoading] = useState(false);
    const [s3URL, setS3URL] = useState("");
    const [s3BucketName, setS3BucketName] = useState("");
    const [s3bucketCostreportName, setS3bucketCostreportName] = useState("");
    const [reportPathPrefix, setreportPathPrefix] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [roleArn, setRoleArn] = useState("");
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setalertOpen] = useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const dispatch = useDispatch();

    const [s3BucketError, setS3BucketError] = useState({
        status: false,
        msg: "",
    });
    const [reportNameError, setReportNameError] = useState({
        status: false,
        msg: "",
    });
    const [reportPathPrefixError, setReportPathPrefixError] = useState({
        status: false,
        msg: "",
    });
    const [roleARNError, setRoleARNError] = useState({ status: false, msg: "" });
    const [displayNameError, setDisplayNameError] = useState({
        status: false,
        msg: "",
    });
    const [copySuccess, setCopySuccess] = useState("");
    const classes = useStyles();

    const { customerId } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const uuidAccountId = useSelector((state) => (state.awsConnectionReducer.uuid));

    
    function onConnectionSuccess() {
        // props.onConnectionSuccess();
        // setalertOpen(false);
        setS3BucketName("");
        setS3bucketCostreportName("");
        setreportPathPrefix("");
        setRoleArn("");
        setDisplayName("");
        setS3URL("");
        setIsArnGenerated(false);

        history.push(RoutesList.LINK_ACCOUNTS + "#aws");
        
    }

    function validateFields() {
        var noErrors = true;
        if (!s3BucketName.trim()) {
            setS3BucketError({
                status: true,
                msg: "Please enter the S3 bucket name",
            });
            noErrors = false;
        } else if (!/^[a-z0-9.\-_]{1,255}$/.test(s3BucketName.trim())) {
            setS3BucketError({
                status: true,
                msg: "Bucket name must not contain spaces or uppercase letters",
            });
            noErrors = false;
        }

        if (!s3bucketCostreportName.trim()) {
            setReportNameError({ status: true, msg: "Please enter the Report Name" });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(s3bucketCostreportName.trim())) {
            setReportNameError({
                status: true,
                msg: "Report name must be unique and only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }
        if (!reportPathPrefix.trim()) {
            setReportPathPrefixError({
                status: true,
                msg: "Please enter the Report Path Prefix",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(reportPathPrefix.trim())) {
            setReportPathPrefixError({
                status: true,
                msg: "Report path prefix only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }

        if (!displayName.trim()) {
            setDisplayNameError({
                status: true,
                msg: "Please enter Connection Display Name",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.\-_\s]{3,50}$/.test(displayName.trim())) {
            setDisplayNameError({
                status: true,
                msg: "Only contains alphanumerics and characters",
            });
            noErrors = false;
        }

        if (!roleArn.trim()) {
            setRoleARNError({ status: true, msg: "Please enter Role ARN" });
            noErrors = false;
        } else if (!/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(roleArn.trim())) {
            setRoleARNError({
                status: true,
                msg: "Please enter the valid Role ARN. (arn:aws:iam::account-id:role/role-name-with-path)",
            });
            noErrors = false;
        }
        return noErrors;
    }

    async function onConnect(e) {
        e.preventDefault();
        if (validateFields()) {
            setConnectionLoading(true);
            await axios
                .post(updateStatus?REACT_APP_EDIT_AWS_CONNECTION:REACT_APP_AWS_CONNECTION, {
                    costUsageReports: {
                        s3bucketCostreportName: s3bucketCostreportName.trim(),
                        s3bucketName: s3BucketName.trim(),
                        s3bucketCostreportPathPrefix: reportPathPrefix.trim(),
                    },
                    customerId,
                    displayName: displayName.trim(),
                    roleArn: roleArn.trim(),
                    uuidAccountId: updateStatus?uuidAccountId:null
                })
                .then((response) => response.data)
                .then((response) => {
                    if ("statusCode" in response) {
                        if('uuidAccountId' in response?.data)
                        {
                        dispatch({
                            type:"AWS_CONNECTION_UUID",payload:response?.data?.uuidAccountId
                        })
                        }
                                                     
                        setalertOpen(true);
                        switch (response.statusCode.toString()) {
                            case "200":
                                {
                                    setUpdateStatus(true);
                                    setAlertTitle("success");
                                    setAlertMsg(response.successMessage);

                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 4000);
                                }
                                break;
                            case "3001":
                                {   setUpdateStatus(true);
                                    setAlertTitle("error");
                                    setAlertMsg("Null CustomerID");
                                    // alert("Null CustomerID");
                                }
                                break;
                            case "3005":
                                {   setUpdateStatus(true);
                                    setAlertTitle("warning");
                                    setAlertMsg("Account already exists");

                                    // alert("Account already exists");
                                }
                                break;
                            case "3048":
                                {   setUpdateStatus(true);
                                    setAlertTitle("error");
                                    setAlertMsg("Role arn is invalid");

                                    // alert("Role arn is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3049":
                                {   setUpdateStatus(true);
                                    setAlertTitle("error");
                                    setAlertMsg("Role arn is invalid");

                                    // alert("Role arn is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3050":
                                {   setUpdateStatus(true);
                                    setAlertTitle("error");
                                    setAlertMsg("s3bucketName is invalid");

                                    // alert("s3bucketName is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3051":
                                {   setUpdateStatus(true);
                                    setAlertTitle("error");
                                    setAlertMsg("S3bucket cost and usage report prefix name or report name is invalid");

                                    // alert(
                                    //   "S3bucket cost and usage report prefix name or report name is invalid"
                                    // );
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;
                            case "3045":
                                {   setUpdateStatus(true);
                                    setAlertTitle("error");
                                    setAlertMsg("S3bucket Costreport Name is invalid");

                                    // alert("S3bucket Costreport Name is invalid");
                                    // setTimeout(function () {
                                    //   onConnectionSuccess();
                                    // }, 2000);
                                }
                                break;

                            default: {
                                setAlertTitle("error");
                                setAlertMsg("Please contact your administrator or our support team, support@cloudtrakr.com");

                            }
                        }
                    } else {
                        setAlertTitle("error");
                        setAlertMsg("Please contact your administrator or our support team, support@cloudtrakr.com");
                    }
                })
                .catch((e) => {
                    // console.log(e)
                })
                .finally(() => setConnectionLoading(false));
        }
        e.preventDefault();
    }

    async function onCloudTemplateGenerate(e) {
        e.preventDefault();
        const bucketName = s3BucketName.trim();
        var noErrors = true;
        if (bucketName == "") {
            setS3BucketError({
                status: true,
                msg: "Please enter the S3 bucket name",
            });
            noErrors = false;
        } else if (/^[a-z0-9.\-_]{1,255}$/.test(bucketName) == false) {
            setS3BucketError({
                status: true,
                msg: "Bucket name must not contain spaces or uppercase letters",
            });
            noErrors = false;
        }
        if (!s3bucketCostreportName.trim()) {
            setReportNameError({ status: true, msg: "Please enter the Report Name" });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(s3bucketCostreportName.trim())) {
            setReportNameError({
                status: true,
                msg: "Report name must be unique and only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }

        if (!reportPathPrefix.trim()) {
            setReportPathPrefixError({
                status: true,
                msg: "Please enter the Report Path Prefix",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(reportPathPrefix.trim())) {
            setReportPathPrefixError({
                status: true,
                msg: "Report path prefix only contains alphanumerics and characters ! - _ . * ' ( )",
            });
            noErrors = false;
        }

        if (noErrors) {
            setCloudFormationLoading(true);
            await axios
                .get(REACT_APP_AWS_CLOUD_FORMATION, {
                    params: {
                        customerId,
                        s3BucketName: bucketName,
                    },
                })
                .then((response) => response.data)
                .then((response) => response.data)
                .then((response) => {
                    if (response) {
                        setIsArnGenerated(true);
                        setS3URL(response.s3URL);
                    } else {
                        alert("Something went wrong");
                    }
                })
                .finally(() => setCloudFormationLoading(false));
        }
        e.preventDefault();
    }

    function onCopyToClipboard() {
        var input = document.querySelector("#s3URLField");
        input.select();
        document.execCommand("copy");
        setCopySuccess(" Copied!");
    }

    function onBlurHandle(currentField = null) {
        const bucketName = s3BucketName?.trim() || "";
        var noErrors = 0;
        var noArnErrors = 0;
        if (bucketName == "") {
            noErrors++;
            if (currentField == "s3BucketName")
                setS3BucketError({
                    status: true,
                    msg: "Please enter the S3 bucket name",
                });
        } else if (/^[a-z0-9.\-_]{1,255}$/.test(bucketName) == false) {
            noErrors++;
            if (currentField == "s3BucketName")
                setS3BucketError({
                    status: true,
                    msg: "Bucket name must not contain spaces or uppercase letters",
                });
        }

        if (!s3bucketCostreportName.trim()) {
            noErrors++;
            if (currentField == "s3bucketCostreportName")
                setReportNameError({
                    status: true,
                    msg: "Please enter the Report Name",
                });
        } else if (!/^[a-zA-Z0-9.*!'()\-_]{1,255}$/.test(s3bucketCostreportName.trim())) {
            noErrors++;
            if (currentField == "s3bucketCostreportName")
                setReportNameError({
                    status: true,
                    msg: "Report name must be unique and only contains alphanumerics and characters ! - _ . * ' ( )",
                });
        }
        if (!reportPathPrefix.trim()) {
            noErrors++;
            if (currentField == "reportPathPrefix")
                setReportPathPrefixError({
                    status: true,
                    msg: "Please enter the Report Path Prefix",
                });
        } else if (!/^[a-zA-Z0-9.*!'()\-_/]{1,255}$/.test(reportPathPrefix.trim())) {
            noErrors++;
            if (currentField == "reportPathPrefix")
                setReportPathPrefixError({
                    status: true,
                    msg: "Report path prefix only contains alphanumerics and characters ! - _ . * ' ( )",
                });
        }

        if (!roleArn.trim()) {
            noArnErrors++;
            if (currentField == "roleArn") setRoleARNError({ status: true, msg: "Please enter Role ARN" });
        } else if (!/^arn:aws:iam::\d{12}:role\/[a-zA-Z0-9-_\/]+$/.test(roleArn.trim())) {
            noArnErrors++;
            if (currentField == "roleArn")
                setRoleARNError({
                    status: true,
                    msg: "Please enter the valid Role ARN. (arn:aws:iam::account-id:role/role-name-with-path)",
                });
        }

        if (!displayName.trim()) {
            if (currentField == "displayName")
                setDisplayNameError({
                    status: true,
                    msg: "Please enter Connection Display Name",
                });
        } else if (!/^[a-zA-Z0-9.\-_\s]{3,50}$/.test(displayName.trim())) {
            if (currentField == "displayName")
                setDisplayNameError({
                    status: true,
                    msg: "Only contains alphanumerics and characters",
                });
        }
    }

    // if (props.connectionMode === "edit") return <EditAWSAccount data={props.editData} onBack={props.onBack} onConnectionSuccess={props.onConnectionSuccess} />;

    return (
        <div>
            <Card.Header>      
                <ConnectionHeader connectionTitle={updateStatus?"Edit AWS Account":"Connect AWS Account"} buttonHidden={true} Icon={AWS_LOGO} onConnect={props.onBack} />
            </Card.Header>

            <Paper
                style={{
                    padding: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                <div style={{ width: "50%" }}>
                    <Stepper  orientation="vertical">
                        <Step active={true}>
                            <StepButton style={{ outline: "none" }}>
                                <StepLabel>
                                    <Typography style={{ fontSize: 18, fontWeight: "bold" }} color="black">
                                        Step 1 : Cost and usage report
                                    </Typography>
                                </StepLabel>
                            </StepButton>
                            <StepContent>
                                <div>
                                    <Form style={{ flex: 1, marginBottom: 10 }} onSubmit={(e) => e.preventDefault()}>
                                        <TextField
                                            value={s3BucketName}
                                            label={"S3 Bucket name"}
                                            onBlur={() => onBlurHandle("s3BucketName")}
                                            hasError={s3BucketError.status}
                                            errorMessage={s3BucketError.msg}
                                            onInput={(v) => {
                                                setIsArnGenerated(false);
                                                setS3BucketName(v);
                                                setS3BucketError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter S3 Bucket name"}
                                        />
                                        <TextField
                                            value={s3bucketCostreportName}
                                            label={"Report name"}
                                            onBlur={() => onBlurHandle("s3bucketCostreportName")}
                                            hasError={reportNameError.status}
                                            errorMessage={reportNameError.msg}
                                            onInput={(v) => {
                                                setIsArnGenerated(false);
                                                setS3bucketCostreportName(v);
                                                setReportNameError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Report name"}
                                        />
                                        <TextField
                                            value={reportPathPrefix}
                                            label={"Report path prefix"}
                                            onBlur={() => onBlurHandle("reportPathPrefix")}
                                            hasError={reportPathPrefixError.status}
                                            errorMessage={reportPathPrefixError.msg}
                                            onInput={(v) => {
                                                setIsArnGenerated(false);
                                                setreportPathPrefix(v);
                                                setReportPathPrefixError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Report path prefix"}
                                        />

                                    </Form>
                                </div>

                                <div className={classes.actionsContainer}></div>
                            </StepContent>
                        </Step>
                        <Step active={true}>
                            <StepButton style={{ outline: "none" }}>
                                <StepLabel>
                                    <Typography style={{ fontSize: 18, fontWeight: "bold" }} color="black">
                                        Step 2 : Cross account role
                                    </Typography>
                                </StepLabel>
                            </StepButton>
                            <StepContent>
                                <span>Role is the primary and secure way to grant cross-account access. You grant access to cost and usage report in S3 bucket with restriced read only access.</span>
                                <div>
                                    <Form style={{ flex: 1, marginBottom: 10 }}>
                                        <span>
                                            <a href={REACT_APP_AWS_ACCOUNT_INSTRUCTIONS} target={"_blank"} style={{ color: "#14aeff" }}>
                                                View Instructions
                                            </a>{" "}
                                            to create cross account role using this cloud formation template in your AWS account.{' '}
                                        </span>
                                        <button onClick={onCloudTemplateGenerate} class="btn btn-primary btn-sm" type="submit">
                                            Generate Cloudformation Template
                                        </button>
                                        <br />
                                        <br />
                                        {s3URL ? (
                                            <div style={{ marginTop: 5, marginBottom: 5 }}>
                                                <Form.Label
                                                    style={{
                                                        marginRight: 10,
                                                        fontFamily: "Poppins_SemiBold",
                                                    }}
                                                >
                                                    Here is an unique cloudformation template for your account to provide cross-account role access to collect cost and usage report. <br />
                                                    Copy this S3 URL to create a role in the cloud formation stack
                                                </Form.Label>
                                                <InputGroup style={{ alignItems: "center" }} className="mb-3">
                                                    <Form.Control value={s3URL} id={"s3URLField"} placeholder="AWS S3 URL" aria-label="" aria-describedby="basic-addon1" />
                                                    <InputGroup.Append>
                                                        <img
                                                            onClick={() => {
                                                                new ClipboardJS("#s3URLField");
                                                                onCopyToClipboard();
                                                            }}
                                                            src={Copy}
                                                            alt="copy"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: 8,
                                                            }}
                                                        />{" "}
                                                        {"  "}
                                                        {copySuccess}
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </div>
                                        ) : null}
                                        <TextField
                                            value={roleArn}
                                            onBlur={() => onBlurHandle("roleArn")}
                                            label={"Role ARN"}
                                            hasError={roleARNError.status}
                                            errorMessage={roleARNError.msg}
                                            onInput={(v) => {
                                                setRoleArn(v);
                                                setRoleARNError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Role ARN"}
                                        />
                                        <TextField
                                            disabled={!isArnGenerated}
                                            value={displayName}
                                            onBlur={() => onBlurHandle("displayName")}
                                            label={"Connection Display name"}
                                            hasError={displayNameError.status}
                                            errorMessage={displayNameError.msg}
                                            onInput={(v) => {
                                                setDisplayName(v);
                                                setDisplayNameError({ status: false, msg: "" });
                                            }}
                                            placeholder={"Enter Connection Display name"}
                                        />
                                        <Button disabled={!isArnGenerated} onClick={onConnect} variant={isArnGenerated ? "primary" : "dark"} type="submit">
                                           {updateStatus? 'Save changes' : 'Add AWS Connection'}
                                        </Button>
                                    </Form>
                                </div>
                                <div className={classes.actionsContainer}></div>
                            </StepContent>
                        </Step>

                    </Stepper>
                </div>

                <div
                    style={{
                        width: "50%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems:'center'
                    }}
                >
                 
                    {cloudFormationLoading || connectionLoading ? <CircularProgress /> : alertOpen && <AlertBox title={alertTitle} msg={alertMsg} onclose={() => setalertOpen(false)} />}
                </div>
            </Paper>
        </div>
    );
}

function AlertBox({ title = null, msg = null, onclose }) {
    return title == "success"  ? (
        <Alert
            action={
                <Link to={RoutesList.LINK_ACCOUNTS + "#aws"}>
                    <Button style={{ width: 180 }} color="inherit" size="small">
                    View AWS connection
                    </Button>
                </Link>
            }
            severity={title}
            // style={{ width: "80%" }}
        >
            <AlertTitle>Successfully connected your AWS account.</AlertTitle>
            {msg}
        </Alert>
    ) : (
        <Alert onClose={onclose} severity={title} style={{ width: "60%" }}>
            {/* <AlertTitle>{title}</AlertTitle> */}
            {msg}
        </Alert>
    );
}

function TextField({ onBlur, value, hasError, label, placeholder, onInput, errorMessage, disabled = false }) {
    return (
        <Form.Group controlId="formBasicEmail">
            <Form.Label className={disabled ? "disabled-field" : ""}>
                {label}
                <span style={{color:'red'}}> *</span>
                {label == "Role ARN" ? (
                    <OverlayTrigger
                        placement="right"
                        overlay={
                            <Popover id="popover-contained">
                                <Popover.Content style={{ fontSize: 11 }}>arn:aws:iam::YOUR_AWS_ACCOUNT_ID:role/CloudTrakrAWSIntegrationRole</Popover.Content>
                            </Popover>
                        }
                    >
                        <ReportIcon />
                    </OverlayTrigger>
                ) : null}
            </Form.Label>
            <Form.Control onBlur={onBlur} value={value} disabled={disabled} placeholder={placeholder} onChange={(event) => onInput(event.target.value)} />
            {hasError ? <Form.Text style={{ color: "red" }}>{errorMessage}</Form.Text> : null}
        </Form.Group>
    );
}
