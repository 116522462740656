import React, {useEffect, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import SideBarList from "../components/SideBarList";
import ConnectAWSAccount from "./Accounts/aws/ConnectAWSAccount";
import ListAWSAccount from "./Accounts/aws/ListAWSAccount";
import ConnectGCPAccount from "./Accounts/GCP/ConnectGCPAccount";
import ListGCPAccount from "./Accounts/GCP/ListGCPAccount";
import DashboardChart from "../pages/Charts/DashboardChart";
import SettingsScreen from "../pages/Settings/Settings";
import { Tooltip, OverlayTrigger, CloseButton } from "react-bootstrap";
import {BrowserRouter as Router, Switch, Route, Link, useLocation, useHistory, Redirect} from "react-router-dom";
import {
    Box,
    ButtonGroup,
    Card,
    CircularProgress,
    Fade, Input,
    LinearProgress,
    Modal,
    Paper,
    Select,
    TextareaAutosize
} from "@material-ui/core";
import RoutesList from "../routes/RoutesList";
import AWSAccount from "./Accounts/AWSAccount";
import AZURE_ICON from "../images/Azure_logo.svg";
import Report_Icon from "../images/resources/Recovery_Services_Backup_Report.svg";
import AWS_ICON from "../images/aws_dark.svg";
import GCP_ICON from "../images/GCP_logo.svg";
import BUDGET_ICON from "../images/budget_calculator.svg";
import SERVERLESS_ICON from "../images/light.svg";
import RECOMMENDATON_ICON from "../images/like.svg";
// import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import clsx from "clsx";
import { fade, makeStyles, useTheme } from "@material-ui/core/styles";
import { ListItemIcon, ListItemText, ListItem, Drawer, AppBar, Toolbar, List, CssBaseline, Typography, Divider, Menu as MenuElement, MenuItem } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {
    Menu,
    Settings,
    Help,
    Person,
    MessageSharp,
    EmojiEmotions,
    SentimentVeryDissatisfied,
    SentimentSatisfiedAlt,
    Assessment,
    Receipt, KeyboardArrowDown, SearchOutlined, ThumbUp,
} from "@material-ui/icons";
import Budgets from "../pages/Budgets/Budgets";
import LinkAccounts from "./Accounts/NewLinkAccount";
import AzureHome from "../pages/DashboardAccountsHome/AzureHome";
import GcpHome from "../pages/DashboardAccountsHome/GcpHome";
import MsalReactAuth from "MsalReactAuth";
import Invoices from "../pages/invoice/Invoices";
import Button from "@material-ui/core/Button";
import Colors from "../styles/color";
import Badge from "@material-ui/core/Badge";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MailIcon from "@material-ui/icons/Mail";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import AWSHome from "./DashboardAccountsHome/AWSHome";
import AddAzureAccount from "../pages/Accounts/AddAzureAccount";
import DropDownMenu from "../pages/dropDownMenu";
import MessagesBox from "./MessagesBox";
import Popover from "@material-ui/core/Popover";
import Apple_Store from "../images/apple_store.svg";
import Reports from "./reports/Reports";
import { SnackbarProvider, useSnackbar } from "notistack";
import QRICON from "../images/qr_code_icon.svg";
import QRCODE from "../images/qr_code.svg";
import { IdToken } from "msal/lib-commonjs/IdToken";
import {GET_CUSTOMER_STATUS_REQUEST, GET_WHITE_LABEL_REQUEST} from "../redux/actions/types";
import default_logo from "../images/app_logo.png";
import { Alert } from "@material-ui/lab";
import axios from "../connection/axios";
import ALL_DASHBOARD_ICON from "../images/newCloudTrakrIcons/MultiCloudIcon.png";
import DisabledScreen from "./DisabledScreen";
import {CUSTOMER_STATUS} from "../util/AppConstants";
import * as types from "../redux/actions/types";
import DashboardLoader from "components/loaders/DashboardLoader";
import Backdrop from "@material-ui/core/Backdrop";
import SearchBox from "../components/SearchBox";
import hotkeys from "hotkeys-js";
import {showSnackBar} from "./MessagesBox";
// import RecommendationsMain from "./RecommendationsMain";
const { REACT_APP_GIVE_FEEDBACK, REACT_APP_PRIVACY_POLICY, REACT_APP_GET_USER_MANAGEMENT, REACT_APP_GET_INVITED_TEAMS } = process.env;

const play_store_url = "https://play.google.com/store/apps/details?id=com.cloudcostmanagementapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
const app_store_url = "https://apps.apple.com/app/id1519053095";
const routes = [
    {
        path: "/dashboard/charts",
        exact: true,
        sidebar: () => <SideBarList />,
        main: () => <DashboardChart />,
    },
    {
        path: RoutesList.AZURE_HOME,
        exact: true,
        sidebar: () => <SideBarList />,
        main: () => <AzureHome />,
    },
    {
        path: "/dashboard/azure/add",
        sidebar: () => <SideBarList />,
        main: () => <AddAzureAccount />,
    },
    {
        path: RoutesList.AWS_ACCOUNT,
        sidebar: () => <SideBarList />,
        main: () => <AWSAccount />,
    },
    {
        path: RoutesList.AWS_HOME,
        sidebar: () => <SideBarList />,
        main: () => <AWSHome />,
    },
    // {
    //   path: "/dashboard/aws/connect",
    //   sidebar: () => <SideBarList />,
    //   main: () => <ConnectAWSAccount />,
    // },
    // {
    //   path: "/dashboard/aws/list",
    //   sidebar: () => <SideBarList />,
    //   main: () => <ListAWSAccount />,
    // },
    {
        path: "/dashboard/gcp/connect",
        // sidebar: () => <SideBarList />,
        main: () => <ConnectGCPAccount />,
    },
    {
        path: RoutesList.GCP_HOME,
        exact: true,
        sidebar: () => <SideBarList />,
        main: () => <GcpHome />,
    },
    {
        path: "/dashboard/budgets",
        main: () => <Budgets />,
    },
    {
        path: "/dashboard/invoice",
        main: () => <Invoices />,
    },
    {
        path: "/dashboard/Reports",
        main: () => <Reports />,
    },

    {
        path: "/dashboard/gcp/accountlist",
        // sidebar: () => <SideBarList />,
        main: () => <ListGCPAccount />,
    },
    {
        path: "/dashboard/settings",
        // sidebar: () => <SideBarList />,
        main: () => <SettingsScreen />,
    },
    {
        path: "/dashboard/linkAccounts",
        // sidebar: () => <SideBarList />,
        main: () => <LinkAccounts />,
    },

    // {
    //     path: RoutesList.PRICING,
    //     // sidebar: () => <SideBarList />,
    //     main: () => <Pricing />,
    // },
    {
        path: RoutesList.TRIAL_EXPIRED,
        // sidebar: () => <SideBarList />,
        main: () => <DisabledScreen />,
    },
    // {
    //     path: RoutesList.RECOMMENDATIONS,
    //     // sidebar: () => <SideBarList />,
    //     main: () => <RecommendationsMain />,
    // },
];

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        // marginLeft: drawerWidth,
        // width: calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        // padding: theme.spacing(2),
    },
    nested: {
        paddingLeft: theme.spacing(2),
    },

    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

const headerHeight = 40;

export default function App() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [upgradeModalOpened, setUpgradeModalOpened] = useState(false);
    const [searchOpened, setSearchOpened] = useState(false);
    const [fKeyPressedTime, setFKeyPressedTime] = useState(null);
    const location = useLocation()?.pathname;
    const history = useHistory();
    const mybutton = useRef();
    const locationPath = window.location.pathname;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function rand() {
        return Math.round(Math.random() * 20) - 10;
    }

    function getModalStyle() {
        const top = 50 + rand();
        const left = 50 + rand();

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const { customerId, customerStatus, dispatchedStatus, dispatchedStatusCount, load } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        customerStatus: state.userDetailsReducer.customerStatus,
        dispatchedStatus: state.userDetailsReducer.dispatchedStatus,
        dispatchedStatusCount: state.userDetailsReducer.dispatchedStatusCount,
        load: state.userDetailsReducer.loading
    }));
    const dispatch = useDispatch();
    var loading = !!!MsalReactAuth().loading;


    useEffect(() => {
        if (customerId) {
            dispatch({
                type: GET_WHITE_LABEL_REQUEST,
                payload: customerId,
            });
        }
    }, [customerId]);

    // console.log(trialExpired,    location != RoutesList.TRIAL_EXPIRED)
    // useEffect(()=>{
    //     console.log({customerStatus});
    //     if(customerStatus == CUSTOMER_STATUS.INACTIVE && locationPath != RoutesList.TRIAL_EXPIRED) {
    //         mybutton.current?.click();
    //     }
    // }, [customerStatus, locationPath]);

    useEffect(()=>{
        if(dispatchedStatusCount < 10 && customerId && !((customerStatus) && dispatchedStatus)){
            setTimeout(()=>{
                dispatch({
                    type: GET_CUSTOMER_STATUS_REQUEST,
                    payload: {
                        customerId,
                        dispatch
                    }
                })
            }, 2000);
        }
    }, [customerStatus, dispatchedStatus, customerId])

    // useEffect(()=>{
    //     console.log("TEX",{trialExpired, locationPath});
    //     if(trialExpired && locationPath != RoutesList.TRIAL_EXPIRED){
    //         history.push(RoutesList.TRIAL_EXPIRED);
    //     }
    // }, [trialExpired, locationPath]);

    // function keyhandlerLoaded(e){
    //     console.log("KEYPRESS this", e);
        // window.$(document).ready(()=>{
        //     console.log("KEYPRESS this", e);
        //     // console.log("KEYPRESS")
        //     // const root = ;
        //     // const root2 = document.querySelector('#root_component');
        //     // console.log("KEYPRESS", root, root2);
        //     document.querySelector('#root_component')
        //         .addEventListener('keypress', (e)=>console.log("KEYPRESS this", e))
        //     document.querySelector('#root_component')
        //         .addEventListener('keydown', (e)=>console.log("KEYDOWN this", e))
        //     window.$('#root_component').keypress(function (e){
        //         console.log("KEYPRESS this", e);
        //         // window.$(this).keypress((e,j,jk)=>{
        //         //     console.log("KEYPRESS", {e,j,jk});
        //         // })
        //     })
        // })
    // }

    useEffect(()=>{
        hotkeys('f', function(event, handler){
            event.preventDefault();
            setSearchOpened(true);
            // const currentTime =  +new Date();
            // console.log("KEY_DIFF", {handler, currentTime, fKeyPressedTime, diff: currentTime - (fKeyPressedTime || 0)});
            // if(fKeyPressedTime && (currentTime - fKeyPressedTime)<1000){
            //     setFKeyPressedTime(null);
            //     setSearchOpened(true);
            // } else {
            //     console.log("KEY_DIFF2");
            //     setFKeyPressedTime(currentTime);
            // }
        });
    }, []);

    return loading ? (
        <Router>
            <div className={classes.root}>
                {/*<input style={{position: 'absolute'}} autoFocus={true} onKeyPress={keyhandlerLoaded}/>*/}
                {/* <CssBaseline /> */}
                <SearchBox handleClose={()=>setSearchOpened(false)} open={searchOpened} classes={classes} />
                <SnackbarProvider>
                    <TopHeader customerStatus={customerStatus} searchOptions={{open: searchOpened, setOpen: setSearchOpened}}
                               history={history}
                               handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} onUpgrade={() => setUpgradeModalOpened(!upgradeModalOpened)} customerId={customerId} />
                    <CustomDrawer open={open} handleDrawerClose={handleDrawerClose} />
                    <main className={classes.content}>
                        <div
                            className={classes.toolbar}
                            style={{
                                height: headerHeight,
                                maxHeight: headerHeight,
                                minHeight: headerHeight,
                            }}
                        />
                        <Link to={RoutesList.TRIAL_EXPIRED} style={{display: 'none'}} ref={mybutton}>
                            <Button>
                                <span>.</span>
                            </Button>
                        </Link>
                        <Switch>
                            {
                                // locationPath == RoutesList.TRIAL_EXPIRED && showPricingModal?
                                //     <Pricing showCloseButton={true} />:
                                // (customerStatus && paymentStatus)?
                                (customerStatus)?
                                customerStatus == CUSTOMER_STATUS.INACTIVE?
                                    (<DisabledScreen customerStatus={customerStatus}/>)
                                    :routes.map((route, index) => (
                                        <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
                                    )):
                                    dispatchedStatusCount < 10?
                                        <Box height={window.innerHeight} display={"flex"} flexDirection={"column"} flex={1} justifyContent={"center"} alignItems={"center"}>
                                            {/* <CircularProgress size={150} thickness={1} />
                                            <Typography variant={"h5"} style={{marginTop: 20, top: "50%" }}>
                                                Loading
                                            </Typography> */}
                                             <DashboardLoader/>
                                        </Box>:
                                        <Box height={window.innerHeight} display={"flex"} flexDirection={"column"} flex={1} justifyContent={"center"} alignItems={"center"}>
                                            <Typography variant={"h5"} style={{marginTop: 20, top: "50%" }}>
                                                Something went wrong. Please try again later.
                                            </Typography>
                                            <Button style={{ marginTop: 8, width: 120 }} color="primary" variant="contained" onClick={()=>window.location.reload()}>
                                                Refresh
                                            </Button>
                                        </Box>
                            }
                        </Switch>
                    </main>
                </SnackbarProvider>
            </div>
        </Router>
    ) : (
       <LinearProgress/>
    );
}

function CustomDrawer({ open, handleDrawerClose }) {
    const classes = useStyles();
    const location = useLocation();

    const [open2, setOpen] = React.useState(true);
    const [open3, setOpen1] = React.useState(true);

    const handleClick1 = () => {
        // alert(open2);
        setOpen(!open2);
    };
    const handleClick2 = () => {
        // alert(open2);
        setOpen1(!open3);
    };
    const route = window.location.pathname;

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                }),
            }}
        >
            <div id={"drawer_container"} style={{ marginTop: headerHeight }}>

                <Divider />
                <List>
                    {[
                        {
                            name: "Multi Cloud",
                            icon: ALL_DASHBOARD_ICON,
                            path: RoutesList.CHARTS,
                            selectionColor: Colors.background,
                        },
                        {
                            name: "Azure",
                            icon: AZURE_ICON,
                            path: RoutesList.AZURE_HOME,
                            selectionColor: Colors.background,
                        },
                        {
                            name: "AWS",
                            icon: AWS_ICON,
                            path: RoutesList.AWS_HOME,
                            selectionColor: Colors.background,
                        },
                        {
                            name: "GCP",
                            icon: GCP_ICON,
                            path: RoutesList.GCP_HOME,
                            selectionColor: Colors.background,
                        },
                    ].map(({ name, icon, path, selectionColor }, index) => (
                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">{name}</Tooltip>}>
                            <Link to={path}>
                                <ListItem
                                    selected={location == path}
                                    button
                                    key={name}
                                    style={{
                                        backgroundColor: path === route ? selectionColor : "",
                                    }}
                                >
                                    <ListItemIcon>
                                        <img src={icon} height={20} />
                                    </ListItemIcon>
                                    <ListItemText primary={name} style={{ color: "", textDecoration: "none" }} />
                                </ListItem>
                            </Link>
                        </OverlayTrigger>
                    ))}
                </List>
                {/*<Divider />*/}
                {/*<List>*/}
                {/*    {[*/}
                {/*        {*/}
                {/*            name: "Recommendations",*/}
                {/*            Icon: ThumbUp,*/}
                {/*            path: RoutesList.RECOMMENDATIONS,*/}
                {/*            selectionColor: Colors.background,*/}
                {/*        },*/}
                {/*    ].map(({ name, Icon, path, selectionColor }, index) => (*/}
                {/*        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">{name}</Tooltip>}>*/}
                {/*            <Link to={path}>*/}
                {/*                <ListItem*/}
                {/*                    selected={location == path}*/}
                {/*                    button*/}
                {/*                    key={name}*/}
                {/*                    style={{*/}
                {/*                        backgroundColor: path === route ? selectionColor : "",*/}
                {/*                    }}*/}
                {/*                >*/}
                {/*                    <ListItemIcon>*/}
                {/*                        <Icon />*/}
                {/*                    </ListItemIcon>*/}
                {/*                    <ListItemText primary={name} />*/}
                {/*                </ListItem>*/}
                {/*            </Link>*/}
                {/*        </OverlayTrigger>*/}
                {/*    ))}*/}
                {/*</List>*/}

                <Divider />
                <List style={{}}>
                    {[
                        {
                            name: "Budgets",
                            Icon: () => <img src={BUDGET_ICON} height={25} />,
                            path: "/dashboard/budgets",
                            selectionColor: Colors.background,
                        },
                        {
                            name: "Reports",
                            Icon: Assessment,
                            path: "/dashboard/Reports",
                            selectionColor: Colors.background,
                        },
                        // {
                        //     name: "Upload invoices",
                        //     Icon: Receipt,
                        //     path: "/dashboard/invoice",
                        //     selectionColor: Colors.background,
                        // }
                    ].map(({ name, Icon, path, selectionColor }, index) => (
                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">{name}</Tooltip>}>
                            <Link to={path}>
                                <ListItem
                                    selected={location == path}
                                    button
                                    key={name}
                                    style={{
                                        backgroundColor: path === route ? selectionColor : "",
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>

                                    <ListItemText primary={name} style={{ color: "", textDecoration: "none" }} />
                                </ListItem>
                            </Link>
                        </OverlayTrigger>
                    ))}
                </List>
                {/*<Divider />*/}
                <Divider />

                <List>
                    {[
                        {
                            name: "Accounts",
                            Icon: AccountBalanceOutlinedIcon,
                            path: RoutesList.LINK_ACCOUNTS,
                            selectionColor: Colors.background,
                        },
                    ].map(({ name, Icon, path, selectionColor }, index) => (
                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">{name}</Tooltip>}>
                            <Link to={path}>
                                <ListItem
                                    selected={location == path}
                                    button
                                    key={name}
                                    style={{
                                        backgroundColor: path === route ? selectionColor : "",
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={name} />
                                </ListItem>
                            </Link>
                        </OverlayTrigger>
                    ))}
                </List>

                <Divider />

                <List>
                    {[
                        // {
                        //     name: "Pricing",
                        //     Icon: () => <img src={PricingIcon} height={25} />,
                        //     path: RoutesList.PRICING,
                        //     selectionColor: Colors.background,
                        // },
                        {
                            name: "Settings",
                            Icon: Settings,
                            path: RoutesList.SETTINGS,
                            selectionColor: Colors.background,
                        },
                    ].map(({ name, Icon, path, selectionColor }, index) => (
                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">{name}</Tooltip>}>
                            <Link to={path}>
                                <ListItem
                                    selected={location == path}
                                    button
                                    key={name}
                                    style={{
                                        backgroundColor: path === route ? selectionColor : "",
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icon />
                                    </ListItemIcon>
                                    <ListItemText primary={name} />
                                </ListItem>
                            </Link>
                        </OverlayTrigger>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}

function TopHeader({ handleDrawerClose, handleDrawerOpen, open, onUpgrade, customerId, customerStatus, history, searchOptions }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
    const [QRpopoverAnchorEl, setQRPopoverAnchorEl] = React.useState(null);

    const [auth, setAuth] = React.useState(true);
    const openProfile = Boolean(anchorEl);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu anchorEl={anchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={menuId} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={isMenuOpen} onClose={handleMenuClose}>
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu anchorEl={mobileMoreAnchorEl} anchorOrigin={{ vertical: "top", horizontal: "right" }} id={mobileMenuId} keepMounted transformOrigin={{ vertical: "top", horizontal: "right" }} open={isMobileMenuOpen} onClose={handleMobileMenuClose}>
            <MenuItem>
                <IconButton aria-label="show 4 new mails" color="inherit">
                    <Badge badgeContent={4} color="secondary">
                        <MailIcon />
                    </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton aria-label="show 11 new notifications" color="inherit">
                    <Badge badgeContent={11} color="secondary">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true" color="inherit">
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );
    const userData = useSelector((state) => ({
        name: state.userDetailsReducer.name,
        email: state.userDetailsReducer.email,
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        companyName: state.userDetailsReducer.companyName,
        companyLogo: state.userDetailsReducer.companyLogo,
    }));

    const messagesOpened = Boolean(popoverAnchorEl);
    const popover_id = messagesOpened ? "messages-popup" : undefined;
    const [feedbackModalState, setFeedbackModalState] = useState(false);

    const openQR = Boolean(QRpopoverAnchorEl);
    const QRid = open ? "simple-popover" : undefined;
    const userAuth = MsalReactAuth();
    const [currentUsername, setCurrentUsername] = useState(null);

    function onFeedbackClose() {
        setFeedbackModalState(false);
    }

    function onFeedbackSubmit(data) {
        axios
            .post(REACT_APP_GIVE_FEEDBACK, {
                ...data,
                customerId,
                customerEmail: userData.email,
            })
            .then((r) => r.data)
            .then((r) => {
                showSnackBar(enqueueSnackbar, closeSnackbar, {
                    autoHideDuration: 2000,
                }).info("Feedback submitted successfully");
            })
            .catch((e) => {
                showSnackBar(enqueueSnackbar, closeSnackbar, {
                    autoHideDuration: 2000,
                }).error("Something went wrong!");
            })
            .finally(() => setFeedbackModalState(false));
    }

    useEffect(() => {
        if (userData.companyLogo || userData.companyName) {
            document.querySelector("link[rel~='icon']").href = userData.companyLogo || default_logo;
            window.document.title = userData.companyName || "CloudTrakr";
        }
    }, [userData.companyLogo, userData.companyName]);

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            style={{ maxHeight: headerHeight }}
        >
            <Toolbar id={"hello"} style={{ minHeight: headerHeight, maxHeight: headerHeight, padding: 0 }}>
                <img src={userData?.companyLogo || default_logo} height={30} style={{ paddingLeft: 15, paddingRight: 15 }} />

                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // border: '1px solid '
                    }}
                >
                    <div style={{ display: "flex", flex: 6, alignItems: "center" }}>
                        {/*<IconButton*/}
                        {/*  color="inherit"*/}
                        {/*  aria-label="open drawer"*/}
                        {/*  // onClick={open?handleDrawerClose:handleDrawerOpen}*/}
                        {/*  onClick={() => {}}*/}
                        {/*  edge="start"*/}
                        {/*  style={{ display: 'hidden', visibility: "hidden", outline: "none" }}*/}
                        {/*  // className={clsx(classes.menuButton, {*/}
                        {/*  //   [classes.hide]: open,*/}
                        {/*  // })}*/}
                        {/*>*/}
                        {/*  <Menu />*/}
                        {/*</IconButton>*/}
                        <div>
                        <Typography variant="h6" noWrap>
                            {userData?.companyName || "CloudTrakr"}
                        </Typography>
                        </div>
                        {/*<div style={{ marginLeft: 20 }}>*/}
                        {/*    <PaymentHeader />*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*    <span style={{color: 'white', fontSize: 12}}>*/}
                        {/*        <span style={{backgroundColor: 'red'}}>*/}
                        {/*            CUSTOMER_STATUS: {customerStatus}*/}
                        {/*        </span>*/}
                        {/*        <span style={{backgroundColor: 'green', paddingLeft: 10}}>*/}
                        {/*            PAYMENT_STATUS: {paymentStatus}*/}
                        {/*        </span>*/}
                        {/*        <span style={{backgroundColor: 'orange', paddingLeft: 10}}>*/}
                        {/*            PAYMENT_MODEL: {paymentModel}*/}
                        {/*        </span>*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div style={{ display: 'flex', flex: 3, marginLeft: 60 }}>*/}
                    {/*</div>*/}
                    <div style={{ flex: 6, display: "flex", justifyContent: "flex-end" }}>
                        <div className={classes.grow} />
                        <div className={classes.sectionDesktop}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            {/*<div style={{  }}>*/}
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.cloudcostmanagementapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                                    target="_blank"
                                >
                                    <img height={45} alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
                                </a>
                                <a href="https://apps.apple.com/app/id1519053095" target="_blank">
                                    <img src={Apple_Store} width={140} height={30} />
                                </a>
                            {/*</div>*/}
                                <IconButton style={{outline: 'none'}} onClick={(event) =>searchOptions?.setOpen(!searchOptions?.open)} aria-describedby={popover_id} aria-label="show 4 new mails" color="inherit">
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Search</Tooltip>}>
                                        <Badge badgeContent={0} color="secondary">
                                            <SearchOutlined style={{ fontSize: 20 }} />
                                        </Badge>
                                    </OverlayTrigger>
                                </IconButton>
                            <a alt="Settings" href="https://docs.cloudtrakr.com/" target="_blank">
                                <IconButton aria-describedby={popover_id} color="inherit" style={{outline: 'none'}}>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Help</Tooltip>}>
                                        <Badge badgeContent={0} color="secondary">
                                            <Help style={{fontSize:20, color: "white" }} />
                                        </Badge>
                                    </OverlayTrigger>
                                </IconButton>
                            </a>

                            <IconButton style={{outline: 'none'}} onClick={(event) => setFeedbackModalState(true)} aria-describedby={popover_id} aria-label="show 4 new mails" color="inherit">
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Feedback</Tooltip>}>
                                    <Badge badgeContent={0} color="secondary">
                                        <EmojiEmotions style={{ fontSize: 20 }} />
                                    </Badge>
                                </OverlayTrigger>
                            </IconButton>
                            <IconButton  style={{outline: 'none', display: customerStatus == CUSTOMER_STATUS.ACTIVE?'':'none'}} onClick={(event) => setPopoverAnchorEl(event.currentTarget)} aria-describedby={popover_id} aria-label="show 4 new mails" color="inherit">
                                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Notifications</Tooltip>}>
                                    <Badge badgeContent={0} color="secondary">
                                        <MessageSharp style={{ fontSize: 20 }} />
                                    </Badge>
                                </OverlayTrigger>
                            </IconButton>
                            <Modal
                                open={feedbackModalState}
                                onClose={onFeedbackClose}
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                style={{
                                    outline: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <FeedbackComponent onFeedbackSubmit={onFeedbackSubmit} onClose={onFeedbackClose} />
                            </Modal>
                            <Popover
                                id={popover_id}
                                open={messagesOpened}
                                anchorEl={popoverAnchorEl}
                                onClose={() => setPopoverAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <MessagesBox />
                            </Popover>

                            <IconButton style={{outline: 'none'}} edge={"end"} aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                                <OverlayTrigger style={{outline: 'none'}} placement="bottom" overlay={<Tooltip id="button-tooltip">Profile</Tooltip>}>
                                    <Person style={{ outline: "none", color: "white", fontSize: 20 }} color={"#FFF"} />
                                </OverlayTrigger>
                                <span style={{fontSize: 14}}>
                                    {" "}{
                                    currentUsername || (userData.name && userData.name?.toString()?.trim() ? userData.name : userData.email)}
                                    </span>
                                <KeyboardArrowDown style={{outline: 'none'}} />
                            </IconButton>
                            <MenuElement
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={openProfile}
                                onClose={handleClose}
                            >
                                <TeamMembers setUsername={setCurrentUsername} custId={customerId} userData={userData} history={history} />
                                <Divider/>
                                <Link to={RoutesList.SETTINGS} style={{textDecoration: 'none', color: 'black'}}>
                                    <MenuItem>
                                        Settings
                                    </MenuItem>
                                </Link>
                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        userAuth.LogoutHandler();
                                    }}
                                >
                                    Logout
                                </MenuItem>
                            </MenuElement>

                            {/*<IconButton style={{outline: 'none'}} onClick={(event) => setQRPopoverAnchorEl(event.currentTarget)} aria-describedby={IdToken}>*/}
                            {/*    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Scan to download</Tooltip>}>*/}
                            {/*        <img src={QRICON} alt="QRICON" style={{ width: 22, height: 20, marginLeft: 4 }} />*/}
                            {/*    </OverlayTrigger>*/}
                            {/*</IconButton>*/}
                            {/*<Popover*/}
                            {/*    id={QRid}*/}
                            {/*    open={openQR}*/}
                            {/*    anchorEl={QRpopoverAnchorEl}*/}
                            {/*    onClose={() => setQRPopoverAnchorEl(null)}*/}
                            {/*    anchorOrigin={{*/}
                            {/*        vertical: "bottom",*/}
                            {/*        horizontal: "right",*/}
                            {/*    }}*/}
                            {/*    transformOrigin={{*/}
                            {/*        vertical: "top",*/}
                            {/*        horizontal: "right",*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <paper*/}
                            {/*        elevation={3}*/}
                            {/*        style={{*/}
                            {/*            width: 200,*/}
                            {/*            height: 300,*/}
                            {/*            justifyContent: "center",*/}
                            {/*            display: "flex",*/}
                            {/*            backgroundColor: "#eee",*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        /!* <span> Scan QR code to install the app</span> *!/*/}
                            {/*        <img src={QRCODE} alt="QRCODE" style={{ width: "80%" }} />*/}
                            {/*    </paper>{" "}*/}
                            {/*</Popover>*/}
                            </div>
                        </div>

                        <div className={classes.sectionMobile}>
                            <IconButton aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    );

    function openMessages() {}
}

function TeamMembers({userData, history, custId, setUsername}){
    const [ownerTeam, setOwnerTeam] = useState(null);
    const dispatch = useDispatch();

    const {customerId, teamList} = useSelector((state) => ({
        customerId: state.userDetailsReducer.id,
        teamList: state.connectedTeamsReducer.teamsList || []
    }));


    const handleChangeTeam = (t) => {
        if(t.value == custId) return;
        dispatch({ type: "SWITCH_TEAM_CLEAR" });
        dispatch({ type: types.SWITCH_TEAM, payload: { id: t.value, role: t.role } });
        history.push(RoutesList.CHARTS);
    };

    useEffect(() => {
        if(customerId) {
            dispatch({
                type: types.GET_CONNECTED_TEAMS_LIST_REQUEST,
                payload: customerId
            })
            const asyncFunc = async () => {
                const data = await axios.get(REACT_APP_GET_USER_MANAGEMENT, {params: {customerId}}).then(d=>d.data);
                if(data){
                    setOwnerTeam(data?.data);
                }
            };
            asyncFunc();
        }
    }, [customerId]);

    useEffect(()=>{
        if(teamList && teamList.length){
            const found  = teamList.find(t=>t.value==custId);
            if(found){
                setUsername(found.label);
            } else {
                setUsername(null)
            }
        }
    }, [teamList, custId])

    return (
        <>
            <span style={{color: 'grey', paddingLeft: 10, fontSize: 14}}>Select Team</span>
            {ownerTeam?.team_name?
                <MenuItem selected={customerId == custId}
                       onClick={() => handleChangeTeam({label: "Default account", value: customerId, role: "Owner"})}>
                    <div>
                        <span>{userData.name && userData.name?.toString()?.trim() ? userData.name : userData.email}</span>
                        <br/>
                        <span style={{color: '#afafaf', fontSize: 13}}>({ownerTeam?.team_name})</span>
                    </div>
                </MenuItem>:
                <MenuItem selected={customerId == custId} onClick={() => handleChangeTeam({label: "Default account", value: customerId, role: "Owner"})}>
                    {userData.name && userData.name?.toString()?.trim() ? userData.name : userData.email}
                </MenuItem>
            }
            {teamList.map((t)=>(
                <MenuItem selected={t.value==custId} onClick={()=>handleChangeTeam(t)}>{t.label}</MenuItem>
            ))}
            <Divider/>
            <MenuItem>
                <Link to={RoutesList.SETTINGS}>
                    Create / Join Team
                </Link>
            </MenuItem>
        </>
    )
}

function FeedbackComponent({ onClose, onFeedbackSubmit }) {
    const [feedbackType, setFeedbackType] = useState("general_feedback");
    const [experience, setExperience] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const HAPPY = "yes";
    const SAD = "no";
    function onSubmit() {
        if (experience && feedbackMessage.toString().length < 5000) {
            onFeedbackSubmit({
                feedbackType,
                experience,
                feedbackMessage,
            });
        }
    }

    return (
        <Paper style={{ width: "36%", outline: "none", backgroundColor: "#cfdac7" }}>
            <Box
                style={{
                    padding: 10,
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant={"h5"} style={{}}>
                    Feedback
                </Typography>
                <CloseButton style={{ outline: "none" }} onClick={onClose} />
            </Box>
            <Box style={{ padding: 10, backgroundColor: "white" }}>
                <Typography>Thank you for taking the time to provide feedback.</Typography>
                <hr />
                <Typography style={{ marginBottom: 10 }}>
                    <b>
                        <span style={{ color: "#ff5501" }}>*</span>Are you satisfied with your experience?
                    </b>
                </Typography>
                <Box component={"div"} display={"flex"}>
                    <SentimentSatisfiedAlt
                        onClick={() => setExperience(HAPPY)}
                        style={{
                            cursor: "pointer",
                            fill: experience == HAPPY ? "#0027ff" : null,
                        }}
                        fontSize={"large"}
                        height={50}
                    />
                    <SentimentVeryDissatisfied
                        onClick={() => setExperience(SAD)}
                        style={{
                            cursor: "pointer",
                            fill: experience == SAD ? "#0027ff" : null,
                        }}
                        fontSize={"large"}
                        height={50}
                    />
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} style={{ marginTop: 10 }}>
                    <Typography aria-label={"demo-simple-select-label"}>
                        <b>Choose feedback type:</b>
                    </Typography>
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={feedbackType} onChange={(e) => setFeedbackType(e.target.value)}>
                        <MenuItem value={"general_feedback"}>General feedback</MenuItem>
                        <MenuItem value={"report_an_issue"}>Report an issue</MenuItem>
                        <MenuItem value={"ui_feedback"}>UI feedback</MenuItem>
                        <MenuItem value={"feature_request"}>Feature request</MenuItem>
                    </Select>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column" }}>
                    <TextareaAutosize rowsMax={8} rows={5} style={{ width: "100%", padding: 5, marginTop: 10 }} aria-label="maximum height" placeholder="Enter your message" maxLength={5000} onChange={(e) => setFeedbackMessage(e.target.value)} />
                    {feedbackMessage?.toString().length > 5000 && (
                        <div style={{ alignSelf: "flex-end" }}>
                            <span style={{ color: "red", fontSize: 14 }}>Max 5000 characters</span>
                        </div>
                    )}
                </Box>
                <Box style={{ marginTop: 10, marginBottom: 10 }}>
                    <Alert severity="info">
                        If you need help, please contact <a href={"mailto: support@cloudtrakr.com?subject=General feedback"}>support@cloudtrakr.com</a>
                    </Alert>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <a href={REACT_APP_PRIVACY_POLICY} target={"_blank"}>
                        <span style={{ fontSize: 14 }}>Privacy policy</span>
                    </a>
                    <Button onClick={onSubmit} style={{ textTransform: "none" }} title={"Submit Feedback"} disabled={!experience} variant={"contained"} color={"primary"}>
                        Submit feedback
                    </Button>
                </Box>
            </Box>
        </Paper>
    );
}
