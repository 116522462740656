import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    allHomeRequest,
    accountRequest,
    getConnectionErrorRequest,
    getAwsRecommendationSummaryRequest,
    getSubscriptionsRequest
} from "../../redux/actions/actions";
import "./style.css";
import ALL_CLOUD_LOGO from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import AZURE_LOGO from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_LOGO from "../../images/GCP_logo.svg";
import CostHeader, { CostHeaderDataComponent } from "../../components/CostHeader";
import Colors from "../../styles/color";
import ConenctAccountSkipPage from "pages/ConnectAccountSkipPage";
import Format from "components/NumberFormat";
import { Box, Paper } from "@material-ui/core";
import axios from "../../connection/axios";
import { AzureManagementGroupFormatter, IndividualMgmtCost, MangementTotalCost } from "components/table/AzureManagementTable";
import ReactDrillDownd3BarChart from "components/D3Charts/ReactDrillDownd3BarChart";
import DashboardTabs from "./DashboardTabs";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import ConnectionError from "../../components/ConnectionError";
import NO_DATA_AVAILABLE from "components/NO_DATA_AVAILABLE";
import DashboardLoader from "components/loaders/DashboardLoader";

import ReactPlayer from "react-player";

import Carousel from "react-bootstrap/Carousel";

import backgroundImage from "../../images/bg1.jpg";
import backgroundImage1 from "../../images/bg2.jpg";
import backgroundImage2 from "../../images/bg3.jpg";
import backgroundImage3 from "../../images/bg4.jpg";
import {Currency} from "components/NumberFormat";
const { REACT_APP_ALL_1Y_TABLE_DATA } = process.env;

const introVideo = process.env.REACT_APP_CLOUDTRAKR_INTRO;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
}));

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -15,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function DashboardChart() {
    const [progress, setProgress] = useState(0);
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 80);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    const classes = useStyles();
    //const location = useLocation();
    const [period, setPeriod] = useState("1M");
    const [yearTableData, setYearTableData] = useState([]);
    const dispatch = useDispatch();

    const { customerId, role, awsRecommenadationSummary, allAccounts, azureAccountCount, awsAccountCount, gcpAccountCount} = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        role: state.userDetailsReducer.role,
        awsRecommenadationSummary:state?.awsRecommeandationSummaryReducer?.awsRecommendationSummaryData?.recommendationsSummary,
        azureAccountCount: state.allHomeReducer.allSubscriptions?.azure.reduce((a, b) => a + b?.subscriptions?.length, 0),
        awsAccountCount: state.allHomeReducer.totalSummary?.totalAWSUsageAccounts ||
            state.allHomeReducer.allSubscriptions?.aws?.length,
        gcpAccountCount: state.allHomeReducer.allSubscriptions?.gcp?.length,
    }));

    const AllCloudSpending = useSelector((state) => state.allHomeReducer.AllCloudSpending);
    const thisMonthSpend = useSelector((state) => state.allHomeReducer.ThisMonthSpend);
    const currency = useSelector((state) => state.allHomeReducer.currency);
    const services = useSelector((state) => state.allHomeReducer.TopFiveServices);
    const allCloudlocations = useSelector((state) => state.allHomeReducer.TopFiveLocations);
    const SpendByCategory = useSelector((state) => state.allHomeReducer.SpendByCategory);
    const serverlessServices = useSelector((state) => state.allHomeReducer.TopFiveServerlessServices);
    const SpendByAccounts = useSelector((state) => state.allHomeReducer.SpendByAccounts);
    const lastmonthCost = useSelector((state) => state.allHomeReducer.LastMonthSpend);

    const loading = useSelector((state) => state.allHomeReducer.loading || state.accountStatusReducer.loading || state.userDetailsReducer.loading || state.userDetailsReducer.tokenLoading);

    const allAccountStatus = useSelector((state) => state.accountStatusReducer.allAccountStatus);

    const newAzureConnectionError = useSelector((state) => state.connectionErrorReducer.azureConnectionErrors);
    const newAWSConnectionError = useSelector((state) => state.connectionErrorReducer.awsConnectionErrors);

    const AzureConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.connections?.[0]?.azure?.tenant,
    }));

    const AWSConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.awsConnections?.[0]?.aws?.tenant,
    }));

    const GCPConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.gcpConnections?.[0]?.gcp?.tenant,
    }));

    function AllHome(id) {
        dispatch(allHomeRequest(id));
    }

    useEffect(() => {
        if(customerId){
            dispatch(getSubscriptionsRequest(customerId));
        }
        dispatch(getConnectionErrorRequest(customerId));
        dispatch(getAwsRecommendationSummaryRequest({customerId}))
    }, [customerId]);

    function AccountStatus(id) {
        if (id) {
            dispatch(accountRequest(id));
        }
    }

    useEffect(() => {
        if (allAccountStatus != null) {
            if (allAccountStatus) {
                if (services == null) {
                    AllHome(customerId);
                }
            }
        } else {
            AccountStatus(customerId);
        }
    }, [customerId, services, allAccountStatus]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => (customerId ? getAll1YearMonthlyData() : null), [customerId]);


    function getAll1YearMonthlyData() {
        axios
            .get(REACT_APP_ALL_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: "monthly",
                },
            })
            .then((response) => response.data)
            .then((response) => {
                Currency.currency = response?.data?.currency || "USD";
                setYearTableData(response?.data?.data || []);
            })
            .catch((e) => {});
    }

    return !loading ? (
        allAccountStatus ? (
            <div
                style={{
                    backgroundColor: Colors.background,
                    // paddingLeft: 10,
                    // paddingRight: 10,
                    minHeight: "95vh",
                }}
            >
                {AllCloudSpending == null ? (
                    <div>
                    <Paper elevation={3} style={{marginTop:8}}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 25,
                            }}
                        >
                            <h5 style={{ color: "steelblue" }}>We are collecting and analyzing your data. Usually cloud providers takes less than 24 hours to provide data from your cloud accounts. Please check back CloudTrakr portal in 24 hours to see the deep visibility and optimization for your cloud spend.</h5>
                            
                        </div>
                        </Paper>
                           <Paper elevation={3} style={{marginTop:8}}>
                        <div style={{display: "flex", flexDirection: "row",justifyContent: "space-between",padding:8 }}>
                        <ReactPlayer
                        style={{ margin: 8}}
                            width={"80%"}
                            height={"300px"}
                            url={introVideo}
                            controls
                            playing
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                        <div style={{ margin: 8, width: "80%" }}>
                            <Carousel controls={false} pause={"hover"}>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>SPEND LESS DO MORE.</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage1} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>VISUALIZE</h3>
                                        <p>Visualize cost breakdowns of each cloud.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage2} alt="Third slide" />

                                    <Carousel.Caption>
                                        <h3>SET</h3>
                                        <p>Set budget and keep your expenses in check.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage3} alt="Fourth slide" />

                                    <Carousel.Caption>
                                        <h3>GET</h3>
                                        <p>Get customized cost, security and performance recommendations.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    </Paper>
                    </div>
                   
                ) : (
                    <div className={classes.root} >
                        {AzureConnectionError?.connectionError &&
                            AzureConnectionError?.connectionError?.map((r, i) => <ConnectionError row={r} date={""} cloud={r.accountType} tenantName={r.tenantName} subscription={r.tenantId} status={r.status} errorDetail={r} />)}
                        {AWSConnectionError?.connectionError &&
                            AWSConnectionError?.connectionError?.map((r, i) => (
                                <ConnectionError row={r} date={r.lastModified} cloud={r.accountType} subscription={r.tenantId} date={r.lastModified} status={r.status} errorDetail={r?.errors?.[0]?.message} />
                            ))}
                        {GCPConnectionError?.connectionError &&
                            GCPConnectionError?.connectionError?.map((r, i) => <ConnectionError row={r} date={""} cloud={r.accountType} subscription={r.tenantId} status={r.status} errorDetail={r?.errors?.errors?.[0]?.message} />)}
                        <div
                            style={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "space-between",
                                // backgroundColor: "#FFF",
                                padding: 10,
                            }}
                        >
                            <CostHeader
                                cloudName={"AllCloud"}
                                currency={Format.formatCurrencySymbol(currency)}
                                cost={Number(thisMonthSpend?.totalCost) || 0}
                                cloudIcon={ALL_CLOUD_LOGO}
                                logoSize={45}
                                color={"#000"}
                                lastMonthSpend={lastmonthCost?.totalCost || 0}
                                ExtraComponent={() => false}
                                date={thisMonthSpend?.endDate|| 0}
                                startDate={thisMonthSpend?.startDate|| 0}
                                lastMonthDate={lastmonthCost?.endDate}
                                hasPotentialSavings={true}
                                potentialSavings={awsRecommenadationSummary?.summaryPotentialSavingsPerMonth||0}
                                >
                                {/* {awsRecommenadationSummary != null && awsRecommenadationSummary != undefined ? <>
                                <CostHeaderDataComponent currency={Format.formatCurrencySymbol(currency)}  data={awsRecommenadationSummary?.summaryPotentialSavingsPerMonth.toFixed(2)} title={"Potential Savings /mon"} /> </>: null} */}
                                {(awsAccountCount >= 0)?
                                <CostHeaderDataComponent shouldShow={true} data={awsAccountCount}
                                                         // title={(awsAccountCount > 1) ? "AWS Accounts": "AWS Account"}
                                                         title={<><img src={AWS_LOGO} height={15} /> {(awsAccountCount > 1) ? "Accounts": "Account"} </>}
                                /> : null}
                                
                                {(azureAccountCount >= 0)?
                                <CostHeaderDataComponent shouldShow={true} data={azureAccountCount} title={<><img src={AZURE_LOGO} height={15} /> {(azureAccountCount > 1) ? "Subscriptions": "Subscription"} </>}/>: null}
                                
                                {(gcpAccountCount >= 0)?
                                <CostHeaderDataComponent shouldShow={true} data={gcpAccountCount}
                                                         // title={(gcpAccountCount > 1) ? "GCP Accounts": "GCP Account"}
                                                         title={<><img src={GCP_LOGO} height={15} /> {(gcpAccountCount > 1) ? "Projects": "Project"} </>}
                                /> : null}
                            </CostHeader>
                        </div>
                        {customerId && <DashboardTabs period={period} customerId={customerId} />}
                    </div>
                )}
            </div>
        ) : (
            <ConenctAccountSkipPage hasAzure={true} hasAWS={true} hasGCP={true} cloudName={"one of your"} role={role} />
        )
    ) : (
        <div className={classes.root}>
            {/* <LinearProgress variant="determinate" value={progress} /> */}
            <DashboardLoader />
        </div>
    );
}

export function BussinessUnit({ data, period, currency }) {
    const [managementTotal, setManagementTotal] = useState(0);
    const [managementChartData, setManagementChartData] = useState(null);
    const [chartData, setChartData] = useState(null);

    function processData(data, period) {
        if (data.type == "subscription") {
            return {
                label: data.name,
                value: data?.cost?.[period] || 0,
            };
        } else if (data?.children) {
            return {
                label: data.name,
                value: data?.cost?.[period] || 0,
                childData: data.children.map((c) => processData(c, period)),
            };
        } else {
            return {
                label: data.name,
                value: data?.cost?.[period] || 0,
            };
        }
    }

    useEffect(() => {
        if (chartData && chartData.length) {
            setManagementTotal(chartData.map((c) => c.cost).reduce((a, b) => a + (b?.[period] || 0), 0));
            setManagementChartData(chartData.map((c) => processData(c, period)));
        }
    }, [chartData, period]);

    useEffect(() => {
        setChartData(AzureManagementGroupFormatter(data));
    }, [period]);

    return <>{managementChartData && managementTotal > 0 ? <ReactDrillDownd3BarChart title={"Spend by Business units"} cost={managementTotal} currency={currency} data={managementChartData} /> : <NO_DATA_AVAILABLE />}</>;
}
