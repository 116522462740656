import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import active_directory from "../../images/active_directory.svg";
import serverless_icon from "../../images/light.svg";

import gcp_billing from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";

import resource_group from "../../images/resource_group.svg";
import subscritpion_icon from "../../images/key.svg";
import recommendation_icon from "../../images/like.svg";
import calendar_icon from "../../images/calendar.svg";
import Overview from "../../images/overview.svg";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import management_group from "../../images/management_group.svg";
import azure_reservations from "../../images/Reservations.svg";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow, ThumbUp } from "@material-ui/icons";
import { Paper, Grid, Divider, ButtonGroup, Button, CircularProgress, IconButton, Collapse } from "@material-ui/core";
import Format from "components/NumberFormat";
import YearTableData from "../YearTableData";
import axios from "../../connection/axios";
import YearCalendarData, { CalendarDataProcessor, Months } from "../YearCalendarData";
import { ArrowRight, CalendarToday, ChevronRight, Menu } from "@material-ui/icons";
import Colors from "../../styles/color";
import moment from "moment";
import Cost, { Currency } from "../Cost";
import SlideShow from "components/CustomSlider/SlideShow";
import ReactD3PieChart from "components/D3Charts/ReactD3PieChart";
import ReactD3BarChart from "components/D3Charts/ReactD3BarChart";
import AzureStorage from "../../pages/DashboardAccountsHome/AzureStorage";
import storage_account_icon from "../../images/resources/Storage_Account.svg";
import function_apps_icon from "../../images/function-apps.png";
import Azure_cosmosDB_icon from "../../images/Azure-Cosmos-DB.svg";
import { BussinessUnit } from "pages/Charts/DashboardChart";
import colors from "../../styles/color";
import { getMonthsFromNow } from "../../util/DateFormatter";
import Calendar_Spend_Icon from "../../images/calendar_months.svg";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GroupedBarChart from "../D3Charts/GroupedBarChart";
import StackedBarChart2 from "../../pages/Charts/StackedBarChart2";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { generateGroupedChartData } from "../../pages/Charts/DashboardTabs";
import Total_Spend_Icon from "../../images/sigma_icon.svg";
import DateRange from "../DateRange";
import AzureFunctions from "../../pages/DashboardAccountsHome/AzureFunctions";
import GCPBillingAccountList from "../../components/GCP/GCPBillingAccountList";
import GCPProjectTabAccount from "pages/DashboardAccountsHome/GCPProjectTabAccount";
import GCPBillingAccount from "../../pages/DashboardAccountsHome/GCPBillingAccount";
import GCPBillingCompute from "pages/DashboardAccountsHome/GCPBillingCompute";
import GCPBillingStorage from "pages/DashboardAccountsHome/GCPBillingStorage";
import gcp_storage from '../../images/gcp_storage.svg'

import gcp_org from "../../images/gcp_org.svg";
import { apiDataCheck } from "../../util/Miscellaneous";
import GCPServicesBillingHome from "pages/DashboardAccountsHome/GCPServicesBillingHome";
import gcp_compute from "../../images/gcp_compute.svg";
import NO_DATA_AVAILABLE from "../NO_DATA_AVAILABLE";
import gcp_big_query from "../../images/gcp_bigquery.svg";
import GCPBigQuery from "../../pages/DashboardAccountsHome/GCPBigQuery";
import {TIMERS} from "../../util/AppConstants";
import billing_icon from "../../images/billing_icon.svg";
const { REACT_APP_GCP_BILLING_1Y_TABLE_DATA } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: Colors.background,
        //padding: theme.spacing(-20),
    },
    paper: {
        padding: theme.spacing(2),
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function GcpBillingTab(props) {
    const { charts, currency, customerId, billingAccount, loading, selectedSubscription } = props;
    const { rec, progress } = props;
    const [resTab, setresTab] = useState(false);
    const classes = useStyles();
    const [value, setValue] = React.useState("2");
    const [parentWidth, setParentWidth] = useState(null);
    const [yearTableData, setYearTableData] = useState([]);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [showDataBy, setShowDataBy] = useState("calendar");
    const [overlayData, setOverlayData] = useState(null);
    const [ResourceGroup, setResourceGroup] = useState(null);
    const [monthInterval, setMonthInterval] = useState(null);
    const [computeData, setComputeData] = useState(null);
    const [containersData, setContainersData] = useState(null);
    const [kubernetesData, setKubernetesData] = useState(null);
    const [gcpMonthWiseChartData, setGcpMonthWiseChartData] = useState([]);
    const [othersMonthWiseChartData, setOthersMonthWiseChartData] = useState([]);
    const [spendByMonthsChartView, setSpendByMonthsChartView] = useState(true);
    const [chartShowingIndex, setChartShowingIndex] = useState(0);

    const [totalSpendChartView, setTotalSpendChartView] = useState(true);
    const [period, setPeriod] = useState("1M");

    const [allCLoud, setAllCLoud] = useState(null);
    const [top5Services, setTop5Services] = useState(null);
    const [topFiveProjects, setTopFiveProjects] = useState(null);
    const [top5Locations, setTop5Locations] = useState(null);
    const [spendBy5Accounts, setSpendBy5Accounts] = useState(null);

    // useEffect(() => {
    //     const RGChartData = [];
    //     charts?.TopFiveResourceGroups?.[period]?.dataSet?.map((i) => {
    //         RGChartData.push({
    //             label: i.label == "" ? "unassigned" : i.label,
    //             value: i.value,
    //         });
    //     });
    //     setResourceGroup(RGChartData);
    // }, [period, charts]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        props?.setActiveTabSub(newValue);
    };

    function rootLoaded() {
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    function onResume() {
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    async function get1YearTableData(tenantId, subscriptionId, period = "monthly") {
        return await axios
            .get(REACT_APP_GCP_BILLING_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                    organizationId: selectedSubscription.organizationId,
                    billingAccountId: selectedSubscription.value,
                },
            })
            .then((response) => response.data)
            .then(apiDataCheck)
            .then((response) => {
                if (response) {
                    if (period === "daily") {
                        setMonthWiseData(response?.data?.data?.SpendByDaily);
                    } else {
                        setYearTableData(response?.data?.data?.SpendByMonth);
                    }
                }
            })
            .catch((e) => {});
    }

    useEffect(() => {
        if (customerId) {
            get1YearTableData()
                .then(async () => await get1YearTableData(null, null, "daily"))
                .then(onResume);
        }
        props?.setActiveTabSub("1");
        props?.setTabSelect("homeTab");
    }, [customerId]);

    useEffect(() => {
        gcpAccountData(period);
    }, [charts?.SpendByBillingAccounts, charts?.GCPCloudSpending, charts?.TopFiveServices, charts?.SpendByProjects, charts?.TopFiveLocations, charts?.SpendByAccounts, charts?.SpendByOrgAndBillingAccountWithProject, period]);

    const gcpAccountData = (p) => {
        if (charts?.GCPCloudSpending && Object.keys(charts?.GCPCloudSpending[p]).length !== 0) {
            if (p in charts?.GCPCloudSpending) {
                const { dataSet, totalCost } = charts?.GCPCloudSpending[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setAllCLoud({ data: data, cost: totalCost });
            } else {
                setAllCLoud(null);
            }
        } else {
            setAllCLoud(null);
        }
        if (charts?.TopFiveServices && Object.keys(charts?.TopFiveServices[p]).length !== 0) {
            if (p in charts?.TopFiveServices) {
                const { dataSet, totalCost } = charts?.TopFiveServices[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5Services({ data: data, cost: totalCost });
            } else {
                setTop5Services(null);
            }
        } else {
            setTop5Services(null);
        }
        if (charts?.SpendByProjects && Object.keys(charts?.SpendByProjects[p]).length !== 0) {
            if (p in charts?.SpendByProjects) {
                const { dataSet, totalCost } = charts?.SpendByProjects[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTopFiveProjects({ data: data, cost: totalCost });
            } else {
                setTopFiveProjects(null);
            }
        } else {
            setTopFiveProjects(null);
        }
        if (charts?.TopFiveLocations && Object.keys(charts?.TopFiveLocations[p]).length !== 0) {
            if (p in charts?.TopFiveLocations) {
                const { dataSet, totalCost } = charts?.TopFiveLocations[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setTop5Locations({ data: data, cost: totalCost });
            } else {
                setTop5Locations(null);
            }
        } else {
            setTop5Locations(null);
        }
        if (charts?.SpendByBillingAccounts && Object.keys(charts?.SpendByBillingAccounts[p]).length !== 0) {
            if (p in charts?.SpendByBillingAccounts) {
                const { dataSet, totalCost } = charts?.SpendByBillingAccounts[p];
                let data = dataSet.sort((a, b) => b.value - a.value).slice(0, 5);
                setSpendBy5Accounts({ data: data, cost: totalCost });
            } else {
                setSpendBy5Accounts(null);
            }
        } else {
            setSpendBy5Accounts(null);
        }
    };

    // async function get1YearTableData(tenantId, subscriptionId, period = "monthly") {
    //     return await axios
    //         .get(REACT_APP_AZURE_1Y_TABLE_DATA, {
    //             params: {
    //                 customerId,
    //                 view: period,
    //                 subscriptionId,
    //                 tenantId,
    //             },
    //         })
    //         .then((response) => response.data)
    //         .then((response) => {
    //             if (period === "daily") {
    //                 setMonthWiseData(response?.data?.data?.SpendByDaily);
    //             } else {
    //                 setYearTableData(response?.data?.data?.SpendByMonth);
    //             }
    //         })
    //         .catch((e) => {});
    // }

    // useEffect(() => {
    //     if (customerId) {
    //         get1YearTableData()
    //             .then(async () => await get1YearTableData(null, null, "daily"))
    //             .then(onResume);
    //     }
    //     props?.setActiveTabSub("1");
    //     props?.setTabSelect("homeTab");
    // }, [customerId]);

    async function onMonthSelect(m, y = null) {
        if (monthWiseData == null || !monthWiseData) {
            await get1YearTableData(null, null, "daily").then(() => {
                setCurrentMonth(m);
            });
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }

        if (y) setCurrentYear(y);
    }

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData, value]);

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData
                .find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData.find((data) => data.month == y.toString() + m.toString());
            // debugger
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
        }
        return { data, date };
    }

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;

            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear]);

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ gcp: { SpendByMonth: yearTableData } }, setGcpMonthWiseChartData, () => {});
    }, [yearTableData]);

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ gcp: { SpendByMonth: yearTableData } }, setOthersMonthWiseChartData, () => {}, ["services", "resources", "projects"]);
    }, [yearTableData]);

    return (
        <div className={classes.root}>
            <TabContext value={value}>
                <TabList
                    onChange={handleChange}
                    // aria-label="simple tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{ maxWidth: "calc(100vw - 80px)" }}
                >
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={{height:26}} src={billing_icon} />
                                Dashboard

                            </div>
                        }
                        value="2"
                        className={classes.tab}
                        {...a11yProps(6)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={DashboardIcon} />
                                Dashboard
                            </div>
                        }
                        value="1"
                        className={classes.tab}
                        onClick={() => setresTab(false)}
                        {...a11yProps(1)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={{ height: 20, margin: 4 }} src={gcp_billing} />
                                Billing Account
                            </div>
                        }
                        value="3"
                        className={classes.tab}
                        onClick={() => setresTab(false)}
                        {...a11yProps(7)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={gcp_project} />
                                Projects
                            </div>
                        }
                        value="4"
                        className={classes.tab}
                        {...a11yProps(2)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={resource_group} />
                                Services
                            </div>
                        }
                        value="5"
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(3)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={gcp_compute} />
                                Compute
                            </div>
                        }
                        value="6"
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(3)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={gcp_storage} />
                                Storage
                            </div>
                        }
                        value="8"
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(3)}
                    />

                    {/* <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <ThumbUp style={{ marginRight: 5, fontSize: 20 }} />
                                    Compute
                                </div>
                            }
                            value="6"
                            className={classes.tab}
                            {...a11yProps(4)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={azure_reservations} />
                                    Storage
                                </div>
                            }
                            value="7"
                            className={classes.tab}
                            {...a11yProps(4)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={ComputeIcon} />
                                    Fuction
                                </div>
                            }
                            value="8"
                            className={classes.tab}
                            {...a11yProps(4)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={([styles.icon], { height: 30, marginRight: 4 })} src={storage_account_icon} />
                                    Big Query
                                </div>
                            }
                            value="9"
                            className={classes.tab}
                            onClick={() => setresTab(true)}
                            {...a11yProps(3.5)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={([styles.icon], { height: 22, marginRight: 4 })} src={function_apps_icon} />
                                    Reservation
                                </div>
                            }
                            value="10"
                            className={classes.tab}
                            onClick={() => setresTab(true)}
                            {...a11yProps(4.5)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={([styles.icon], { height: 30, marginRight: 4 })} src={Azure_cosmosDB_icon} />
                                    Recommendations
                                </div>
                            }
                            value="11"
                            className={classes.tab}
                            onClick={() => setresTab(true)}
                            {...a11yProps(3.5)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={serverless_icon} height={30} />
                                    Serverless
                                </div>
                            }
                            value="12"
                            className={classes.tab}
                            {...a11yProps(5)}
                        /> */}
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={([styles.icon], { width: 20, height: 20, marginRight: 4 })} src={gcp_big_query} />
                                Big Query
                            </div>
                        }
                        value="7"
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(7)}
                    />
                </TabList>
                <Divider />
                <div style={{ marginTop: 0 }}></div>
                <TabPanel value="1">
                    {charts != null && Object.keys(charts).length > 0 ? (
                        <Paper elevation={10}>
                            <div>
                                <div
                                    className={"accordian_item1"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                        <img src={Total_Spend_Icon} height={25} /> Total spend
                                    </h5>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {totalSpendChartView && (
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <span
                                                    style={{
                                                        marginRight: 4,
                                                        fontSize: 12,
                                                        color: "#616161",
                                                    }}
                                                >
                                                    Last
                                                </span>
                                                <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setPeriod} defaultSelected={period} />
                                            </div>
                                        )}
                                        <IconButton onClick={() => setTotalSpendChartView(!totalSpendChartView)} style={{ outline: "none" }}>
                                            {totalSpendChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Collapse in={totalSpendChartView}>
                                    <div style={{ padding: 10 }}>
                                        <SlideShow names={["Spend by GCP", "Spend by Billing Account", "Top 5 Projects", "Top 5 services", "Top 5 locations"]}>
                                            <div className="chart-align">
                                                {/*{allCLoud != null && currency ? (*/}
                                                <ReactD3PieChart title={"Spend by GCP"} cost={allCLoud?.cost} currency={currency} data={allCLoud?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{spendBy5Accounts != null && currency ? (*/}
                                                <ReactD3BarChart title={"Spend by Billing Accounts"} cost={spendBy5Accounts?.cost} currency={currency} data={spendBy5Accounts?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{topFiveProjects != null && currency ? (*/}
                                                <ReactD3BarChart title={"Top 5 Projects"} cost={topFiveProjects?.cost} currency={currency} mouseEffect={false} data={topFiveProjects?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{top5Services != null && currency ? (*/}
                                                <ReactD3BarChart title={"Top 5 services"} cost={top5Services?.cost} currency={currency} mouseEffect={false} data={top5Services?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>

                                            <div className="chart-align">
                                                {/*{top5Locations != null && currency ? (*/}
                                                <ReactD3BarChart title={"Top 5 locations"} cost={top5Locations?.cost} currency={currency} mouseEffect={false} data={top5Locations?.data} />
                                                {/*) : (*/}
                                                {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                {/*)}*/}
                                            </div>
                                        </SlideShow>
                                    </div>
                                </Collapse>
                            </div>
                        </Paper>
                    ) : (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <h4 style={{ color: "steelblue" }}>No data found for the last {period == "1D" ? "1 day" : period == "1W" ? "1 week" : period}</h4>
                        </div>
                    )}
                    {/* <div>
                        {charts && Object.keys(charts?.SpendBySubscriptions?.[period]).length > 0 ? (
                            <Paper elevation={10}>
                                <div>
                                    <div
                                        className={"accordian_item1"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                            <img src={Total_Spend_Icon} height={25} /> Total spend
                                        </h5>
                                        <div style={{ display: "flex", alignItems: "center" }}>

                                            <IconButton onClick={() => setTotalSpendChartView(!totalSpendChartView)} style={{ outline: "none" }}>
                                                {totalSpendChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Collapse in={totalSpendChartView}>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow
                                                names={[
                                                    "Spend by Business units",
                                                    "Spend by Subscriptions",
                                                    "Top 5 Services",
                                                    "Top 5 Locations",
                                                    "Top 5 Resource groups",
                                                    "Spend by Category",
                                                    "Spend by Tenants",
                                                    "Spend by Compute",
                                                    "Spend by Containers",
                                                    "Spend by Kubernetes",
                                                ]}
                                            >
                                                <div>
                                                    {mgmtLoading ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <CircularProgress thickness={2} size={80} />
                                                        </div>
                                                    ) : managmentGroup && managmentGroup != null ? (
                                                        <BussinessUnit data={managmentGroup?.managementGroups?.children} period={period} currency={currency} />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                position: "absolute",
                                                                marginLeft: "35%",
                                                            }}
                                                        >
                                                            No data found for
                                                        </div>
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.SpendBySubscriptions?.[period]?.dataSet && (
                                                        <ReactD3BarChart
                                                            title={"Spend By Subscriptions"}
                                                            mouseEffect={true}
                                                            cost={charts?.SpendBySubscriptions?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendBySubscriptions?.[period]?.dataSet.sort((a, b) => b.value - a.value)}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.TopFiveServices?.[period]?.dataSet && (
                                                        <ReactD3BarChart
                                                            title={"Top 5 Services"}
                                                            mouseEffect={true}
                                                            cost={charts?.TopFiveServices?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.TopFiveServices?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.TopFiveLocations?.[period]?.dataSet && (
                                                        <ReactD3BarChart
                                                            title={"Top 5 Locations"}
                                                            mouseEffect={true}
                                                            cost={charts?.TopFiveLocations?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.TopFiveLocations?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {ResourceGroup && (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Top 5 ResourceGroups"}
                                                            cost={charts?.TopFiveResourceGroups?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={ResourceGroup.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.SpendByCategory?.[period]?.dataSet && (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Spend By Category"}
                                                            cost={charts?.SpendByCategory?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendByCategory?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.SpendByAccounts?.[period]?.dataSet && (
                                                        <ReactD3PieChart
                                                            title={"Spend By Tenants"}
                                                            cost={charts?.SpendByAccounts?.[period]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendByAccounts?.[period]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    )}
                                                </div>
                                                <div>
                                                    {azureCompute?.[period]?.resources?.length && computeData ? (
                                                        <ReactD3BarChart mouseEffect={true} title={"Spend by Compute"} cost={azureCompute?.[period]?.totalCost} currency={currency} data={computeData.sort((a, b) => b.value - a.value).slice(0, 5)} />
                                                    ) : (
                                                        <span style={{ justifyContent: "center", display: "flex" }}>No Compute found for {period}</span>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureContainers?.[period]?.resources?.length && containersData ? (
                                                        <ReactD3BarChart mouseEffect={true} title={"Spend by Containers"} cost={azureContainers?.[period]?.totalCost} currency={currency} data={containersData.sort((a, b) => b.value - a.value)} />
                                                    ) : (
                                                        <span style={{ justifyContent: "center", display: "flex" }}>No Containers found for {period}</span>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureKubernetes?.[period]?.resources?.length && kubernetesData ? (
                                                        <ReactD3BarChart mouseEffect={true} title={"Spend by Kubernetes"} cost={azureKubernetes?.[period]?.totalCost} currency={currency} data={kubernetesData.sort((a, b) => b.value - a.value)} />
                                                    ) : (
                                                        <span style={{ justifyContent: "center", display: "flex" }}>No Kubernetes found for {period}</span>
                                                    )}
                                                </div>
                                            </SlideShow>
                                        </div>
                                    </Collapse>
                                </div>
                            </Paper>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <h4 style={{ color: "steelblue" }}>No data found for the last {period == "1D" ? "1 day" : period == "1W" ? "1 week" : period}</h4>
                            </div>
                        )}
                        <div>
                            <Paper elevation={10} style={{ marginTop: 20 }}>
                                <div>
                                    <div
                                        className={"accordian_item1"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                            <img src={Calendar_Spend_Icon} height={25} /> Spend by Azure
                                        </h5>
                                        <div style={{ display: "flex", alignItems: "center" }}>

                                            <IconButton onClick={() => setSpendByMonthsChartView(!spendByMonthsChartView)} style={{ outline: "none" }}>
                                                {spendByMonthsChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    {!spendByMonthsChartView && <hr style={{ backgroundColor: "#e4e4e4", padding: 0, margin: 0 }} />}
                                    <Collapse in={spendByMonthsChartView}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 10,
                                                paddingTop: 0,
                                                //  justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <SlideShow showingIndex={chartShowingIndex} names={["Spend by Azure", "Top 5 Resource Groups", "Top 5 Resources"]} view="All" arrowsHandled={true}>
                                                <Paper>
                                                    <GroupedBarChart
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        // mouseEffect={true}
                                                        data={azureMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                                <Paper>
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"resourceGroups"}
                                                        keyField={"resourceGroupName"}
                                                        valueKey={"cost"}
                                                        // mouseEffect={true}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                                <Paper>
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"resources"}
                                                        keyField={"resourceId"}
                                                        valueKey={"cost"}
                                                        // mouseEffect={true}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                            </SlideShow>
                                            <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Paper>
                        </div>
                        <div style={{ marginTop: 35, marginBottom: 20 }}>
                            <AzureResources tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} />
                        </div>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AzureSubscriptionsList tenantLogo={active_directory} currency={Format.formatCurrencySymbol(currency)} accountList={charts?.SpendByAccounts?.[period]?.dataSet || 0} />
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginTop: 20 }}>
                        {yearTableData && yearTableData.length ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: 10,
                                }}
                            >

                                <div>
                                    <Paper elevation={0}>
                                        <ButtonGroup style={{ outline: "none" }}>
                                            <Button
                                                onClick={() => setShowDataBy("table")}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: showDataBy === "table" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                }}
                                            >
                                                <Menu
                                                    style={{
                                                        color: showDataBy === "table" ? "white" : "rgba(63, 81, 181, 0.86)",
                                                    }}
                                                />
                                            </Button>
                                            <Button
                                                onClick={() => setShowDataBy("calendar")}
                                                style={{
                                                    outline: "none",
                                                    backgroundColor: showDataBy === "calendar" ? "rgba(63, 81, 181, 0.86)" : "white",
                                                }}
                                            >
                                                <CalendarToday
                                                    style={{
                                                        color: showDataBy === "calendar" ? "white" : "rgba(63, 81, 181, 0.86)",
                                                    }}
                                                />
                                            </Button>
                                        </ButtonGroup>
                                    </Paper>
                                </div>
                            </div>
                        ) : null}
                        <div>
                            {showDataBy === "table" ? (
                                <div style={{ overflow: "hidden" }}>
                                    {parentWidth && (
                                        <YearTableData
                                            hideLegend={true}
                                            width={parentWidth}
                                            data={{

                                                azure: yearTableData,
                                            }}
                                            months={yearTableData?.map((m) => m.month)}
                                            currency={Format.formatCurrencySymbol(currency || "USD")}
                                        />
                                    )}
                                </div>
                            ) : (
                                <YearCalendarData
                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                    year={currentYear}
                                    onNext={() => setCurrentYear(currentYear + 1)}
                                    onPrev={() => setCurrentYear(currentYear - 1)}
                                    yearData={CalendarDataProcessor(yearTableData, "year")}
                                    monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                    onMonthSelect={onMonthSelect}
                                    month={currentMonth}
                                />
                            )}
                        </div>
                    </div> */}
                    <div>
                        <Paper elevation={10} style={{ marginTop: 20 }}>
                            <div>
                                <div
                                    className={"accordian_item1"}
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                        <img src={Calendar_Spend_Icon} height={25} /> Spend by months
                                    </h5>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <IconButton onClick={() => setSpendByMonthsChartView(!spendByMonthsChartView)} style={{ outline: "none" }}>
                                            {spendByMonthsChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                    </div>
                                </div>
                                {!spendByMonthsChartView && <hr style={{ backgroundColor: "#e4e4e4", padding: 0, margin: 0 }} />}
                                <Collapse in={spendByMonthsChartView}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            padding: 10,
                                            paddingTop: 0,
                                            //  justifyContent: 'center'
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <SlideShow delay={TIMERS.LOWER_CHART_INTERVAL} showingIndex={chartShowingIndex} names={["Spend by GCP", "Spend By Projects", "Top 5 Resources"]} view="All" arrowsHandled={true}>
                                            <Paper>
                                                <GroupedBarChart
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    // mouseEffect={true}
                                                    data={gcpMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </Paper>
                                            <Paper>
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"projects"}
                                                    keyField={"projectId"}
                                                    valueKey={"cost"}
                                                    // mouseEffect={true}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </Paper>
                                            <Paper>
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"resources"}
                                                    keyField={"skuId"}
                                                    valueKey={"cost"}
                                                    // mouseEffect={true}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </Paper>
                                        </SlideShow>
                                        <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </Paper>
                    </div>
                    <br />
                    <div>
                        <GCPBillingAccountList tenantLogo={gcp_org} currency={Format.formatCurrencySymbol(currency)} period={period} billingAccountName={selectedSubscription.title} accountList={charts?.SpendByProjects || 0} />
                    </div>
                </TabPanel>

                <TabPanel value="2" onLoad={rootLoaded} id={"root_dashboard"}>
                    <div style={{ marginTop: 4, maxWidth: "calc(100vw - 120px)", overflow: "auto" }}>
                        <Paper style={{ padding: 5, marginTop: 10 }}>
                            {yearTableData && yearTableData.length ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        // margin: 10,
                                        marginBottom: 0,
                                    }}
                                >
                                    <h5
                                        style={{
                                            fontWeight: "bold",
                                            marginTop: 6,
                                            marginRight: 4,
                                        }}
                                    >
                                        <img src={Calendar_Spend_Icon} height={25} /> Spend by months
                                    </h5>
                                </div>
                            ) : null}
                            <hr style={{ padding: 0, margin: 0 }} />
                            <div
                                style={{
                                    display: "flex",
                                    alignSelf: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <YearTableData
                                    selectedCloud={"gcp"}
                                    hideLegend={true}
                                    width={parentWidth}
                                    data={{
                                        gcp: yearTableData,
                                    }}
                                    months={yearTableData?.map((m) => m.month)}
                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                    onMonthPress={onMonthSelect}
                                    selectedMonth={currentMonth}
                                    selectedYear={currentYear}
                                />
                            </div>
                        </Paper>
                        <div
                            style={{
                                display: "flex",
                                marginTop: 8,
                                flexDirection: "row",
                                justifyContent: "flex-start",
                            }}
                        >
                            <div style={{ flex: 0.5 }}>{monthInterval ? <PauseIcon onClick={() => onPause()} /> : <PlayArrow onClick={() => onResume()} />}</div>

                            <YearCalendarData
                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                year={currentYear}
                                onNext={() => setCurrentYear(currentYear + 1)}
                                onPrev={() => setCurrentYear(currentYear - 1)}
                                yearData={CalendarDataProcessor(yearTableData, "year")}
                                monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                onMonthSelect={onMonthSelect}
                                month={currentMonth}
                                renderOverlay={(d, m, y, cost) => {
                                    const { data, date } = getOverlayData(d, m, y);

                                    if (data)
                                        return setOverlayData({
                                            data,
                                            date,
                                            cost: data?.cost,
                                        });
                                    return null;
                                }}
                            />
                            <div
                                style={{
                                    marginLeft: 10,
                                    width: 500,
                                    height: 400,
                                    display: "flex",
                                }}
                            >
                                {/*<OverlayComponent cost={overlayData?.cost} data={overlayData?.data}*/}
                                {/*                  date={overlayData?.date}*/}
                                {/*                  dataKeys={*/}
                                {/*                      [*/}
                                {/*                          {*/}
                                {/*                              key: 'resourceGroups',*/}
                                {/*                              title: "Top Resource Groups",*/}
                                {/*                              subKey: 'resourceGroupName',*/}
                                {/*                              icon: resource_group*/}
                                {/*                          },*/}
                                {/*                          {*/}
                                {/*                              key: 'resources',*/}
                                {/*                              title: "Top Resources",*/}
                                {/*                              subKey: 'resourceId',*/}
                                {/*                              icon: resource_group*/}
                                {/*                          }*/}
                                {/*                      ]*/}
                                {/*                  }*/}
                                {/*                  onClick={() => {*/}
                                {/*                      setValue("3");*/}
                                {/*                      props?.setActiveTab("3");*/}
                                {/*                  }}*/}
                                {/*/>*/}
                                {/*<span> sadfas {JSON.stringify(overlayData)}</span>*/}
                                {overlayData && overlayData.data && (
                                    <OverlayComponent
                                        cost={overlayData?.cost}
                                        data={overlayData?.data}
                                        date={overlayData?.date}
                                        currency={Format.formatCurrencySymbol(currency || "USD")}
                                        dataKeys={[
                                            {
                                                key: "services",
                                                title: "Top Services",
                                                subKey: "service",
                                                icon: gcp_compute,
                                                onClick: () => {
                                                    setValue("5");
                                                    props?.setActiveTabSub("5");
                                                },
                                            },
                                            {
                                                key: "resources",
                                                title: "Top Resources",
                                                subKey: "skuId",
                                                icon: resource_group,
                                                onClick: () => {
                                                    setValue("5");
                                                    props?.setActiveTabSub("5");
                                                },
                                            },
                                        ]}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                      <br/>
                        {charts != null && Object.keys(charts).length > 0 ? (
                            <Paper elevation={10}>
                                <div>
                                    <div
                                        className={"accordian_item1"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                            <img src={Total_Spend_Icon} height={25} /> Total spend
                                        </h5>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            {totalSpendChartView && (
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <span
                                                    style={{
                                                        marginRight: 4,
                                                        fontSize: 12,
                                                        color: "#616161",
                                                    }}
                                                >
                                                    Last
                                                </span>
                                                    <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setPeriod} defaultSelected={period} />
                                                </div>
                                            )}
                                            <IconButton onClick={() => setTotalSpendChartView(!totalSpendChartView)} style={{ outline: "none" }}>
                                                {totalSpendChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Collapse in={totalSpendChartView}>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow names={["Spend by GCP", "Spend by Billing Account", "Top 5 Projects", "Top 5 services", "Top 5 locations"]}>
                                                <div className="chart-align">
                                                    {/*{allCLoud != null && currency ? (*/}
                                                    <ReactD3PieChart title={"Spend by GCP"} cost={allCLoud?.cost} currency={currency} data={allCLoud?.data} />
                                                    {/*) : (*/}
                                                    {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                    {/*)}*/}
                                                </div>

                                                <div className="chart-align">
                                                    {/*{spendBy5Accounts != null && currency ? (*/}
                                                    <ReactD3BarChart title={"Spend by Billing Accounts"} cost={spendBy5Accounts?.cost} currency={currency} data={spendBy5Accounts?.data} />
                                                    {/*) : (*/}
                                                    {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                    {/*)}*/}
                                                </div>

                                                <div className="chart-align">
                                                    {/*{topFiveProjects != null && currency ? (*/}
                                                    <ReactD3BarChart title={"Top 5 Projects"} cost={topFiveProjects?.cost} currency={currency} mouseEffect={false} data={topFiveProjects?.data} />
                                                    {/*) : (*/}
                                                    {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                    {/*)}*/}
                                                </div>

                                                <div className="chart-align">
                                                    {/*{top5Services != null && currency ? (*/}
                                                    <ReactD3BarChart title={"Top 5 services"} cost={top5Services?.cost} currency={currency} mouseEffect={false} data={top5Services?.data} />
                                                    {/*) : (*/}
                                                    {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                    {/*)}*/}
                                                </div>

                                                <div className="chart-align">
                                                    {/*{top5Locations != null && currency ? (*/}
                                                    <ReactD3BarChart title={"Top 5 locations"} cost={top5Locations?.cost} currency={currency} mouseEffect={false} data={top5Locations?.data} />
                                                    {/*) : (*/}
                                                    {/*    <NO_DATA_AVAILABLE>{"No data found for " + period}</NO_DATA_AVAILABLE>*/}
                                                    {/*)}*/}
                                                </div>
                                            </SlideShow>
                                        </div>
                                    </Collapse>
                                </div>
                            </Paper>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <h4 style={{ color: "steelblue" }}>No data found for the last {period == "1D" ? "1 day" : period == "1W" ? "1 week" : period}</h4>
                            </div>
                        )}
                        <div>
                            <Paper elevation={10} style={{ marginTop: 20 }}>
                                <div>
                                    <div
                                        className={"accordian_item1"}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <h5 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20 }}>
                                            <img src={Calendar_Spend_Icon} height={25} /> Spend by months
                                        </h5>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <IconButton onClick={() => setSpendByMonthsChartView(!spendByMonthsChartView)} style={{ outline: "none" }}>
                                                {spendByMonthsChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                            </IconButton>
                                        </div>
                                    </div>
                                    {!spendByMonthsChartView && <hr style={{ backgroundColor: "#e4e4e4", padding: 0, margin: 0 }} />}
                                    <Collapse in={spendByMonthsChartView}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 10,
                                                paddingTop: 0,
                                                //  justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <SlideShow delay={TIMERS.LOWER_CHART_INTERVAL} showingIndex={chartShowingIndex} names={["Spend by GCP", "Spend By Projects", "Top 5 Resources"]} view="All" arrowsHandled={true}>
                                                <Paper>
                                                    <GroupedBarChart
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        // mouseEffect={true}
                                                        data={gcpMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                                <Paper>
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"projects"}
                                                        keyField={"projectId"}
                                                        valueKey={"cost"}
                                                        // mouseEffect={true}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                                <Paper>
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"resources"}
                                                        keyField={"skuId"}
                                                        valueKey={"cost"}
                                                        // mouseEffect={true}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                            </SlideShow>
                                            <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </Paper>
                        </div>
                        <br />
                        <div>
                            <GCPBillingAccountList tenantLogo={gcp_org} currency={Format.formatCurrencySymbol(currency)} period={period} billingAccountName={selectedSubscription.title} accountList={charts?.SpendByProjects || 0} />
                        </div>
                </TabPanel>
                <TabPanel value="3">
                    <div>
                        {" "}
                        <GCPBillingAccount tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} billingAccount={charts} orgData={billingAccount} loading={loading} />
                    </div>
                </TabPanel>
                <TabPanel value="4">
                    <div>
                        <GCPProjectTabAccount tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} />
                    </div>
                </TabPanel>
                <TabPanel value="5">
                    <div>
                        <GCPServicesBillingHome tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} />
                    </div>
                </TabPanel>
                <TabPanel value="6">
                    <div>
                        {" "}
                        <GCPBillingCompute tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value="7">
                    <div>
                        <GCPBigQuery tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)}
                                     customerId={customerId}
                                     type={'billing'}
                                     billingAccountId={selectedSubscription?.value}
                        />
                    </div>
                </TabPanel>
                <TabPanel value="8">
                <div>
                        <GCPBillingStorage tab={resTab} showData={rec} currency={Format.formatCurrencySymbol(currency)} selectedSubscription={selectedSubscription} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value="9">
                    <div></div>
                </TabPanel>
                <TabPanel value="10">
                    <div>
                        {/* <AzureManagementGroup
                            tab={resTab}
                            // showData={rec}
                            // tenantId={rec.tenantId}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                        /> */}
                    </div>
                </TabPanel>
                <TabPanel value="11">
                    <div>
                        {/* <AzureReservations
                            tab={resTab}
                            // showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                        /> */}
                    </div>
                </TabPanel>
                <TabPanel value="12">
                    <div>
                        {/* <AzureCompute
                            tab={resTab}
                            // showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                        /> */}
                    </div>
                </TabPanel>
                <TabPanel value="13">
                    <div>
                        {/* <AzureCosmosDB
                            tab={resTab}
                            // showData={rec}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                        /> */}
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
}

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -15,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};
export function OverlayComponent({ cost, date, data, dataKeys = [], currency = null }) {
    // debugger
    return (
        <div style={{ flex: 1 }}>
            <Paper style={{ maxWidth: 500, maxHeight: 400, flexDirection: "column" }}>
                <div
                    style={{
                        color: "white",
                        flex: 2,
                        backgroundColor: "#3f51b5",
                        flexDirection: "column",
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                    }}
                >
                    <h4 style={{ padding: 0, margin: 0 }}>
                        {currency || <Currency />}
                        <Cost>{cost}</Cost>
                    </h4>
                    <h6 style={{ padding: 0, margin: 0 }}>{date}</h6>
                </div>
                <div style={{ overflowY: "scroll", maxHeight: 400 }}>
                    <div style={{ height: 333 }}>
                        {dataKeys.map(({ key, title, subKey, subKey2 = "", icon = "", onClick = () => {} }) => (
                            <div style={{ flex: 4, padding: 10 }}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <b>{title}</b>
                                    <a>
                                        <ChevronRight onClick={onClick} style={{ cursor: "pointer" }} />
                                    </a>
                                </div>
                                {/*<br/>*/}
                                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                    {data?.[key]?.map((r) => (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        // margin: '5px',
                                                        // maxWidth: '200px',
                                                        // border : 'solid black 2px',
                                                        // display: 'inline-block',
                                                        // padding : '5px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "flex-start",
                                                            height: "100%",
                                                        }}
                                                    >
                                                        <img src={icon} height={15} width={15} style={{ marginTop: 5, marginRight: 5 }} />
                                                    </div>
                                                    <span
                                                        style={{
                                                            wordBreak: "break-word",
                                                            paddingRight: 10,
                                                            color: r?.[subKey] || r?.[subKey2] ? "" : colors.gray,
                                                        }}
                                                        id={"overlay_item_span"}
                                                    >
                                                        {r?.[subKey] || r?.[subKey2] || "unassigned"}
                                                    </span>
                                                </div>
                                                <div style={{ paddingLeft: 10 }}>
                                                    {currency || <Currency />}
                                                    <Cost>{r?.cost}</Cost>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Paper>
        </div>
    );
}
