import React from "react";
import { Breadcrumbs, Link, Typography, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function BreadCrumbs({
  parent,
  child,
  subChild,
  parentLink,
  childLink,
  subchildLink,
  hasChild = false,
  hasSubChild = false,
  hasthirdChild = false,
  thirdchild,
}) {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      style={{ marginTop: 0 }}
      aria-label="breadcrumb"
    >
      <Link color="inherit" href={parentLink}>
        {parent}
      </Link>
      {hasChild && subChild ? (
        <Link color="inherit" href={subchildLink}>
          {child}
        </Link>
      ) : (
        <Typography color="textPrimary">{child}</Typography>
      )}
      {hasSubChild ? (
        <Typography color="textPrimary">{subChild}</Typography>
      ) : null}
      {hasthirdChild && (
        <Typography color="textPrimary">{thirdchild}</Typography>
      )}
    </Breadcrumbs>
  );
}
