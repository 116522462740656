import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import Box from "@material-ui/core/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getGCPComputeRequest, getGCPComputeProjectAccountRequest } from "../../redux/actions/actions";
import { Divider, LinearProgress, Typography } from "@material-ui/core";
import Cost from "../../components/Cost";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import gcp_compute from "../../images/gcp_compute.svg";
import error_icon from "../../images/error.svg";

import { lambdaCommonConversion } from "../../util/LambdaFunctionFormatter";
import { CancelRounded } from "@material-ui/icons";
import { bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import colors from "../../styles/color";
import axios from "../../connection/axios";
import { OverlayTrigger, Popover } from "react-bootstrap";
import TablePaginationActions from "../../components/TablePaginationActions";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 600,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function GCPProjectCompute({ currency, selectedSubscription,projectId, customerId }) {
    const dispatch = useDispatch();
    // const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [showingDetails, setShowingDetails] = React.useState(null);
    const [computeData, setComputeData] = useState(null);
    const classes = useRowStyles();
    let gcpCompute = useSelector((state) => state?.gcpComputeReducer?.ComputeProjectAccountData);
    let loading = useSelector((state) => state?.gcpComputeReducer?.ComputeProjectAccountLoading);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        let billingAccountId=selectedSubscription.value
       
        if (customerId) {
            if(gcpCompute == null){
            dispatch(
                getGCPComputeProjectAccountRequest({
                    customerId,
                    projectId,
                    billingAccountId,
                })
            );
            }
         
        }
    }, [customerId,selectedSubscription]);


    useEffect(() => {
       
            Conversion(gcpCompute);
       
    }, [gcpCompute]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function Conversion(data) {
        var final = [];
        if (data) {
            if (data?.compute && data?.compute?.length) {
                final.push(data?.compute?.map(({ name, id, ...rest }) => ({ name, id, ...data, compute: rest })) || []);
            } else if (data?.computeDataError) {
                final.push(data);
            }
        }
        final.flat(1);
        setComputeData(final.flat(1));
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange title={"GCP Compute"} titleIcon={gcp_compute} showDateRange={false} collapsible={false}>
                {({ period }) => (
                    <div className={classes.root}>
                        {computeData != null && computeData?.length ? (
                            <div>
                                <Paper elevation={3}>
                                    <Box display={"flex"} flex={1}>
                                        <Box flex={showingDetails ? 0.3 : 1}>
                                            <TableContainer className={classes.container}>
                                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                Instance Id
                                                            </StyledTableCell>

                                                            {showingDetails ? null : (
                                                                <>
                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Instance name
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Project Id
                                                                    </StyledTableCell>

                                                                         <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Billing account Id
                                                                    </StyledTableCell>

                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Billing account name
                                                                    </StyledTableCell>

                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Organization Id
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Organization name
                                                                    </StyledTableCell>
                                                                </>
                                                            )}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(computeData || [])?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                            <ExpandRow row={r} index={i} currency={currency} showingDetails={showingDetails} setShowingDetails={setShowingDetails} gcpCompute={gcpCompute} />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                        {showingDetails?.compute && Object.keys(showingDetails?.compute).length > 0 ? (
                                            <Box display={showingDetails ? "flex" : "none"} flex={showingDetails ? 0.7 : 0}>
                                                <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
                                                    <Box display={"flex"} alignItems={"center"}>
                                                        <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                                            <Typography component={"h4"} variant={"h4"}>
                                                                <img src={gcp_compute} height={30} /> {showingDetails?.name}
                                                            </Typography>
                                                        </Box>
                                                        <CancelRounded onClick={() => setShowingDetails(null)} />
                                                    </Box>
                                                    <Divider />
                                                    <Box style={{ padding: 10 }} flexDirection={"coloum"}>
                                                        {showingDetails?.compute ? (
                                                            <Box>
                                                                <Box flexDirection={"row"} display={"flex"} justifyContent={"spacebetween"}>
                                                                    <Box flex={1}>
                                                                        <Typography>
                                                                            <b>Instance Id</b>
                                                                        </Typography>
                                                                        <Typography>{showingDetails?.id}</Typography>
                                                                        <Typography style={{ marginTop: 10 }}>
                                                                            <b>Name</b>
                                                                        </Typography>
                                                                        <Typography>{showingDetails?.name || "-"}</Typography>
                                                                        <Typography style={{ marginTop: 10 }}>
                                                                            <b>Status</b>
                                                                        </Typography>
                                                                        <Typography>{showingDetails?.compute?.status || "-"}</Typography>
                                                                    </Box>

                                                                    <Box flex={1}>
                                                                        <Typography>
                                                                            <b>Machine type</b>
                                                                        </Typography>
                                                                        <Typography>{showingDetails?.compute?.machineType?.split("/")[10]}</Typography>
                                                                        <Typography style={{ marginTop: 10 }}>
                                                                            <b>zone</b>
                                                                        </Typography>
                                                                        <Typography>{showingDetails?.compute?.zone?.split("/")[8] || "-"}</Typography>
                                                                    </Box>
                                                                </Box>
                                                                <TableContainer className={classes.container}>
                                                                    <Table className={classes.table} stickyHeader aria-label="sticky table">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                                                    Size(GB)
                                                                                </StyledTableCell>

                                                                                <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                                                    Mode
                                                                                </StyledTableCell>

                                                                                <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                                                    Type
                                                                                </StyledTableCell>

                                                                                <StyledTableCell align="left" style={{ fontSize: 14, fontWeight: "bold" }}>
                                                                                    Licenses
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {(showingDetails?.compute?.disks || [])?.slice()?.map((row, i) => (
                                                                                <React.Fragment>
                                                                                    <StyledTableRow hover className={classes.root} key={row.resourceId} style={{ cursor: "pointer" }}>
                                                                                        <TableCell align="left">{row?.diskSizeGb}</TableCell>

                                                                                        <TableCell align="left">{row?.mode}</TableCell>
                                                                                        <TableCell align="left">{row?.type}</TableCell>
                                                                                        <TableCell align="left">{row?.licenses[0].split("/")[9]}</TableCell>
                                                                                    </StyledTableRow>
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                                <br />
                                                            </Box>
                                                        ) : null}
                                                    </Box>
                                                    <Divider />
                                                </Paper>
                                                {/* } */}
                                            </Box>
                                        ) : null}
                                    </Box>
                                    <TablePagination
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={computeData?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
        </div>
    );
}

export function ExpandRow(props) {
    const { row, currency, showingDetails, setShowingDetails,gcpCompute } = props;
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.id} style={{ cursor: "pointer" }} onClick={() => (row?.compute ? setShowingDetails(row) : setShowingDetails(null))}>
                <TableCell align="left">
                    {" "}
                    <img src={gcp_compute} height={20} />
                    <span style={{ color: colors.blue, marginLeft: 4 }}>{"id" in row ? row?.id == "" ? <span style={{ color: colors.gray }}>{"unassigned"}</span> : row?.id : <span style={{ color: colors.gray }}>{"unassigned"}</span>}</span>
                </TableCell>
                {showingDetails != null ? null : (
                    <>
                        {" "}
                        <TableCell align="left">
                            {" "}
                            <span style={{ color: colors.blue }}>
                                {"computeDataError" in row ? (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                            <Popover id="popover-contained">
                                                <Popover.Title as="h3">Error</Popover.Title>
                                                <Popover.Content>{row?.computeDataError}</Popover.Content>
                                            </Popover>
                                        }
                                    >
                                        <div>
                                            {" "}
                                            <img src={error_icon} height={14} /> <span style={{ color: "red" }}>{row?.computeDataError?.slice(0, 40)}...</span>
                                        </div>
                                    </OverlayTrigger>
                                ) : (
                                    <span>{row?.name}</span>
                                )}
                            </span>
                        </TableCell>
                        <TableCell align="left">{row?.projectId}</TableCell>
                        <TableCell align="left">{gcpCompute?.billingAccountId}</TableCell>
                        <TableCell align="left">{gcpCompute?.billingAccountName}</TableCell>
                        <TableCell align="left">{gcpCompute?.organizationId}</TableCell>
                        <TableCell align="left">{gcpCompute?.organizationName}</TableCell>
                    </>
                )}
            </StyledTableRow>
        </React.Fragment>
    );
}
