import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";

function AwsCostOptimizerBar({ high = 0, medium = 0, low = 0, type }) {
    const [bar, setBar] = useState({
        highWidh: null,
        mediumWidth: null,
        lowWidth: null,
    });

    function CalculateBar(h, m, l) {
        let sum = h + m + l;

        setBar({
            highWidh: (h / sum) * 600,
            mediumWidth: (m / sum) * 600,
            lowWidth: (l / sum) * 600,
        });
    }
    useEffect(() => {
        CalculateBar(high, medium, low);
    }, [high, medium, low]);
    return (
        <div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        width: 600,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            width: 600,
                            height: 40,
                            marginTop: 5,
                        }}
                    >
                        <div style={{ width: bar.highWidh, background: "#C85250" }} />
                        <div style={{ width: bar.mediumWidth, background: "#FAD02C" }} />
                        <div style={{ width: bar.lowWidth, background: "#41729F" }} />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            marginTop: 5,
                            marginBottom: 5,
                        }}
                    >
                        <div>
                            <h6 style={{ color: "#C85250", fontSize: 12 }}>OPTIMIZED</h6>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <b>{high}</b>
                            </div>
                        </div>
                        <div>
                            <h6 style={{ color: "#FAD02C", fontSize: 12 }}>{Object.keys(type)?.length > 2 ?'UNDER_PROVISIONED':'NOT_OPTIMIZED'}</h6>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <b>{medium}</b>
                            </div>
                        </div>
                        {Object.keys(type)?.length > 2 && (
                            <div>
                                <h6 style={{ color: "#41729F", fontSize: 12 }}>OVER_PROVISIONED</h6>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <b>{low}</b>
                                </div>{" "}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AwsCostOptimizerBar;
