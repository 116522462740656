import React, { useEffect, useState } from "react";
import AWS_LOGO from "../../../images/AWS_LOGO";
import AWS_SUB from "../../../images/aws_sub.svg";
import ConnectionHeader from "../../../components/ConnectionHeader";
import axios from "../../../connection/axios";
import { Button, Form, Collapse, Card, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import APP_LOGO from "../../../images/app_logo.png";
import history from "../../../services/history";
import RoutesList from "../../../routes/RoutesList";
import { BrowserRouter, Link } from "react-router-dom";
import Edit_icon from "../../../images/edit.svg";
import cross from "../../../images/cross.svg";
import IconButton from "@material-ui/core/IconButton";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";

import { Divider, Paper, Typography, LinearProgress, CircularProgress } from "@material-ui/core";
import Colors from "../../../styles/color";
import VerfiedIcon from "../../../images/verified.svg";
import ErrorIcon from "../../../images/error.svg";
import org from "../../../images/aws_org.svg";
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import EditAWSAccount from "./EditAWSAccount";
import LinkText from "components/LinkText";
import { EditSharp } from "@material-ui/icons";

const { REACT_APP_AWS_TENANTS } = process.env;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        // maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(0.5),
    },
    table: {
        maxHeight: 100,
    },
    container: {
        maxHeight: 440,
    },
}));

export default function ListAWSAccount(props) {
    const classes = useStyles();
    const { customerId, role } = props;
    const [tenantsList, setTenantsList] = useState([]);
    const [loading, setLoading] = useState(false);

    // const { customerId } = useSelector((state) => ({
    //   customerId: state.userDetailsReducer.id,
    // }));

    async function getAccountList() {
        setLoading(true);
        await axios
            .get(REACT_APP_AWS_TENANTS, {
                params: { customerId },
            })
            .then((response) => response.data)
            .then((response) => {
                if (response.statusCode === 200) {
                    setTenantsList(response.data);
                } else {
                }
                // console.log(response);
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        getAccountList();
    }, [customerId, props.shouldReload]);

    function LoadingImage() {
        setInterval(() => {});
        return (
            <div style={{ marginTop: 40, marginBottom: 40 }}>
                <img src={APP_LOGO} style={{}} className={"image"} />
                {/*<span>Loading accounts</span>*/}
            </div>
        );
    }

    function onConnect() {
        props.onConnect();
        // return <Link to={RoutesList.AWS_CONNECT} />
        // history.push(RoutesList.AWS_CONNECT);
        // new BrowserRouter().props.forceRefresh();
    }

    return (
        <div style={{pointerEvents: 'none'}}>
            {props.hasHeader && (
                <Card.Header>
                    <ConnectionHeader Icon={AWS_LOGO} tooltip={"Connect AWS Account"} onConnect={onConnect} />
                </Card.Header>
            )}
            <div
                style={{
                    display: loading ? "flex" : "inherit",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                }}
            >
                {loading ? (
                    <CircularProgress />
                ) : tenantsList && tenantsList.length ? (
                    tenantsList.map((t, i) => (
                        <Tenant
                            header={props.hasHeader}
                            name={t.displayName}
                            tenantId={t.tenantId}
                            s3BucketName={t.s3BucketName}
                            s3BucketCostReportName={t.s3BucketCostReportName}
                            s3bucketCostreportPathPrefix={t.s3bucketCostreportPathPrefix}
                            roleArn={t.roleArn}
                            uuidAccountId={t.uuidAccountId}
                            organizations={t.organization}
                            listAccounts={t.listAccounts}
                            errors={t.errors}
                            status={t.status}
                            key={i}
                            data={t}
                            onEdit={() => props.onEdit(t)}
                            role={role}
                        />
                    ))
                ) : (
                    <span>No account found</span>
                )}
            </div>
        </div>
    );
}

function Tenant({ name, tenantId, s3BucketName, s3BucketCostReportName, s3bucketCostreportPathPrefix, roleArn, uuidAccountId, data, organizations, listAccounts, onEdit, header, status, errors = null, role }) {
    const classes = useStyles();
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div>
            <Paper elevation={3}>
                <div
                    style={{
                        backgroundColor: Colors.tableHeader,
                        alignItems: "center",
                        flexDirection: "row",
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 8,
                    }}
                >
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        <span className="name">
                            {" "}
                            <img
                                src={AWS_SUB}
                                alt="AWS_SUB"
                                style={{
                                    width: 24,
                                    marginRight: 4,
                                    padding: 3,
                                }}
                            />{" "}
                            {name}
                        </span>{" "}
                        <span style={{ fontWeight: "bold", marginLeft: 12 }}>({tenantId})</span>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ marginRight: 16 }}>
                            {!isEditing ? (
                                // <Link style={{ pointerEvents: role == "Owner" || role == null ? "initial" : "none" }}>

                                        <LinkText
                                            onClick={() =>
                                               setIsEditing(true)
                                            }
                                            role={role}
                                        >
                                            <EditSharp style={{ fontSize: 18 }} />
                                            Edit
                                        </LinkText>

                                // </Link>
                            ) : (
                                // <Link>
                                    <div onClick={() => setIsEditing(false)}>
                                        <CancelRoundedIcon/>
                                        <span>Cancel Edit</span>
                                    </div>
                                // </Link>
                            )}
                        </div>
                        <span>
                            {" "}
                            {status == "active" ? (
                                <div>
                                    <img
                                        src={VerfiedIcon}
                                        alt="VerfiedIcon"
                                        style={{
                                            width: 24,
                                            marginRight: 4,
                                            padding: 3,
                                        }}
                                    />
                                    <span>Active</span>
                                </div>
                            ) : errors?.length > 0 ? (
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={
                                        <Popover id="popover-contained">
                                            <Popover.Title as="h3">
                                                <img
                                                    src={ErrorIcon}
                                                    alt="ErrorIcon"
                                                    style={{
                                                        width: 24,
                                                        marginRight: 4,
                                                        padding: 3,
                                                    }}
                                                />
                                                {status}
                                            </Popover.Title>
                                            <Popover.Content>{errors.length > 0 && errors?.[0]?.message}</Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <div>
                                        <img
                                            src={ErrorIcon}
                                            alt="ErrorIcon"
                                            style={{
                                                width: 24,
                                                marginRight: 4,
                                                padding: 3,
                                            }}
                                        />
                                        <span style={{ color: "red" }}>{status}</span>
                                    </div>
                                </OverlayTrigger>
                            ) : (
                                <div>
                                    <img
                                        src={ErrorIcon}
                                        alt="ErrorIcon"
                                        style={{
                                            width: 24,
                                            marginRight: 4,
                                            padding: 3,
                                        }}
                                    />
                                    <span style={{ color: "red" }}>{status}</span>
                                </div>
                            )}
                        </span>
                    </div>
                </div>

                {isEditing ? (
                    <EditAWSAccount data={data} />
                ) : organizations && organizations?.length ? (
                    organizations.map((o, i) => <Organization name={o.Name} Id={o.Id} accounts={o.Account} orgUnits={o.OrgUnits} key={i} />)
                ) : listAccounts && listAccounts?.length ? (
                    <TableContainer component={Paper}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center">Account Id</StyledTableCell>
                                    <StyledTableCell align="center">Account Name</StyledTableCell>
                                    <StyledTableCell align="center">Role Arn</StyledTableCell>
                                    <StyledTableCell align="center">Email</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {listAccounts && listAccounts?.length ? (
                                listAccounts.map((l, i) => <LinkedAccounts id={l.Id} name={l.Name} status={l.Status} arn={l.Arn} email={l.Email} key={i} />)
                            ) : (
                                <Typography style={{ justifyContent: "center", padding: 4 }}>No Org Account found</Typography>
                            )}
                        </Table>
                    </TableContainer>
                ) : (
                    <AccountDetails data={data} />
                )}
            </Paper>
            <br />
        </div>
    );
}

function AccountDetails({ data }) {
    return (
        <div style={{ flexDirection: "column", padding: 8 }}>
            <Typography style={{ padding: 4 }}>
                <span style={{ fontWeight: "bold" }}>Role Arn :</span> {data.roleArn}
            </Typography>
            <Typography style={{ padding: 4 }}>
                {" "}
                <span style={{ fontWeight: "bold" }}>S3 Bucket name :</span> {data.s3BucketName}
            </Typography>
            <Typography style={{ padding: 4 }}>
                {" "}
                <span style={{ fontWeight: "bold" }}>Report name :</span> {data.s3BucketCostReportName}
            </Typography>
            <Typography style={{ padding: 4 }}>
                {" "}
                <span style={{ fontWeight: "bold" }}>Report Path Prefix name :</span> {data.s3bucketCostreportPathPrefix}
            </Typography>
        </div>
    );
}

function LinkedAccounts({ id, name, status, arn, email }) {
    return (
        <TableBody>
            <StyledTableRow hover key={id}>
                <StyledTableCell align="center">{id}</StyledTableCell>
                <StyledTableCell align="center">{name}</StyledTableCell>
                <StyledTableCell align="center">{arn}</StyledTableCell>
                <StyledTableCell align="center">{email}</StyledTableCell>
                <StyledTableCell align="right">
                    {status == "ACTIVE" ? (
                        <div>
                            <img
                                src={VerfiedIcon}
                                alt="VerfiedIcon"
                                style={{
                                    width: 24,
                                    marginRight: 4,
                                    padding: 3,
                                }}
                            />
                            <span>Active</span>
                        </div>
                    ) : (
                        <div>
                            <img
                                src={ErrorIcon}
                                alt="ErrorIcon"
                                style={{
                                    width: 24,
                                    marginRight: 4,
                                    padding: 3,
                                }}
                            />
                            <span style={{ color: "red" }}>{status}</span>
                        </div>
                    )}
                </StyledTableCell>
            </StyledTableRow>
        </TableBody>
    );
}

function Organization({ name, Id, accounts, orgUnits }) {
    const classes = useRowStyles();

    return (
        <Paper elevation={3}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <span
                        style={{
                            fontFamily: "Poppins_SemiBold",
                            marginTop: 5,
                            marginBottom: 5,
                            marginLeft: 16,
                        }}
                    >
                        {name}
                    </span>
                    <span
                        style={{
                            marginTop: 5,
                            marginBottom: 5,
                            marginLeft: 16,
                        }}
                    >
                        {Id}
                    </span>
                    <br />
                    <TableBody>{accounts && accounts.length ? accounts.map((a, i) => <Account name={a.Name} orgUnits={orgUnits} status={a.Status} id={a.Id} key={i} />) : null}</TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

function Account({ color: variant, status, name, id, org_icon, accounts = [], orgUnits = [] }) {
    const classes = useRowStyles();

    const [isCollapsing, setIsCollapsing] = useState(false);
    function onCollapse() {
        setIsCollapsing(!isCollapsing);
    }
    const shouldShowCollapse = accounts?.length > 0 || orgUnits?.length > 0 || null;
    return (
        // <div style={{ padding: 5, marginLeft: 20 }}>
        <React.Fragment>
            {/* <StyledTableRow hover className={classes.root} key={id}> */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: 8,
                }}
            >
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <IconButton aria-label="expand row" edge="end" size="small" onClick={onCollapse}>
                        {shouldShowCollapse ? isCollapsing ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> : null}
                    </IconButton>
                    <Typography>
                        {" "}
                        {id ? (
                            <img
                                src={org}
                                alt="org"
                                style={{
                                    width: 20,
                                    marginLeft: 4,
                                    marginRight: 4,
                                }}
                            />
                        ) : (
                            <FolderOutlinedIcon style={{ margin: 4 }} />
                        )}
                        {name}
                    </Typography>
                    <Typography style={{ fontWeight: "bold", marginLeft: 12 }}> {id ? "(" + id + ")" : null} </Typography>
                </div>
                <div>
                    {/* {status == "ACTIVE" ? (
            <div>
              <img
                src={VerfiedIcon}
                alt="VerfiedIcon"
                style={{
                  width: 24,
                  marginRight: 4,
                  padding: 3,
                }}
              />
              <span>Active</span>
            </div>
          ) : null} */}
                </div>
            </div>

            {/* <TableCell></TableCell>

        <TableCell
          component="th"
          scope="row"
          size="small"
          padding="none"
        ></TableCell>

        <TableCell
          component="th"
          scope="row"
          size="small"
          padding="none"
        ></TableCell> */}
            {/* </StyledTableRow> */}
            <Divider />
            <Collapse in={!isCollapsing}>
                <div id="accounts-list">
                    {accounts && accounts.length ? accounts.map((a, i) => <Account color={"success"} name={a.Name} orgUnits={orgUnits} id={a.Id} key={i} status={a.Status} />) : null}
                    {orgUnits && orgUnits.length ? orgUnits.map((ou, i) => <Account color={"info"} name={ou.Name} accounts={ou?.Account} orgUnits={ou.OrgUnits} org_icon={"aaa"} key={i} />) : null}
                </div>
            </Collapse>
        </React.Fragment>
        // </div>
    );
}
