import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import GCP_ICON from "../../../images/GCP_logo.svg";
import gcp_billing from "../../../images/gcp_billing.svg";
import gcp_project from "../../../images/gcp_project.svg";
import gcp_org from "../../../images/gcp_org.svg";
import "./Gcp.css";
import QuestionMark from "../../../images/questionmark.png";
import Modal from "react-bootstrap/Modal";
import axios from "../../../connection/axios";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";
import { LinearProgress, Breadcrumbs, Typography, makeStyles, Paper, Grid, Link, colors } from "@material-ui/core";

const { REACT_APP_GCP_ADD_ACCOUNT } = process.env;
export default function ListGCPAccount() {
    const [gcpAccounts, setGcpAccounts] = useState([]);
    const [connectionLoading, setConnectionLoading] = useState(false);
    const { id } = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const [progress, setProgress] = useState(0);
    function getGCPAccounts(customerId) {
        setConnectionLoading(true);
        axios
            .get(REACT_APP_GCP_ADD_ACCOUNT + "getGCPAccounts", {
                params: { customerId },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if (responseJson.statusCode == 200) {
                    if (responseJson.data.organizations.length > 0) {
                        setGcpAccounts(responseJson.data.organizations);
                        // console.log("gcpconnect", responseJson);
                    } else {
                        alert("Something went wrong");
                    }
                }
                setConnectionLoading(true);
            })
            .finally(() => setConnectionLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }
    useEffect(() => {
        //
        getGCPAccounts(id);
    }, [id]);
    return (
        <div>
            {connectionLoading ? (
                <div>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
            ) : (
                <div>
                    <Card>
                        <Card.Header>
                            <div>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link color="inherit" href="/dashboard/charts">
                                        Home
                                    </Link>
                                    <Link color="inherit" href="/dashboard/gcp">
                                        GCP
                                    </Link>
                                    <Typography color="textPrimary">Account</Typography>
                                </Breadcrumbs>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src={GCP_ICON}
                                    alt="GCP logo"
                                    style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 12,

                                        padding: 4,
                                    }}
                                />
                                <h3 style={{ marginTop: 12 }}>GCP Accounts</h3>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div
                                style={{
                                    borderRadius: 5,
                                    padding: 10,
                                    borderWidth: 2,
                                    paddingTop: 10,
                                    borderColor: "#E8E8E8",
                                    borderStyle: "solid",
                                    backgroundColor: "#FFF",
                                    paddingLeft: 10,
                                }}
                            >
                                {gcpAccounts.map((item, index) => (
                                    <div>
                                        <ListGroup>
                                            <ListGroup.Item>
                                                <img
                                                    src={gcp_org}
                                                    alt="GCP Organization logo"
                                                    style={{
                                                        width: 25,
                                                        height: 25,
                                                        marginRight: 12,
                                                        padding: 3,
                                                    }}
                                                />
                                                <b>{item.organizationName}</b>
                                            </ListGroup.Item>
                                            <ListGroup.Item>{item.organizationId}</ListGroup.Item>
                                        </ListGroup>
                                        <br />
                                        <div>
                                            {item.billingAccounts?.map((item, index) => (
                                                <Table striped bordered hover>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <img
                                                                    src={gcp_billing}
                                                                    alt="GCP Billing logo"
                                                                    style={{
                                                                        width: 25,
                                                                        height: 25,
                                                                        marginRight: 12,
                                                                        padding: 3,
                                                                    }}
                                                                />
                                                                Billing Name
                                                            </th>
                                                            <th>Billing Id</th>
                                                            <th>Display Name</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{item.billingAccountName}</td>
                                                            <td>{item.billingAccountId}</td>
                                                            <td>{item.displayName}</td>
                                                            <td>{item.status}</td>
                                                        </tr>
                                                    </tbody>
                                                    <Table className="center">
                                                        <thead>
                                                            <tr align="center">
                                                                <th>
                                                                    <img
                                                                        src={gcp_project}
                                                                        alt="GCP Project logo"
                                                                        style={{
                                                                            width: 20,
                                                                            height: 20,
                                                                            marginRight: 12,
                                                                            padding: 4,
                                                                        }}
                                                                    />
                                                                    Project Id{" "}
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        {item.projects?.map((item, index) => (
                                                            <tbody>
                                                                <ListGroup align="center" variant="flush">
                                                                    <ListGroup.Item>{item.id}</ListGroup.Item>
                                                                </ListGroup>
                                                            </tbody>
                                                        ))}
                                                    </Table>
                                                </Table>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            )}
        </div>
    );
}
