import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Format from "components/NumberFormat";
import { useSelector, useDispatch } from "react-redux";
import { azureServerlessRequest, azureServerlessTenantRequest, azureServerlessHomeRequest} from "../../redux/actions/actions";
import { LinearProgress } from "@material-ui/core";
import gcp_billing from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import serverless_icon from "../../images/newCloudTrakrIcons/AzureServerless.png";
import DateRange from "../../components/DateRange";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import TableSearch from "../../components/TableSearch";
import chevronRight from "../../images/chevron-right.svg";
import ApiManagement_logo from "../../images/api-management-services.png";
import AzureCosmosDB_logo from "../../images/azure-cosmos-db.png";
import FunctionApps_logo from "../../images/function-apps.png";
import LogAnalytics_logo from "../../images/Log-Analytics-Workspaces.svg";
import StorageAccounts_logo from "../../images/Storage-Accounts.svg";
import ApplicationInsights_logo from "../../images/application-insights.png";
import AppService from "../../images/App-Services.svg";
import Function_logo from "../../images/function.png";
import big_query from "../../images/gcpResources/gcp_bigquery.svg";
import ComputeIcon from "../../images/Virtual-Machine.svg";
import ContainerCardWithDateRange from "../ContainerCardWithDateRange";
import TablePaginationActions from "../TablePaginationActions";
import IconMapper from '../../util/IconMapper.js'

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 440,
    },
});

export default function AzureServerlessHome({subscriptionId=null, tenantId=null, currency = "$" }) {
    const dispatch = useDispatch();
    const classes = useRowStyles();
    const [progress, setProgress] = useState(0);
    const [period, setPeriod] = useState("1M");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.id,
    }));

    let AzureServerlessData = useSelector((state) => subscriptionId ? state?.azureServerlessReducer?.charts?.charts:(tenantId) ? state?.azureServerlessReducer?.homeTenantData?.charts : state?.azureServerlessReducer?.homeData?.charts);

    let loading = useSelector((state) => state?.azureServerlessReducer?.loading);

    console.log('Azure Serverless',AzureServerlessData)

    useEffect(() => {
        const customerId = userData.id;
        if (subscriptionId) {
        dispatch(
            azureServerlessRequest({
                customerId,
                tenantId,
                subscriptionId,
            })
        );
        }else if(tenantId){
            dispatch(
                azureServerlessTenantRequest({
                    customerId,
                    tenantId,
                })
            );
            
        }else{
            dispatch(
                azureServerlessHomeRequest({
                    customerId,
                })
            );
            }
         
    }, [userData.id,subscriptionId,tenantId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.label,
                f?.value,
                
            ], search);
        }
        return true;
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Azure Serverless"}
                defaultPeriod={"1M"}
                titleIcon={serverless_icon}
                showDateRange={true}     
                collapsible={true}        
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(AzureServerlessData?.SpendByServerlessServices?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(AzureServerlessData?.SpendByServerlessServices?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + AzureServerlessData?.SpendByServerlessServices?.[period]?.totalCost ? Format.formatNumber(AzureServerlessData?.SpendByServerlessServices?.[period]?.totalCost): "$__.__"}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!AzureServerlessData != null && AzureServerlessData?.SpendByServerlessServices?.[period]?.dataSet?.length}
                onPeriodChange={()=>handleChangeRowsPerPage({target: {value: 10}})}
            >
                {({ period }) =>
                    AzureServerlessData != null && AzureServerlessData?.SpendByServerlessServices?.[period]?.dataSet ? (
                        <Paper elevation={3}>
                           <TableSearch onSearch={setSearch} />

                            <TableContainer>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell />
                                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Service
                                            </StyledTableCell>

                                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Cost
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(AzureServerlessData?.SpendByServerlessServices?.[period]?.dataSet)?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                            <ExpandRowRG row={r} index={i} data={AzureServerlessData?.SpendByServerlessServices?.[period]?.dataSet} currency={currency} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={AzureServerlessData?.SpendByServerlessServices?.[period]?.dataSet?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                        </Paper>
                    ) : (
                        <span style={{ display: "flex", justifyContent: "center" }}>No data found</span>
                    )
                }
            </ContainerCardWithDateRange>
        </div>
    );
}

function ExpandRowRG(props) {
    const { row, index, data, currency } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="inherit">
                <div style={{display:'flex'}}>
                   <IconMapper name={row?.label} height={18}/>
                    {row?.label}
                    </div>
                </TableCell>

                <TableCell align="center">{currency + Format.formatNumber(Number(row.value).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelDataRG data={row.resources} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}
function ExpandLavelDataRG({ data, open, select, currency }) {
    const [resource, setResource] = useState(null);

    // useEffect(() => {
    //     if (select != null) {
    //         let value = data?.services[select];
    //         setResource(value);
    //     }
    // }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>{<ExpandableTableAws tableRow={data} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function ExpandableTableAws({ tableRow, currency }) {
    const classes = useRowStyles();

    return (
        <Paper elevation={3}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Resource Id
                            </StyledTableCell>

                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Location
                            </StyledTableCell>

                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow.map((r, i) => (
                            <ExpandRow row={r} index={i} currency={currency} table={"services"} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export function ExpandRow(props) {
    const { row, index, data, currency, table } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.cost}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell></TableCell>

                <TableCell align="inherit">
                    {" "}
                    {/* <img src={gcp_project} width={18} height={18} style={{ marginRight: 5 }} /> */}
                    {row.resourceId}
                </TableCell>
                <TableCell align="inherit"> {row.location}</TableCell>

                <TableCell align="center">{currency + Format.formatNumber(Number(row.cost).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    const [resource, setResource] = useState(null);

    // useEffect(() => {
    //     if (select != null) {
    //         let value = data.services[select];
    //         setResource(value);
    //     }
    // }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Meter name</th>

                    <th>Cost</th>
                </tr>

                {data &&
                    data.meter.map((m) => (
                        <tr>
                            <td>{m.meterName}</td>

                            <td>{currency + Format.formatNumber(Number(m?.cost))}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}
