import React, { useEffect, useState } from "react";
import { resourcesExtraction } from "./AzureRecommendation";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Modal, Backdrop, Fade } from "@material-ui/core";
import AvailabilityIcon from "../../images/High_Availability.svg";
import CostIcon from "../../images/Cost.svg";
import SecurityIcon from "../../images/Security.svg";
import OExcellenceIcon from "../../images/Operational-Excellence.svg";
import PerformanceIcon from "../../images/Performance.svg";
import {
  RecHeader,
  HeaderText,
  RecTotal,
  RecTotalText,
  HeaderIcon,
} from "./style";
import RecommendBar from "./RecommendBar";
import NoRecommendation from "./NoRecommendation";
import RecommendationDetailPage from "./RecommendationDetailPage";

const useStyles = makeStyles((theme) => ({
  root1: {
    flexGrow: 1,
    marginBottom: 10,
  },
  paper1: {
    height: 250,
    width: 262,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#eeeeee",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper3: {
    //width: "99%",
    // height: 680,
    //  backgroundColor: "#eee",
    //  boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
  },
}));

function Recommendationtypes({ detail }) {
  const [details, setDetails] = useState(null);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  const [cost, setCost] = useState({
    total: "",
    high: "",
    medium: "",
    low: "",
    totalImpactedResources: "",
    details: [],
  });
  const [security, setSecurity] = useState({
    total: "",
    high: "",
    medium: "",
    low: "",
    totalImpactedResources: "",
    details: [],
  });
  const [availability, setAvailability] = useState({
    total: "",
    high: "",
    medium: "",
    low: "",
    totalImpactedResources: "",
    details: [],
  });
  const [excellence, setExcell] = useState({
    total: "",
    high: "",
    medium: "",
    low: "",
    totalImpactedResources: "",
    details: [],
  });
  const [performance, setPerform] = useState({
    total: "",
    high: "",
    medium: "",
    low: "",
    totalImpactedResources: "",
    details: [],
  });

  useEffect(() => {
    recommendCalculation(detail);
  }, [detail]);

  const recommendCalculation = async (values) => {
    let data = await resourcesExtraction(values);
    const {
      newArray,
      CostIR,
      AvailabilityIR,
      SecurityIR,
      PerformanceIR,
      ExcellenceIR,
      recommendationsVal,
      totalIR,
    } = data;
    try {
      let highSecurity = 0;
      let mediumSecurity = 0;
      let lowSecurity = 0;
      let highAvail = 0;
      let mediumAvail = 0;
      let lowAvail = 0;
      let highExcel = 0;
      let mediumExcel = 0;
      let lowExcel = 0;
      let highCost = 0;
      let mediumCost = 0;
      let lowCost = 0;
      let highPer = 0;
      let mediumPer = 0;
      let lowPer = 0;

      let costHighData = [];
      let costMediumData = [];
      let costLowData = [];
      let securityHighData = [];
      let securityMediumData = [];
      let securityLowData = [];
      let availabilityHighData = [];
      let availabilityMediumData = [];
      let availabilityLowData = [];
      let excellenceHighData = [];
      let excellenceMediumData = [];
      let excellenceLowData = [];
      let performHighData = [];
      let performMediumData = [];
      let performLowData = [];

      if (newArray.length > 0) {
        for (let i = 0; i < newArray.length; i++) {
          if (newArray[i].category === "Security") {
            if (newArray[i].impact === "High") {
              highSecurity++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];

              securityHighData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Medium") {
              mediumSecurity++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              securityMediumData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Low") {
              lowSecurity++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              securityLowData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
          }

          if (newArray[i].category === "HighAvailability") {
            if (newArray[i].impact === "High") {
              highAvail++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              availabilityHighData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Medium") {
              mediumAvail++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              availabilityMediumData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Low") {
              lowAvail++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              availabilityLowData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
          }
          if (newArray[i].category === "OperationalExcellence") {
            if (newArray[i].impact === "High") {
              highExcel++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              excellenceHighData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Medium") {
              mediumExcel++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              excellenceMediumData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Low") {
              lowExcel++;

              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              excellenceLowData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
          }
          if (newArray[i].category === "Cost") {
            if (newArray[i].impact === "High") {
              highCost++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              costHighData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Medium") {
              mediumCost++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              costMediumData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Low") {
              lowCost++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              costLowData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
          }
          if (newArray[i].category === "Performance") {
            if (newArray[i].impact === "High") {
              highPer++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              performHighData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Medium") {
              mediumPer++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              performMediumData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
            if (newArray[i].impact === "Low") {
              lowPer++;
              const {
                impact,
                category,
                impactedField,
                lastUpdated,
                impactedValue,
                shortDescription,
              } = newArray[i];
              performLowData.push({
                impact: impact,
                category: category,
                impactedField: impactedField,
                lastUpdated: lastUpdated,
                impactedValue: impactedValue,
                shortDescription: shortDescription,
              });
            }
          }
        }

        setCost({
          total: highCost + mediumCost + lowCost,
          high: highCost,
          medium: mediumCost,
          low: lowCost,
          totalImpactedResources: recommendationsVal.costVal,
          details: { costHighData, costMediumData, costLowData, CostIR },
        });
        setSecurity({
          total: highSecurity + mediumSecurity + lowSecurity,
          high: highSecurity,
          medium: mediumSecurity,
          low: lowSecurity,
          totalImpactedResources: recommendationsVal.securityVal,
          details: {
            securityHighData,
            securityMediumData,
            securityLowData,
            SecurityIR,
          },
        });
        setAvailability({
          total: highAvail + mediumAvail + lowAvail,
          high: highAvail,
          medium: mediumAvail,
          low: lowAvail,
          totalImpactedResources: recommendationsVal.availabilityVal,
          details: {
            availabilityHighData,
            availabilityMediumData,
            availabilityLowData,
            AvailabilityIR,
          },
        });
        setExcell({
          total: highExcel + mediumExcel + lowExcel,
          high: highExcel,
          medium: mediumExcel,
          low: lowExcel,
          totalImpactedResources: recommendationsVal.excellenceVal,
          details: {
            excellenceHighData,
            excellenceMediumData,
            excellenceLowData,
            ExcellenceIR,
          },
        });
        setPerform({
          total: highPer + mediumPer + lowPer,
          high: highPer,
          medium: mediumPer,
          low: lowPer,
          totalImpactedResources: recommendationsVal.performanceVal,
          details: {
            performHighData,
            performMediumData,
            performLowData,
            PerformanceIR,
          },
        });
      }
    } catch (error) {}
  };

  return (
    <div className={classes.root1}>
      <Grid container spacing={2}>
        <Grid item xs>
          <Paper
            className={cost?.total != 0 ? classes.paper1 : null}
            variant={
              details != null
                ? details.type == "Cost"
                  ? "outlined"
                  : null
                : null
            }
            elevation={cost?.total != 0 ? 3 : 0}
            onClick={
              cost?.total != 0
                ? () => {
                    handleOpen();
                    setDetails({
                      data: cost,
                      type: "Cost",
                    });
                  }
                : null
            }
          >
            <RecHeader>
              <HeaderIcon src={CostIcon} />
              <HeaderText>Cost</HeaderText>
            </RecHeader>
            {cost?.total != 0 ? (
              <div>
                <RecTotal>
                  <h6>{cost?.total}</h6>
                  <RecTotalText>
                    <b>Recommendations</b>
                  </RecTotalText>
                </RecTotal>
                <div>
                  {cost && (
                    <RecommendBar
                      high={cost?.high}
                      medium={cost?.medium}
                      low={cost?.low}
                      IR={cost?.totalImpactedResources}
                    />
                  )}
                </div>
              </div>
            ) : (
              <NoRecommendation rec={"cost"} />
            )}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={security?.total != 0 ? classes.paper1 : null}
            variant={
              details != null
                ? details.type == "Security"
                  ? "outlined"
                  : null
                : null
            }
            elevation={security?.total != 0 ? 3 : 0}
            onClick={
              (() => {
                handleScroll();
              },
              security?.total != 0
                ? () => {
                    handleOpen();
                    setDetails({
                      data: security,
                      type: "Security",
                    });
                  }
                : null)
            }
          >
            <RecHeader>
              <HeaderIcon src={SecurityIcon} />
              <HeaderText>Security</HeaderText>
            </RecHeader>
            {security?.total != 0 ? (
              <div>
                <RecTotal>
                  <h6>{security?.total}</h6>
                  <RecTotalText>
                    <b>Recommendations</b>
                  </RecTotalText>
                </RecTotal>
                {security && (
                  <RecommendBar
                    high={security?.high}
                    medium={security?.medium}
                    low={security?.low}
                    IR={security?.totalImpactedResources}
                  />
                )}
              </div>
            ) : (
              <NoRecommendation rec={"security"} />
            )}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={availability?.total != 0 ? classes.paper1 : null}
            elevation={availability?.total != 0 ? 3 : 0}
            variant={
              details != null
                ? details.type == "HighAvailability"
                  ? "outlined"
                  : null
                : null
            }
            onClick={
              availability?.total != 0
                ? () => {
                    handleOpen();
                    setDetails({
                      data: availability,
                      type: "HighAvailability",
                    });
                  }
                : null
            }
          >
            <RecHeader>
              <HeaderIcon availability src={AvailabilityIcon} />
              <HeaderText>High availability</HeaderText>
            </RecHeader>
            {availability?.total != 0 ? (
              <div>
                <RecTotal>
                  <h6>{availability?.total}</h6>
                  <RecTotalText>
                    <b>Recommendations</b>
                  </RecTotalText>
                </RecTotal>
                {availability && (
                  <RecommendBar
                    high={availability?.high}
                    medium={availability?.medium}
                    low={availability?.low}
                    IR={availability?.totalImpactedResources}
                  />
                )}
              </div>
            ) : (
              <NoRecommendation rec={"high availability"} />
            )}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={excellence?.total != 0 ? classes.paper1 : null}
            elevation={excellence?.total != 0 ? 3 : 0}
            variant={
              details != null
                ? details.type == "OperationalExcellence"
                  ? "outlined"
                  : null
                : null
            }
            onClick={
              excellence?.total != 0
                ? () => {
                    handleOpen();
                    setDetails({
                      data: excellence,
                      type: "OperationalExcellence",
                    });
                  }
                : null
            }
          >
            <RecHeader>
              <HeaderIcon src={OExcellenceIcon} />
              <HeaderText style={{ marginLeft: 0 }}>
                Operational excellence
              </HeaderText>
            </RecHeader>
            {excellence?.total != 0 ? (
              <div>
                <RecTotal>
                  <h6>{excellence?.total}</h6>
                  <RecTotalText>
                    <b>Recommendations</b>
                  </RecTotalText>
                </RecTotal>
                {excellence && (
                  <RecommendBar
                    high={excellence?.high}
                    medium={excellence?.medium}
                    low={excellence?.low}
                    IR={excellence?.totalImpactedResources}
                  />
                )}
              </div>
            ) : (
              <NoRecommendation rec={"operational excellence"} />
            )}
          </Paper>
        </Grid>
        <Grid item xs>
          <Paper
            className={performance?.total != 0 ? classes.paper1 : null}
            elevation={performance?.total != 0 ? 3 : 0}
            variant={
              details != null
                ? details.type == "Performance"
                  ? "outlined"
                  : null
                : null
            }
            onClick={
              performance?.total != 0
                ? () => {
                    handleOpen();
                    setDetails({ data: performance, type: "Performance" });
                  }
                : null
            }
          >
            <RecHeader>
              <HeaderIcon src={PerformanceIcon} />
              <HeaderText>Performance</HeaderText>
            </RecHeader>
            {performance?.total != 0 ? (
              <div>
                <RecTotal>
                  <h6>{performance?.total}</h6>
                  <RecTotalText>
                    <b>Recommendations</b>
                  </RecTotalText>
                </RecTotal>
                {performance && (
                  <RecommendBar
                    high={performance?.high}
                    medium={performance?.medium}
                    low={performance?.low}
                    IR={performance?.totalImpactedResources}
                  />
                )}
              </div>
            ) : (
              <NoRecommendation rec={"performance"} />
            )}
          </Paper>
        </Grid>
      </Grid>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div
              className={classes.paper3}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RecommendationDetailPage
                data={details?.data}
                selectedRecommendation={details?.type}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    </div>
  );
}

export default Recommendationtypes;
