const colors = {
  skyBlue: "#31BEF3",
  yellow: "#FBD63D",
  white: "#EFEFEF",
  green: "#81C784",
  background: "#f5f5f5",
  tableHeader: "#cfdac8",
  blue: "#3f51b5",
  gray: "#a9a9a9",
  lightGreen:'#39b83e'
};
export default colors;
