import { combineReducers } from "redux";
import tokenReducer from "./tokenReducer";
import userDetailsReducer from "./userDetailsReducer";
import allHomeReducer from "./allHomeReducer";
import allGcpReducer from "./allGcpReducer";
import accountStatusReducer from "./accountStatusReducer";
import azureRecommendationReducer from "./azureRecommendationReducer";
import azureServerlessReducer from "./azureServerlessReducer";
import awsServerlessReducer from "./awsServerlessReducer";
import gcpServerlessReducer from "./gcpServerlessReducer";
import getBudgetReducer from "./getBudgetReducer";
import invoiceReducer from "./invoiceReducer";
import reportsReducer from "./reportsReducer";
import getbudgetSummaryReducer from "./getBudgetSummaryReducer";
import azureManagementGroupReducer from "./azureManagmentGroupReducer";
import awsReservationReducer from "./awsReservationReducer";
import connectionErrorReducer from "./connectionErrorReducer";
import azureReservationReducer from "./azureReservationReducer";
import azureCCKReducer from "./azureCCKReducer";
import AWSCCKReducer from "./awsCCKReducer";
import azureCosmosDBReducer from "./azureCosmosDBReducer";
import azureCosmosDBMetricsReducer from "./azureCosmosDBMetricsReducer";
import azureCosmosDBSubReducer from "./azureCosmosDBSubReducer";
import awsDynamoDBReducer from "./awsDynamoDBReducer";
import awsLambdaReducer from "./awslambdaReducer";
import gcpProjectReducer from "./gcpProjectReducer";
import appStateReducer from "./appStateReducer";
import gcpServicesReducer from "./gcpServicesReducer";
import gcpComputeReducer from "./gcpComputeReducer";
import gcpStorageReducer from "./gcpStorageReducer";
import allComputeReducer from "./allComputeReducer";
import connectedTeamsReducer from "./connectedTeamsReducer";
import azureFunctionReducer from "./azureFunctionReducer";
import allDatabaseReducer from "./allDatabaseReducer";
import allFunctionsReducer from "./allFunctionsReducer";
import allStorageReducer from "./allStorageReducer";
import awsRDSReducer from "./awsRDSReducer";
import awsComputeOptimizerReducer from "./awsComputeOptimizerReducer";
import azureStorageReducer from "./azureStorageReducer";
import allAccountReducer from "./allAccountReducer";
import allServicesReducer from "./allServicesReducer";
import allResourcesReducer from "./allResourcesReducer";
import awsRDSRecommendationReducer from "./awsRDSRecommendationReducer";
import awsEC2RecommendationReducer from "./awsEC2RecommeandationReducer";
import azureTagsReducer from "./azureTagsReducer";
import awsRecommeandationSummaryReducer from "./awsRecommendationSummaryReducer";
import azureServicesReducer from './azureServicesReducer';
import awsDynamoDBRecommendationReducer from "./awsDynamoDBRecommendationReducer";
import azureStoppedVmRecommendationReducer from "./azureStoppedVmRecommendationReducer";
import awsS3RecommendationReducer from "./awsS3RecommendationReducer";
import awsConnectionReducer from "./awsConnectionReducer";

const rootReducer = (state, action) => {
    if (action.type === "SIGN_OUT") {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

const appReducer = combineReducers({
    tokenReducer,
    appStateReducer,
    allHomeReducer,
    accountStatusReducer,
    allGcpReducer,
    userDetailsReducer,
    azureRecommendationReducer,
    azureServerlessReducer,
    awsServerlessReducer,
    gcpServerlessReducer,
    getBudgetReducer,
    invoiceReducer,
    reportsReducer,
    getbudgetSummaryReducer,
    azureManagementGroupReducer,
    awsReservationReducer,
    connectionErrorReducer,
    azureReservationReducer,
    azureCCKReducer,
    AWSCCKReducer,
    azureCosmosDBReducer,
    azureCosmosDBMetricsReducer,
    azureCosmosDBSubReducer,
    awsDynamoDBReducer,
    awsLambdaReducer,
    gcpProjectReducer,
    gcpServicesReducer,
    gcpComputeReducer,
    gcpStorageReducer,
    allComputeReducer,
    connectedTeamsReducer,
    azureFunctionReducer,
    allDatabaseReducer,
    allFunctionsReducer,
    allStorageReducer,
    awsRDSReducer,
    awsComputeOptimizerReducer,
    azureStorageReducer,
    allAccountReducer,
    allServicesReducer,
    allResourcesReducer,
    awsRDSRecommendationReducer,
    awsEC2RecommendationReducer,
    azureTagsReducer,
    awsRecommeandationSummaryReducer,
    azureServicesReducer,
    awsDynamoDBRecommendationReducer,
    azureStoppedVmRecommendationReducer,
    awsS3RecommendationReducer,
    awsConnectionReducer
});
