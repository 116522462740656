import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { Divider } from "@material-ui/core";
import Format from "../../components/NumberFormat";
import { Alert } from "bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import reservation from "../../images/Reserved-Instance-Reporting.svg";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../TablePaginationActions";
import TableSearch from "../TableSearch";

const Months = ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 450,
    },
}));

export default function AwsReservationTable({ title = "", data = null, currency, loading, progress }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useRowStyles();

    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.billType,
                f?.lineItemType,
                f?.productCode,
                f?.reservationSubscriptionId,
                f?.awsAccountId,
            ], search);
        }
        return true;
    }

    return (
        <div>
            <Paper elevation={0.5}>
                <TableSearch onSearch={setSearch} />
                <TableContainer style={{ height: 350 }} className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Date
                                </StyledTableCell>

                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Bill type
                                </StyledTableCell>

                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Line item type
                                </StyledTableCell>

                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Product code
                                </StyledTableCell>

                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Reservation
                                </StyledTableCell>
                                {title == "AWSReservation" && (
                                    <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                        AWS account id
                                    </StyledTableCell>
                                )}

                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Blended cost
                                </StyledTableCell>

                                <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                    Upfront cost
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{data && data?.map((r, i) => r?.reservations && r?.reservations
                                ?.filter(filteredTable)
                                ?.map((j, k) => <ExpandRow title={title} row={j} month={r} index={i} data={data} currency={currency} />))}</TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={data?.map((r, i) => r?.reservations && r?.reservations
                                                ?.filter(filteredTable)).flat(1)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
            </Paper>
        </div>
    );
}

export function ExpandRow(props) {
    let startMonth;
    let startYear;
    const { row, month, index, data, currency, table, title } = props;
    startYear = month.month.substring(0, 4);
    startMonth = month.month.substring(4);
    const classes = useRowStyles();
    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.date}>
                <TableCell align="center">{Months[Number(startMonth).toString()] + " " + startYear}</TableCell>
                <TableCell align="center">{row?.billType}</TableCell>
                <TableCell align="center">{row?.lineItemType}</TableCell>
                <TableCell align="center">{row?.productCode}</TableCell>
                <TableCell align="center">{row?.reservationSubscriptionId}</TableCell> {title == "AWSReservation" && <TableCell align="center">{row?.awsAccountId}</TableCell>}
                {row.billType == "Purchase" && row.lineItemType == "Fee" ? <TableCell align="center">{currency + Format.formatNumber(Number(row?.blendedCost).toFixed(2))}</TableCell> : <TableCell align="center">--</TableCell>}
                {row.billType == "Anniversary" && row?.lineItemType == "RIFee" ? <TableCell align="center">{currency + Format.formatNumber(Number(row?.reservationUpfrontValue).toFixed(2))}</TableCell> : <TableCell align="center">--</TableCell>}
            </StyledTableRow>
        </React.Fragment>
    );
}
