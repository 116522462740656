import React, { useState, useEffect } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Azure_cosmosDB_icon from "../../images/Azure-Cosmos-DB.svg";
import DynamoDBIcon from "../../images/DynamoDB.svg";

import AzureLogo from "../../images/Azure_logo.svg";
import AwsLogo from "../../images/aws_dark.svg";
import database_icon from "../../images/database.png";
import Collapse from "@material-ui/core/Collapse";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getAllDatabaseRequest } from "../../redux/actions/actions";
import Cost from "../../components/Cost";
import DateRangeformatter, { commonConversion } from "../../util/CosmosDBFormatter";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import { dynamoDBCommonConversion } from "../../util/DynamoDBFormatter";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";

import { CancelRounded } from "@material-ui/icons";
import { bytesToSiz2e, ALPHA_NUM_SORTER } from "../../util/Miscellaneous";
import LineChart from "pages/Charts/LineChart";
import TablePagination from "@material-ui/core/TablePagination";
import CarousalCard from "components/CarousalCard";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";

import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@material-ui/core";
import { bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import TablePaginationActions from "../../components/TablePaginationActions";
import colors from "styles/color";
import { TABLE_SORT } from "../../util/AppConstants";
import TableSortHeader from "../../components/TableSortHeader";
import TableSearch from "../../components/TableSearch";

import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const { REACT_APP_AZURE_COSMOSDB_DETAILS, REACT_APP_AZURE_COSMOSDB_METRICS, REACT_APP_AWS_DYNAMODB_DETAILS, REACT_APP_AWS_DYNAMODB_METRICS, REACT_APP_AZURE_COSMOSDB_CHARTDATA } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{  padding: 0,paddingRight:8 }}>
            {props.children}
        </TCell>
    );
}

const TABLE_SORT_DEFAULT_COLUMN = "cost";

export default function AllCloudDatabase({ period, currency, customerId }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [cosmosData, setCosmosData] = useState([]);
    const [cosmosMetricsData, setCosmosMetricsData] = useState([]);
    const [cosmosMetricsFormatedData, setCosmosMetricsFormatedData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [showMetricSpecific, setShowMetricSpecific] = useState([]);

    const [showingDetails, setShowDetails] = useState(null);
    const [metricTotals, setMetricTotals] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [dynamoDB, setDynamoDBData] = useState([]);
    // const [allArray, setAllArray] = useState("");
    const [cosmosDBLoader, setCosmosDBLoader] = useState(false);
    const [metricsLoding, setMetricsLoding] = useState(false);
    const [azureCosmosDBDetailData, setAzureCosmosDBDetailData] = useState([]);
    const [showingFunction, setShowingFunction] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    let allArray = useSelector((state) => state?.allDatabaseReducer?.allDatabaseData);

    let loading = useSelector((state) => state?.allDatabaseReducer?.loading);

    useEffect(() => {
        if (allArray == null) {
            dispatch(getAllDatabaseRequest({ customerId }));
        }
    }, [customerId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function dataMetricsConversion(data) {
        var final = {};
        for (var key in data?.metrics) {
            final[key] = data?.metrics[key]?.value[0];
            final[key] = final[key]?.timeseries.map((t) => {
                const data = {};
                var dataKey = new Set(Object.keys(t.data[0]));
                dataKey.delete("timeStamp");
                dataKey = dataKey.keys().next().value;
                data[dataKey] = t.data[0][dataKey];

                data["name"] = t?.metadatavalues[0]?.value;
                data["unit"] = final[key]?.unit;
                return data;
            });
        }
        setCosmosMetricsData(final);
    }

    function chartDataConversion(showingMetrics) {
        var result = [];

        for (var obj in showingMetrics) {
            const selected = showingMetrics[obj];

            const name = selected.value?.[0]?.name?.localizedValue;
            const unit = selected.value?.[0]?.unit;

            var metricData = selected.value?.[0]?.timeseries?.[0]?.data?.[0];
            var timeseries = selected.value?.[0]?.timeseries?.[0]?.data;
            if (timeseries && timeseries?.length) {
                var dataKey = new Set(Object.keys(metricData));
                dataKey.delete("timeStamp");
                dataKey = dataKey.keys().next().value;

                var dataKeyT = new Set(Object.keys(timeseries?.[0]));
                dataKeyT.delete("timeStamp");
                dataKeyT = dataKeyT.keys().next().value;

                timeseries = timeseries.map(({ timeStamp, ...rest }) => {
                    let date = new Date(timeStamp);
                    date = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
                    return { date, value: rest[dataKeyT] };
                });

                timeseries = sumDuplicates(timeseries, "date", "value").map(({ date, ...rest }) => ({ ...rest, date: new Date(date) }));
                metricData = { timeseries, unit, total: metricData[dataKey] };
            }
            result.push({ name, unit, metricData, key: obj });
        }

        setChartData(result);
        setMetricTotals(
            result.map((r, i) => {
                // if()
                return (r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b) / (r.unit.toLowerCase() === "percent" ? r?.metricData?.timeseries.length : 1)).toFixed(2);
            })
        );
    }

    function showFunctionDetails(e) {
        setCosmosDBLoader(true);

        axios
            .get(REACT_APP_AZURE_COSMOSDB_DETAILS, {
                params: {
                    customerId,
                    subscriptionId: e?.subscriptionId,
                    resourceGroups: e?.resourceId.split("/")[4],
                    databaseAccounts: e?.resourceId.split("/")[8],
                },
            })
            .then((response) => response?.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response?.data) {
                        setShowingFunction({ ...e, ...response.data?.azureCosmosDbAccountDetails?.[0] });

                        getFunctionMetricData(e);
                        getFunctionChartData(e);
                    } else {
                        setShowingFunction(null);
                    }
                }
            })
            .catch((e) => {})
            .finally(() => setCosmosDBLoader(false));
    }

    function getFunctionMetricData(e) {
        setMetricsLoding(true);

        axios
            .get(REACT_APP_AZURE_COSMOSDB_METRICS, {
                params: {
                    customerId,
                    subscriptionId: e?.subscriptionId,
                    resourceGroups: e?.resourceId.split("/")[4],
                    databaseAccounts: e?.resourceId.split("/")[8],
                },
            })
            .then((response) => response?.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        dataMetricsConversion(response?.data);
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setMetricsLoding(false));
    }

    function getFunctionChartData(e) {
        setMetricsLoding(true);

        axios
            .get(REACT_APP_AZURE_COSMOSDB_CHARTDATA, {
                params: {
                    customerId,
                    subscriptionId: e?.subscriptionId,
                    resourceGroups: e?.resourceId.split("/")[4],
                    databaseAccounts: e?.resourceId.split("/")[8],
                },
            })
            .then((response) => response?.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        chartDataConversion(response?.data?.metrics);
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setMetricsLoding(false));
    }

    function showDynamoFunctionDetails(e) {
        setCosmosDBLoader(true);

        axios
            .post(REACT_APP_AWS_DYNAMODB_DETAILS, {
                customerId,
                tableName: e.resourceId,
                tenantId: e.subscriptionId,
                region: e.location,
            })
            .then((response) => response?.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response?.data) {
                        setShowingFunction({ ...e, ...response.data });

                        getDynamoMetricData(e);
                    } else {
                        setShowingFunction(null);
                    }
                }
            })
            .catch((e) => {})
            .finally(() => setCosmosDBLoader(false));
    }
    function getDynamoMetricData(e) {
        setMetricsLoding(true);

        axios
            .post(REACT_APP_AWS_DYNAMODB_METRICS, {
                customerId,
                tableName: e.resourceId,
                tenantId: e.subscriptionId,
                region: e.location,
            })
            .then((response) => response?.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        dataMetricsConversion(response?.data);

                        if (response?.data) {
                            const final = [];
                            for (var o in response?.data) {
                                const data = response?.data?.[o]?.[0];
                                const timeseries = data.Timestamps.map((t, i) => ({
                                    date: new Date(t),
                                    value: data?.Values[i],
                                }));
                                const label = data.Label.match(/[A-Z][a-z]+/g).join(" ");
                                const unit = data.Id.match(/[A-Z][a-z]+/g).join(" ");
                                final.push({ label, timeseries, unit: unit === "Sum" ? "Count" : unit });
                            }
                            // setCosmosMetricsData(final);
                            setChartData(final);

                            setMetricTotals(final.map((r, i) => (r?.timeseries.map((r) => r.value).reduce((a, b) => a + b) / 1).toFixed(2)));
                        }
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setMetricsLoding(false));
    }

    function resolveDateTime(dateTime) {
        const date = moment(dateTime);
        return date.format("LL") + " at " + date.format("LTS");
    }

    const [datePeriod, setDatePeriod] = React.useState(null);

    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_ASC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    const [search, setSearch] = useState(null);
    const [open, setOpen] = useState(false);

    const filterTable = (f) => {
        if (search && search.toString().length) {
            return !!TableSearch.doesItIncludes([f?.resourceId, f?.meters?.[0]?.productFamily, f?.cloudProvider, f?.usageAccountName, f?.usageAccountId, f?.subscriptionId,f?.subscriptionName, f?.resourceType, f?.serviceName, f?.location], search);
        }
        return true;
    };

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Database"}
                titleIcon={database_icon}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start: "__",
                    end: "__",
                    rawStart: moment(allArray?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(allArray?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(allArray?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0))}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!allArray != null && allArray?.[period]?.resources?.length}
                onPeriodChange={(period) => {
                    setDatePeriod(period);
                    handleChangeRowsPerPage({ target: { value: 10 } });
                }}
            >
                {({ period }) => (
                    <div elevation={10} style={{ padding: 10 }}>
                        {allArray && allArray?.[period]?.resources?.length ? (
                                <Paper className={classes.paper}>
                                <CarousalCard
                                    cardTitle1={"Total cost"}
                                    cardTitle2={"Total Resources"}
                                    cost={allArray && allArray?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0)}
                                    res={allArray?.[period]?.resources?.length}
                                    currency={currency}
                                />
                                <TableSearch
                                    onSearch={(e) => {
                                        handleChangeRowsPerPage({ target: { value: 10 } });
                                        setSearch(e);
                                    }}
                                />

                                <TableContainer style={{ height: 350 }} component={Paper}>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                            <TableRow>
                                                <TableSortHeader
                                                    classes={classes}
                                                    numSelected={selected.length}
                                                    order={order}
                                                    orderBy={orderBy}
                                                    onRequestSort={handleRequestSort}
                                                    rowCount={allArray?.[datePeriod]?.resources?.length}
                                                    headCells={[
                                                        {},
                                                        { numeric: 0, id: "id", label: "Resource Id" },
                                                        { numeric: 0, id: "account", label: "Cloud Account/Subscription" },
                                                        { enabled: !showingFunction, numeric: 0, id: "type", label: "Resource type" },
                                                        { enabled: !showingFunction, numeric: 0, id: "location", label: "Location" },
                                                        { enabled: !showingFunction, numeric: 1, id: "cost", label: "Cost" },
                                                    ]}
                                                />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allArray &&
                                                allArray?.[period]?.resources
                                                    ?.filter(filterTable)
                                                    ?.sort((r1, r2) => {
                                                        const fi = {
                                                            cost: parseFloat(r2.cost) - parseFloat(r1.cost),
                                                            id: ALPHA_NUM_SORTER.compare(
                                                                r1?.cloudProvider == "azure" ? r1?.resourceId : r1?.resourceId || r1?.meters?.[0]?.productFamily,
                                                                r2?.cloudProvider == "azure" ? r2?.resourceId : r2?.resourceId || r2?.meters?.[0]?.productFamily
                                                            ),
                                                            account: ALPHA_NUM_SORTER.compare(r1?.usageAccountId || r1?.subscriptionId, r2?.usageAccountId || r2?.subscriptionId),
                                                            type: ALPHA_NUM_SORTER.compare(r1?.serviceName || r1?.resourceType, r2?.serviceName || r2?.resourceType),
                                                            location: ALPHA_NUM_SORTER.compare(r1?.location, r2?.location),
                                                        }[orderBy];
                                                        return fi * order;
                                                    })
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    ?.map((row, index) => {
                                                        return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                       
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={allArray?.[period]?.resources?.filter(filterTable)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

function MyTableRow({ row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row?.serviceName + row?.usageAccountId + row?.cost + index}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th">
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                        {row?.cloudProvider == "azure" ? (
                            <>
                                <img src={Azure_cosmosDB_icon} height={26} style={{ marginRight: 4 }} />
                                <span
                                // onClick={() => {
                                //     showFunctionDetails(row);
                                // }}
                                >
                                    {row?.resourceId?.length > 30 ? (
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-contained">
                                                    <Popover.Content style={{ fontSize: 11 }}>{row?.resourceId}</Popover.Content>
                                                </Popover>
                                            }
                                        >
                                            <div>{row?.resourceId?.substring(0, 30) + "..."}</div>
                                        </OverlayTrigger>
                                    ) : (
                                        row?.resourceId
                                    )}
                                </span>
                            </>
                        ) : (
                            <>
                                <img src={DynamoDBIcon} height={26} style={{ marginRight: 4 }} />
                                <span
                                // onClick={() => {
                                //     showDynamoFunctionDetails(row);
                                // }}
                                >
                                    {row?.resourceId?.length > 30 ? (
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-contained">
                                                    <Popover.Content style={{ fontSize: 11 }}>{row?.resourceId}</Popover.Content>
                                                </Popover>
                                            }
                                        >
                                            <div>{row?.resourceId?.substring(0, 30) + "..."}</div>
                                        </OverlayTrigger>
                                    ) : (
                                        row?.resourceId || <span style={{ color: colors.gray }}> {row?.meters?.[0]?.productFamily}</span>
                                    )}
                                </span>
                            </>
                        )}
                    </div>
                </TableCell>
                <TableCell align="left">
                    {row?.cloudProvider == "azure" ? (
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-contained">
                                    <Popover.Content style={{ fontSize: 11 }}>{row?.subscriptionId}</Popover.Content>
                                </Popover>
                            }
                        >
                            <div>
                                <img src={AzureLogo} height={22} /> {row?.subscriptionName}
                            </div>
                        </OverlayTrigger>
                    ) : (
                        <OverlayTrigger placement="bottom" overlay={<Popover id="popover-contained">{"usageAccountName" in row && <Popover.Content style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Content>}</Popover>}>
                            <div>
                                <img src={AwsLogo} height={22} /> {row?.usageAccountId || row?.usageAccountName}{" "}
                            </div>
                        </OverlayTrigger>
                    )}
                </TableCell>

                <TableCell align="left">{row?.serviceName || row?.resourceType}</TableCell>

                <TableCell align="left">{row?.location}</TableCell>
                <TableCell align="right">
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                 {data?.meters && Object.keys(data?.meters?.[0])?.length > 3 || data?.cloudProvider=='aws' ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Description</th>
                        <th>Usage Type</th>
                        <th>Total</th>
                    </tr>
                ) : (
                    <tr>
                        <th>Service name</th>
                        <th>Meter name</th>
                        <th>Total</th>
                    </tr>
                )}

                {data?.cloudProvider=='aws' ? data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length > 3 ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>
                                    <td>{m.lineItemUsageType||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>{m.serviceName||'-'}</td>
                                    <td>{m?.meterName||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            )
                        ):data && data?.billing?.map((a)=>a?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length > 3 ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : (
                                <tr>
                                    <td>{m.serviceName||'-'}</td>
                                    <td>{m?.meterName||'-'}</td>

                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            )
                        )
                        )}
            </table>
        </div>
    );
}

function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
