import { Paper } from "@material-ui/core";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import React, { useEffect, useState } from "react";
import Carousel from "react-simply-carousel";
import color from "../styles/color";
import Format from "components/NumberFormat";

function CarousalCard(props) {
    const { cardTitle1, cardTitle2,cost, res, width, height, currency} = props;
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <div
            style={{
                display: "flex",
                flexdirection: "row",
                justifyContent: "center",
            }}
        >
            <div style={{ padding: 6 }}>
                <Paper style={{ width: width / 6, height: height / 9, background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 9, textAlign: "center" }} elevation={3}>
                    <h4 className="carouselValue">{Format.formatNumberInt(res)}</h4>
                    <h6 style={{ whiteSpace: "nowrap" }} className="carouselText">
                        {cardTitle2}
                    </h6>
                </Paper>
            </div>
         
            <div style={{ padding: 6 }}>
                <Paper style={{ width: width / 6, height: height / 9, background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 9, textAlign: "center" }} elevation={3}>
                    <h4 className="carouselValue">
                        {currency}
                        {Format.formatNumber(cost)}
                    </h4>
                    <h6 style={{ whiteSpace: "nowrap" }} className="carouselText">
                        {cardTitle1}
                    </h6>
                </Paper>
            </div>
        </div>
    );
}
export default GetWindowDimension(CarousalCard);
