import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import Calendar_Spend_Icon from "../../images/newCloudTrakrIcons/AzureBilling.png";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {PauseCircleFilled, PlayArrow, PlayCircleFilled} from "@material-ui/icons";
import CalendarLoader from "../../components/loaders/CalendarLoader";
import YearTableData from "../../components/YearTableData";
import Format from "../../components/NumberFormat";
import YearCalendarData, {CalendarDataProcessor, Months} from "../../components/YearCalendarData";
import {OverlayComponent} from "../../components/tab/AzureHomeTab";
import aws_account from "../../images/aws_org.svg";
import azure_subscription from "../../images/key.svg";
import gcp_billing_account from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import aws_region from "../../images/aws_region.svg";
import azure_location from "../../images/azure_location.svg";
import cloud_location from "../../images/cloud_location.png";
import resource_group from "../../images/resource_group.svg";
import gcp_compute from "../../images/gcp_compute.svg";
import azure_resource_group from "../../images/azure_resource_group.svg";
import eye_icon from "../../images/eye_icon.svg";
import GroupedBarChart from "../../components/D3Charts/GroupedBarChart";
import {Box} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {TIMERS} from "../../util/AppConstants";
import {getMonthsFromNow} from "../../util/DateFormatter";
import {arraySaveToCSV} from "../../util/Miscellaneous";
import PauseIcon from "@material-ui/icons/Pause";
import subscritpion_icon from "../../images/key.svg";
import azure_service from "../../images/resource_group.svg";
const $ = window.$;

const DEFAULT_BILLING_TABLE_ID = 'azurebillingtable';
const DEFAULT_BILLING_CSV_FILENAME = 'azure_billing.csv';

export default function AzureHomeBilling({somerefs = [], excludeOverlayKeys = [], csv_filename = DEFAULT_BILLING_CSV_FILENAME, csv_table_id = DEFAULT_BILLING_TABLE_ID, calendarLoading, yearTableData, monthWiseData, currency, get1YearTableData = ()=>{}}){
    const [overlayData, setOverlayData] = useState(null);
    const [currentCloud, setCurrentCloud] = useState("total");
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [monthInterval, setMonthInterval] = useState(null);
    const [parentWidth, setParentWidth] = useState(null);

    const billingComponentRef = useRef();

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    useEffect(()=>{
        onResume();
    }, [])

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;

            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear]);

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData]);

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData
                .find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates?.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData?.find((data) => data.month == y.toString() + m.toString());
            // debugger
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
        }
        return { data, date };
    }

    function onResume() {
        setCurrentCloud("total");
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    async function onMonthSelect(m, y = null) {
        if (monthWiseData == null || !monthWiseData) {
            await get1YearTableData(null, null, "daily").then(() => {
                setCurrentMonth(m);
            });
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }
        if (y) setCurrentYear(y);
    }

    window.$(document).ready(rootLoaded);

    function rootLoaded() {
        if (parentWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    useEffect(() => {
        window.$(document).ready(() => {
            rootLoaded();
            // console.log((document.querySelector('#root_dashboard')));
        });
    }, []);

    function onCSVSave(){
        const csvData = $(`#${csv_table_id}`).table2csv('json', {
            separator: "###",
            appendHeaders: ['Cloud', 'azure'],
            removeColumns: 0
        });
        arraySaveToCSV(csvData, csv_filename);
    }

    return (
        <div>
            <div onLoad={rootLoaded} id={"root_dashboard"}><span></span></div>
            <Box ref={billingComponentRef}>
                <ContainerCardWithDateRange someref={somerefs?.[0]} showDateRange={false} title={'Spend by months'} titleIcon={Calendar_Spend_Icon}
                                            HeaderTitleComponent={(e,setCollapseClickable)=>
                                                <>
                                                    <div style={{display: 'flex', alignItems: 'center',
                                                        justifyContent: 'center', marginLeft: 10}}>
                                                        <span>Spend by months</span>
                                                        <div style={{marginLeft: 5}} onMouseOver={()=>setCollapseClickable(false)} onMouseOut={()=>setCollapseClickable(true)}>
                                                            {yearTableData?
                                                                (monthInterval ?
                                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                                        <PauseCircleFilled style={{color: "#0000008a"}} onClick={() => onPause()}/>
                                                                    </OverlayTrigger>:
                                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                                        <PlayCircleFilled style={{color: "#0000008a"}}  onClick={() => onResume()}/>
                                                                    </OverlayTrigger>):
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            saveAsCSV={true}
                                            onSaveAsCSV={onCSVSave}
                >
                    {()=><div style={{}}>
                        {calendarLoading ?
                            <CalendarLoader/> :
                            <>
                                <div style={{}}>
                                    <hr style={{padding: 0, margin: 0}}/>
                                    <div style={{overflow: "hidden", zIndex: 20}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignSelf: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <YearTableData
                                                selectedCloud={"azure"}
                                                hideLegend={true}
                                                width={parentWidth}
                                                data={{
                                                    azure: yearTableData,
                                                }}
                                                months={yearTableData?.map((m) => m.month)}
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                onMonthPress={onMonthSelect}
                                                selectedMonth={currentMonth}
                                                selectedYear={currentYear}
                                                tableId={csv_table_id}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: 8,
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                    }}
                                >
                                    <div style={{flex: 0.5}}></div>
                                    <YearCalendarData
                                        currentCloud={'azure'}
                                        currency={Format.formatCurrencySymbol(currency || "USD")}
                                        year={currentYear}
                                        onNext={() => setCurrentYear(currentYear + 1)}
                                        onPrev={() => setCurrentYear(currentYear - 1)}
                                        yearData={CalendarDataProcessor(yearTableData, "year")}
                                        monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                        onMonthSelect={onMonthSelect}
                                        month={currentMonth}
                                        renderOverlay={(d, m, y, cost) => {
                                            const {data, date} = getOverlayData(d, m, y);

                                            if (data)
                                                return setOverlayData({
                                                    data,
                                                    date,
                                                    cost: data?.cost,
                                                });
                                            return null;
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginLeft: 10,
                                            width: 500,
                                            height: 400,
                                            display: "flex",
                                        }}
                                    >
                                        {/*<OverlayComponent cost={overlayData?.cost} data={overlayData?.data}*/}
                                        {/*                  date={overlayData?.date}*/}
                                        {/*                  dataKeys={*/}
                                        {/*                      [*/}
                                        {/*                          {*/}
                                        {/*                              key: 'resourceGroups',*/}
                                        {/*                              title: "Top Resource Groups",*/}
                                        {/*                              subKey: 'resourceGroupName',*/}
                                        {/*                              icon: resource_group*/}
                                        {/*                          },*/}
                                        {/*                          {*/}
                                        {/*                              key: 'resources',*/}
                                        {/*                              title: "Top Resources",*/}
                                        {/*                              subKey: 'resourceId',*/}
                                        {/*                              icon: resource_group*/}
                                        {/*                          }*/}
                                        {/*                      ]*/}
                                        {/*                  }*/}
                                        {/*                  onClick={() => {*/}
                                        {/*                      setValue("3");*/}
                                        {/*                      props?.setActiveTab("3");*/}
                                        {/*                  }}*/}
                                        {/*/>*/}
                                        {/*<span> sadfas {JSON.stringify(overlayData)}</span>*/}
                                        {overlayData && overlayData.data && (
                                            <OverlayComponent
                                                cost={overlayData?.cost}
                                                data={overlayData?.data}
                                                date={overlayData?.date}
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                dataKeys={[
                                                    {
                                                        key: "subscriptions",
                                                        title: "Top Azure Subscriptions",
                                                        subKey: "subscriptionName",
                                                        subKey2: "subscriptionId",
                                                        icon: subscritpion_icon,
                                                        onMouseOver: 'subscriptionId'
                                                    },
                                                    {
                                                        key: "locations",
                                                        title: "Top Locations",
                                                        subKey: "location",
                                                        icon: azure_location,
                                                    },
                                                    {
                                                        key: "services",
                                                        title: "Top Services",
                                                        subKey: "service",
                                                        icon: azure_service,
                                                    },
                                                    {
                                                        key: "resourceGroups",
                                                        title: "Top Resource Groups",
                                                        subKey: "resourceGroupName",
                                                        icon: resource_group,
                                                    },
                                                    {
                                                        key: "resources",
                                                        title: "Top Resources",
                                                        subKey: "resourceId",
                                                        icon: resource_group,
                                                    },
                                                ].filter(f=>excludeOverlayKeys.indexOf(f.key) === -1)}
                                                onClick={() => {
                                                    // props?.setActiveTab(TAB_SET.RESOURCE_GROUPS_RESOURCES);
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                    </div>}
                </ContainerCardWithDateRange>
            </Box>
        </div>
    )
}
