import { Paper } from "@material-ui/core";
import GetWindowDimension from "components/HOC/GetWindowDimension";
import React, { useEffect, useState } from "react";
import Carousel from "react-simply-carousel";
import color from '../styles/color';

 function RecommendationsCard(props) {
    const {summary, width, height,icon} = props;
    const [activeSlide, setActiveSlide] = useState(0);

    return (
             <div>
            <Carousel
                updateOnItemClick
                containerProps={{
                    style: {
                        width: "100%",
                        justifyContent: "space-between",
                    },
                }}
                activeSlideIndex={activeSlide}
                activeSlideProps={{
                    style: {
                        // background: "blue",
                    },
                }}
                onRequestChange={setActiveSlide}
                forwardBtnProps={{
                    children: ">",
                    style: {
                        width: 60,
                        height: 60,
                        minWidth: 60,
                        alignSelf: "center",
                        background: "white",
                        border: "none",
                        outline: "none",
                        fontSize: 40,
                    },
                }}
                backwardBtnProps={{
                    children: "<",
                    style: {
                        width: 60,
                        height: 60,
                        minWidth: 60,
                        alignSelf: "center",
                        background: "white",
                        border: "none",
                        outline: "none",
                        fontSize: 40,
                    },
                }}
                itemsToShow={summary?.length > 4 ? 4 : summary?.length - 1}
                speed={400}
            >
                {summary && summary?.map((item, index) => (
                    <div style={{ padding: 10 }} key={index}>
                        <Paper style={{ width: width / 6.5, height: height / 7, background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 10, textAlign: "center" }} elevation={3} key={index}>
                            <h5 className="carouselValue">{item?.cost}</h5>
                            <span style={{fontSize:14}}>{item?.costTitle||''}</span>
                        <div style={{display: "flex", flexdirection:'row',alignItems: "center",justifyContent:'center'}}>
                            <img style={{padding:2}} src={item?.icon} height={18} />
                            <span style={{fontSize:14}}>{item?.value} {item.title}</span>
                        </div> 
                        </Paper>
                    </div>
                ))}
            </Carousel>
            </div>
      
    );
}
export default GetWindowDimension(RecommendationsCard);