import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Format from "components/NumberFormat";
import { useSelector, useDispatch } from "react-redux";
import { getGCPProjectAccountRequest } from "../../redux/actions/actions";
import { LinearProgress } from "@material-ui/core";
import gcp_billing from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import DateRange from "../../components/DateRange";
import moment from "moment";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import TablePaginationActions from "../../components/TablePaginationActions";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 440,
    },
});

export default function GCPProjectAccountHome({ currency, selectedSubscription }) {
    const dispatch = useDispatch();
    const classes = useRowStyles();
    const [progress, setProgress] = useState(0);
    const [period, setPeriod] = useState("1M");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

  
    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    let GcpProjectData = useSelector((state) => state?.gcpProjectReducer?.ProjectAccountData);

    let loading = useSelector((state) => state?.gcpProjectReducer?.ProjectAccountLoading);

    useEffect(() => {
        const customerId = userData.id;
        const organizationId = selectedSubscription?.organizationId;
        const billingAccountId = selectedSubscription?.value;
        if(GcpProjectData == null){
        dispatch(
            getGCPProjectAccountRequest({
                customerId,
                organizationId,
                billingAccountId,
            })
        );
        }
    }, [userData.id]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange title={'GCP Projects'} defaultPeriod={"1M"} titleIcon={gcp_project}
                                        ExtraHeaderComponent={({period})=>(
                                            GcpProjectData != null && Object.keys(GcpProjectData?.data?.SpendByProjects?.[period])?.length !== 0 ? (
                                                <div style={{ marginTop: 4 }}>
                                                    <span>{moment(GcpProjectData?.data?.SpendByProjects?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> -{" "}
                                                    <span>{moment(GcpProjectData?.data?.SpendByProjects?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                                                </div>
                                            ) : null
                                        )}
                                        MiddleComponent={({period})=>(
                                            <span style={{display: "flex", justifyContent: "flex-end"}}>
                                               Total: {currency}{!isNaN(Format.formatNumber(Number((GcpProjectData?.data?.SpendByProjects?.[period]?.totalCost)).toFixed(2)))?
                                                Format.formatNumber(Number(GcpProjectData?.data?.SpendByProjects?.[period]?.totalCost).toFixed(2)):
                                                "__.__"}
                                            </span>
                                        )} collapsible={false}
            >
                {({period})=>(
                    GcpProjectData != null && GcpProjectData?.data?.SpendByProjects?.[period]?.projects ? (
                        <Paper elevation={3}>
                            <TableContainer>
                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell />
                                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Project Id
                                            </StyledTableCell>

                                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Billing account Id
                                            </StyledTableCell>
                                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Billing account name
                                            </StyledTableCell>
                                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Organization Id
                                            </StyledTableCell>
                                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                Organization name
                                            </StyledTableCell>

                                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                {/*{currency + Format.formatNumber(Number(GcpProjectData?.data?.SpendByProjects?.[period]?.totalCost).toFixed(2))}*/}
                                                Cost
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(GcpProjectData?.data?.SpendByProjects?.[period]?.projects).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                            <ExpandRowRG row={r} index={i} data={GcpProjectData?.data?.SpendByProjects?.[period]?.projects} currency={currency} />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={GcpProjectData?.data?.SpendByProjects?.[period]?.projects?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                        </Paper>
                    ) : (
                        <span style={{ display: "flex", justifyContent: "center", fontSize: 20 }}>No data found</span>
                    )
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

function ExpandRowRG(props) {
    const { row, index, data, currency } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="inherit">
                    {" "}
                    <img src={gcp_project} width={18} height={18} style={{ marginRight: 5 }} />
                    {row.projectId}
                </TableCell>
                <TableCell align="inherit" component="th" scope="row" size="small" padding="none">
                    {row.billingAccountId}
                </TableCell>

                <TableCell align="inherit" component="th" scope="row" size="small" padding="none">
                    {row.billingAccountName}
                </TableCell>
                <TableCell align="inherit" component="th" scope="row" size="small" padding="none">
                    {row.organizationId}
                </TableCell>
                <TableCell align="inherit" component="th" scope="row" size="small" padding="none">
                    {row.organizationName}
                </TableCell>
                <TableCell align="center">{currency + Format.formatNumber(Number(row.totalCost).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelDataRG data={row.services} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}
function ExpandLavelDataRG({ data, open, select, currency }) {
    const [resource, setResource] = useState(null);

    // useEffect(() => {
    //     if (select != null) {
    //         let value = data?.services[select];
    //         setResource(value);
    //     }
    // }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>{<ExpandableTableAws tableRow={data} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function ExpandableTableAws({ tableRow, currency }) {
    const classes = useRowStyles();

    return (
        <Paper elevation={3}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Service
                            </StyledTableCell>

                            <StyledTableCell align="inherit" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Location
                            </StyledTableCell>

                            <StyledTableCell align="center" style={{ fontSize: 17, fontWeight: "bold" }}>
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow.map((r, i) => (
                            <ExpandRow row={r} index={i} currency={currency} table={"services"} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export function ExpandRow(props) {
    const { row, index, data, currency, table } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.cost}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell></TableCell>

                <TableCell align="inherit">
                    {" "}
                    {/* <img src={gcp_project} width={18} height={18} style={{ marginRight: 5 }} /> */}
                    {row.service}
                </TableCell>
                <TableCell align="inherit"> {row.location}</TableCell>

                <TableCell align="center">{currency + Format.formatNumber(Number(row.cost).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    const [resource, setResource] = useState(null);

    // useEffect(() => {
    //     if (select != null) {
    //         let value = data.services[select];
    //         setResource(value);
    //     }
    // }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Sku Id</th>
                    <th>Sku description</th>
                    <th>Usage amount</th>
                    <th>Usage unit</th>
                    <th>Cost</th>
                </tr>

                {data &&
                    data.meter.map((m) => (
                        <tr>
                            <td>{m.skuId}</td>
                            <td>{m.skuDescription}</td>
                            <td>{m.usageAmount}</td>
                            <td>{m.usageUnit}</td>
                            <td>{currency + Format.formatNumber(Number(m?.cost))}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}
