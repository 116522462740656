import React, {useState} from "react";
import {Modal, Fade, Paper, Input, TextField} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import RoutesList from "../routes/RoutesList";
import {getGCPServicesAccountFailure} from "../redux/actions/actions";
import TabList from "@material-ui/lab/TabList";
import Tab from "@material-ui/core/Tab";
import DashboardIcon from "../images/newCloudTrakrIcons/MultiCloudIcon.png";
import billing_icon from "../images/billing_icon.svg";
import {ThumbUp} from "@material-ui/icons";
import management_group from "../images/management_group.svg";
import subscription_icon from "../images/key.svg";
import ResourceGroupsIcon from "../images/resources/Resource_Group.svg";
import azure_service from "../images/resource_group.svg";
import ComputeIcon from "../images/Virtual-Machine.svg";
import storage_account_icon from "../images/resources/Storage_Account.svg";
import Azure_cosmosDB_icon from "../images/Azure-Cosmos-DB.svg";
import function_apps_icon from "../images/function-apps.png";
import azure_reservations from "../images/newCloudTrakrIcons/AzureReservations.png";
import AzureTagsIcon from "../images/newCloudTrakrIcons/AzureTags.png";
import AZURE_LOGO from "../images/Azure_logo.svg";
import AWS_LOGO from "../images/aws_dark.svg";
import GCP_LOGO from "../images/GCP_logo.svg";
import COMBINED_CLOUD_LOGO from "../images/allCloudLogo.svg";
import {capitalizeFirstLetter, objectIterator} from "../util/Miscellaneous";
import aws_recommendation from "../images/aws_recommendation.svg";
import aws_org from "../images/awsAccounts/Aws-Org-newIcon.svg";
import aws_account from "../images/aws_org.svg";
import resource_group from "../images/resource_group.svg";
import all_resource from "../images/All-Resources.svg";
import S3Icon from "../images/awsAccounts/Amazon-Simple-_Storage-Service-S3.svg";
import RDS from "../images/RDS.svg";
import DynamoDBIcon from "../images/DynamoDB.svg";
import lambdaFunctionIcon from "../images/lambdaFucntion.png";
import reservation from "../images/Reserved-Instance-Reporting.svg";
import serverless_icon from "../images/light.svg";
import gcp_org from "../images/gcp_org.svg";
import gcp_billing from "../images/gcp_billing.svg";
import gcp_project from "../images/gcp_project.svg";
import gcp_compute from "../images/gcp_compute.svg";
import gcp_storage from "../images/gcp_storage.svg";
import gcp_big_query from "../images/gcp_bigquery.svg";
import {Link} from "react-router-dom";
import history from "../services/history";
import {SET_CLOUD_ACTIVE_TAB_REQUEST} from "../redux/actions/types";
import {useDispatch} from "react-redux";
import allCloudCompute_icon from "../images/allCloudCompute.png";
import database_icon from "../images/database.png";
import allCloudFunctions_icon from "../images/allCloudFunctions.png";
import allCloudStorage_icon from "../images/allCloudStorage.png";

const ICON_SET = {
    All: {
        'Dashboard': ()=><img src={DashboardIcon} height={18} />,
        'Billing': ()=><img src={billing_icon} height={26} />,
        'Recommendations': ()=><img src={aws_recommendation} height={18} />,
        'Accounts': ()=><img src={aws_account} height={18} />,
        'Services': ()=><img src={azure_service} height={18} />,
        'Resources': ()=><img src={all_resource} height={40} />,
        'Compute': ()=><img src={allCloudCompute_icon} height={18} />,
        'Database': ()=><img src={database_icon} height={18} />,
        'Functions': ()=><img src={allCloudFunctions_icon} height={18} />,
        'Storage': ()=><img src={allCloudStorage_icon} height={18} />,
        'cloud': ()=><img src={COMBINED_CLOUD_LOGO} height={40} />
    },
    Azure: {
        'Dashboard': ()=><img src={DashboardIcon} height={20} /> ,
        'Billing': ()=><img src={billing_icon} height={20} /> ,
        'Recommendations': ()=><img src={aws_recommendation} height={20} /> ,
        'Business units': ()=><img src={management_group} height={20} /> ,
        'Subscriptions': ()=><img src={subscription_icon} height={20} /> ,
        'Services': ()=><img src={azure_service} height={20}/>,
        'Resource Groups': ()=><img src={ResourceGroupsIcon} height={20} /> ,
        'Resources': ()=><img src={azure_service} height={20} /> ,
        'Compute': ()=><img src={ComputeIcon} height={20} /> ,
        'Storage': ()=><img src={ResourceGroupsIcon} height={20} /> ,
        'CosmosDB': ()=><img src={Azure_cosmosDB_icon} height={20} /> ,
        'Function Apps': ()=><img src={function_apps_icon} height={20} /> ,
        'Reservations': ()=><img src={azure_reservations} height={20} /> ,
        'Tags': ()=><img src={AzureTagsIcon} height={20} /> ,
        'cloud': ()=><img src={AZURE_LOGO} height={20} /> ,
    },
    AWS: {
        'Dashboard': ()=><img src={DashboardIcon} height={20} /> ,
        'Billing': ()=><img src={billing_icon} height={20} /> ,
        'Recommendations': ()=><img src={aws_recommendation} height={20} /> ,
        'Organizations': ()=><img src={aws_org} height={20} /> ,
        'Accounts': ()=><img src={aws_account} height={20} /> ,
        'Services': ()=><img src={resource_group} height={20} /> ,
        'Resources': ()=><img src={all_resource} height={20} /> ,
        'Compute': ()=><img src={ComputeIcon} height={20} /> ,
        'S3': ()=><img src={S3Icon} height={20} /> ,
        'RDS': ()=><img src={RDS} height={20} /> ,
        'DynamoDB': ()=><img src={DynamoDBIcon} height={20} /> ,
        'Lambda': ()=><img src={lambdaFunctionIcon} height={20} /> ,
        'Reservations': ()=><img src={reservation} height={20} /> ,
        'cloud': ()=><img src={AWS_LOGO} height={20} /> ,
    },
    GCP: {
        'Dashboard': ()=><img src={DashboardIcon} height={20} /> ,
        'Billing': ()=><img src={billing_icon} height={20} /> ,
        'Organizations': ()=><img src={gcp_org} height={20} /> ,
        'Billing Accounts':()=><img src={gcp_billing} height={20} /> ,
        'Projects': ()=><img src={gcp_project} height={20} /> ,
        'Services': ()=><img src={resource_group} height={20} /> ,
        'Compute': ()=><img src={gcp_compute} height={20} /> ,
        'Storage': ()=><img src={gcp_storage} height={20} /> ,
        'Big Query': ()=><img src={gcp_big_query} height={20} /> ,
        'cloud': ()=><img src={GCP_LOGO} height={20} />
    },
}

const SEARCH_LIST = {
    All: ['Dashboard', 'Billing', 'Recommendations', 'Accounts', 'Services', 'Resources', 'Compute', 'Database',
        'Functions', 'Storage'],
    Azure: ['Dashboard', 'Billing', 'Recommendations', 'Business units', 'Subscriptions','Services',
        'Resource Groups', 'Resources', 'Compute', 'Storage', 'CosmosDB', 'Function Apps', 'Reservations', 'Tags'],
    AWS: ['Dashboard', 'Billing', 'Recommendations', 'Organizations', 'Accounts',
        'Services', 'Resources', 'Compute', 'S3', 'RDS', 'DynamoDB', 'Lambda', 'Reservations'],
    GCP: ['Dashboard', 'Billing', 'Organizations', 'Billing Accounts', 'Projects',
        'Services', 'Compute', 'Storage', 'Big Query'],
}

const TAB_SET = {
    "Dashboard": "DASHBOARD",
    "Billing": "BILLING",
    "Recommendations": "RECOMMENDATIONS",
    "Business units": "MANAGEMENT_GROUPS",
    'Organizations': "ORGANIZATIONS",
    "Subscriptions": "SUBSCRIPTIONS",
    "Accounts": "ACCOUNTS",
    "Resource Groups": "RESOURCE_GROUPS_RESOURCES",
    "Services": "SERVICES",
    "Compute": "COMPUTE",
    "Storage": "STORAGE",
    "CosmosDB": "COSMOS_DB",
    "Function Apps": "FUNCTION_APPS",
    "Reservations": "RESERVATIONS",
    "Tags": "TAGS",
    "DynamoDB": "DYNAMO_DB",
    "Lambda": "LAMBDA",
    "Serverless": "SERVERLESS",
    "Resources": "RESOURCES",
    "Functions": "FUNCTIONS",
    "Database": "DATABASE",
    "RDS": "RDS",
    "S3": "S3BUCKET",
}

export default function SearchBox({open, handleClose, classes, onSearch = ()=>{}}){
    const [searchTerm, setSearchTerm] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const dispatch  = useDispatch();

    function onSearch(s){
        setSearchTerm(s);
        setSuggestions([]);
        if(s.length < 2) return;
        const clouds = Object.keys(SEARCH_LIST);
        const searchTerms = s.split(' ');

        if(false && containsCloud(searchTerms)){
            const cloud = containsCloud(searchTerms).whichCloud;
            const otherTerms = searchTerms.map(s=>!s.toLowerCase().includes(cloud) );
            const result = furtherSearch(cloud, otherTerms);
            if(result && result.cloud && result?.components) {
                // alert(JSON.stringify(result));
                // const result = [furtherSearch(result?.cloud, searchTerms)];
                // setSuggestions(result.filter(r=>r!=null && r!= undefined));
                // debugger
                // setSuggestions({cloud: result?.cloud, components: result?.components})
            }
        } else {
            const clouds = Object.keys(SEARCH_LIST);
            const result = clouds.map(c=>furtherSearch(c, searchTerms));
            setSuggestions(result.filter(r=>r!=null && r!= undefined));
        }

        function furtherSearch(key, terms){
            const components = terms?.map(t=>{
                if(SEARCH_LIST?.[key]?.find(s=>s.toLowerCase().includes(t.toLowerCase()))){
                    return SEARCH_LIST?.[key]?.find(s=>s.toLowerCase().includes(t.toLowerCase()));
                }
                return null
            }).filter(c=>c!=null && c!=undefined);
            if(components.length)
                return {components, cloud: key};
        }
    }

    function containsCloud(t){
        const whichCloud = t.find(t=> {
            if ('Azure'?.toLowerCase().includes(t.toLowerCase())) {
                return 'Azure'
            }
            if ('AWS'?.toLowerCase().includes(t.toLowerCase())) {
                return 'AWS'
            }
            if ('GCP'?.toLowerCase().includes(t.toLowerCase())) {
                return 'GCP'
            }
        })
        if(whichCloud){
            return {whichCloud}
        }
        return null
    }

    function moveToRoute(cloud, component){
        if((cloud == 'Azure' || cloud == 'AWS' || cloud == 'All') && component in TAB_SET){
            dispatch({
                type: SET_CLOUD_ACTIVE_TAB_REQUEST,
                payload: { cloud: cloud=='Azure'?"azureActiveTab":cloud=='AWS'?"awsActiveTab":cloud=='All'?'allActiveTab':null, tab: TAB_SET?.[component] },
            })
            history.push(cloud == 'Azure'?RoutesList.AZURE_HOME: RoutesList.AWS_HOME);
        }
        setSuggestions([]);
        handleClose();
    }

    function handleTabSelection(tab) {
        dispatch({
            type: SET_CLOUD_ACTIVE_TAB_REQUEST,
        });
    }


    return (
      <Modal style={{outline: "none"}}
             open={open}
             onClose={handleClose}
             className={classes.modal}
             closeAfterTransition
             BackdropComponent={Backdrop}
             BackdropProps={{
               timeout: 500,
             }}
       >
          <Fade in={open} style={{outline: "none"}}>
          <div style={{width: '100%', justifyContent:'center', display: 'flex',}}>
              <div style={{width: '50%', marginTop: 80, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Paper style={{outline: "none", borderRadius: 50}}>
                  <TextField  InputProps={{disableUnderline: true}} autoFocus={true} onChange={({currentTarget: {value}})=>onSearch(value?.toString()?.trim())} placeholder="Search" fullWidth={true} style={{textDecoration: 'none', padding: 10, outline: "none", paddingLeft: 20,}}/>
                </Paper>
                  {suggestions.length?<div style={{ }}>
                      <center>
                          <Paper style={{ alignItems: 'center', outline: "none", padding: 5, marginTop: 5, width: '95%', overflow: 'scroll', maxHeight: .5 * window.innerHeight}}>
                              {suggestions.map(({cloud, components}) =>
                                  <>{components.sort((a, b) => {
                                      return a.toLowerCase()?.startsWith(searchTerm) ? 1 : -1
                                  }).map(c =><>
                                      <Link to={cloud == 'Azure'?
                                          RoutesList.AZURE_HOME:
                                          cloud == 'AWS'?RoutesList.AWS_HOME:cloud == 'GCP'?RoutesList.GCP_HOME:RoutesList.CHARTS}> <div onClick={()=>moveToRoute(cloud, c)} style={{cursor: 'pointer', display: 'flex',padding: 10,
                                          alignItems: 'center',  }}>
                                      {/*<div style={{display: 'flex', justifyContent: 'space-between'}}>*/}
                                          <span>
                                              {/*<img src={ICON_SET?.[cloud]?.[c]} style={{height: 20 }} /> {c}*/}
                                              {(ICON_SET?.[cloud]?.[c])()} {c}
                                          </span>
                                          <div style={{paddingLeft: 20}}>
                                            ... in {cloud} <img src={ICON_SET?.[cloud]?.['cloud']} style={{height: 20 }} />
                                          </div>
                                      {/*</div>*/}
                                  </div>
                                  </Link>
                                      <hr style={{padding: 0, margin: 0}}/></>)}</>
                              )}
                          </Paper>
                      </center>
                  </div>:null}
              </div>
          </div>
        </Fade>
      </Modal>
  )
}
