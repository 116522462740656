import React from 'react'
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {TableCell as TCell} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import Format from 'components/NumberFormat';
import colors from 'styles/color';
import {PAYMENT_MODEL} from "../../util/AppConstants";
import Button from "@material-ui/core/Button";
import {Info} from "@material-ui/icons";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import LinkText from "../../components/LinkText";
import Checkbox from "components/Checkbox";

const DAYS_PERIOD = 30;

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 600,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{  padding: 2,paddingRight:8,paddingLeft:8 }}>
            {props.children}
        </TCell>
    );
}

export default function ExpandRow(props) {
    const { row, currency, showingDetails, setShowingDetails,icon, subscriptionId, type, onVolumeClick = ()=>{}, volumeLinkEnabled = false, selectedInstances = [], onSelectInstance } = props;

    const classes = useRowStyles();

    /**
     *
     * BASE_PATH =
     AWS_REGION=_________
     EC2_INSTANCE_ID=__________________
     EC2_PATH=#InstanceDetails:instanceId

     https://AWS_REGION/BASE_PATH+ AWS_REGION+EC2_PATH+EC2_INSTANCE_ID

     https://_________.console.aws.amazon.com/ec2/v2/home?region=________#InstanceDetails:instanceId=_______


     */
    function generateURL(){
        const BASE_PATH = ".console.aws.amazon.com/ec2/v2/home?region=";
        const AWS_REGION = row?.region;
        const EC2_INSTANCE_ID = row?.ec2Name || row?.instanceId;
        const EC2_PATH = "#InstanceDetails:instanceId="

        const FINAL_URL = "https://" + AWS_REGION + BASE_PATH + AWS_REGION + EC2_PATH + EC2_INSTANCE_ID;
        window.open(FINAL_URL, "_blank");
    }

    function getTotalVolumeSize(e, output_unit){
         function sizeInBytes(input_unit, size, to = null, new_size){
             if(!((size?.toString() || false) || (new_size?.toString() || false))) return null;
            var newSize = size;
            if(to){
                size = 1;
            }
            switch(to || input_unit){
                case "KB":  newSize = size*1000;
                break;
                case "MB":  newSize = size*1000*1000;
                break;
                case "GB":  newSize = size*1000*1000*1000;
                break;
                case "TB":  newSize = size*1000*1000*1000*1000;
                break;
                case "PB":  newSize = size*1000*1000*1000*1000*1000;
                break;
                case "KiB": newSize = size*1024;
                break;
                case "MiB": newSize = size*1024*1024;
                break;
                case "GiB": newSize = size*1024*1024*1024;
                break;
                case "TiB": newSize = size*1024*1024*1024*1024;
                break;
                case "PiB": newSize = size*1024*1024*1024*1024*1024;
                break;
            };
            return to != null?new_size/newSize:newSize;
         }
         function bytesToSize(bytes, decimals = 2) {
            if (bytes) {
                if (bytes === 0) return "0 Bytes";

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
            } else {
                return "-";
            }
        }
         var newSize = e?.volumes?.map(v=>sizeInBytes(v?.unit, v?.size, null))
             ?.filter(f=>f!=null)
             ?.reduce((a,b)=>a+b, null);
        newSize=bytesToSize(newSize);
        return newSize || "--";
    }

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}
                            // style={{ cursor: "pointer" }}
                            onClick={() => setShowingDetails(row.details)}>
                <TableCell align="left">
                    <Checkbox onClick={()=>onSelectInstance(row?.instanceId || row?.ec2Name)} color={"primary"} checked={selectedInstances.indexOf(row?.instanceId || row?.ec2Name) !== -1} />
                </TableCell>
                <TableCell align="left">
                    {" "}
                    <img src={icon} height={20} />
                </TableCell>
               <TableCell align="left">
                    <span
                        // className={classes.link}
                        style={{
                            // color: colors.blue,
                            whiteSpace: 'nowrap'}}>
                        {row?.ec2Name || row?.instanceId}{" "}
                        <OverlayTrigger placement="bottom"
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                <span style={{}}>
                                                    <b>Instance type</b><br/>
                                                    <span style={{color: colors.gray}}>{row?.instanceType}</span>
                                                </span>
                                            </Tooltip>
                                        }
                            // <Tooltip id="button-tooltip">{row?.instanceType}</Tooltip>

                        >
                            <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                        </OverlayTrigger>
                    </span>
                </TableCell>
                {/* <TableCell align="center">{row?.serviceName}</TableCell> */}
                {showingDetails != null ? null : (
                    <>
                        <TableCell align="left" style={{whiteSpace: 'nowrap'}}>{row?.region}</TableCell>
                        <TableCell align="left">{subscriptionId || row?.accountId}</TableCell>
                        {type == 'stopped' && <TableCell align="left">{
                            row?.numberOfDaysStopped?((row?.numberOfDaysStopped?.toString()) +
                            (parseInt(row?.numberOfDaysStopped) > 1? " days":" day" )):
                            "-"
                        }</TableCell>}
                        <TableCell align="left">
                            <LinkText onClick={()=>onVolumeClick(row?.volumes)} role={volumeLinkEnabled && (row?.volumes?.length > 1)?'Owner':'other'}>
                                {
                                    (row?.volumes?.length?.toString()) +
                                    (row?.volumes?.length > 1?" volumes": " volume")
                                || "-"
                                }
                            </LinkText>
                        </TableCell>
                        {type == 'stopped' &&
                            <TableCell align="left">
                                {getTotalVolumeSize(row)}
                            </TableCell>
                        }
                        <TableCell align="left">{
                            Format.formatCurrencySymbol(currency) +
                            (type == 'idle'?
                                Format.formatNumber(Number(row?.volumes?.map(r=>r.totalCost || r.avgCost||0)?.reduce((a,b)=>a+b, 0) * DAYS_PERIOD))+ " /mon":
                                Format.formatNumber(Number(row?.volumes?.map(r=>r.avgCostPerDay || r.avgCost||0)?.reduce((a,b)=>a+b, 0)).toFixed(2)) + " /day")
                        }</TableCell>
                        {type == 'idle' &&
                            <TableCell align="left">
                                {
                                Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(
                                    parseFloat(row?.avgCost)* DAYS_PERIOD 
                                )+ " /mon"
                                }
                            </TableCell>
                        }
                        <TableCell align="left">
                            {Format.formatCurrencySymbol(currency)}
                            {
                                type=='stopped'?
                                    <>
                                        {Format.formatNumber(Number(row.totalSavingsMonths).toFixed(2)) + " /mon"}
                                        <br/>
                                        {row.totalSavingsDaysString?<span style={{fontSize: 11, color: "#7f7f7f"}}>
                                            {Format.formatCurrencySymbol(currency)}
                                            {row.totalSavingsDaysString}
                                        </span>:null}
                                    {/*<OverlayTrigger placement="bottom"*/}
                                    {/*                overlay={*/}
                                    {/*                    <Tooltip id="button-tooltip">*/}
                                    {/*            <span style={{}}>*/}
                                    {/*                {Format.formatCurrencySymbol(currency)}*/}
                                    {/*                {row.totalSavingsDaysString}*/}
                                    {/*            </span>*/}
                                    {/*                    </Tooltip>*/}
                                    {/*                }*/}
                                    {/*    // <Tooltip id="button-tooltip">{row?.instanceType}</Tooltip>*/}

                                    {/*>*/}
                                    {/*    <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />*/}
                                    {/*</OverlayTrigger>*/}
                                    </>:
                                   Format.formatNumber(Number(row.totalSavingsMonths).toFixed(2)) + " /mon"
                            }
                        </TableCell>
                        <TableCell align="center">
                            <Button
                                size={'small'}
                                onClick={generateURL}
                                // onClick={onUpgrade}
                                variant="contained"
                                // color="primary"
                                // size="large"
                                style={{ fontSize: 10, borderRadius: 15 , backgroundColor: "#3f50b5", color: "#FFF",textTransform: 'initial'}}
                            >
                                Fix in AWS
                            </Button>
                        </TableCell>
                    </>
                )}
            </StyledTableRow>
        </React.Fragment>
    );
}

// export function expandRow22(props) {
//     const { row, currency, showingDetails, setShowingDetails,icon, subscriptionId, type, onVolumeClick = ()=>{}, volumeLinkEnabled = false } = props;
//
//     function generateURL(){
//         const BASE_PATH = ".console.aws.amazon.com/ec2/v2/home?region=";
//         const AWS_REGION = row?.region;
//         const EC2_INSTANCE_ID = row?.ec2Name || row?.instanceId;
//         const EC2_PATH = "#InstanceDetails:instanceId="
//
//         const FINAL_URL = "https://" + AWS_REGION + BASE_PATH + AWS_REGION + EC2_PATH + EC2_INSTANCE_ID;
//         window.open(FINAL_URL, "_blank");
//     }
//
//     function getTotalVolumeSize(e, output_unit){
//          function sizeInBytes(input_unit, size, to = null, new_size){
//              if(!((size?.toString() || false) || (new_size?.toString() || false))) return null;
//             var newSize = size;
//             if(to){
//                 size = 1;
//             }
//             switch(to || input_unit){
//                 case "KB":  newSize = size*1000;
//                 break;
//                 case "MB":  newSize = size*1000*1000;
//                 break;
//                 case "GB":  newSize = size*1000*1000*1000;
//                 break;
//                 case "TB":  newSize = size*1000*1000*1000*1000;
//                 break;
//                 case "PB":  newSize = size*1000*1000*1000*1000*1000;
//                 break;
//                 case "KiB": newSize = size*1024;
//                 break;
//                 case "MiB": newSize = size*1024*1024;
//                 break;
//                 case "GiB": newSize = size*1024*1024*1024;
//                 break;
//                 case "TiB": newSize = size*1024*1024*1024*1024;
//                 break;
//                 case "PiB": newSize = size*1024*1024*1024*1024*1024;
//                 break;
//             };
//             return to != null?new_size/newSize:newSize;
//          }
//          function bytesToSize(bytes, decimals = 2) {
//             if (bytes) {
//                 if (bytes === 0) return "0 Bytes";
//
//                 const k = 1024;
//                 const dm = decimals < 0 ? 0 : decimals;
//                 const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
//
//                 const i = Math.floor(Math.log(bytes) / Math.log(k));
//
//                 return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
//             } else {
//                 return "-";
//             }
//         }
//          var newSize = e?.volumes?.map(v=>sizeInBytes(v?.unit, v?.size, null))
//              ?.filter(f=>f!=null)
//              ?.reduce((a,b)=>a+b, null);
//         newSize=bytesToSize(newSize);
//         return newSize || "--";
//     }
//
//     if(props?.mode == 'data'){
//         return {
//             volumes: row?.volumes,
//             id: row?.ec2Name || row?.instanceId,
//             location: row?.region,
//             account:subscriptionId || row?.accountId,
//             daysStopped:row?.numberOfDaysStopped?((row?.numberOfDaysStopped?.toString()) +
//                 (parseInt(row?.numberOfDaysStopped) > 1? " days":" day" )):"-",
//             ebsvol:(row?.volumes?.length?.toString()) +
//             (row?.volumes?.length > 1?" volumes": " volume"),
//             ebsSize:getTotalVolumeSize(row),
//             ebsSavings:Format.formatCurrencySymbol(currency) +
//                ( type==='idle'?
//                 Format.formatNumber(Number(row?.volumes?.map(r=>r.totalCost || r.avgCost||0)?.reduce((a,b)=>a+b, 0) * DAYS_PERIOD))+ " /mon":
//                 Format.formatNumber(Number(row?.volumes?.map(r=>r.avgCostPerDay || r.avgCost||0)?.reduce((a,b)=>a+b, 0)).toFixed(2)) + " /day"),
//             totalSavings:Format.formatCurrencySymbol(currency) + Format.formatNumber(Number(row.totalSavingsMonths).toFixed(2)) + " /mon" + "&" +
//                 (row.totalSavingsDaysString?Format.formatCurrencySymbol(currency) + row.totalSavingsDaysString:""),
//             ec2Savings:  Format.formatCurrencySymbol(currency) +
//                 Format.formatNumber(
//                     parseFloat(row?.avgCost)* DAYS_PERIOD
//                 )+ " /mon"
//         }
//     }
// }
//
