import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ManagementTable from "components/table/AzureManagementTable";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import management_group from "../../images/newCloudTrakrIcons/AzureBusinessUnits.png";
import { getAzureManagementRequest, getAzureResourceGroupsRequest, getAzureTenantManagementRequest } from "../../redux/actions/actions";
import Format from "components/NumberFormat";
import { LinearProgress, Paper, Collapse, IconButton } from "@material-ui/core";

import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";

import { ExpandRow } from "./GCPBigQuery";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        minHeight: "95vh",
        //marginBottom: 5,
    },
}));

function AzureManagement({ currency, tab, showData, progress, customerId }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [totalCost, setTotalCost] = useState(null);
    const [period, setPeriod] = useState("1M");
    const loading = useSelector((state) => state.azureManagementGroupReducer.loading);

    let managmentGroup = useSelector((state) => state.azureManagementGroupReducer.managementGroups);
    let mgmtHome = useSelector((state) => state.azureManagementGroupReducer.mgmtHome);
    // let tenant_Id = useSelector((state) => state.allHomeReducer?.allSubscriptions?.azure?.[0]?.tenantId);

    useEffect(() => {
        if ("all" in showData) {
            if (showData.all != null) {
                if (mgmtHome == null) {
                    dispatch(getAzureManagementRequest(customerId));
                }
            }
        } else {
            const { tenantId } = showData;
            dispatch(
                getAzureManagementRequest({
                    customerId,
                    tenantId,
                })
            );
        }
    }, [customerId, showData]);

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div className={classes.root}>
            <ContainerCardWithDateRange
                title={"Business units"}
                titleIcon={management_group}
                showDateRange={true}
                collapsible={true}
                // datePeriod={({period})=>({
                // start:"__",
                //end: "__",
                //     start: moment(AWSServerlessData?.SpendByServerlessServices?.[period]?.startDate, "YYYYMMDD"),
                //     end: moment(AWSServerlessData?.SpendByServerlessServices?.[period]?.endDate, "YYYYMMDD")
                // })}
                totalCost={({ period }) => currency + (isNaN(Number(totalCost?.[period]).toFixed(2)) ? "--" : Format.formatNumber(Number(totalCost?.[period]).toFixed(2))) || "--"}
                datePeriodEnabled={true}
                onlyTotal={true}
                // showDatePeriod={(period)=>!!(AWSServerlessData != null && AWSServerlessData?.SpendByServerlessServices?.[period]?.dataSet?.length)}
            >
                {({ period }) => (
                    <>
                        {"all" in showData ? (
                            mgmtHome && mgmtHome.managementGroups.length > 0 ? (
                                mgmtHome.managementGroups.map((item) => (
                                    <ManagementTable data={item.children} headerData={item} currency={currency} period={period} loading={loading} progress={progress} setTotalParent={setTotalCost} />
                                ))
                            ) : (
                                <span style={{ display: "flex", justifyContent: "center" }}>No data found</span>
                            )
                        ) : managmentGroup && managmentGroup?.managementGroups ? (
                            <ManagementTable
                                data={managmentGroup?.managementGroups?.children}
                                headerData={managmentGroup?.managementGroups}
                                currency={currency}
                                period={period}
                                loading={loading}
                                progress={progress}
                                setTotalParent={setTotalCost}
                            />
                        ) : (
                            <span style={{ display: "flex", justifyContent: "center" }}>No data found</span>
                        )}
                    </>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

export default AzureManagement;
