import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
// import {similarObject} from "../listS3bucket/ConversionFunc";
import RecommendationsTable from "./RecommendationTable";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import DynamoDBIcon from "../../images/DynamoDB.svg";
import Button from "@material-ui/core/Button";
import recommendation_icon from "../../images/recommended.png";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
import AwsLogo from "../../images/aws_dark.svg";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import Color from "../../styles/color";
import Box from "@material-ui/core/Box";
import {bytesToSize}  from "../../util/Miscellaneous";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function AWSDynamoDBRecommendation({ title, data, view, subscriptionId, lastUpdated, currency, cards, loading, collapseRef }) {
    const classes = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [progress, setProgress] = useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resource,
                f?.linkedAWSAccountId,
                f?.region,
                f?.yearlyPotentialSavings,
            ], search);
        }
        return true;
    }
    
    return (
        <div>
            <ContainerCardWithDateRange
                someref={collapseRef}
                title={title}
                titleIcon={AwsLogo}
                showDateRange={false}
                collapsible={true}
                HeaderTitleComponent={()=>(
                    <div>
                        <img src={DynamoDBIcon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                        <span>
                            {title}
                        </span>
                    </div>
                )}
                ExtraHeaderComponent={({ period }) =>
                    data != null && Object.keys(data)?.length ? (
                        <div style={{ marginTop: 4 }}>
                            <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                        </div>
                    ) : null
                }
                CenterComponent={()=>(
                    <div>
                     {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name}</h6>:null}
                  </div>
                    )}
                 >
                {({ period }) => (
                       <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {!loading ?(
                        <div style={{flex:1}}>
                        {cards && <RecommendationsSummaryCards summary={cards} />}

                        <div className={classes.root}>
                            <Collapse in={totalExpand}>
                                {data != null && data?.dynamoDBRecommendations?.length ? (
                                    <Paper className={classes.paper}>
                                     <TableSearch onSearch={setSearch} />
                                     <TableContainer style={{maxHeight:450}}>
                                            <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                <AwsDynamoDBHead view={view} subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={data?.dynamoDBRecommendations?.length} />
                                                <TableBody>
                                                    {data?.dynamoDBRecommendations
                                                    ?.filter(filteredTable)
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                        return <MyTableRow date={data} view={view} subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10, 25, 50, 75]}
                                            component="div"
                                            count={data?.dynamoDBRecommendations?.filter(filteredTable)?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                            </Collapse>
                        </div>
                     </div>
                     ):<CircularProgress thickness={2} size={60} />
                      }
                      </div>
            )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
        backgroundColor: Color.tableHeader,

    },
}));

function AwsDynamoDBHead({ view }) {

    const classes = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>Table name</TableCell>
                <TableCell className={classes.tableRow}>
                    <OverlayTrigger
                     placement="left"
                     overlay={
                     <Popover id="popover-contained">
                        <Popover.Content style={{fontSize:11}}>
                               Write Capacity Unit
                            </Popover.Content>
                        </Popover>
                    }>
                  <div>
                  Provisioned WCU
                  </div>
                </OverlayTrigger>
                </TableCell>
                <TableCell className={classes.tableRow}>
                <OverlayTrigger
                     placement="left"
                     overlay={
                     <Popover id="popover-contained">
                        <Popover.Content style={{fontSize:11}}>
                               Read Capacity Unit
                            </Popover.Content>
                        </Popover>
                    }>
                  <div>
                  Provisioned RCU
                  </div>
                </OverlayTrigger>
                    </TableCell>
                {view == "all" ? <TableCell className={classes.tableRow}>AWS Account</TableCell> : null}
                <TableCell className={classes.tableRow}>Item count</TableCell>
                <TableCell className={classes.tableRow}>Table size</TableCell>
                <TableCell className={classes.tableRow}>Region</TableCell>
                <TableCell className={classes.tableRow}>Table status</TableCell>
                <TableCell className={classes.tableRow}>Potential Savings</TableCell>
            </TableRow>
        </TableHead>
    );
}

function generateURL(data){
    if(data){
    const BASE_PATH = ".console.aws.amazon.com/dynamodbv2/home?region=";
    const AWS_REGION = data?.region;
    const AWS_DATABASE = "#capacity-settings?table="
    const AWS_Database=data?.resource
    // const CLUSTER = "&tab=overview"

    const FINAL_URL = "https://" + AWS_REGION + BASE_PATH + AWS_REGION + AWS_DATABASE +AWS_Database;
    window.open(FINAL_URL, "_blank");
    }
}

function MyTableRow({date, row, view, index, list, setList, currency }) {
    const [open, setOpen] = useState(true);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <>
                        <IconButton
                            style={{ cursor: "pointer" }}
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                setOpen(!open);
                                setList(index);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>{" "}
                        {row?.resource}
                    </>
                </TableCell>
                <TableCell>{row?.provisionedWriteCapacityUnits}</TableCell>
                <TableCell>{row?.provisionedReadCapacityUnits}</TableCell>

                {view == "all" ? <TableCell>{row?.linkedAWSAccountId}</TableCell> : null}
                <TableCell>{row?.ItemCount}</TableCell>
                <TableCell>{bytesToSize(row?.TableSizeBytes)}</TableCell>
                <TableCell>{row?.region}</TableCell>
                <TableCell>{row?.tableStatus}</TableCell>
                <TableCell>
                  <div style={{border: "1px solid #338307",padding:2}}>
                    
                    {Format.formatCurrencySymbol(currency)}
                    {Format.formatNumber(Math.max(...row?.recommendations?.map((m)=>(
                       (parseFloat(m?.yearlyPotentialSavings)/12)
                    ))))+ " /mon"}
                     <br />
                    {Format.formatCurrencySymbol(currency)}
                    {Format.formatNumber(Math.max(...row?.recommendations?.map((m)=>(
                        m?.yearlyPotentialSavings
                    ))))+ " /yr"}
                   
                  </div>
                </TableCell>
            </TableRow>
            <ExpandLavelData date={date} data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ date, data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable date={date} data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({date, data, currency }) {
    return (
        <>
            <div class="overflow-auto">
               
                {"recommendations" in data && data?.recommendations?.length ? (
                    <>
                       <div style={{display: "flex", flexdirection:'row',marginTop:4}}>
                        <b>Recommendations</b> 
                        <td style={{marginLeft:4}}><span style={{border: "1px solid #338307",margin:2,padding:2}}>{data?.recommendations?.[0]?.recommendationShortDescription} </span>
                        </td>
                       </div>
                       <div style={{display: "flex", flexdirection:'row',marginTop:8}}>
                        <b>Based on the last 30 days ({moment(date?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}-{moment(date?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}) usage</b>
                       </div>
                       <br/>
                        <table id="customers">
                            <tr>
                                <th>Provisioned</th>    
                                <th>Consumed</th>
                                <th>Recommended</th>
                                <th>Total Prov. cost</th>
                                <th style={{ whiteSpace: "nowrap" }}><span>Total Recommended cost</span></th>
                                <th>Potential savings</th>
                               
                                <th>
                                    {" "}
                                    Action
                                   
                                </th>
                            </tr>

                            {data &&
                                data?.recommendations
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.yearlyPotentialSavings || 0) - parseFloat(a?.yearlyPotentialSavings || 0);
                                    })
                                    .map((m) => (
                                        <tr style={{ borderColor: m?.isBestRecommendation == true ? Color?.lightGreen : null, border: m?.isBestRecommendation == true ? "3px solid green" : null }}>
                                            <td>
                                                
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {" "}
                                                        {m?.fromRCU} RCU ({Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.provisionedRCUYearlyPrice)/12) + " /mon"})<br />
                                                        {m?.fromWCU} WCU ({Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.provisionedWCUYearlyPrice)/12) + " /mon"})
                                                    </span>
                                               
                                            </td>
                                            <td style={{whiteSpace: "nowrap" }}>
                                                   <span style={{ whiteSpace: "nowrap" }}>
                                                     {" "}
                                                     {m?.consumedRCULast30Day<=1?'< 1':Format.formatNumber(parseFloat(m?.consumedRCULast30Day))} RCU ({Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.consumedRCUYearlyPrice)/12) + " /mon"})<br />
                                                     {m?.consumedWCULast30Day<=1?'< 1':Format.formatNumber(parseFloat(m?.consumedWCULast30Day))} WCU ({Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.consumedWCUYearlyPrice)/12) + " /mon"})
                                                  </span>
                                            </td>
                                            <td style={{whiteSpace: "nowrap" }}>
                                                   <span style={{ whiteSpace: "nowrap" }}>
                                                     {" "}
                                                     {m?.toRCU} RCU ({Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.recommendedRCUYearlyPrice)/12) + " /mon"})<br />
                                                     {m?.toWCU} WCU ({Format.formatCurrencySymbol(currency)}{Format.formatNumber(parseFloat(m?.recommendedWCUYearlyPrice)/12) + " /mon"})
                                                  </span>
                                            </td>

                                            <td style={{ whiteSpace: "nowrap" }}>
                                            
                                            {Format.formatCurrencySymbol(currency)}
                                            {Format.formatNumber(parseFloat(m?.provisionedTotalRWCUYearlyPrice)/12) + " /mon"}
                                            <br />
                                            {Format.formatCurrencySymbol(currency)}
                                            {Format.formatNumber(m?.provisionedTotalRWCUYearlyPrice) + " /yr"}
                                          
                                            </td>
                                            <td style={{ whiteSpace: "nowrap" }}>
                                            
                                            {Format.formatCurrencySymbol(currency)}
                                            {Format.formatNumber(parseFloat(m?.recommendedTotalRWCUYearlyPrice)/12) + " /mon"}
                                            <br />
                                            {Format.formatCurrencySymbol(currency)}
                                            {Format.formatNumber(parseFloat(m?.recommendedTotalRWCUYearlyPrice))+ " /yr"}
                                          
                                            </td>
                                                                                     
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(parseFloat(m?.yearlyPotentialSavings)/12) + " /mon"}
                                                <br />
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.yearlyPotentialSavings) + " /yr"}
                                              
                                            </td>
                                            <td align="left">
                                                <Button 
                                                onClick={()=>{generateURL(data)}}
                                                 variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                    Fix in AWS
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                        </table>
                    </>
                ) : null}
            </div>
        </>
    );
}
