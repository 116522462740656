import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LinearProgress } from "@material-ui/core";
import { getAWSS3BucketRequest } from "redux/actions/actions";
import S3Icon from "../../images/newCloudTrakrIcons/AWSS3.png";
import AllS3BucketList from "./AllS3BucketList";
import NO_DATA_AVAILABLE from "components/NO_DATA_AVAILABLE";
import AccountS3BucketList from "./AccountS3BucketList";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import S3SummaryCards from "./S3SummaryCards";

export default function ListS3Bucket(props) {
    const dispatch = useDispatch();
    const { customerId, subscriptionId, tenantId, currency } = props;
    const { S3BucketAll, S3BucketAccount, loading } = useSelector((state) => ({ S3BucketAll: state.awsLambdaReducer.S3BucketAll, S3BucketAccount: state.awsLambdaReducer.S3BucketAccount, loading: state.awsLambdaReducer.loading }));
    useEffect(() => {
        if (subscriptionId == "all") {
            if (S3BucketAll == null) {
                const obj = { customerId: customerId };
                dispatch(getAWSS3BucketRequest(obj));
            }
        } else {
            const obj = { customerId: customerId, tenantId: tenantId, subscriptionId: subscriptionId };
            dispatch(getAWSS3BucketRequest(obj));
        }
    }, [subscriptionId]);

    return !loading ? (
        <div>
            {subscriptionId == "all" ? (
                S3BucketAll && Object.keys(S3BucketAll).length ? (
                    <AllS3BucketList data={S3BucketAll} customerId={customerId} currency={currency} />
                ) : (
                    <ContainerCardWithDateRange title={"S3"} titleIcon={S3Icon} showDateRange={false} collapsible={true}>
                        {({ period }) => <div><NO_DATA_AVAILABLE/></div>}
                    </ContainerCardWithDateRange>
                )
            ) : S3BucketAccount && Object.keys(S3BucketAccount).length ? (
                <AccountS3BucketList data={S3BucketAccount} customerId={customerId} currency={currency}/>
            ) : (
                <ContainerCardWithDateRange title={"S3"} titleIcon={S3Icon} showDateRange={false} collapsible={true}>
                    {({ period }) => <div><NO_DATA_AVAILABLE/></div>}
                </ContainerCardWithDateRange>
            )}
        </div>
    ) : (
        <div>
            <LinearProgress />
        </div>
    );
}
