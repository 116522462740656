import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@material-ui/core";
import storage_account_icon from "../../images/newCloudTrakrIcons/AzureStorage.png";
import Cost from "../../components/Cost";
import { bytesToSize } from "../../util/Miscellaneous";
import axios from "../../connection/axios";
import { CancelRounded } from "@material-ui/icons";
import SlideShow from "../../components/CustomSlider/SlideShow";
import ReactD3BarChart from "../../components/D3Charts/ReactD3BarChart";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import Format from "components/NumberFormat";
import { getAzureStorageRequest,getAzureStorageAccountRequest,getAzureStorageTenantAccountRequest } from "redux/actions/actions";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
import DetailsDialogBox from '../../components/DetailsDialogBox';
import TableSearch from "../../components/TableSearch";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";

import Collapse from "@material-ui/core/Collapse";

import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

const { REACT_APP_GET_AZURE_STORAGE_DETAILS, REACT_APP_GET_AZURE_STORAGE_APP_INSIGHTS_METRICS } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8 }}>
            {props.children}
        </TCell>
    );
}

export default function AzureStorage({ showData, currency, curr = "USD", tab, progress, tenantId = null, subscriptionId = null, subscriptionList, customerId }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    // const [data, setData] = useState(null);
    // const [loading, setLoading] = useState(true);
    // const customerId = useSelector((state) => state.userDetailsReducer.id);
    // const loading = useSelector(
    //   (state) => state.azureRecommendationReducer.loading
    // );
    const [showingStorage, setShowStorage] = useState(null);
    const [chart, setChart] = useState([]);
    const [meterChart, setMeterChart] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [metricsData, setMetricsData] = useState([]);
    const [metricTotals, setMetricTotals] = useState([]);
    const [search, setSearch] = useState(null);
    const [openDetails, setOpenDetails] = useState(false);
    const [list, setList] = useState(null);

    const handleClose = () => {
        setOpenDetails(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (subscriptionId) {
            dispatch(getAzureStorageAccountRequest({
                customerId,
                subscriptionId,
                tenantId,
            }));
                
           
        } else if(tenantId){
            // if(data==null){
            dispatch(
                getAzureStorageTenantAccountRequest({
                    customerId,
                    tenantId,
                })
            );
            // }
        } else {
            // if(data==null){
            dispatch(getAzureStorageRequest({
                customerId,
            }));
        // }
        }
    }, [customerId,subscriptionId,tenantId]);

    let data = useSelector((state) => subscriptionId ? state?.azureStorageReducer?.azureStorageSubscriptionData:(tenantId) ? state?.azureStorageReducer?.azureStorageTenantData : state?.azureStorageReducer?.azureStorageData);
    let loading = useSelector((state) => state?.azureStorageReducer?.loading);

    function top5StorageAccountCharts(data1) {
        let chartData = [];
        {
            data1 &&
                data1?.map((data, row) => {
                    
                    data?.billing &&
                    data?.billing.map((row, l) => {
                                    parseFloat(row?.cost) > 0 &&
                                        chartData.push({
                                            label: data?.storage_account_name,
                                            value: row?.cost,
                                        });
                                });
                       
                });
        }
        // setChart(chartData);
        return chartData;
    }

    function meterStorageAccountCharts(row) {
        let chartData = [];
        {
            row?.billing.map(
                (i, j) =>
                    i?.meter &&
                    i?.meter.map(
                        (row, l) =>
                            row?.cost > 1 &&
                            chartData.push({
                                label: row?.meterName,
                                value: row?.cost,
                            })
                    )
            );
        }
        setMeterChart(chartData);
    }

    function showStorageDetails(e) {
        setShowStorage({ ...e });
        setDetailsLoading(true)
        axios
            .get(REACT_APP_GET_AZURE_STORAGE_DETAILS, {
                params: {
                    customerId,
                    tenantId:e.tenantId,
                    subscriptionId: e.subscriptionId,
                    resourceGroups: e.resource_group,
                    storageAccountName: e.storage_account_name,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        setShowStorage({ ...e, ...response.data});
                        getFunctionMetricData(e);
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setDetailsLoading(false))
            
    }

    function getFunctionMetricData(e) {
        setDetailsLoading(true)

        axios
            .get(REACT_APP_GET_AZURE_STORAGE_APP_INSIGHTS_METRICS, {
                params: {
                    customerId,
                    tenantId:e.tenantId,
                    subscriptionId: e.subscriptionId,
                    resourceGroups: e.resource_group,
                    storageAccountName: e.storage_account_name,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200 && response.data) {
                        var result = [];
                        for (var obj in response?.data?.value) {
                            const selected = response?.data?.value[obj];

                            const name = selected.name?.localizedValue;
                            const unit = selected?.unit;

                            var metricData = selected?.timeseries?.[0]?.data?.[0];
                            var timeseries = selected?.timeseries?.[0]?.data;

                            if(metricData && timeseries) {
                                var dataKey = new Set(Object.keys(metricData));
                                dataKey.delete("timeStamp");
                                dataKey = dataKey.keys().next().value;

                                var dataKeyT = new Set(Object.keys(timeseries?.[0]));
                                dataKeyT.delete("timeStamp");
                                dataKeyT = dataKeyT.keys().next().value;

                                timeseries = timeseries.map(({timeStamp, ...rest}) => {
                                    let date = new Date(timeStamp);
                                    date = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)) + "-" + (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
                                    return {date, value: rest[dataKeyT]};
                                });

                                timeseries = sumDuplicates(timeseries, "date", "value").map(({
                                                                                                 date,
                                                                                                 ...rest
                                                                                             }) => ({
                                    ...rest,
                                    date: new Date(date)
                                }));
                                metricData = {timeseries, unit, total: metricData[dataKey]};
                                result.push({name, unit, metricData});
                            }
                        }
                        setMetricsData(result);
                        setMetricTotals(result.map((r, i) => r?.metricData?.timeseries.map((r) => r.value).reduce((a, b) => a + b)));
                    } else {
                    }
                } else {
                }
            })
            .catch((e) => {})
            .finally(() => setDetailsLoading(false));
    }

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.storage_account_name,
                f?.subscriptionName,
                f?.location,
                f?.account_kind,
                f?.resource_group,
                f?.cost,
                
            ], search);
        }
        return true;
    }


    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Storage accounts"}
                titleIcon={storage_account_icon}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(data?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(data?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(data?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!data != null && data?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <div elevation={10} style={{ padding: 10 }}>
                        {data && data?.[period]?.resources?.length ? (
                            <>
                                <div>
                                    <div>
                        {data && data?.[period]?.resources?.length ? (
                            <ReactD3BarChart
                                title={"Top 5 storage accounts"}

                                cost={
                                    data?.[period]?.totalCost}
                                currency={curr}
                                data={top5StorageAccountCharts(data?.[period]?.resources).sort((a, b) => b.value - a.value).slice(0, 5)}
                            />
                        ) : null}
                              </div>
                                    {/* </SlideShow> */}
                                </div>

                                <br />
                                <Box display={data?.[period]?.resources?.length ? "flex" : "none"}>
                                    <Box flex={1}>
                                    <TableSearch onSearch={setSearch} />

                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} aria-label="simple table">
                                                <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                                    <TableRow>
                                                        <TableCell/>
                                                        <TableCell>
                                                            <b>Name</b>
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <b>Subscription</b>
                                                        </TableCell>
                                                                <TableCell align="left">
                                                                    <b>Kind</b>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <b>Resource group</b>
                                                                </TableCell>
                                                                {/* <TableCell align="left">
                                                                    <b>Capacity</b>
                                                                </TableCell> */}
                                                                <TableCell align="left">
                                                                    <b>Location</b>
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    <b>Cost</b>
                                                                </TableCell>
                                                           
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ padding: 0 }}>
                                                    {data?.[period]?.resources
                                                        ?.sort((r1, r2) => r2.billing?.map((b) => parseFloat(b.cost))?.reduce((a, b) => a + b, 0) - r1.billing?.map((b) => parseFloat(b.cost))?.reduce((a, b) => a + b, 0))
                                                        ?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        ?.map((row,index) => (
                                                            <MyTableRow  data={data} period={period} row={row} index={index} list={list} setList={setList} currency={currency} setOpenDetails={setOpenDetails} showStorageDetails={(row)=>showStorageDetails(row)}  />
                                                           
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    {/* <Box flex={showingStorage ? 0.75 : 0}>                                   
                                            <Paper elevation={5} style={{ padding: 10 }}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                                        <Typography component={"h4"} variant={"h4"}>
                                                            <img src={storage_account_icon} height={40} /> {showingStorage?.storage_account_name}
                                                        </Typography>
                                                        <Typography component={"h5"} variant={"h5"}>
                                                            {currency}
                                                            <Cost>
                                                                {showingStorage?.billing
                                                                    .map((b) => parseFloat(b.cost))
                                                                    .reduce((a, b) => a + b, 0)
                                                                    .toFixed(2)}
                                                            </Cost>
                                                        </Typography>
                                                    </Box>
                                                    <CancelRounded onClick={() => setShowStorage(null)} />
                                                </Box>
                                                <Divider />

                                                <Box style={{ padding: 10 }} flexDirection={"row"} display={"flex"}>
                                                    <Box flex={1}>
                                                        <Typography>
                                                            <b>Access Tier</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.access_tier || "-"}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Account Kind</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.account_kind}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Location</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.location}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Performance</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.performance}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Subscription Id</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.subscription_id}</Typography>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Typography>
                                                            <b>Replication</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.replication}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Resource group</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.resource_group}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Status</b>
                                                        </Typography>
                                                        <Typography>{showingStorage?.status}</Typography>
                                                        <Typography style={{ marginTop: 10 }}>
                                                            <b>Capacity</b>
                                                        </Typography>
                                                        <Typography>{bytesToSize(showingStorage?.capacity.average)}</Typography>
                                                          </Box>
                                                </Box>
                                                <Divider />

                                                <Box>
                                                    <TableContainer component={Paper}>
                                                        <Table className={classes.table} aria-label="simple table">
                                                            <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <b>Service</b>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <b>Meter</b>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <b>Cost</b>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody style={{ padding: 0 }}>
                                                                {showingStorage?.billing.map(
                                                                    (i, j) =>
                                                                        i?.meter &&
                                                                        i?.meter.map((row, l) => (
                                                                            <TableRow key={row?.meterName}>
                                                                                <TableCell
                                                                                    component="th"
                                                                                    scope="row"
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                    }}
                                                                                >
                                                                                    {row?.serviceName}
                                                                                </TableCell>
                                                                                <TableCell>{row?.meterName}</TableCell>
                                                                                <TableCell>{currency + Number(row?.cost).toFixed(2)}</TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Box>
                                            </Paper>                                     
                                    </Box> */}
                                </Box>
                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={data?.[period]?.resources?.filter(filteredTable)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </>
                        ) : (
                            <Typography style={{ display: "flex", justifyContent: "center" }}>No data found</Typography>
                        )}
                         <DetailsDialogBox
                            open={openDetails}
                            data={showingStorage}
                            title={'Azure storage'}
                            detailsData={showingStorage}
                            chartData={metricsData}
                            handleClose={() => {
                                handleClose();
                            }}
                            currency={currency}
                            loading={detailsLoading}
                            metricTotals={metricTotals}
                            rawStart={moment(data?.[period]?.startDate, "YYYYMMDD")}
                            rawEnd={moment(data?.[period]?.endDate, "YYYYMMDD")}
                        />
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}


export function MyTableRow(props) {
    const { data, period, row, index, list, setList, currency, showStorageDetails,setOpenDetails } = props;
    const [open, setOpen] = useState(false);

    return (
        <React.Fragment>
            <TableRow key={row?.storage_account_name}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row" style={{ display: "flex", alignItems: "center" }}>
                    <img src={storage_account_icon} height={20} />{" "}
                    <a
                        href={"#"}
                        onClick={() => {
                            showStorageDetails({ ...row, subscriptionId: row?.subscriptionId || data?.subscriptionId, tenantId: row?.tenantId || data?.tenantId });
                            setOpenDetails(true);
                        }}
                    >
                        {row?.storage_account_name}
                    </a>
                </TableCell>
                <TableCell align="left">
                    <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Popover id="popover-contained">
                                <Popover.Content style={{ fontSize: 11 }}>{row?.subscriptionId || data?.subscriptionId}</Popover.Content>
                            </Popover>
                        }
                    >
                        <div>{row?.subscriptionName || data?.subscriptionName}</div>
                    </OverlayTrigger>
                </TableCell>

                <TableCell align="left">{row?.account_kind}</TableCell>
                <TableCell align="left">{row?.resource_group}</TableCell>
                {/* <TableCell align="left">{row?.capacity?.average ? bytesToSize(row?.capacity?.average) : "--"}</TableCell> */}
                <TableCell align="left">{row?.location}</TableCell>
                <TableCell align="right">
                    {currency}
                    {Format.formatNumber(row?.billing?.map((b) => parseFloat(b.cost))?.reduce((a, b) => a + b, 0))}
                </TableCell>
            </TableRow>
            <ExpandLavelData index={index} data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                 {data?.billing && Object.keys(data?.billing)?.length ? (
                    <tr>
                        <th>Service name</th>
                        <th>Meter name</th>                      
                        <th>Cost</th>
                    </tr>
                ):null}

                {data &&
                    data?.billing?.map((b) =>
                    Object.keys(b)?.length ? (
                        b?.meter?.map((m) =>
                        <tr>
                            <td>{m?.serviceName||'-'}</td>
                            <td>{m?.meterName||'-'}</td>
                            <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                        </tr>
                        )
                    ) : null
                )}
                    
            </table>
        </div>
    );
}



function sumDuplicates(data, checkKey, valueKey) {
    var result = [];
    data.forEach(function (a) {
        if (!this[a[checkKey]]) {
            const data = {};
            data[checkKey] = a[checkKey];
            data[valueKey] = 0;
            // this[a.date] = { date: a.date, value: 0 };
            this[a[checkKey]] = data;
            result.push(this[a[checkKey]]);
        }
        this[a[checkKey]][valueKey] += a[valueKey];
    }, Object.create(null));
    return result;
}
