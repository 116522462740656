import React, { useState, useEffect } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import azure_reservations from "../../images/Reservations.svg";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getAzureCCKRequest,getAzureCCKSubRequest,getAzureCCKTenantRequest } from "../../redux/actions/actions";
import { LinearProgress } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow } from "@material-ui/icons";
import Cost from "../../components/Cost";
import ComputeIcon from "../../images/newCloudTrakrIcons/AzureCompute.png";
import KubernetesIcon from "../../images/Kubernetes-Services.svg";
import ContainersIcon from "../../images/Container-Instances.svg";
import { Tooltip, OverlayTrigger,Popover } from "react-bootstrap";
import DateRange from "components/DateRange";
import StartEndDate from "components/StartEndDate";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import CarousalCard from "components/CarousalCard";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function AzureCompute({ currency,subscriptionId = null,tenantId = null }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [totalExpand, setTotalExpand] = useState(true);
    const [period, setPeriod] = useState("1M");
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState(null);
    const [ec2Data,setEc2Data] = React.useState([]);
    const [ebsData,setEBSData] = React.useState([]);
    const [searchEc2, setSearchEc2] = useState(null);
    const [searchEbs, setSearchEbs] = useState(null);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        subscriptions: state.allHomeReducer.allSubscriptions?.azure,
        allSub: state.allHomeReducer.allSubscriptions,
    }));

    let azureCompute = useSelector((state) => subscriptionId ?state?.azureCCKReducer?.azureCCKSubData?.data?.SpendByCompute:tenantId? state?.azureCCKReducer?.azureCCKTenantData?.data?.SpendByCompute:state?.azureCCKReducer?.azureCCKdata?.data?.SpendByCompute);
    let azureContainers = useSelector((state) => subscriptionId ?state?.azureCCKReducer?.azureContainersSubData?.data?.SpendByContainers :tenantId? state?.azureCCKReducer?.azureContainersTenantData?.data?.SpendByCompute:state?.azureCCKReducer?.azureContainersData?.data?.SpendByContainers);
    let azureKubernetes = useSelector((state) => subscriptionId ?state?.azureCCKReducer?.azureKubernetesSubData?.data?.SpendBykubernetes:tenantId? state?.azureCCKReducer?.azureKubernetesTenantData?.data?.SpendByCompute:state?.azureCCKReducer?.azureKubernetesData?.data?.SpendByKubernetes);

    let loading = useSelector((state) => state?.azureCCKReducer?.loading);

    useEffect(() => {
        const customerId = userData.id;

        if (subscriptionId && tenantId) {
            if (azureCompute == null) {
                let dataType = "compute";
                dispatch(
                    getAzureCCKSubRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                        dataType,
                    })
                );
            }
            if (azureContainers == null) {
                let dataType = "containers";
                dispatch(
                    getAzureCCKSubRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                        dataType,
                    })
                );
            }
            if (azureKubernetes == null) {
                let dataType = "kubernetes";
                dispatch(
                    getAzureCCKSubRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                        dataType,
                    })
                );
            }
               
        }else if (tenantId){
            if (azureCompute == null) {
                let dataType = "compute";
                dispatch(
                    getAzureCCKTenantRequest({
                        customerId,
                        tenantId,
                        dataType,
                    })
                );
            }
            if (azureContainers == null) {
                let dataType = "containers";
                dispatch(
                    getAzureCCKTenantRequest({
                        customerId,
                        tenantId,
                        dataType,
                    })
                );
            }
            if (azureKubernetes == null) {
                let dataType = "kubernetes";
                dispatch(
                    getAzureCCKTenantRequest({
                        customerId,
                        tenantId,
                        dataType,
                    })
                );
            }
        }else{
            if (azureCompute == null) {
                let dataType = "compute";
                dispatch(
                    getAzureCCKRequest({
                        customerId,
                        dataType,
                    })
                );
            }
            if (azureContainers == null) {
                let dataType = "containers";
                dispatch(
                    getAzureCCKRequest({
                        customerId,
                        dataType,
                    })
                );
            }
            if (azureKubernetes == null) {
                let dataType = "kubernetes";
                dispatch(
                    getAzureCCKRequest({
                        customerId,
                        dataType,
                    })
                );
            }
            }
        
    }, [userData.id, subscriptionId,tenantId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(()=>{
        let vol={}; 
        let i_data={};
        if(!azureCompute) return
        Object.keys(azureCompute).forEach(key=>{
            const data = azureCompute[key];
            vol[key] = Object.assign({},data);
            i_data[key] = Object.assign({},data);
            vol[key]['resources'] = [];
            i_data[key]['resources'] = [];
            data?.resources?.forEach(r=>r.resourceName?.toString().startsWith('i-')||r.resourceType?.toString().endsWith('virtualMachines')||r.resourceType?.toString().endsWith('virtualMachineScaleSets')?i_data[key].resources.push(r):vol[key].resources.push(r))
            vol[key]['totalCost'] = vol[key].resources.map((r)=>parseFloat(parseFloat(r.cost).toPrecision(4))).reduce((a,b)=>(a)+(b), 0)
            i_data[key]['totalCost'] = i_data[key].resources.map((r)=>parseFloat(parseFloat(r.cost).toPrecision(4))).reduce((a,b)=>(a)+(b), 0)
        })   
          setEBSData(vol);
          setEc2Data(i_data);
        }, [azureCompute]);

    const filterTableEc2 = (f)=> {
        if(searchEc2 && searchEc2.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.subscriptionName,
                f?.resourceName,
                f?.cloudProvider,
                f?.resourceType,
                f?.resourceLocation,
                f?.cost
            ], searchEc2);
        }
        return true;
    }
    const filterTableEbs = (f)=> {
        if(searchEbs && searchEbs.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.subscriptionName,
                f?.resourceName,
                f?.resourceGroup,
                f?.cloudProvider,
                f?.resourceType,
                f?.resourceLocation,
                f?.cost
            ], searchEbs);
        }
        return true;
    }
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceName,
                f?.subscriptionName,
                f?.resourceLocation,
                f?.resourceGroup,
                f?.cost,
                
            ], search);
        }
        return true;
    }
   
    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
             {/* <ContainerCardWithDateRange
                title={"Spend by Compute"}
                titleIcon={ComputeIcon}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(azureCompute?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(azureCompute?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(azureCompute?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!azureCompute != null && azureCompute?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
           >
                {({ period }) => (
            <div className={classes.root}> 
                    <Collapse in={totalExpand}>
                        {azureCompute?.[period] != null && azureCompute?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                                <h6 style={{ textAlign: "right" }}>
                                    <b>
                                        Total: {currency}
                                        <Cost>{azureCompute?.[period]?.totalCost}</Cost>
                                    </b>
                                </h6>
                                <TableContainer>
                                <TableSearch onSearch={setSearch} />
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AzureComputeHead subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={azureCompute.length} />
                                        <TableBody>
                                            {(azureCompute?.[period]?.resources)?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={azureCompute?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                            </Paper>
                        ) : (
                            <h6 style={{ textAlign: "center" }}>No data found</h6>
                        )}
                    </Collapse>
                 
            </div>
            )}
            </ContainerCardWithDateRange> */}
           
           <ContainerCardWithDateRange
                title={"Spend by Compute"}
                titleIcon={ComputeIcon}
                showDateRange={true}
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(azureCompute?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(azureCompute?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency + Format.formatNumber(ec2Data?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!(ec2Data != null && ec2Data?.[period]?.resources?.length)}
                collapsible={true}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {ec2Data?.[period] != null && ec2Data?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                               <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={ec2Data?.[period]?.totalCost} res={ec2Data?.[period]?.resources?.length} currency={currency} />

                                <TableSearch onSearch={setSearchEc2} />
                                <TableContainer style={{ height: 350 }}>
                                <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AzureComputeHead subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={ec2Data.length} totalCost={ec2Data?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {ec2Data?.[period]?.resources
                                                ?.filter(filterTableEc2)
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={ec2Data?.[period]?.resources?.filter(filterTableEc2)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
            <ContainerCardWithDateRange
                title={"Spend by Disks/Snapshots"}
                titleIcon={ComputeIcon}
                showDateRange={true}
                datePeriod={({period})=>({
                    start:"__",
                    end: "__",
                    rawStart: moment(azureCompute?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(azureCompute?.[period]?.endDate, "YYYYMMDD")
                })}
                totalCost={({period})=>(
                    currency + Format.formatNumber(ebsData?.[period]?.totalCost)
                )}
                datePeriodEnabled={true}
                showDatePeriod={(period)=>!!(ebsData != null && ebsData?.[period]?.resources?.length)}
                collapsible={true}
            >
                {({ period }) => (
                    <div className={classes.root}>
                        {ebsData?.[period] != null && ebsData?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                               <CarousalCard cardTitle1={'Total cost'} cardTitle2={"Total Resources"} cost={ebsData?.[period]?.totalCost} res={ebsData?.[period]?.resources?.length} currency={currency} />

                                <TableSearch onSearch={setSearchEbs} />
                                <TableContainer style={{ height: 350 }}>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AzureComputeHead subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={ebsData.length} totalCost={ebsData?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {ebsData?.[period]?.resources
                                                ?.filter(filterTableEbs)
                                                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                    rowsPerPageOptions={[10, 25, 50, 75]}
                                    component="div"
                                    count={ebsData?.[period]?.resources?.filter(filterTableEbs)?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                    </div>
                )}
            </ContainerCardWithDateRange>
            <br />
            {/* ----------------------------------------Azure containers-------------------------------------------------- */}
            <ContainerCardWithDateRange
                title={"Spend by Containers"}
                titleIcon={ContainersIcon}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(azureContainers?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(azureContainers?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(azureContainers?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!azureContainers != null && azureContainers?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
           >
                {({ period }) => (
            <div className={classes.root}>
               
                    <Collapse in={totalExpand}>
                        {azureContainers?.[period] != null && azureContainers?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                                <h6 style={{ textAlign: "right" }}>
                                    Total: {currency}
                                    <Cost>{azureContainers?.[period]?.totalCost}</Cost>
                                </h6>
                                <TableContainer>
                                <TableSearch onSearch={setSearch} />
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AzureComputeHead  subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={azureContainers.length} />
                                        <TableBody>
                                            {(azureContainers?.[period]?.resources)?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={azureContainers?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                            </Paper>
                        ) : (
                            <h6 style={{ textAlign: "center" }}>No data found</h6>
                        )}
                    </Collapse>
                
            </div>
              )}
              </ContainerCardWithDateRange>
            <br />
            {/* ------------------------------------------------Azure Kubernetes-------------------------------------------- */}
            <ContainerCardWithDateRange
                title={"Spend by Kubernetes"}
                titleIcon={KubernetesIcon}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    rawStart: moment(azureKubernetes?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(azureKubernetes?.[period]?.endDate, "YYYYMMDD"),
                    start:"__",
                    end: "__"
                })}
                totalCost={({ period }) => currency + Format.formatNumber(azureKubernetes?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!azureKubernetes != null && azureKubernetes?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
          >
                {({ period }) => (
            <div className={classes.root}>
                   
                    <Collapse in={totalExpand}>
                        {azureKubernetes?.[period] != null && azureKubernetes?.[period]?.resources?.length ? (
                            <Paper className={classes.paper}>
                                <h6 style={{ textAlign: "right" }}>
                                    <b>
                                        Total: {currency}
                                        <Cost>{azureKubernetes?.[period]?.totalCost}</Cost>
                                    </b>
                                </h6>
                                <TableContainer>
                                <TableSearch onSearch={setSearch} />
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <AzureComputeHead subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={azureKubernetes.length} />
                                        <TableBody>
                                            {(azureKubernetes?.[period]?.resources)?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={azureKubernetes?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                            </Paper>
                        ) : (
                            <h6 style={{ textAlign: "center" }}>No data found</h6>
                        )}
                    </Collapse>
               
            </div>
            )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
    },
}));

function AzureComputeHead({ subscriptionId,totalCost, currency = "" }) {

    const classes = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell className={classes.tableRow}>Resource name</TableCell>
                <TableCell className={classes.tableRow}>Resource type</TableCell>
                <TableCell className={classes.tableRow}>Resource group</TableCell>
                {subscriptionId ==null && <TableCell className={classes.tableRow}>Subscripiton</TableCell>}
                <TableCell className={classes.tableRow}>Location</TableCell>
                <TableCell className={classes.tableRow}>Cost</TableCell>
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({subscriptionId, row, index, list, setList, currency }) {
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState("");

    useEffect(() => {
        if (row) {
            setDate(moment(row.date, "YYYYMMDD").format("MMM DD, YYYY"));
        }
    }, [row]);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <IconButton
                        style={{ cursor: "pointer" }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                {/* <TableCell component="th" scope="row" padding="none">
          {date}
        </TableCell> */}
                <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">{row?.resourceName}</Tooltip>}>
                    <TableCell style={{whiteSpace: "nowrap"}}>{row?.resourceName.substring(0, 20) + "..."}</TableCell>
                </OverlayTrigger>
                <TableCell>{row?.resourceType.split("/")[1]}</TableCell>
                <TableCell>{row?.resourceGroup}</TableCell>
                {subscriptionId == null && (
                    <TableCell>
                        {"subscriptionId" in row ? (
                            <OverlayTrigger placement="bottom" overlay={<Popover id="popover-contained">{"subscriptionId" in row && <Popover.Content style={{ fontSize: 11 }}>{row?.subscriptionId}</Popover.Content>}</Popover>}>
                                <span style={{ whiteSpace: "nowrap" }}>{row?.subscriptionName}</span>
                            </OverlayTrigger>
                        ) : (
                            row?.subscriptionName
                        )}
                    </TableCell>
                )}
                <TableCell>{row?.resourceLocation}</TableCell>

                <TableCell>
                    {currency}
                    {Format.formatNumber(Number(row.cost))}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Service name</th>
                    <th>Meter name</th>
                    <th>Total</th>
                </tr>

                {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) => (
                            <tr>
                                <td>{m?.serviceName}</td>
                                <td>{m.meterName}</td>

                                <td>{currency + Format.formatNumber(Number(m.cost))}</td>
                            </tr>
                        ))}
            </table>
        </div>
    );
}
