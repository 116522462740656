import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Azure_logo from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_ICON from "../../images/GCP_logo.svg";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { getBudgetsRequest } from "../../redux/actions/actions";
import AlertIcon from "../../images/alertIcon.svg";
import { Edit } from "@material-ui/icons";
import NumberFormat from "../../components/NumberFormat";
import Format from "../../components/NumberFormat";
import LinearProgress from "@material-ui/core/LinearProgress";
import ProgressBar from "react-bootstrap/ProgressBar";
import Colors from "../../styles/color";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: Colors.tableHeader,
        color: "black",
        fontWeight: "bold",
        padding: theme.spacing(1.5),
    },
    body: {
        fontSize: 14,
        padding: theme.spacing(0.8),
    },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(0.5),
    },
    table: {
        maxHeight: 100,
    },
    container: {
        maxHeight: 440,
    },
}));

const StyledTableRow = withStyles((theme) => ({
    logoSize: { height: 40, width: 40 },
}))(TableRow);

export default function BudgetList({ data = [], showModal = () => {}, onDelete, onEdit, customerId, role }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    // const userData = useSelector((state) => ({
    //     id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    // }));

    return (
        <TableContainer className={classes.container} component={Paper}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">Cloud</StyledTableCell>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="center">Budget Period</StyledTableCell>
                        <StyledTableCell align="center">Budget Amount</StyledTableCell>
                        <StyledTableCell align="center">Actual Cost</StyledTableCell>
                        <StyledTableCell align="center">Alert Level</StyledTableCell>
                        <StyledTableCell align="center">Progress</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data.map((cloud, i) => {
                        return cloud.budgets.map((b, j) => (
                            <Budget
                                key={i + cloud.cloudProvider + j + b.budget_name}
                                dispatch={dispatch}
                                cloud={cloud.cloudProvider}
                                budgetName={b.budget_name}
                                budgetAmount={b.budgetAmount}
                                actualCost={b.actualCost}
                                budgetPeriod={b.budgetPeriod}
                                alertLevel={b.alertLevel}
                                handleShow={() =>
                                    showModal({
                                        cloudProvider: cloud.cloudProvider,
                                        budgetPeriod: b.budgetPeriod,
                                    })
                                }
                                callback={() => getBudgetsRequest({ all: "all", customerId: customerId })}
                                onDelete={onDelete}
                                onEdit={onEdit}
                                role={role}
                            />
                        ));
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function Budget({ cloud, budgetAmount, budgetPeriod, alertLevel, budgetName, onDelete, onEdit, actualCost, role }) {
    const { currency } = useSelector((state) => ({
        currency: state.allHomeReducer.currency,
    }));
    return (
        <StyledTableRow hover key={cloud}>
            <StyledTableCell align="center" component="th" scope="row">
                {
                    {
                        azure: <img style={{ width: 24, height: 24, marginRight: 4, marginTops: 4 }} src={Azure_logo} />,
                        aws: <img style={{ width: 24, height: 24, marginRight: 4, marginTops: 4 }} src={AWS_LOGO} />,
                        gcp: <img style={{ width: 24, height: 24, marginRight: 4, marginTops: 4 }} src={GCP_ICON} />,
                    }[cloud]
                }
            </StyledTableCell>
            <StyledTableCell align="center">{budgetName}</StyledTableCell>
            <StyledTableCell align="center">{budgetPeriod}</StyledTableCell>

            <StyledTableCell align="center">
                {Format.formatCurrencySymbol(currency || "USD")}
                {NumberFormat.formatNumber(budgetAmount)}
            </StyledTableCell>
            <StyledTableCell align="center">
                {Format.formatCurrencySymbol(currency || "USD")}
                {NumberFormat.formatNumber(actualCost)}
            </StyledTableCell>
            <StyledTableCell align="center">
                {alertLevel}
                {"%"}
            </StyledTableCell>
            <StyledTableCell align="center">
                <ProgressBar>
                    {(alertLevel * budgetAmount) / 100 <= actualCost ? (
                        <ProgressBar striped variant="success" key={"p1"} now={(actualCost / ((alertLevel * budgetAmount) / 100)) * 100 - ((actualCost / ((alertLevel * budgetAmount) / 100)) * 100 - 100)} />
                    ) : (
                        <ProgressBar striped variant="success" key={"p2"} now={(actualCost / ((alertLevel * budgetAmount) / 100)) * 100} />
                    )}

                    <ProgressBar striped variant="danger" key={"p3"} now={(actualCost / ((alertLevel * budgetAmount) / 100)) * 100 - 100} />
                </ProgressBar>
                <span>{((actualCost / ((alertLevel * budgetAmount) / 100)) * 100).toFixed(2)}%</span>
            </StyledTableCell>

            <StyledTableCell align="center">
                <div
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Button
                        variant={""}
                        color={"primary"}
                        onClick={() =>
                            onEdit({
                                budgetName,
                                budgetAmount,
                                alertLevel,
                                cloudProvider: cloud,
                                budgetPeriod,
                            })
                        }
                        disabled={role == "Owner" || role == null ? false : true}
                    >
                        <Edit />
                    </Button>
                    <Button
                        variant={""}
                        color={"primary"}
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                            onDelete({
                                cloudProvider: cloud,
                                budgetLevel: cloud,
                                budgetPeriod,
                            })
                        }
                        disabled={role == "Owner" || role == null ? false : true}
                    >
                        <DeleteIcon />
                    </Button>
                </div>
            </StyledTableCell>
        </StyledTableRow>
    );
}
