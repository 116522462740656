import React, { useEffect, useRef, useState } from "react";
import Azure_logo from "../../images/Azure_logo.svg";
import key from "../../images/key.svg";
import axios from "../../connection/axios";
import Format from "../../components/NumberFormat";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useSelector, useDispatch } from "react-redux";
import { accountRequest, getAzureManagementRequest, getSubscriptionsRequest, getAzureCCKRequest } from "../../redux/actions/actions";
import CostHeader, { CostHeaderDataComponent } from "../../components/CostHeader";
import DateRange from "../../components/DateRange";
import ConenctAccountSkipPage from "../ConnectAccountSkipPage";
import { LinearProgress, Link, makeStyles, Paper, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import BreadCrumbs from "../../components/BreadCrumbs";
import AzureHomeTab from "components/tab/AzureHomeTab";
import AzureSubscriptionTab from "components/tab/AzureSubscriptionTab";
import AzureTenantTab from "components/tab/AzureTenantTab";
import Color from "../../styles/color";

import ConnectionError from "../../components/ConnectionError";
import { SET_CLOUD_ACTIVE_TAB, SET_CLOUD_ACTIVE_TAB_REQUEST } from "../../redux/actions/types";
import DashboardLoader from "components/loaders/DashboardLoader";
import active_directory from "../../images/active_directory.svg";

import ReactPlayer from "react-player";
import Carousel from "react-bootstrap/Carousel";

import backgroundImage from "../../images/bg1.jpg";
import backgroundImage1 from "../../images/bg2.jpg";
import backgroundImage2 from "../../images/bg3.jpg";
import backgroundImage3 from "../../images/bg4.jpg";

const { REACT_APP_AZURE_TENANT_SUMMARY_OVERVIEW, REACT_APP_AZURE_TENANT_HOME, REACT_APP_AZURE_HOME, REACT_APP_AZURE_SUBSCRIPTION_HOME, REACT_APP_AZURE_HOME_SUMMARY_OVERVIEW, REACT_APP_AZURE_SUBSCRIPTION_SUMMARY_OVERVIEW } = process.env;
const introVideo = process.env.REACT_APP_CLOUDTRAKR_INTRO;

const DEFAULT_SUBSCRIPTION = {
    tenantId: null,
    tenantName: "Default",
    title: "All",
    value: "all",
};

const TAB_SET = {
    DASHBOARD: "DASHBOARD",
    MANAGEMENT_GROUPS: "MANAGEMENT_GROUPS",
    SUBSCRIPTIONS: "SUBSCRIPTIONS",
    RESOURCE_GROUPS_RESOURCES: "RESOURCE_GROUPS_RESOURCES",
    RESOURCE_GROUPS: "RESOURCE_GROUPS",
    RESOURCES: "RESOURCES",
    BILLING: "BILLING",
    RECOMMENDATIONS: "RECOMMENDATIONS",
    RESERVATIONS: "RESERVATIONS",
    COMPUTE: "COMPUTE",
    STORAGE: "STORAGE",
    FUNCTION_APPS: "FUNCTION_APPS",
    COSMOS_DB: "COSMOS_DB",
    SERVERLESS: "SERVERLESS",
    TAGS: "TAGS",
    SERVICES: "SERVICES",
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

export default function AzureHome() {
    const dispatch = useDispatch();
    const [isSticky, setSticky] = useState(false);
    const [rec, setRec] = useState({
        all: null,
        tenantId: null,
        subscriptionId: null,
    });
    const ref = useRef(null);
    const ref2 = useRef(null);

    const handleScroll = () => {
        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <= 80);
        }
        if (ref2.current) {
            setSticky(ref2.current.getBoundingClientRect().top <= 150);
        }
    };
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", () => handleScroll);
        };
    }, []);

    const classes = useStyles();
    // const [activeTab, setActiveTab] = useState(TAB_SET.DASHBOARD);
    const [activeTabSub, setActiveTabSub] = useState(TAB_SET.DASHBOARD);
    const [tabSelect, setTabSelect] = useState(null);
    const [loading, setloading] = useState(false);
    const [currency, setCurrency] = useState();
    const [cloud, setCloud] = useState();

    const [thisMonthSpend, setThisMonthSpend] = useState(null);
    const [topFiveServices, setTopFiveServices] = useState([]);
    const [topFiveLocations, setTopFiveLocations] = useState([]);
    const [topFiveResourceGroups, setTopFiveResourceGroups] = useState([]);
    const [topFiveServerlessServices, setTopFiveServerlessServices] = useState([]);
    const [spendByCategory, setSpendByCategory] = useState([]);
    const [spendByResourcegroups, setSpendByResourcegroups] = useState([]);
    const [spendByAccounts, setSpendByAccounts] = useState([]);
    const [spendBySubscriptions, setSpendBySubscriptions] = useState([]);
    const [lastMonthSpend, setLastMonthSpend] = useState(null);
    const [lastMonthDate, setLastMonthDate] = useState(null)
    const [lastMonthVariation, setLastMonthVariation] = useState(null);
    const [period, setPeriod] = useState("1M");
    const [dateCreated, setDateCreated] = useState(null);
    const [response, setResponse] = useState();
    const [charts, setCharts] = useState(null);

    const [accountList, setAccountList] = useState([]);
    const [serverlessList, setServerlessList] = useState([]);
    const [serverlessTotalValue, setServerlessTotalValue] = useState([]);

    const [progress, setProgress] = useState(0);
    const [selectedTenant, setTenant] = useState(DEFAULT_SUBSCRIPTION);
    const [selectedSubscription, setSubscription] = useState(DEFAULT_SUBSCRIPTION);
    const [subscriptionList, setSubscriptionList] = useState([DEFAULT_SUBSCRIPTION]);
    const [index, setIndex] = useState(0);

    const [parentWidth, setParentWidth] = useState(null);

    const [subscriptionsCount, setSubscriptionsCount] = useState(null);
    const [resourceGroupsCount, setResourceGroupsCount] = useState(null);
    const [servicesCount, setServicesCount] = useState(null);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
        subscriptions: state.allHomeReducer.allSubscriptions?.azure,
        allSub: state.allHomeReducer.allSubscriptions,
        role: state.userDetailsReducer.role,
    }));

    const AzureConnectionError = useSelector((state) => ({
        connectionError: state.connectionErrorReducer?.connections?.[0]?.azure?.tenant,
    }));

    function onTenantChange(tenant) {
        setTenant({
            tenantId: tenant.tenantId,
            title: tenant?.tenantName,
            value: null,
            type: "tenant",
        });
        getTenantData(null, tenant?.tenantId);
        if (activeTab === TAB_SET.MANAGEMENT_GROUPS) handleTabSelection(TAB_SET.DASHBOARD);
    }

    function onSubscriptionChange(subscription) {
        const { value, tenantId } = subscription;
        setTenant(DEFAULT_SUBSCRIPTION);
        setRec({ all: null, tenantId: tenantId, subscriptionId: value, type: "subscription" });
        setSubscription({ ...subscription, type: "subscription" });
        if (subscription.title != "All") {
            getSubscriptionData(subscription.value, subscription.tenantId);
            const { value, tenantId } = subscription;
            const customerId = userData.id;
            const sub_value = { customerId, tenantId, value };
            if (activeTab === TAB_SET.MANAGEMENT_GROUPS) handleTabSelection(TAB_SET.DASHBOARD);
        } else {
            getAzureAllHome();
        }
    }

    async function getSubscriptionData(value, tenantId) {
        setloading(true);
        await axios
            .get(REACT_APP_AZURE_SUBSCRIPTION_HOME, {
                params: {
                    customerId: userData.id,
                    tenantId,
                    subscriptionId: value,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        azureDataSetter(response);
                    } else {
                        azureNullDataSetter(response);
                    }
                } else {
                    setResponse("No status code");
                }
            })
            .finally(() => setloading(false))

            .catch((error) => {
                console.error(error);
            });
    }

    async function getTenantData(value, tenantId) {
        setloading(true);
        await axios
            .get(REACT_APP_AZURE_TENANT_HOME, {
                params: {
                    customerId: userData.id,
                    tenantId,
                    subscriptionId: value,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        azureDataSetter(response);
                    } else {
                        azureNullDataSetter(response);
                    }
                } else {
                    setResponse("No status code");
                }
            })
            .finally(() => setloading(false))

            .catch((error) => {
                console.error(error);
            });
    }

    const azureAccountStatus = useSelector((state) => state.accountStatusReducer.azureAccountStatus);
    const loadingA = useSelector((state) => state.accountStatusReducer.loading);

    const subscriptionServerless = useSelector((state) => state.azureServerlessReducer?.charts);
    const serverlessSubName = useSelector((state) => state.azureServerlessReducer.subscriptionName);
    const managmentGroup = useSelector((state) => state.azureManagementGroupReducer.managementGroups);
    const mgmtLoading = useSelector((state) => state.azureManagementGroupReducer.loading);
    const activeTab = useSelector((state) => state.appStateReducer.azureActiveTab);

    useEffect(() => {
        if (userData.id) {
            getAzureAllHome();
            if (userData.allSub !== null) {
                if (userData.allSub) {
                    const tenantId = userData.allSub?.azure?.[0]?.tenantId;
                    const customerId = userData.id;
                    if (managmentGroup == null) {
                        dispatch(
                            getAzureManagementRequest({
                                customerId,
                                tenantId,
                            })
                        );
                    }
                }
            } else {
                dispatch(getSubscriptionsRequest(userData.id));
            }
        }
    }, [userData.id, userData.allSub]);

    useEffect(() => {
        if (userData.id) {
            const customerId = userData.id;

            let dataType = "compute";
            dispatch(
                getAzureCCKRequest({
                    customerId,
                    dataType,
                })
            );

            dataType = "containers";
            dispatch(
                getAzureCCKRequest({
                    customerId,
                    dataType,
                })
            );

            dataType = "kubernetes";
            dispatch(
                getAzureCCKRequest({
                    customerId,
                    dataType,
                })
            );
        }
    }, [userData.id]);

    function getAzureAllHome() {
        setRec({ all: "all", tenantId: null, subscriptionId: null });
        getAzureHome();
    }

    function AccountStatus(id) {
        if (id) {
            dispatch(accountRequest(id));
        }
    }

    useEffect(() => {
        if (azureAccountStatus == null) {
            AccountStatus(userData.id);
        }
    }, [azureAccountStatus, userData.id]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        if (userData.id) {
            const tenantId = selectedSubscription?.tenantId;
            const subscriptionId = selectedSubscription?.value;

            axios
                .get(tenantId && subscriptionId ? REACT_APP_AZURE_SUBSCRIPTION_SUMMARY_OVERVIEW : REACT_APP_AZURE_HOME_SUMMARY_OVERVIEW, {
                    params: {
                        customerId: userData.id,
                        tenantId,
                        subscriptionId,
                    },
                })
                .then((response) => response.data)
                .then((response) => {
                    if (response?.data?.data) {
                        setSubscriptionsCount(parseInt(response?.data?.data?.SpendBySubscriptions?.totalSubscription) || null);
                        setResourceGroupsCount(parseInt(response?.data?.data?.SpendByResourceGroup?.totalResourceGroup) || null);
                        setServicesCount(parseInt(response?.data?.data?.SpendByServices?.totalServices) || null);
                    }
                });
        }
    }, [userData.id, selectedSubscription]);

    useEffect(() => {
        if (userData.id && selectedTenant != DEFAULT_SUBSCRIPTION) {
            const tenantId = selectedTenant?.tenantId;

            axios
                .get(REACT_APP_AZURE_TENANT_SUMMARY_OVERVIEW, {
                    params: {
                        customerId: userData.id,
                        tenantId,
                    },
                })
                .then((response) => response.data)
                .then((response) => {
                    if (response?.data?.data) {
                        setSubscriptionsCount(parseInt(response?.data?.data?.SpendBySubscriptions?.totalSubscription) || null);
                        setResourceGroupsCount(parseInt(response?.data?.data?.SpendByResourceGroup?.totalResourceGroup) || null);
                        setServicesCount(parseInt(response?.data?.data?.SpendByServices?.totalServices) || null);
                    }
                });
        }
    }, [selectedTenant]);

    useEffect(() => {
        if (userData.subscriptions && userData.subscriptions?.constructor.name == "Array") {
            const list = userData.subscriptions
                .map((t) =>
                    t.subscriptions.map((s) => ({
                        tenantId: t.tenantId,
                        tenantName: t.tenantName,
                        title: s.subName,
                        value: s.subscriptionId,
                    }))
                )
                .flat(1);
            setSubscriptionList((s) => [DEFAULT_SUBSCRIPTION, ...list]);
        }
    }, [userData.subscriptions]);

    function handleTabSelection(tab) {
        dispatch({
            type: SET_CLOUD_ACTIVE_TAB_REQUEST,
            payload: { cloud: "azureActiveTab", tab },
        });
    }

    return !loadingA && !loading ? (
        azureAccountStatus ? (
            <div
                style={{
                    backgroundColor: Color.background,
                    paddingLeft: 10,
                    paddingRight: 10,
                    minHeight: "95vh",
                }}
            >
                {charts && charts == null ? (
                    <div>
                    <Paper elevation={3}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 25,
                                flexDirection: "column",
                            }}
                        >
                            <h6>
                                Azure Account added successfully <DoneAllIcon style={{ color: "green" }} />
                            </h6>
                            <h5 style={{ color: "steelblue" }}>We are collecting and analyzing your data. Usually cloud providers takes less than 24 hours to provide data from your cloud accounts. Please check back CloudTrakr portal in 24 hours to see the deep visibility and optimization for your cloud spend.</h5>
                        </div>
                    </Paper>
                    <Paper elevation={3} style={{marginTop:8}}>
                        <div style={{display: "flex", flexDirection: "row",justifyContent: "space-between",padding:8 }}>
                        <ReactPlayer
                        style={{ margin: 8}}
                            width={"80%"}
                            height={"300px"}
                            url={introVideo}
                            controls
                            playing
                            muted
                            config={{
                                file: {
                                    attributes: {
                                        autoPlay: true,
                                        muted: true,
                                    },
                                },
                            }}
                        />
                        <div style={{ margin: 8, width: "80%" }}>
                            <Carousel controls={false} pause={"hover"}>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>SPEND LESS DO MORE.</h3>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage1} alt="Second slide" />

                                    <Carousel.Caption>
                                        <h3>VISUALIZE</h3>
                                        <p>Visualize cost breakdowns of each cloud.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage2} alt="Third slide" />

                                    <Carousel.Caption>
                                        <h3>SET</h3>
                                        <p>Set budget and keep your expenses in check.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img style={{ height: 300, width: 300 }} className="d-block w-100" src={backgroundImage3} alt="Fourth slide" />

                                    <Carousel.Caption>
                                        <h3>GET</h3>
                                        <p>Get customized cost, security and performance recommendations.</p>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </div>
                    </Paper>
                    </div>
                ) : (
                    <div className={classes.root}>
                        <div style={{ padding: 0.5 }}>
                            <div
                                style={{
                                    padding: 2,
                                }}
                            >
                                {/*<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>*/}
                                {/*    <>*/}
                                {AzureConnectionError?.connectionError &&
                                    AzureConnectionError?.connectionError?.map((r, i) => (
                                        <ConnectionError row={r} cloud={r.accountType} tenantName={r.tenantName} subscription={r.tenantId} status={r.status} errorDetail={r} />
                                    ))}
                                {selectedTenant == DEFAULT_SUBSCRIPTION && selectedSubscription.title == "All" ? (
                                    <BreadCrumbs parent={"Home"} child={"Azure"} parentLink={"/dashboard/charts"} hasChild={true} hasSubChild={false} />
                                ) : selectedTenant != DEFAULT_SUBSCRIPTION ? (
                                    <BreadCrumbs
                                        parent={"Home"}
                                        child={"Azure"}
                                        childLink={"/dashboard/azureHome"}
                                        subChild={selectedTenant.title}
                                        parentLink={"/dashboard/charts"}
                                        hasChild={true}
                                        hasSubChild={true}
                                    />
                                ) : (
                                    <BreadCrumbs
                                        parent={"Home"}
                                        child={"Azure"}
                                        subChild={selectedSubscription.title}
                                        parentLink={"/dashboard/charts"}
                                        subchildLink={"/dashboard/azureHome"}
                                        hasChild={true}
                                        hasSubChild={true}
                                    />
                                )}
                                {/*</>*/}
                                {/*<MyDropdown options={subscriptionList} />*/}
                                {/*</div>*/}

                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // border: '1px solid'
                                    }}
                                >
                                    <div>
                                        <CostHeader
                                            logoSize={35}
                                            cloudName={"Azure"}
                                            currency={Format.formatCurrencySymbol(currency)}
                                            cost={thisMonthSpend?.totalCost || 0}
                                            cloudIcon={Azure_logo}
                                            color={"#000"}
                                            lastMonthSpend={lastMonthSpend}
                                            ExtraComponent={() => false}
                                            date={thisMonthSpend?.endDate|| 0}
                                            startDate={thisMonthSpend?.startDate|| 0}
                                            lastMonthDate={lastMonthDate?.endDate}
                                        >
                                            {/*Only at All level and tenant level*/}
                                            {subscriptionsCount != null &&
                                            subscriptionsCount != undefined &&
                                            (selectedSubscription == DEFAULT_SUBSCRIPTION || selectedTenant != DEFAULT_SUBSCRIPTION) ? (
                                                <CostHeaderDataComponent data={subscriptionsCount} title={"Subscriptions"} />
                                            ) : null}
                                            {/*All level, tenant level and subscription level*/}
                                            {resourceGroupsCount != null && resourceGroupsCount != undefined ? <CostHeaderDataComponent data={resourceGroupsCount} title={"Resource groups"} /> : null}
                                            {/*Only at Subscription level*/}
                                            {servicesCount != null && servicesCount != undefined && selectedSubscription !== DEFAULT_SUBSCRIPTION ? (
                                                <CostHeaderDataComponent data={servicesCount} title={"Services"} />
                                            ) : null}
                                        </CostHeader>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: -25,
                                            flex: 0.25,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            // border: '1px solid'
                                        }}
                                    >
                                        <div style={{}} className={`sticky-wrapper${isSticky ? " sticky-aws-daterange" : ""}`} ref={ref}>
                                            <div className={"sticky-inner-aws-daterange"} style={{ marginTop: 10, backgroundColor: "white" }}>
                                                <Autocomplete
                                                    clearIndicator={true}
                                                    disableClearable={true}
                                                    value={selectedTenant != DEFAULT_SUBSCRIPTION ? selectedTenant : selectedSubscription}
                                                    options={subscriptionList}
                                                    groupBy={(option) =>
                                                        JSON.stringify({
                                                            tenantId: option.tenantId,
                                                            tenantName: option.tenantName,
                                                        })
                                                    }
                                                    renderOption={(option, state) => {
                                                        if (!option.title || option.value == "all") {
                                                            return <span style={{ textTransform: "capitalize" }}>{option?.value || ""}</span>;
                                                        } else {
                                                            return (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        paddingLeft: 10,
                                                                    }}
                                                                >
                                                                    <span>{option?.title || ""}</span>
                                                                    <span style={{ fontSize: 10 }}>{option?.value || ""}</span>
                                                                </div>
                                                            );
                                                        }
                                                    }}
                                                    renderGroup={({ group: p, children }) => {
                                                        const group = JSON.parse(p);
                                                        if (!group?.tenantId && group.tenantName == "Default") {
                                                            return (
                                                                <div>
                                                                    <span>{children}</span>
                                                                </div>
                                                            );
                                                        }
                                                        return (
                                                            <div>
                                                                {group?.tenantId || group?.tenantName ? (
                                                                    <div className={"dropdownGroup"} onClick={() => onTenantChange({ tenantId: group?.tenantId, tenantName: group?.tenantName })}>
                                                                        <span>{group?.tenantName || group?.tenantId}</span>
                                                                        <br />
                                                                        <span style={{ fontSize: 10 }}>{group?.tenantId || ""}</span>
                                                                    </div>
                                                                ) : null}
                                                                <div>{children}</div>
                                                            </div>
                                                        );
                                                    }}
                                                    getOptionLabel={(option) => option?.title || ""}
                                                    style={{ width: 370 }}
                                                    size={"small"}
                                                    onChange={(e, value) => onSubscriptionChange(value)}
                                                    renderInput={(params) => {
                                                        return <TextField {...params} label="Azure Tenants & Subscriptions" variant="outlined" />;
                                                    }}
                                                />
                                                {(selectedSubscription.title != "All") || selectedTenant?.tenantId ? (
                                                    <div>
                                                        <img
                                                            src={selectedTenant?.tenantId?active_directory:key}
                                                            alt="key"
                                                            style={{
                                                                width: 20,
                                                                height: 20,
                                                                marginLeft: 8,
                                                            }}
                                                        />
                                                        <span style={{ fontSize: 12 }}> {selectedTenant?.tenantId || selectedSubscription.value}</span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div style={{}} className={`sticky-wrapper${isSticky ? " sticky-aws-dropdown" : ""}`} ref={ref2}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {selectedTenant != DEFAULT_SUBSCRIPTION ? (
                            <AzureTenantTab
                                TAB_SET={TAB_SET}
                                activeTab={activeTab}
                                // setActiveTab={setActiveTab}
                                setActiveTabSub={handleTabSelection}
                                setActiveTab={handleTabSelection}
                                setTabSelect={setTabSelect}
                                charts={charts}
                                period={period}
                                currency={currency}
                                rec={rec}
                                progress={progress}
                                selectedTenant={selectedTenant}
                                selectedSubscription={selectedSubscription}
                                serverlessTotalValue={serverlessTotalValue}
                                serverlessList={serverlessList}
                                subscriptionServerless={subscriptionServerless}
                                customerId={userData.id}
                                subscriptionList={subscriptionList}
                            />
                        ) : selectedSubscription.title == "All" ? (
                            <AzureHomeTab
                                TAB_SET={TAB_SET}
                                activeTab={activeTab}
                                // setActiveTab={setActiveTab}
                                setActiveTab={handleTabSelection}
                                setTabSelect={setTabSelect}
                                charts={charts}
                                period={period}
                                currency={currency}
                                rec={rec}
                                progress={progress}
                                selectedSubscription={selectedSubscription}
                                serverlessTotalValue={serverlessTotalValue}
                                serverlessList={serverlessList}
                                subscriptionServerless={subscriptionServerless}
                                customerId={userData.id}
                                subscriptionList={subscriptionList}
                                managmentGroup={managmentGroup}
                                mgmtLoading={mgmtLoading}
                            />
                        ) : (
                            <AzureSubscriptionTab
                                TAB_SET={TAB_SET}
                                activeTab={activeTab}
                                // setActiveTabSub={setActiveTabSub}
                                setActiveTabSub={handleTabSelection}
                                setTabSelect={setTabSelect}
                                charts={charts}
                                period={period}
                                currency={currency}
                                rec={rec}
                                progress={progress}
                                selectedSubscription={selectedSubscription}
                                serverlessTotalValue={serverlessTotalValue}
                                serverlessList={serverlessList}
                                subscriptionServerless={subscriptionServerless}
                                customerId={userData.id}
                                subscriptionList={subscriptionList}
                            />
                        )}
                    </div>
                )}
            </div>
        ) : (
            <ConenctAccountSkipPage hasAzure={true} hasGCP={false} hasAWS={false} cloudName={" your Azure"} role={userData.role} />
        )
    ) : (
        <div className={classes.root}>
            {/* <LinearProgress variant="determinate" value={progress} /> */}
            <DashboardLoader />
        </div>
    );

    function getAzureHome() {
        setloading(true);
        axios
            .get(REACT_APP_AZURE_HOME + `?customerId=${userData.id}`)
            .then((response) => response.data)
            .then((response) => {
                if ("statusCode" in response) {
                    if (response.statusCode == 200) {
                        azureDataSetter(response);
                    } else {
                        azureNullDataSetter(response);
                    }
                } else {
                    setResponse("No status code");
                }
            })
            .finally(() => setloading(false))

            .catch((error) => {
                console.error(error);
            });
    }

    function azureDataSetter(response) {
        setCurrency(response?.data?.currency || "USD");
        if (response?.statusCode == 200) {
            const {
                data: {
                    currency,
                    cloudProvider,
                    charts: {
                        ThisMonthSpend,
                        LastMonthSpend,
                        TopFiveServices,
                        TopFiveLocations,
                        SpendByCategory,
                        SpendByAccounts,
                        TopFiveResourceGroups,
                        SpendBySubscriptions,
                        TopFiveServerlessServices,
                        SpendByResourcegroups = null,
                    },
                },
            } = response;
            setResponse(response?.statusCode);
            setCloud(cloudProvider);
            setThisMonthSpend(ThisMonthSpend);
            setTopFiveLocations(TopFiveLocations);
            setTopFiveServices(TopFiveServices);
            setTopFiveResourceGroups(TopFiveResourceGroups);
            setTopFiveServerlessServices(TopFiveServerlessServices);
            setSpendByCategory(SpendByCategory);
            setSpendByResourcegroups(SpendByResourcegroups);
            setDateCreated(response?.data?.dateCreated);
            setSpendByAccounts(SpendByAccounts);
            setSpendBySubscriptions(SpendBySubscriptions);
            setCurrency(currency);
            setLastMonthSpend(LastMonthSpend?.dataSet?.[0].value || 0);
            setLastMonthDate(LastMonthSpend)
            setLastMonthVariation(LastMonthSpend?.dataSet?.[1].value || 0);

            setCharts({
                TopFiveServices,
                TopFiveLocations,
                SpendByCategory,
                SpendByAccounts,
                TopFiveResourceGroups,
                SpendBySubscriptions,
                SpendByResourcegroups,
            });

            setAccountList(SpendByAccounts?.[period].dataSet);
            setServerlessList(TopFiveServerlessServices?.[period]);
            setServerlessTotalValue(TopFiveServerlessServices?.[period].totalCost);
        } else {
            setResponse(response?.statusCode);
        }
    }

    function azureNullDataSetter(response) {
        setCurrency(response?.data?.currency || "USD");

        setResponse(response);
        setCloud(null);
        setThisMonthSpend(null);
        setTopFiveLocations(null);
        setTopFiveServices(null);
        setTopFiveResourceGroups(null);
        setTopFiveServerlessServices(null);
        setSpendByCategory(null);
        setSpendByResourcegroups(null);
        setDateCreated(null);
        setSpendByAccounts(null);
        setSpendBySubscriptions(null);
        setCurrency(currency);
        setLastMonthSpend(null);
        setLastMonthVariation(null);

        setCharts(null);
        setAccountList(null);
        setServerlessList(null);
        setServerlessTotalValue(null);
        setSubscriptionsCount(null);
        setResourceGroupsCount(null);
        setServicesCount(null);
    }
}
