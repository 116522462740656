import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import AzureResources from "pages/DashboardAccountsHome/AzureResources";
import AzureServices from "pages/DashboardAccountsHome/AzureServices";
import AzureRecommendation from "pages/recommendations/AzureRecommendation";
import AzureManagementGroup from "pages/DashboardAccountsHome/AzureManagementGroup";
import AzureServerlessHome from "components/Serverless/AzureServerlessHome";
import active_directory from "../../images/active_directory.svg";
import azure_service from "../../images/newCloudTrakrIcons/AzureServices.png";
import resource_group from "../../images/newCloudTrakrIcons/AzureResourceGroups.png";
import AzureResourcesIcon from "../../images/newCloudTrakrIcons/AzureResources.png";
import subscritpion_icon from "../../images/newCloudTrakrIcons/AzureSubscriptions.png";
import recommendation_icon from "../../images/newCloudTrakrIcons/AzureRecommendations.png";
import calendar_icon from "../../images/calendar_icon.png";
import Overview from "../../images/overview.svg";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import management_group from "../../images/newCloudTrakrIcons/AzureBusinessUnits.png";
import azure_reservations from "../../images/newCloudTrakrIcons/AzureReservations.png";
import billing_icon from "../../images/newCloudTrakrIcons/AzureBilling.png";
import { LinearProgress } from "@material-ui/core";

import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { KeyboardArrowDown, KeyboardArrowUp, PauseCircleFilled, PlayArrow, PlayCircleFilled, Print, ThumbUp } from "@material-ui/icons";
import { Paper, Grid, Divider, ButtonGroup, Button, CircularProgress, IconButton, Collapse } from "@material-ui/core";
import AzureSubscriptionsList from "components/Azure/AzureSubscriptionsList";
import AzureResourceGroupList from "components/Azure/AzureResourceGroupList";
import AzureReservations from "pages/DashboardAccountsHome/AzureReservation";
import AzureCompute from "pages/DashboardAccountsHome/AzureCompute";
import AzureCosmosDB from "pages/DashboardAccountsHome/AzureCosmosDB";
import Format from "components/NumberFormat";
import YearTableData from "../YearTableData";
import axios from "../../connection/axios";
import YearCalendarData, { CalendarDataProcessor, Months } from "../YearCalendarData";
import { ArrowRight, CalendarToday, ChevronRight, Menu } from "@material-ui/icons";
import Colors from "../../styles/color";
import moment from "moment";
import Cost, { Currency } from "../Cost";
import SlideShow from "components/CustomSlider/SlideShow";
import ReactD3PieChart from "components/D3Charts/ReactD3GridPieChart";
import ReactD3BarChart from "components/D3Charts/ReactD3GridChart";
import AzureStorage from "../../pages/DashboardAccountsHome/AzureStorage";
import storage_account_icon from "../../images/newCloudTrakrIcons/AzureStorage.png";
import function_apps_icon from "../../images/newCloudTrakrIcons/AzureFunction.png";
import Azure_cosmosDB_icon from "../../images/newCloudTrakrIcons/AzureCosmosDB.png";
import serverless_icon from "../../images/newCloudTrakrIcons/AzureServerless.png";
import { BussinessUnit } from "pages/Charts/DashboardChart";
import colors from "../../styles/color";
import { getMonthsFromNow } from "../../util/DateFormatter";
import Calendar_Spend_Icon from "../../images/calendar_months.svg";
import ComputeIcon from "../../images/newCloudTrakrIcons/AzureCompute.png";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import GroupedBarChart from "../D3Charts/GroupedBarChart";
import StackedBarChart2 from "../../pages/Charts/StackedBarChart2";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { generateGroupedChartData } from "../../pages/Charts/DashboardTabs";
import Total_Spend_Icon from "../../images/sigma_icon.svg";
import DateRange from "../DateRange";
import AzureFunctions from "../../pages/DashboardAccountsHome/AzureFunctions";
import NO_DATA_AVAILABLE from "../NO_DATA_AVAILABLE";
import { TIMERS } from "../../util/AppConstants";
import aws_account from "../../images/aws_org.svg";
import azure_location from "../../images/azure_location.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ResourceGroups from "pages/Resource&Resource_groups/ResourceGroups";
import Resources from "pages/Resource&Resource_groups/Resources";
import ResourceGroupsIcon from "../../images/newCloudTrakrIcons/AzureResourceGroups.png";
import AZURE_LOGO from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_LOGO from "../../images/GCP_logo.svg";
import COMBINED_CLOUD_LOGO from "../../images/allCloudLogo.svg";
import { isNumeric } from "../../util/Miscellaneous";
import { useReactToPrint } from "react-to-print";
import CalendarLoader from "../loaders/CalendarLoader";
import AzureTagsIcon from "../../images/newCloudTrakrIcons/AzureTags.png";
import AzureTags from "../../pages/DashboardAccountsHome/AzureTags";
import ContainerCardWithDateRange from "../ContainerCardWithDateRange";
import all_resource from "../../images/All-Resources.svg";
import TableSearch from "../TableSearch";
import TableMonthlySpend from "../../pages/AllClouds/TableMonthlySpend";
import Total_Spend_Icon_New from "../../images/spend_icon.png";
import AzureHomeBilling from "./AzureHomeBilling";
import ArrowDown from "../../images/double-chevron-down.svg";
import MountUnmount from "../MountUnmount";
import ArrowUp from "../../images/double-chevron-up.svg";

const { REACT_APP_AZURE_1Y_TABLE_DATA } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: Colors.background,
        //padding: theme.spacing(-20),
    },
    paper: {
        padding: theme.spacing(2),
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
}));

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

export default function AzureHomeTab(props) {
    const { charts, currency, period, customerId, TAB_SET, activeTab } = props;
    const { managmentGroup, mgmtLoading, rec } = props;
    const [resTab, setresTab] = useState(false);
    const [chartPeriod, setChartPeriod] = useState("1M");
    const [subPeriod, setSubPeriod] = useState("1M");
    const classes = useStyles();
    // const [value, setValue] = React.useState(TAB_SET.DASHBOARD);
    const [parentWidth, setParentWidth] = useState(null);
    const [yearTableData, setYearTableData] = useState([]);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [showDataBy, setShowDataBy] = useState("table");
    const [overlayData, setOverlayData] = useState(null);
    const [ResourceGroup, setResourceGroup] = useState(null);
    const [monthInterval, setMonthInterval] = useState(null);
    const [computeData, setComputeData] = useState(null);
    const [containersData, setContainersData] = useState(null);
    const [kubernetesData, setKubernetesData] = useState(null);
    const [azureMonthWiseChartData, setAzureMonthWiseChartData] = useState([]);
    const [othersMonthWiseChartData, setOthersMonthWiseChartData] = useState([]);
    const [spendByMonthsChartView, setSpendByMonthsChartView] = useState(true);
    const [chartShowingIndex, setChartShowingIndex] = useState(0);
    const [chartTop5ShowingIndex, setChartTop5ShowingIndex] = useState(0);
    const [chartComputeIndex, setChartComputeShowingIndex] = useState(0);

    const [totalSpendChartView, setTotalSpendChartView] = useState(true);
    const [periodSpendByTotal, setPeriod] = useState("1M");
    const [loadingCalendarTableData, setLoadingCalendarTableData] = useState(false);
    const [progress, setProgress] = useState(0);

    const [spendByMonthAccountData, setSpendByMonthAccountData] = useState(null);
    const [spendByMonthResourceGroupData, setSpendByMonthResourceGroupData] = useState(null);
    const [spendByMonthResourcesData, setSpendByMonthResourcesData] = useState(null);
    const [spendByMonthServicesData, setSpendByMonthServicesData] = useState(null);
    const [ytdSearch, setYTDSearch] = useState(null);

    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    let azureCompute = useSelector((state) => state?.azureCCKReducer?.azureCCKdata?.data?.SpendByCompute);
    let azureContainers = useSelector((state) => state?.azureCCKReducer?.azureContainersData?.data?.SpendByContainers);
    let azureKubernetes = useSelector((state) => state?.azureCCKReducer?.azureKubernetesData?.data?.SpendByKubernetes);
    let tagsList = useSelector((state) => state.azureTagsReducer.tagsList);

    useEffect(() => {
        const RGChartData = [];
        charts?.TopFiveResourceGroups?.[chartPeriod]?.dataSet?.map((i) => {
            RGChartData.push({
                label: i.label == "" ? "unassigned" : i.label,
                value: i.value,
            });
        });
        setResourceGroup(RGChartData);
    }, [chartPeriod, charts]);

    useEffect(() => {
        getChartData(period, azureCompute, azureContainers, azureKubernetes);
    }, [period, azureCompute, azureContainers, azureKubernetes]);

    async function getChartData(period, azureCompute, azureContainers, azureKubernetes) {
        const ComputeChartData = [];
        const ContainersChartData = [];
        const KubernetesChartData = [];

        azureCompute?.[period]?.resources?.map((i) => {
            ComputeChartData.push({
                label: i.resourceName == "" ? "unassigned" : i.resourceName,
                value: Number(i.cost),
            });
        });

        if (ComputeChartData && ComputeChartData.length > 0) {
            setComputeData(await filterArraySameItemName(ComputeChartData));
        }

        azureContainers?.[period]?.resources?.map((i) => {
            ContainersChartData.push({
                label: i.resourceName == "" ? "unassigned" : i.resourceName,
                value: Number(i.cost),
            });
        });
        if (ContainersChartData && ContainersChartData.length > 0) {
            setContainersData(await filterArraySameItemName(ContainersChartData));
        }
        azureKubernetes?.[period]?.resources?.map((i) => {
            KubernetesChartData.push({
                label: i.resourceName == "" ? "unassigned" : i.resourceName,
                value: Number(i.cost),
            });
        });
        if (KubernetesChartData && KubernetesChartData.length > 0) {
            setKubernetesData(await filterArraySameItemName(KubernetesChartData));
        }
    }

    //-----------------Converteer function -------------------------

    async function filterArraySameItemName(obj) {
        let holder = {};
        obj.forEach(function (d) {
            if (holder.hasOwnProperty(d.label)) {
                holder[d.label] = holder[d.label] + d.value;
            } else {
                holder[d.label] = d.value;
            }
        });

        var obj2 = [];

        for (var prop in holder) {
            obj2.push({ label: prop, value: String(holder[prop]) });
        }
        return obj2.sort((a, b) => Number(b.value) - Number(a.value)).slice(0, 5);
    }

    const handleChange = (event, newValue) => {
        props?.setActiveTab(newValue);
    };

    function rootLoaded() {
        const e = window.$(".root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    function onResume() {
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    async function get1YearTableData(tenantId, subscriptionId, period = "monthly") {
        setLoadingCalendarTableData(true);
        return await axios
            .get(REACT_APP_AZURE_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                    subscriptionId,
                    tenantId,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data?.SpendByDaily);
                } else {
                    setYearTableData(response?.data?.data?.SpendByMonth);
                }
            })
            .catch((e) => {})
            .finally(() => setLoadingCalendarTableData(false));
    }

    useEffect(() => {
        if (customerId) {
            get1YearTableData()
                .then(async () => await get1YearTableData(null, null, "daily"))
                .then(onResume);
        }
        props?.setTabSelect("homeTab");
    }, [customerId]);

    async function onMonthSelect(m, y = null) {
        if (monthWiseData == null || !monthWiseData) {
            await get1YearTableData(null, null, "daily").then(() => {
                setCurrentMonth(m);
            });
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }

        if (y) setCurrentYear(y);
    }

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData, activeTab]);

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData
                .find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates?.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData?.find((data) => data.month == y.toString() + m.toString());
            // debugger
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
        }
        return { data, date };
    }

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;

            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear]);

    function filterArraySameItemName(obj) {
        let holder = {};
        obj.forEach(function (d) {
            if (holder.hasOwnProperty(d.label)) {
                holder[d.label] = holder[d.label] + d.value;
            } else {
                holder[d.label] = d.value;
            }
        });

        var obj2 = [];

        for (var prop in holder) {
            obj2.push({ label: prop, value: String(holder[prop]) });
        }
        return obj2.sort((a, b) => Number(b.value) - Number(a.value)).slice(0, 5);
    }

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ azure: { SpendByMonth: yearTableData } }, setAzureMonthWiseChartData, () => {});
    }, [yearTableData]);

    useEffect(() => {
        if (yearTableData)
            generateGroupedChartData(
                { azure: { SpendByMonth: yearTableData } },
                (data) => {
                    setOthersMonthWiseChartData(
                        data?.map((t) => ({
                            ...t,
                            values: t.values.map((v) => ({
                                ...v,
                                value: {
                                    resourceGroups: v.value?.resourceGroups?.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost))?.slice(0, 5),
                                    resources: v.value?.resources?.map((a)=>{
                                        a.resourceId = a?.resourceId.replace(/\//g,"");
                                        return a;
                                    })?.sort((a, b) => parseFloat(b.cost) - parseFloat(a.cost))?.slice(0, 5),
                                },
                            })),
                        })) || []
                    );
                },
                () => {},
                ["resourceGroups", "resources"]
            );
    }, [yearTableData]);

    const billingComponentRef = useRef();

    const onPrint = useReactToPrint({
        content: () => billingComponentRef.current,
    });

    useEffect(() => {
        setSpendByMonthAccountData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise.subscriptions.forEach((subWise) => {
                    if (subWise.subscriptionId in accounts) {
                        accounts[subWise.subscriptionId].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.subscriptionId] = { list: [], name: subWise.subscriptionName };
                        accounts[subWise.subscriptionId].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key.replace(/\//g,""), name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Subscriptions",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthAccountData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    useEffect(() => {
        setSpendByMonthResourceGroupData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise.resourceGroups.forEach((subWise) => {
                    if (subWise.resourceGroupName in accounts) {
                        accounts[subWise.resourceGroupName].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.resourceGroupName] = { list: [] };
                        accounts[subWise.resourceGroupName].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key.replace(/\//g,""), name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Resource groups",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthResourceGroupData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    useEffect(() => {
        setSpendByMonthResourcesData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise.resources.forEach((subWise) => {
                    if (subWise.resourceId in accounts) {
                        accounts[subWise.resourceId].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.resourceId] = { list: [] };
                        accounts[subWise.resourceId].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key.replace(/\//g,""), name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Resources",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthResourcesData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    const [buffer, setBuffer] = React.useState(10);
    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        setSpendByMonthServicesData(null);
        if (yearTableData && yearTableData?.length) {
            const accounts = {};
            const finalData = [];

            yearTableData?.forEach((monthWise) => {
                monthWise.services.forEach((subWise) => {
                    if (subWise.service in accounts) {
                        accounts[subWise.service].list.push({ month: monthWise.month, cost: subWise.cost });
                    } else {
                        accounts[subWise.service] = { list: [] };
                        accounts[subWise.service].list.push({ month: monthWise.month, cost: subWise.cost });
                    }
                });
            });

            Object.keys(accounts).forEach((key) => {
                const account = accounts[key].list;

                const newData = getMonthsFromNow()
                    .reverse()
                    .map((m, i) => {
                        const time = moment(m, "MMM YYYY");
                        const found = account.findIndex((d) => {
                            const int = moment(d?.month, "YYYYMM");
                            return time.isSame(int);
                        });
                        if (found > -1) {
                            return account?.[found];
                        } else {
                            return { month: parseInt(moment(m).format("YYYYMM")), cost: "_" };
                        }
                    });

                finalData.push({ subscriptionId: key.replace(/\//g,""), name: accounts[key].name, list: newData });
            });

            let otherRow = [];
            if (finalData && finalData.length) {
                const totalData = finalData.map((f) => f.list.map((l) => (isNumeric(l.cost) ? parseFloat(l.cost) : 0)));
                const finalTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                for (var col = 0; col < totalData?.[0]?.length || 0; col++) {
                    totalData.forEach((row) => {
                        finalTotal[col] += row[col];
                    });
                }

                otherRow.push({
                    subscriptionId: "All Services",
                    list: finalData?.[0]?.list?.map((f, i) => ({ month: f.month, cost: "" + finalTotal[i] })),
                });
            }

            setSpendByMonthServicesData({ list: finalData, other: otherRow });
        }
    }, [yearTableData]);

    const pausePlayTotalRef = useRef(null);
    const pausePlaySpendRef = useRef(null);
    const pausePlaySlideRef = useRef(null);
    const pausePlaySlide2Ref = useRef(null);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    return (
        <div className={classes.root}>
            <TabContext value={activeTab}>
                <TabList
                    onChange={handleChange}
                    // aria-label="simple tabs example"
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{ maxWidth: "calc(100vw - 80px)" }}
                >
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={billing_icon} />
                                Dashboard
                            </div>
                        }
                        value={TAB_SET.BILLING}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.BILLING)}
                    />
                    {/*<Tab*/}
                    {/*    style={styles.tab}*/}
                    {/*    label={*/}
                    {/*        <div style={styles.labelContainer}>*/}
                    {/*            <img style={styles.icon} src={DashboardIcon} />*/}
                    {/*            Dashboard*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*    value={TAB_SET.DASHBOARD}*/}
                    {/*    className={classes.tab}*/}
                    {/*    onClick={() => setresTab(false)}*/}
                    {/*    {...a11yProps(TAB_SET.DASHBOARD)}*/}
                    {/*/>*/}
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                              <img style={styles.icon} src={recommendation_icon} />
                                Recommendations
                            </div>
                        }
                        value={TAB_SET.RECOMMENDATIONS}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.RECOMMENDATIONS)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={management_group} />
                                Business units
                            </div>
                        }
                        value={TAB_SET.MANAGEMENT_GROUPS}
                        className={classes.tab}
                        onClick={() => setresTab(false)}
                        {...a11yProps(TAB_SET.MANAGEMENT_GROUPS)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={subscritpion_icon} />
                                Subscriptions
                            </div>
                        }
                        value={TAB_SET.SUBSCRIPTIONS}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.SUBSCRIPTIONS)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={azure_service} />
                                Services
                            </div>
                        }
                        value={TAB_SET.SERVICES}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.SERVICES)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={ResourceGroupsIcon} />
                                Resource groups
                            </div>
                        }
                        value={TAB_SET.RESOURCE_GROUPS}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.RESOURCE_GROUPS)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={AzureResourcesIcon} />
                                Resources
                            </div>
                        }
                        value={TAB_SET.RESOURCES}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.RESOURCES)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={ComputeIcon} />
                                Compute
                            </div>
                        }
                        value={TAB_SET.COMPUTE}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.COMPUTE)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={storage_account_icon} />
                                Storage
                            </div>
                        }
                        value={TAB_SET.STORAGE}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.STORAGE)}
                    />
                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={Azure_cosmosDB_icon} />
                                CosmosDB
                            </div>
                        }
                        value={TAB_SET.COSMOS_DB}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.COSMOS_DB)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={function_apps_icon} />
                                Function Apps
                            </div>
                        }
                        value={TAB_SET.FUNCTION_APPS}
                        className={classes.tab}
                        onClick={() => setresTab(true)}
                        {...a11yProps(TAB_SET.FUNCTION_APPS)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={azure_reservations} />
                                Reservations
                            </div>
                        }
                        value={TAB_SET.RESERVATIONS}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.RESERVATIONS)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={serverless_icon} height={30} />
                                Serverless
                            </div>
                        }
                        value={TAB_SET.SERVERLESS}
                        className={classes.tab}
                        {...a11yProps(TAB_SET.SERVERLESS)}
                    />

                    <Tab
                        style={styles.tab}
                        label={
                            <div style={styles.labelContainer}>
                                <img style={styles.icon} src={AzureTagsIcon} />
                                Tags
                            </div>
                        }
                        value={TAB_SET.TAGS}
                        className={classes.tab}
                        onClick={() => setresTab(false)}
                    />
                </TabList>
                <Divider />

                <TabPanel value={"dep"+TAB_SET.DASHBOARD} onLoad={rootLoaded} className={"root_dashboard"}>
                    <div>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlaySlideRef?.current?.click()}>
                                                            {pausePlaySlideRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setPeriod}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {({ period: chartPeriod }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 1 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ padding: 10 }}>
                                                <SlideShow names={["Spend by Tenants", "Spend by Subscriptions", "Spend by Business units", "Spend by Category"]} arrowsHandled={true} showingIndex={chartShowingIndex} pausePlayRef={pausePlaySlideRef}>
                                                    <div>
                                                        {charts?.SpendByAccounts?.[chartPeriod]?.dataSet ? (
                                                            <ReactD3PieChart
                                                                title={"Spend By Tenants"}
                                                                cost={charts?.SpendByAccounts?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.SpendByAccounts?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Spend By Tenants</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {(charts?.SpendBySubscriptions?.[chartPeriod]?.dataSet && (
                                                            <ReactD3BarChart
                                                                title={"Spend By Subscriptions"}
                                                                mouseEffect={true}
                                                                cost={charts?.SpendBySubscriptions?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.SpendBySubscriptions?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value)}
                                                            />
                                                        )) || <NO_DATA_AVAILABLE>No data found for Spend By Subscriptions</NO_DATA_AVAILABLE>}
                                                    </div>

                                                    <div>
                                                        {mgmtLoading ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <CircularProgress thickness={2} size={80} />
                                                            </div>
                                                        ) : managmentGroup && managmentGroup != null ? (
                                                            <BussinessUnit data={managmentGroup?.managementGroups?.children} period={chartPeriod} currency={currency} />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Business units</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>

                                                    <div>
                                                        {charts?.SpendByCategory?.[chartPeriod]?.dataSet ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Spend By Category"}
                                                                cost={charts?.SpendByCategory?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.SpendByCategory?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Spend By Category</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 1 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlaySlide2Ref?.current?.click()}>
                                                            {pausePlaySlide2Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setPeriod}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {({ period: chartPeriod }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 1 }}>
                                                <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ padding: 10 }}>
                                                <SlideShow names={["Top 5 Services", "Top 5 Locations", "Top 5 Resource groups"]} arrowsHandled={true} showingIndex={chartTop5ShowingIndex} pausePlayRef={pausePlaySlide2Ref}>
                                                    <div>
                                                        {charts?.TopFiveServices?.[chartPeriod]?.dataSet ? (
                                                            <ReactD3BarChart
                                                                title={"Top 5 Services"}
                                                                mouseEffect={true}
                                                                cost={charts?.TopFiveServices?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.TopFiveServices?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {charts?.TopFiveLocations?.[chartPeriod]?.dataSet ? (
                                                            <ReactD3BarChart
                                                                title={"Top 5 Locations"}
                                                                mouseEffect={true}
                                                                cost={charts?.TopFiveLocations?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={charts?.TopFiveLocations?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {ResourceGroup && ResourceGroup.length ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Top 5 ResourceGroups"}
                                                                cost={charts?.TopFiveResourceGroups?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={ResourceGroup.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 ResourceGroups</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 1 }}>
                                                <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                        <br />

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange showDateRange={false} title={"Calendar"} titleIcon={calendar_icon}>
                                        {() => (
                                              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                              {loadingCalendarTableData ? (
                                                <CircularProgress justifyContent={'center'} thickness={2} size={60} />
                                                 ) : (
                                            <YearCalendarData
                                                style={{ flex: 1 }}
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                year={currentYear}
                                                onNext={() => setCurrentYear(currentYear + 1)}
                                                onPrev={() => setCurrentYear(currentYear - 1)}
                                                yearData={CalendarDataProcessor(yearTableData, "year")}
                                                monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                                onMonthSelect={onMonthSelect}
                                                month={currentMonth}
                                                renderOverlay={(d, m, y, cost) => {
                                                    const { data, date } = getOverlayData(d, m, y);
                                                    if (data)
                                                        return setOverlayData({
                                                            data,
                                                            date,
                                                            cost: data?.cost,
                                                        });
                                                    return null;
                                                }}
                                                calendarWidth={"100%"}
                                            />
                                            )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>

                                <Grid item xs={6}>
                                        <ContainerCardWithDateRange showDateRange={false} title={"Calendar details"} titleIcon={calendar_icon}>
                                            {() => (
                                                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                {!!!overlayData ? (
                                                    <CircularProgress thickness={2} size={60} />
                                                     ) : (
                                                        overlayData && overlayData.data && (
                                                <OverlayComponent
                                                    cost={overlayData?.cost}
                                                    data={overlayData?.data}
                                                    date={overlayData?.date}
                                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                                    dataKeys={[
                                                        {
                                                            key: "subscriptions",
                                                            title: "Top Azure Subscriptions",
                                                            subKey: "subscriptionName",
                                                            subKey2: "subscriptionId",
                                                            icon: subscritpion_icon,
                                                            onMouseOver: "subscriptionId",
                                                        },
                                                        {
                                                            key: "locations",
                                                            title: "Top Locations",
                                                            subKey: "location",
                                                            icon: azure_location,
                                                        },
                                                        {
                                                            key: "services",
                                                            title: "Top Services",
                                                            subKey: "service",
                                                            icon: azure_service,
                                                        },
                                                        {
                                                            key: "resourceGroups",
                                                            title: "Top Resource Groups",
                                                            subKey: "resourceGroupName",
                                                            icon: resource_group,
                                                        },
                                                        {
                                                            key: "resources",
                                                            title: "Top Resources",
                                                            subKey: "resourceId",
                                                            icon: resource_group,
                                                        },
                                                    ]}
                                                    onClick={() => {
                                                        // props?.setActiveTab(TAB_SET.RESOURCE_GROUPS_RESOURCES);
                                                    }}
                                                    maxWidth={"100%"}
                                                    maxHeight={700}
                                                    />
                                                    ))}
                                                    </div>
                                                )}
                                        </ContainerCardWithDateRange>
                                </Grid>
                            </Grid>

                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    showDateRange={true}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                            {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    onPeriodChange={setPeriod}
                                    // saveAsCSV={true}
                                    // onSaveAsCSV={onSaveAsCSV}
                                >
                                    {({ period: chartPeriod }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 1 }}>
                                                <IconButton onClick={() => setChartComputeShowingIndex(chartComputeIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ padding: 10 }}>
                                                <SlideShow names={["Spend by Compute", "Spend by Containers", "Spend by Kubernetes"]} arrowsHandled={true} showingIndex={chartComputeIndex} pausePlayRef={pausePlayTotalRef}>
                                                    <div>
                                                        {azureCompute?.[chartPeriod]?.resources?.length && computeData ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Spend by Compute"}
                                                                cost={azureCompute?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={computeData.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>{"No Compute found for " + chartPeriod}</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {azureContainers?.[chartPeriod]?.resources?.length && containersData ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Spend by Containers"}
                                                                cost={azureContainers?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={containersData.sort((a, b) => b.value - a.value)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>{"No Containers found for " + chartPeriod}</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {azureKubernetes?.[chartPeriod]?.resources?.length && kubernetesData ? (
                                                            <ReactD3BarChart
                                                                mouseEffect={true}
                                                                title={"Spend by Kubernetes"}
                                                                cost={azureKubernetes?.[chartPeriod]?.totalCost}
                                                                currency={currency}
                                                                data={kubernetesData.sort((a, b) => b.value - a.value)}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>{"No Kubernetes found for " + chartPeriod}</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 1 }}>
                                                <IconButton onClick={() => setChartComputeShowingIndex(chartComputeIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                        <br />
                        {parentWidth && (
                            <YearTableData
                                hideLegend={true}
                                width={parentWidth}
                                data={{
                                    // azure: yearTableData?.map((c) => c.cost),
                                    azure: yearTableData,
                                }}
                                months={yearTableData?.map((m) => m.month)}
                                currency={Format.formatCurrencySymbol(currency || "USD")}
                            />
                        )}
                        <br />
                        <div style={{ marginTop: 10 }}>
                            <ContainerCardWithDateRange
                                showDateRange={false}
                                title={"Spend by months"}
                                titleIcon={calendar_icon}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Spend by months</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                        {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {() => (
                                    <div style={{}}>
                                        <div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: 10,
                                                    paddingTop: 0,
                                                    //  justifyContent: 'center'
                                                }}
                                            >
                                                <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                                <SlideShow
                                                    pausePlayRef={pausePlaySpendRef}
                                                    delay={TIMERS.LOWER_CHART_INTERVAL}
                                                    showingIndex={chartShowingIndex}
                                                    names={["Spend by Azure", "Top 5 Resource Groups", "Top 5 Resources"]}
                                                    view="All"
                                                    arrowsHandled={true}
                                                >
                                                    {loadingCalendarTableData ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <CircularProgress thickness={2} size={80} />
                                                        </div>
                                                    ) : (
                                                        <Paper>
                                                            <GroupedBarChart
                                                                maxWidth={null}
                                                                currency={Format.formatCurrencySymbol(currency)}
                                                                totalCost={null}
                                                                // mouseEffect={true}
                                                                data={azureMonthWiseChartData}
                                                                cloudCount={1}
                                                            />
                                                        </Paper>
                                                    )}
                                                    <Paper>
                                                        <StackedBarChart2
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            keyStack={"resourceGroups"}
                                                            keyField={"resourceGroupName"}
                                                            valueKey={"cost"}
                                                            // mouseEffect={true}
                                                            data={othersMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                    <Paper>
                                                        <StackedBarChart2
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            keyStack={"resources"}
                                                            keyField={"resourceId"}
                                                            valueKey={"cost"}
                                                            // mouseEffect={true}
                                                            data={othersMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                </SlideShow>
                                                <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </div>
                        <br />
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AzureSubscriptionsList tenantLogo={active_directory} currency={Format.formatCurrencySymbol(currency)} data={charts?.SpendByAccounts} />
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: 35, marginBottom: 20 }}>
                            <AzureResources tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.SUBSCRIPTIONS}>
                    <Paper elevation={10} style={{ marginTop: 5 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10 }}>
                                <img height={30} src={subscritpion_icon} alt="Subscription icon" style={{ marginRight: 6 }} />
                                <h5 style={{ fontWeight: "bold" }}> Spend By Subscriptions</h5>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {totalSpendChartView && (
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        <span
                                            style={{
                                                marginRight: 4,
                                                fontSize: 12,
                                                color: "#616161",
                                            }}
                                        >
                                            Last
                                        </span>
                                        <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setSubPeriod} defaultSelected={subPeriod} />
                                    </div>
                                )}
                                <IconButton onClick={() => setTotalSpendChartView(!totalSpendChartView)} style={{ outline: "none" }}>
                                    {totalSpendChartView ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Collapse in={totalSpendChartView}>
                                {charts?.SpendBySubscriptions?.[subPeriod]?.dataSet && Object.keys(charts.SpendBySubscriptions?.[subPeriod]).length > 0 ? (
                                    <Paper className={classes.paper}>
                                        <div
                                            style={{
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <ReactD3BarChart
                                                title={"Spend By Subscriptions"}
                                                mouseEffect={true}
                                                cost={charts?.SpendBySubscriptions?.[subPeriod]?.totalCost}
                                                currency={currency}
                                                data={charts?.SpendBySubscriptions?.[subPeriod]?.dataSet.sort((a, b) => b.value - a.value)}
                                            />
                                        </div>
                                    </Paper>
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                            </Collapse>
                        </div>
                    </Paper>

                    <div style={{ marginTop: 16 }}>
                        <AzureSubscriptionsList tenantLogo={active_directory} currency={Format.formatCurrencySymbol(currency)} data={charts?.SpendByAccounts} />
                    </div>

                    <br />
                    <div style={{}}>
                        <ContainerCardWithDateRange title={"Spend by months"} titleIcon={subscritpion_icon} showDateRange={false} collapsible={true}>
                            {() => (
                                <>
                                    {loadingCalendarTableData || spendByMonthAccountData ? <TableSearch onSearch={setYTDSearch} /> : null}
                                    {loadingCalendarTableData || spendByMonthAccountData ? (
                                        <TableMonthlySpend
                                            loading={loadingCalendarTableData}
                                            cols={14}
                                            subscriptionNameAsOverlay={true}
                                            tableLabel={"Subscription"}
                                            width={parentWidth}
                                            data={spendByMonthAccountData?.list?.filter((s) => {
                                                if (ytdSearch && ytdSearch.toString().length) {
                                                    return !!TableSearch.doesItIncludes([s.cloud, s.subscriptionId, s.name], ytdSearch);
                                                }
                                                return true;
                                            })}
                                            staticRows={spendByMonthAccountData?.other}
                                            currency={Format.formatCurrencySymbol(currency)}
                                            showCloud={false}
                                        />
                                    ) : (
                                        <NO_DATA_AVAILABLE height={"auto"} />
                                    )}
                                </>
                            )}
                        </ContainerCardWithDateRange>
                    </div>
                    {/* </Grid> */}
                    {/* </Grid> */}
                </TabPanel>
                <TabPanel value={TAB_SET.SERVICES}>
                    <div>
                        <AzureServices
                            parentWidth={parentWidth}
                            loadingCalendarTableData={loadingCalendarTableData}
                            spendByMonthServicesData={spendByMonthServicesData}
                            showData={rec}
                            currency={Format.formatCurrencySymbol(currency)}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RESOURCE_GROUPS_RESOURCES}>
                    <div>
                        <AzureResources tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                    </div>
                </TabPanel>
                {/* Resouce_Groups */}
                <TabPanel value={TAB_SET.RESOURCE_GROUPS}>
                    <div>
                        <ResourceGroups
                            parentWidth={parentWidth}
                            loadingCalendarTableData={loadingCalendarTableData}
                            spendByMonthResourceGroupData={spendByMonthResourceGroupData}
                            showData={rec}
                            currency={Format.formatCurrencySymbol(currency)}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RESOURCES}>
                    <div>
                        <Resources
                            parentWidth={parentWidth}
                            loadingCalendarTableData={loadingCalendarTableData}
                            spendByMonthResourcesData={spendByMonthResourcesData}
                            showData={rec}
                            currency={Format.formatCurrencySymbol(currency)}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.STORAGE}>
                    <div>
                        <AzureStorage tab={resTab} showData={rec} period={period} curr={currency} currency={Format.formatCurrencySymbol(currency)} progress={progress} subscriptionList={props.subscriptionList} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RECOMMENDATIONS}>
                    <div>
                        <AzureRecommendation customerId={customerId} progress={progress} currency={Format.formatCurrencySymbol(currency)} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.FUNCTION_APPS}>
                    <div>
                        <AzureFunctions tab={resTab} showData={rec} period={period} curr={currency} currency={Format.formatCurrencySymbol(currency)} progress={progress} subscriptionList={props.subscriptionList} customerId={customerId} />
                    </div>
                </TabPanel>

                <TabPanel value={TAB_SET.SERVERLESS}>
                    <div>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <AzureServerlessHome currency={Format.formatCurrencySymbol(currency)} progress={progress} customerId={customerId} />
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>

                <TabPanel value={TAB_SET.BILLING} onLoad={rootLoaded} className={"root_dashboard"}>
                    <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                                <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                    </div>
                    <div style={{ maxWidth: "calc(100vw - 120px)" }} ref={billingComponentRef}>
                        <AzureHomeBilling
                            somerefs={[
                                {how: setDashboardComponentsRef, what:'billing1', where: dashboardComponentsRef},
                            ]}
                            yearTableData={yearTableData}
                            currency={currency}
                            calendarLoading={loadingCalendarTableData}
                            monthWiseData={monthWiseData}
                            get1YearTableData={get1YearTableData}
                            csv_table_id={"azurehomebillingtable1"}
                            csv_filename={"azure_billing.csv"}
                        />
                    </div>
                    <br/>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={{how: setDashboardComponentsRef, what:'billing2', where: dashboardComponentsRef}}
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlaySlideRef?.current?.click()}>
                                                        {pausePlaySlideRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setPeriod}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {({ period: chartPeriod }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 1 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow names={["Spend by Tenants", "Spend by Subscriptions", "Spend by Business units", "Spend by Category"]} arrowsHandled={true} showingIndex={chartShowingIndex} pausePlayRef={pausePlaySlideRef}>
                                                <div>
                                                    {charts?.SpendByAccounts?.[chartPeriod]?.dataSet ? (
                                                        <ReactD3PieChart
                                                            title={"Spend By Tenants"}
                                                            cost={charts?.SpendByAccounts?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendByAccounts?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Spend By Tenants</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {(charts?.SpendBySubscriptions?.[chartPeriod]?.dataSet && (
                                                        <ReactD3BarChart
                                                            title={"Spend By Subscriptions"}
                                                            mouseEffect={true}
                                                            cost={charts?.SpendBySubscriptions?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendBySubscriptions?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value)}
                                                        />
                                                    )) || <NO_DATA_AVAILABLE>No data found for Spend By Subscriptions</NO_DATA_AVAILABLE>}
                                                </div>

                                                <div>
                                                    {mgmtLoading ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <CircularProgress thickness={2} size={80} />
                                                        </div>
                                                    ) : managmentGroup && managmentGroup != null ? (
                                                        <BussinessUnit data={managmentGroup?.managementGroups?.children} period={chartPeriod} currency={currency} />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Business units</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>

                                                <div>
                                                    {charts?.SpendByCategory?.[chartPeriod]?.dataSet ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Spend By Category"}
                                                            cost={charts?.SpendByCategory?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.SpendByCategory?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Spend By Category</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                        </div>
                                        <div style={{ position: "absolute", right: 1 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={{how: setDashboardComponentsRef, what:'billing3', where: dashboardComponentsRef}}
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlaySlide2Ref?.current?.click()}>
                                                        {pausePlaySlide2Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setPeriod}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {({ period: chartPeriod }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 1 }}>
                                            <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow names={["Top 5 Services", "Top 5 Locations", "Top 5 Resource groups"]} arrowsHandled={true} showingIndex={chartTop5ShowingIndex} pausePlayRef={pausePlaySlide2Ref}>
                                                <div>
                                                    {charts?.TopFiveServices?.[chartPeriod]?.dataSet ? (
                                                        <ReactD3BarChart
                                                            title={"Top 5 Services"}
                                                            mouseEffect={true}
                                                            cost={charts?.TopFiveServices?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.TopFiveServices?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.TopFiveLocations?.[chartPeriod]?.dataSet ? (
                                                        <ReactD3BarChart
                                                            title={"Top 5 Locations"}
                                                            mouseEffect={true}
                                                            cost={charts?.TopFiveLocations?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={charts?.TopFiveLocations?.[chartPeriod]?.dataSet.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {ResourceGroup && ResourceGroup.length ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Top 5 ResourceGroups"}
                                                            cost={charts?.TopFiveResourceGroups?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={ResourceGroup.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 ResourceGroups</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                        </div>
                                        <div style={{ position: "absolute", right: 1 }}>
                                            <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange
                                someref={{how: setDashboardComponentsRef, what:'billing33', where: dashboardComponentsRef}}
                                showDateRange={true}
                                title={"Total spend"}
                                titleIcon={Total_Spend_Icon_New}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Total spend</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                        {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                                onPeriodChange={setPeriod}
                                // saveAsCSV={true}
                                // onSaveAsCSV={onSaveAsCSV}
                            >
                                {({ period: chartPeriod }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            position: "relative",
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 1 }}>
                                            <IconButton onClick={() => setChartComputeShowingIndex(chartComputeIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <div style={{ padding: 10 }}>
                                            <SlideShow names={["Spend by Compute", "Spend by Containers", "Spend by Kubernetes"]} arrowsHandled={true} showingIndex={chartComputeIndex} pausePlayRef={pausePlayTotalRef}>
                                                <div>
                                                    {azureCompute?.[chartPeriod]?.resources?.length && computeData ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Spend by Compute"}
                                                            cost={azureCompute?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={computeData.sort((a, b) => b.value - a.value).slice(0, 5)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>{"No Compute found for " + chartPeriod}</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureContainers?.[chartPeriod]?.resources?.length && containersData ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Spend by Containers"}
                                                            cost={azureContainers?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={containersData.sort((a, b) => b.value - a.value)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>{"No Containers found for " + chartPeriod}</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {azureKubernetes?.[chartPeriod]?.resources?.length && kubernetesData ? (
                                                        <ReactD3BarChart
                                                            mouseEffect={true}
                                                            title={"Spend by Kubernetes"}
                                                            cost={azureKubernetes?.[chartPeriod]?.totalCost}
                                                            currency={currency}
                                                            data={kubernetesData.sort((a, b) => b.value - a.value)}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>{"No Kubernetes found for " + chartPeriod}</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                        </div>
                                        <div style={{ position: "absolute", right: 1 }}>
                                            <IconButton onClick={() => setChartComputeShowingIndex(chartComputeIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                    </Grid>
                    <br/>
                    <div style={{ marginTop: 10 }}>
                        <ContainerCardWithDateRange
                            someref={{how: setDashboardComponentsRef, what:'billing4', where: dashboardComponentsRef}}
                            showDateRange={false}
                            title={"Spend by months"}
                            titleIcon={calendar_icon}
                            HeaderTitleComponent={(e, setCollapseClickable) => (
                                <>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                        <span>Spend by months</span>
                                        <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                            <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                    {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </>
                            )}
                            // saveAsCSV={true}
                            // onSaveAsCSV={onSaveAsCSV}
                        >
                            {() => (
                                <div style={{}}>
                                    <div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                padding: 10,
                                                paddingTop: 0,
                                                //  justifyContent: 'center'
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <SlideShow
                                                pausePlayRef={pausePlaySpendRef}
                                                delay={TIMERS.LOWER_CHART_INTERVAL}
                                                showingIndex={chartShowingIndex}
                                                names={["Spend by Azure", "Top 5 Resource Groups", "Top 5 Resources"]}
                                                view="All"
                                                arrowsHandled={true}
                                            >
                                                {loadingCalendarTableData ? (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <CircularProgress thickness={2} size={80} />
                                                    </div>
                                                ) : (
                                                    <Paper>
                                                        <GroupedBarChart
                                                            maxWidth={null}
                                                            currency={Format.formatCurrencySymbol(currency)}
                                                            totalCost={null}
                                                            // mouseEffect={true}
                                                            data={azureMonthWiseChartData}
                                                            cloudCount={1}
                                                        />
                                                    </Paper>
                                                )}
                                                <Paper>
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"resourceGroups"}
                                                        keyField={"resourceGroupName"}
                                                        valueKey={"cost"}
                                                        // mouseEffect={true}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                                <Paper>
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"resources"}
                                                        keyField={"resourceId"}
                                                        valueKey={"cost"}
                                                        // mouseEffect={true}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </Paper>
                                            </SlideShow>
                                            <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ContainerCardWithDateRange>
                    </div>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <AzureSubscriptionsList somerefs={[{how: setDashboardComponentsRef, what:'billing5', where: dashboardComponentsRef}]} tenantLogo={active_directory} currency={Format.formatCurrencySymbol(currency)} data={charts?.SpendByAccounts} />
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: 35, marginBottom: 20 }}>
                        <AzureResources somerefs={[{how: setDashboardComponentsRef, what:'billing6', where: dashboardComponentsRef}]} tab={resTab} showData={rec} period={period} currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                    </div>

                </TabPanel>
                <TabPanel value={TAB_SET.MANAGEMENT_GROUPS}>
                    <div>
                        <AzureManagementGroup
                            tab={resTab}
                            showData={rec}
                            // tenantId={rec.tenantId}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            customerId={customerId}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RESERVATIONS}>
                    <div>
                        <AzureReservations
                            tab={resTab}
                            // showData={rec}
                            // period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.COMPUTE}>
                    <div>
                        <AzureCompute
                            tab={resTab}
                            // showData={rec}
                            // period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.COSMOS_DB}>
                    <div>
                        <AzureCosmosDB tab={resTab} showData={rec} period={period} curr={currency} currency={Format.formatCurrencySymbol(currency)} progress={progress} subscriptionList={props.subscriptionList} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.TAGS}>
                    <div>
                        <AzureTags
                            tab={resTab}
                            tenantId={null}
                            subscriptionId={null}
                            // showData={rec}
                            // tenantId={rec.tenantId}
                            period={period}
                            currency={Format.formatCurrencySymbol(currency)}
                            progress={progress}
                            customerId={customerId}
                            tagsList={tagsList}
                        />
                    </div>
                </TabPanel>
            </TabContext>
        </div>
    );
}

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -10,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};

const CLOUD_ICONS = {
    azure: AZURE_LOGO,
    aws: AWS_LOGO,
    gcp: GCP_LOGO,
    all: COMBINED_CLOUD_LOGO,
};

export function OverlayComponent({ maxHeight = 400, maxWidth = 500, cloud, headerIcon, cost, date, data, dataKeys = [], onClick = () => {}, currency = null, showArrows = true }) {
    // debugger
    function getName(name) {
        // return name?.substring(0,name.indexOf("-", name.indexOf("-")+ 1)) + "..."
        return name?.substring(0, 32) + (name.length > 32 ? "..." : "");
    }

    return (
        <div style={{ flex: 1 }}>
            <Paper style={{ maxWidth: maxWidth, maxHeight: maxHeight, flexDirection: "column", borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
                <div
                    style={{
                        color: "white",
                        flex: 2,
                        backgroundColor: "#3f51b5",
                        // flexDirection: "column",
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                    }}
                >
                    <img src={headerIcon} height={cloud == "gcp" ? 35 : 40} />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <h4 style={{ padding: 0, margin: 0 }}>
                            {currency || <Currency />}
                            <Cost>{cost}</Cost>
                        </h4>
                        <h6 style={{ padding: 0, margin: 0 }}>{date}</h6>
                    </div>
                    <div />
                </div>
                <div style={{ overflowY: "scroll", maxHeight: 400 }}>
                    <div style={{ height: 333 }}>
                        {dataKeys
                            .filter((d) => d != null)
                            .map(({ key, title, subKey, subKey2 = "", icon = "", iconSet = null, onMouseOver, onMouseOverSet }) => (
                                <div style={{ flex: 4, padding: 10 }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <b>{title}</b>
                                        {/*{showArrows?<a>*/}
                                        {/*    <ChevronRight onClick={onClick} style={{cursor: "pointer"}}/>*/}
                                        {/*</a>:null}*/}
                                    </div>
                                    {/*<br/>*/}
                                    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        {data?.[key]
                                            ?.sort((a, b) => (parseFloat(b.cost) || 0) - (parseFloat(a.cost) || 0))
                                            ?.map((r) => (
                                                <>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                // margin: '5px',
                                                                // maxWidth: '200px',
                                                                // border : 'solid black 2px',
                                                                // display: 'inline-block',
                                                                // padding : '5px'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "flex-start",
                                                                    height: "100%",
                                                                }}
                                                            >
                                                                {r.cloudProvider ? <img src={CLOUD_ICONS[r.cloudProvider]} height={15} width={15} style={{ marginTop: 5, marginRight: 10 }} /> : null}
                                                                <img src={icon || iconSet[r.cloudProvider]} height={15} width={15} style={{ marginTop: 5, marginRight: 8 }} />
                                                            </div>
                                                            {onMouseOver || (onMouseOverSet && onMouseOverSet[r?.cloudProvider || cloud]) ? (
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    overlay={
                                                                        <Tooltip id="button-tooltip">
                                                                            {r?.[onMouseOver || onMouseOverSet[r?.cloudProvider || cloud]]}
                                                                            {/*<br/>*/}
                                                                            {/*{r?.[subKey]}*/}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span
                                                                        style={{
                                                                            wordBreak: "break-word",
                                                                            paddingRight: 10,
                                                                            color: r?.[subKey] || r?.[subKey2] ? "" : colors.gray,
                                                                        }}
                                                                        id={"overlay_item_span"}
                                                                    >
                                                                        {getName(r?.[subKey]?.replace(/\//g,"") || r?.[subKey2]?.replace(/\//g,"") || "unassigned")}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        wordBreak: "break-word",
                                                                        paddingRight: 10,
                                                                        color: r?.[subKey] || r?.[subKey2] ? "" : colors.gray,
                                                                    }}
                                                                    id={"overlay_item_span"}
                                                                >
                                                                    {getName(r?.[subKey]?.replace(/\//g,"") || r?.[subKey2]?.replace(/\//g,"") || "unassigned")}
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div style={{ paddingLeft: 10 }}>
                                                            {currency || <Currency />}
                                                            {isNumeric(String(r?.cost)) ? <Cost>{r?.cost}</Cost> : "_._"}
                                                            {/*<Cost>{}</Cost>*/}
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                    </div>
                                    <hr style={{ padding: 0, margin: 0, marginTop: 5 }} />
                                </div>
                            ))}
                    </div>
                </div>
            </Paper>
        </div>
    );
}
