import React, { useState, useEffect } from "react";
//import "./style.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Format from "components/NumberFormat";
import colors from "styles/color";
import azure_service from "../../images/newCloudTrakrIcons/AzureServices.png";
import TableSearch from "../../components/TableSearch";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../TablePaginationActions";
import IconMapper from '../../util/IconMapper.js'


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        fontWeight: "bold"
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.2),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 6,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        maxHeight: 450,
    },
});

export default function AzureServicesTable({result = null, currency }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const classes = useRowStyles();

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.service,
                f?.cost,
                
            ], search);
        }
        return true;
    }
    return (
        <Paper elevation={0}>
            <TableContainer className={classes.container}>
            <TableSearch onSearch={setSearch} />
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="inherit">
                                Service
                            </StyledTableCell>

                            <StyledTableCell align="right">
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.sort((a, b) => b.cost - a.cost)?.map((r, i) => (
                            <ExpandRowRG  row={r} index={i} currency={currency} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 75]}
                component="div"
                count={result?.filter(filteredTable)?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
        </Paper>
    );
}
const useStyles = makeStyles((theme) => ({
    img: {
        width: 18,
        height: 18,
        marginRight: 4,
    },
    }));


function ExpandRowRG(props) {
    const { row, index, currency } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell align="left" component="th" scope="row" size="small" padding="none">
                    <div style={{display:'flex'}}>
                        <IconMapper name={row?.service} height={18}/>
                        {row?.service}
                    </div>
                </TableCell>

                <TableCell align="right">{currency + Format.formatNumber(Number(row.cost).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelDataRG data={row.resources} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}
function ExpandLavelDataRG({ data, open, select, currency }) {
   

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={4}>{ <ExpandableTableAzure tableRow={data} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function ExpandableTableAzure({tableRow, currency }) {
    const classes = useRowStyles();

    return (
        <Paper elevation={0}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell />
                            <StyledTableCell align="left">
                                Resource name
                            </StyledTableCell>

                            <StyledTableCell align="left">
                             Resource type
                            </StyledTableCell>

                            <StyledTableCell align="left">
                                Location
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                Cost
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableRow?.sort((a, b) => b.cost - a.cost)?.map((r, i) => (
                            <ExpandRow row={r} index={i}  currency={currency} table={"accounts"} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export function ExpandRow(props) {
    const {row, index, data, currency, table } = props;
    const [open, setOpen] = useState(false);
    const [list, setList] = useState(null);
    const classes = useRowStyles();

    // useEffect(() => {
    //  console.log("index == ",index);
    // }, [index])

    return (
        <React.Fragment>
            <StyledTableRow hover className={classes.root} key={row.resourceId}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                            setList(index);
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell align="left">{row?.resourceId}</TableCell>

                <TableCell align="left">{row?.resourceType}</TableCell>

                <TableCell align="left">{row?.location}</TableCell>

                <TableCell align="center">{currency + Format.formatNumber(Number(row["cost"]).toFixed(2))}</TableCell>
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    const [resource, setResource] = useState(null);

    // useEffect(() => {
    //     if (select != null) {
    //         let value = data.services[select];
    //         setResource(value);
    //     }
    // }, [select, data]);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>{<Htmltable data={data} currency={currency} />}</Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                <tr>
                    <th>Meter</th>
                    <th>Cost</th>
                </tr>

                {data &&
                    data?.meters?.sort((a, b) => b.cost - a.cost).map((m) => (
                        <tr>
                            <td>{m.meter}</td>
                            <td>{currency + Format.formatNumber(Number(m["cost"]))}</td>
                        </tr>
                    ))}
            </table>
        </div>
    );
}
