import React from "react";
import styles from './styles.css';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Button} from "@material-ui/core";

export default function MaterialBlueButton({title = '', onClick = ()=>{}, tooltip = '', tooltipRequired = true, buttonStyle = {}}) {
    function beforeClick(e){
        e.preventDefault();
        onClick();
    }
  return (
      <OverlayTrigger
          show={tooltipRequired}
          placement="bottom"
          overlay={
              <Tooltip id="button-tooltip">
                  {tooltip}
              </Tooltip>
          }
      >
          <Button style={{
              background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
              border: 0,
              borderRadius: 3,
              boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
              color: "white",
              height: 40,
              padding: "0 40px",
              textTransform: "none",
              ...buttonStyle
          }} onClick={onClick}>
              {title}
          </Button>
      </OverlayTrigger>
  );
}
