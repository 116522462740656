import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ManagementTable from "components/table/AzureManagementTable";
import { Dropdown } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HtmlECTable from "../../components/table/HtmlECTable";
import management_group from "../../images/management_group.svg";

import {
    getAzureTagsRequest
} from "../../redux/actions/actions";
import Format from "components/NumberFormat";
import { LinearProgress, Paper, Collapse, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow } from "@material-ui/icons";
import { data } from "jquery";
import DateRange from "components/DateRange";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import moment from "moment";
import AzureTagsTable from "../../components/table/AzureTagsTable";
import AzureTagsIcon from "../../images/newCloudTrakrIcons/AzureTags.png";
import colors from "../../styles/color";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        minHeight: "95vh",
        //marginBottom: 5,
    },
}));

function AzureTags({ currency, tab, customerId, subscriptionId, tagsList = [], tenantId }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [totalCost, setTotalCost] = useState(null);
    const [period, setPeriod] = useState("1M");
    const [selectedTag, setSelectedTag] = useState(null);
    const [tagsData, setTagsData] = useState(null);
    const [progress, setProgress] = useState(0);
    const [datePeriod, setDatePeriod] = useState("1M");
    const loading = useSelector((state) => state.azureTagsReducer.loading);
    useEffect(() => {
        if(customerId)
            dispatch(
                getAzureTagsRequest({
                    customerId,
                    tenantId,
                    subscriptionId
                })
            );
    }, [customerId, tenantId, subscriptionId]);

    useEffect(()=>{
        if(tagsList?.[period]?.tags){
            const found = tagsList?.[datePeriod]?.tags?.find(t=> {
                if(selectedTag=='untagged-tag') {
                    return t.tagLabel == "";
                }
                else {
                    return t.tagLabel == selectedTag;
                }
            });
            setTagsData(found || null);
        }
    }, [selectedTag, datePeriod]);

    useEffect(()=>{
        if(tagsList){
            setSelectedTag(tagsList?.[datePeriod]?.tags?.[0]?.tagLabel == ''?'untagged-tag':tagsList?.[datePeriod]?.tags?.[0]?.tagLabel);
        }
    }, [tagsList]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <div className={classes.root}>
            <ContainerCardWithDateRange title={"Spend by Tags"} titleIcon={AzureTagsIcon}
                                        showDateRange={true}
                                        collapsible={true}
                                        // datePeriod={({period})=>({
                                        // start:"__",
                                        //end: "__",
                                        //     start: moment(AWSServerlessData?.SpendByServerlessServices?.[period]?.startDate, "YYYYMMDD"),
                                        //     end: moment(AWSServerlessData?.SpendByServerlessServices?.[period]?.endDate, "YYYYMMDD")
                                        // })}
                                        totalCost={({period})=>(
                                            (currency + (isNaN(Number(tagsData?.cost).toFixed(2)) ? "--" : Format.formatNumber(Number(tagsData?.cost).toFixed(2))) || "--")
                                        )}

                                        datePeriod={({ period }) => ({
                                            start:"__",
                                            end: "__",
                                            rawStart: moment(tagsList?.[period]?.startDate, "YYYYMMDD"),
                                            rawEnd: moment(tagsList?.[period]?.endDate, "YYYYMMDD"),
                                        })}
                                        showDatePeriod={(period) => !!(tagsData?.tagValues?.length)}
                                        datePeriodEnabled={true}
                                        onlyTotal={true}
                                        HeaderTitleComponent={(period, setCollapsible = ()=>{})=>(
                                            <>
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: 10}}>
                                                <span>Spend by Tags</span>
                                                {(tagsList?.[period]?.tags && tagsList?.[period]?.tags?.length)?<div style={{marginLeft: 20}}>
                                                    <Dropdown onMouseOver={()=>setCollapsible(false)} onMouseOut={()=>setCollapsible(true)} onSelect={(tag)=>setSelectedTag(tag || "untagged-tag")}>
                                                        <Dropdown.Toggle   variant="none" id="dropdown-basic">
                                                            {(selectedTag == 'untagged-tag'? <span style={{color: colors.gray}}>Untagged</span>:selectedTag) || tagsList?.[period]?.tags?.[0]?.tagLabel}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {
                                                                tagsList?.[period]?.tags.map(t => (
                                                                    <Dropdown.Item eventKey={t.tagLabel}>
                                                                        {t.tagLabel || <span style={{color: colors.gray}}>Untagged</span>}
                                                                    </Dropdown.Item>
                                                                ))
                                                            }
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>:null}
                                            </div>
                                            </>
                                        )}
                                        onPeriodChange={(period)=>{
                                            setDatePeriod(period)
                                        }}
                                        // showDatePeriod={(period)=>!!(AWSServerlessData != null && AWSServerlessData?.SpendByServerlessServices?.[period]?.dataSet?.length)}

            >
                {({ period }) => (
                    (tagsList?.[period]?.tags) && (tagsData?.tagValues?.length) ? (
                        <AzureTagsTable data={tagsData?.tagValues}
                                        currency={currency}
                                        period={period} loading={loading} progress={progress}
                                        setTotalParent={setTotalCost} selectedTag={selectedTag}
                                        subscriptionLevel={!!(subscriptionId && tenantId)}
                        />
                    ) : (
                        <span style={{ display: "flex", justifyContent: "center" }}>No data found</span>
                    )
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

export default AzureTags;
