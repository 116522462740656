import React, { useState, useEffect } from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
// import {similarObject} from "../listS3bucket/ConversionFunc";
import RecommendationsTable from "./RecommendationTable";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import Ec2Icon from "../../images/EC2.svg";
import Button from "@material-ui/core/Button";
import recommendation_icon from "../../images/recommended.png";
import AwsLogo from "../../images/aws_dark.svg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import Color from "../../styles/color";
import Box from "@material-ui/core/Box";
import {bytesToSize}  from "../../util/Miscellaneous";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Badge from "@material-ui/core/Badge";
import {CancelRounded, Help} from "@material-ui/icons";
const AWS_TERMINATE_INSTANCE_HELP = "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html#new-console";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function AWSEC2Recommendation({ title, data, view, subscriptionId, lastUpdated, currency, cards, loading, collapseRef }) {
    const classes = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [progress, setProgress] = useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.accountId,
                f?.region
                // f?.accountId,
                // f?.architecture,
                // f?.cpu,
                // f?.OS,
                // f?.memory,
                // f?.region,
                // f?.cost,
            ], search);
        }
        return true;
    }
    
    return (
        <div>
            <ContainerCardWithDateRange
                someref={collapseRef}
                title={title}
                titleIcon={AwsLogo}
                showDateRange={false}
                collapsible={true}
                HeaderTitleComponent={()=>(
                    <div style={{ whiteSpace: "nowrap" }}>
                        <img src={Ec2Icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                        <span>
                            {title}
                        </span>
                    </div>
                )}
                ExtraHeaderComponent={({ period }) =>
                    data != null && Object.keys(data)?.length ? (
                        <div style={{ marginTop: 4 }}>
                            <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                        </div>
                    ) : null
                }
                CenterComponent={()=>(
                    <div>
                     {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                  </div>
                    )}
            >
                {({ period }) => (
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                  {!loading ?(
                      <div style={{flex:1}}>
                        {cards && <RecommendationsSummaryCards summary={cards} />}

                        <div className={classes.root}>
                            <Collapse in={totalExpand}>
                                {data != null && data?.data?.length ? (
                                    <Paper className={classes.paper}>
                                      <TableSearch onSearch={setSearch} />

                                        <TableContainer style={{maxHeight:450}}>
                                            <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                <AwsEC2RecommendationHead view={view} subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={data?.data?.length} />
                                                <TableBody>
                                                    {data?.data
                                                    ?.filter(filteredTable)
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                        return <MyTableRow view={view} subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10, 25, 50, 75]}
                                            component="div"
                                            count={data?.data?.filter(filteredTable)?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                ) : (
                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                            </Collapse>
                        </div>
                        </div>
                     ):<CircularProgress thickness={2} size={60} />
                      }
                      </div>
            )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
        backgroundColor: Color.tableHeader,

    },
}));

function AwsEC2RecommendationHead({ view }) {

    const classes = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>Resource Id</TableCell>
                <TableCell className={classes.tableRow}>Instance type</TableCell>
                {/* {view == "all" ? <TableCell className={classes.tableRow}>AWS Account</TableCell> : null} */}
                <TableCell className={classes.tableRow}>AWS Account</TableCell>
                <TableCell className={classes.tableRow}>Architecture</TableCell>
                <TableCell className={classes.tableRow}>CPU</TableCell>
                <TableCell className={classes.tableRow}>OS</TableCell>
                <TableCell className={classes.tableRow}>Memory</TableCell>
                <TableCell className={classes.tableRow}>Region</TableCell>
                <TableCell className={classes.tableRow}>Status</TableCell>
                <TableCell className={classes.tableRow}>Current cost /month</TableCell>
            </TableRow>
        </TableHead>
    );
}

function generateURL(data){
    if(data){
    const BASE_PATH = ".console.aws.amazon.com/ec2/home?region=";
    const AWS_REGION = data?.region;
    const AWS_DATABASE = "#Instances:"
    const AWS_Database=data?.resource
    const CLUSTER = ";is-cluster=false"

    const FINAL_URL = "https://" + AWS_REGION + BASE_PATH + AWS_REGION + AWS_DATABASE;
    window.open(FINAL_URL, "_blank");
    }
}

function MyTableRow({ row, view, index, list, setList, currency }) {
    const [open, setOpen] = useState(true);

    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    <>
                        <IconButton
                            style={{ cursor: "pointer" }}
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                                setOpen(!open);
                                setList(index);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>{" "}
                        {row?.resourceId}
                    </>
                </TableCell>
                <TableCell>{row?.instanceType}</TableCell>
                {/* {view == "all" ? <TableCell>{row?.tenantId}</TableCell> : null} */}
                <TableCell>{row?.accountId}</TableCell> 
                <TableCell>{row?.architecture}</TableCell>
                <TableCell>{row?.cpu}</TableCell>
                <TableCell>{row?.OS}</TableCell>
                <TableCell>{row?.memory}</TableCell>
                <TableCell>{row?.region}</TableCell>
                <TableCell>{row?.status}</TableCell>
                <TableCell>
                    {Format.formatCurrencySymbol(currency)}
                    {Format.formatNumber(row?.cost)}
                </TableCell>
            </TableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />
        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <>
            <div class="overflow-auto">
              
                {"recommendations" in data && data?.recommendations?.length ? (
                    <>
                        <b>Recommendations - {data?.recommendations?.[0]?.shortRecommendation}</b>
                        <table id="customers">
                            <tr>
                                <th>From instance</th>
                                <th>To instance</th>
                                <th style={{ whiteSpace: "nowrap" }}>To configuration</th>
                                <th>Current price</th>
                                <th style={{ whiteSpace: "nowrap" }}><span>On-Demand<br/> price</span></th>
                                <th>Potential savings</th>
                                <th>
                                    {" "}
                                    Action
                                    <div>
                                      <OverlayTrigger placement="left"
                                        overlay={<Tooltip id="button-tooltip">How to terminate Prev.Gen. EC2 instance?</Tooltip>}>
                                        <Badge badgeContent={0} color="secondary">
                                        <Help 
                                        onClick={()=>window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")} 
                                        style={{cursor: "pointer", fontSize:20, color: "#303" }} />
                                        </Badge>
                                        </OverlayTrigger>
                                    </div>
                                </th>
                            </tr>

                            {data &&
                                data?.recommendations
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.yearlyPotentialSavings || 0) - parseFloat(a?.yearlyPotentialSavings || 0);
                                    })
                                    .map((m) => (
                                        <tr style={{ borderColor: m?.isBestRecommendation == true ? Color?.lightGreen : null, border: m?.isBestRecommendation == true ? "3px solid green" : null }}>
                                            <td>
                                                
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {" "}
                                                        {data?.instanceType} <br />
                                                        {m?.fromInstanceType}
                                                    </span>
                                               
                                            </td>
                                            <td style={{ alignItems: "center",whiteSpace: "nowrap" }}>
                                                {" "}
                                                {/* {m?.isBestRecommendation == true ? <img style={{ height: 16, marginRight: 4 }} src={recommendation_icon} /> : null} */}
                                               
                                            <span style={{ whiteSpace: "nowrap" }}>
                                                        {m?.toInstance}
                                                    </span>
                                               
                                            </td>
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.vcpu} CPU, {m?.memory} mem
                                            </td>
                                          
                                            <td>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.currentPrice)}{' /'}{'unit' in data ? data?.unit : 'mon'}
                                                {/* {data?.reservations?.[0]?.duration == "3yr" ? " /3yrs" : " /yr"} */}
                                                </span>
                                            </td>
                                           
                                            <td style={{ whiteSpace: "nowrap" }}>{Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.onDemand)}{' /'}{'unit' in data ? data?.unit : 'mon'}</td>
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.monthlySavings)}{' /'}{'unit' in data ? data?.unit : 'mon'}
                                            </td>
                                            <td align="left">
                                                <Button 
                                                onClick={()=>{generateURL(data)}}
                                                 variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                    Fix in AWS
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                        </table>
                    </>
                ) : null}
            </div>
        </>
    );
}
