import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell as TCell, TableContainer,TablePagination, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { getAWSDynamoDBRequest, getAWSDynamoDBAccRequest } from "../../redux/actions/actions";
import Cost from "../../components/Cost";
import DynamoDBIcon from "../../images/newCloudTrakrIcons/AWSDynamoDB.png";
import { CancelRounded } from "@material-ui/icons";
import { bytesToSize, convertToInternationalCurrencySystem } from "../../util/Miscellaneous";
import colors from "../../styles/color";
import axios from "../../connection/axios";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import LineChart from "../Charts/LineChart";
import TablePaginationActions from "../../components/TablePaginationActions";
import DetailsDialogBox from "../../components/DetailsDialogBox";
import TableSearch from "../../components/TableSearch";
import {OverlayTrigger, Tooltip, Popover} from "react-bootstrap";

import Collapse from "@material-ui/core/Collapse";

import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
const { REACT_APP_AWS_DYNAMODB_DETAILS, REACT_APP_AWS_DYNAMODB_METRICS } = process.env;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            // backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 450,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 0,paddingRight:8  }}>
            {props.children}
        </TCell>
    );
}


export default function AWSDynamoDB({ currency, tenantId, subscriptionId, customerId }) {
    const dispatch = useDispatch();
    // const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [totalCost, setTotalCost] = useState("");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [showingDetails, setShowingDetails] = React.useState(null);
    const [metricsData, setMetricsData] = React.useState(null);
    const [metricTotals, setMetricTotals] = React.useState(null);
    const [detailsLoading, setDetailsLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const classes = useRowStyles();

    let dynamoDB = useSelector((state) => (subscriptionId != "all" && tenantId ? state?.awsDynamoDBReducer?.dynamoDBAccountData : state?.awsDynamoDBReducer?.dynamoDBData));
    let loading = useSelector((state) => (subscriptionId != "all" && tenantId ? state?.awsDynamoDBReducer?.dyamoDBAccountLoading : state?.awsDynamoDBReducer?.loading));

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if (customerId) {
            if (dynamoDB == null && !tenantId && subscriptionId == "all") {
                dispatch(
                    getAWSDynamoDBRequest({
                        customerId,
                    })
                );
            } else if (subscriptionId && tenantId) {
                dispatch(
                    getAWSDynamoDBAccRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                    })
                );
            }
        }
    }, [customerId, subscriptionId, tenantId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    async function getDynamoDBDetails(e) {
        setShowingDetails(e)
        setOpen(true)
        setDetailsLoading(true);
        return axios
            .post(REACT_APP_AWS_DYNAMODB_DETAILS, {
                customerId,
                tableName: e.serviceName,
                tenantId: e.payerAccountId,
                subscriptionId:e.usageAccountId,
                region: e.location,
            })
            .then((response) => response.data)
            .then((response) => {
                if (response?.data) {
                    setShowingDetails({ ...e, ...response?.data });        
                }
                getDynamoDBMetrics(e);
            })
            .catch((e) => {});
            // .finally(() => setDetailsLoading(false));
    }

    async function getDynamoDBMetrics(e) {
        setDetailsLoading(true);
        return axios
            .post(REACT_APP_AWS_DYNAMODB_METRICS, {
                customerId,
                tableName: e.serviceName,
                tenantId: e.payerAccountId,
                subscriptionId:e.payerAccountId,
                region: e.location,
            })
            .then((response) => response.data)
            .then((response) => {
                if (response?.data) {
                    const final = [];
                    for (var o in response?.data) {
                        const data = response?.data?.[o]?.[0];
                        const timeseries = data.Timestamps.map((t, i) => ({
                            date: new Date(t),
                            value: data?.Values[i],
                        }));
                        const name = data.Label.match(/[A-Z][a-z]+/g).join(" ");
                        const unit = data.Id.match(/[A-Z][a-z]+/g).join(" ");
                        final.push({ name, timeseries, unit: unit === "Sum" ? "Count" : unit });
                    }
                    setMetricsData(final);
                    setMetricTotals(
                        final?.map((r, i) => (
                            r.timeseries.length > 0
                                ? r.timeseries.map((r) => (
                                      r.value.length > 0 ? r.value.reduce((a, b) => a + b).toFixed(2) : null
                                ))
                                : null
                        ))
                    );
                }
            })
            .finally(() => setDetailsLoading(false));
    }

    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.meters?.[0]?.productFamily,
                f?.location,
                f?.usageAccountId,
                f?.usageAccountName
            ], search);
        }
        return true;
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"DynamoDB"}
                titleIcon={DynamoDBIcon}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start:"__",
                    end: "__",
                    rawStart: moment(dynamoDB?.[period]?.startDate, "YYYYMMDD"),
                    rawEnd: moment(dynamoDB?.[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) => currency + Format.formatNumber(dynamoDB?.[period]?.totalCost)}
                datePeriodEnabled={true}
                showDatePeriod={(period) => !!dynamoDB != null && dynamoDB?.[period]?.resources?.length}
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <div elevation={10} style={{ padding: 10 }}>
                        {dynamoDB != null && dynamoDB?.[period]?.resources?.length ? (
                            <div>
                                <Paper elevation={3}>
                                    <Box display={"flex"} flex={1}>
                                        <Box flex={1}>
                                            <TableSearch onSearch={setSearch} />
                                            <TableContainer style={{ height: 350 }} className={classes.container}>
                                                <Table className={classes.table} stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell/>
                                                            <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                Table Name
                                                            </StyledTableCell>

                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                    Location 
                                                                    </StyledTableCell>

                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                    AWS Account
                                                                    </StyledTableCell>

                                                                    <StyledTableCell align="left" style={{ fontSize: 17, fontWeight: "bold" }}>
                                                                        Cost
                                                                    </StyledTableCell>
                                                               
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {(dynamoDB?.[period]?.resources || [])
                                                            ?.filter(filteredTable)
                                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                            <MyTableRow data={dynamoDB} row={r} index={i} list={list} setList={setList} currency={currency} getDynamoDBDetails={()=>getDynamoDBDetails(r)}  />
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                        {/* <Box display={showingDetails ? "flex" : "none"} flex={showingDetails ? 0.7 : 0}>
                                            <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                                        <Typography component={"h4"} variant={"h4"}>
                                                            <img src={DynamoDBIcon} height={30} /> {showingDetails?.TableName}
                                                        </Typography>
                                                        <Typography component={"h5"} variant={"h5"}>
                                                            {currency}
                                                            {showingDetails?.cost ? <Cost>{showingDetails?.cost}</Cost> : "__.__"}
                                                        </Typography>
                                                    </Box>
                                                    <CancelRounded onClick={() => setShowingDetails(null)} />
                                                </Box>
                                               
                                                {metricDetailsLoading ? (
                                                    <LinearProgress variant="determinate" value={progress} />
                                                ) : (
                                                    <Box padding={2}>
                                                        {showingMetricDetails && showingMetricDetails?.length
                                                            ? showingMetricDetails.map((m, i) => (
                                                                  <>
                                                                      <Box>
                                                                          <Typography>{m.label}</Typography>
                                                                          <LineChart
                                                                              onMouseOverData={(data) => {
                                                                                  let d = showMetricSpecific;
                                                                                  d[i] = data;
                                                                                  setShowMetricSpecific(d);
                                                                              }}
                                                                              data={m?.timeseries}
                                                                              currency={currency}
                                                                              currencyRequired={false}
                                                                              unit={m?.unit}
                                                                          />
                                                                      </Box>
                                                                      <Box style={{ marginTop: 10 }}>
                                                                          <Typography>
                                                                              {m.label}:{" "}
                                                                              {
                                                                                  {
                                                                                      Count: convertToInternationalCurrencySystem(showMetricSpecific?.[i] || metricTotals?.[i]),
                                                                                      Bytes: bytesToSize(showMetricSpecific?.[i] || metricTotals?.[i]),
                                                                                      Percent: (showMetricSpecific?.[i] || metricTotals?.[i]) + "%",
                                                                                  }[m?.unit]
                                                                              }
                                                                          </Typography>
                                                                      </Box>
                                                                      <hr />
                                                                  </>
                                                              ))
                                                            : null}
                                                    </Box>
                                                )}
                                            </Paper>
                                        </Box> */}
                                    </Box>
                                    <TablePagination
                                        ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={dynamoDB?.[period]?.resources?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </div>
                        ) : (
                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                        )}
                        <DetailsDialogBox
                            open={open}
                            data={showingDetails}
                            title={'DynamoDB'}
                            detailsData={showingDetails}
                            chartData={metricsData}
                            handleClose={() => {
                                handleClose();
                            }}
                            currency={currency}
                            loading={detailsLoading}
                            metricTotals={metricTotals}
                            rawStart={moment(dynamoDB?.[period]?.startDate, "YYYYMMDD")}
                            rawEnd={moment(dynamoDB?.[period]?.endDate, "YYYYMMDD")}
                        />
                    </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

export function MyTableRow(props) {
    const { data, row,index, list, setList, currency,  getDynamoDBDetails } = props;
    const [open, setOpen] = useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <StyledTableRow
                hover
                className={classes.root}
                key={row.usageAccountId}
                style={{ cursor: "pointer" }}
                // onClick={() => getDynamoDBDetails()}
            >  
            <TableCell>
                  <IconButton
                      style={{ cursor: "pointer" }}
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                          setOpen(!open);
                          setList(index);
                      }}
                  >
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
              </TableCell>
                <TableCell align="left">
                    {" "}
                    <img src={DynamoDBIcon} height={20} style={{ marginRight: 4 }} />
                    <span>{row?.resourceId || <span style={{ color: colors.gray }}> {row?.meters?.[0]?.productFamily} </span>}</span>
                </TableCell>
                <TableCell align="left">{row?.location}</TableCell>
                <TableCell align="left">
                    {"usageAccountName" in row ?
                        <OverlayTrigger placement="right" overlay={<Popover id="popover-contained">{"usageAccountName" in row &&
                         <Popover.Content style={{ fontSize: 11 }}>{row?.usageAccountName}</Popover.Content>}</Popover>}>
                           <span style={{whiteSpace: 'nowrap'}}>
                            {row?.usageAccountId || row.usageAccountName}
                        </span>
                        </OverlayTrigger>
                        :row?.usageAccountId || data?.usageAccountId}
                </TableCell>
                <TableCell align="left"> {currency + Format.formatNumber(Number(row?.cost).toFixed(2))}</TableCell>{" "}
            </StyledTableRow>
            <ExpandLavelData data={row} open={open} select={list} currency={currency} />

        </React.Fragment>
    );
}

export function ExpandLavelData({ data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                       <Box margin={2.5}>
                            <Htmltable data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export function Htmltable({ data, currency }) {
    return (
        <div style={{ maxHeight: 300 }} class="overflow-auto">
            <table id="customers">
                 {data?.meters && Object.keys(data?.meters?.[0])?.length ? (
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Total</th>
                    </tr>
                ) : null}

                        {data &&
                    data?.meters
                        ?.sort((a, b) => {
                            return parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);
                        })
                        .map((m) =>
                            Object.keys(m)?.length ? (
                                <tr>
                                    <td>{m?.operation||'-'}</td>
                                    <td>{m?.productFamily||'-'}</td>
                                    <td>{m.LineItemUsageType||'-'}</td>
                                    <td>{m.lineItemDescription||'-'}</td>
                                    <td>{currency + Format.formatNumber(Number(m.cost||0))}</td>
                                </tr>
                            ) : null
                        )}
            </table>
        </div>
    );
}
