import React from "react";
import { NoRecommend } from "./style";
import CheckIcon from "../../images/check.svg";

function NoRecommendation({ rec }) {
  return (
    <div>
      <NoRecommend>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={CheckIcon} width={35} height={35} />
          </div>

          <spam
            style={{
              fontSize: 12,
              display: "flex",
              textAlign: "center",
            }}
          >
            You are following all of our {rec} recommendations
          </spam>
        </div>
      </NoRecommend>
    </div>
  );
}
export default NoRecommendation;
