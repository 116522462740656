const RoutesList = {
  ROOT: "/",
  REGISTER: "/register",
  DASHBOARD: "/dashboard",
  GCP_HOME: "/dashboard/gcp",
  GCP_CONNECT: "/dashboard/gcp/connect",
  GCP_LIST: "/dashboard/gcp/accountlist",
  AWS_HOME: "/dashboard/aws",
  AWS_ACCOUNT: "/dashboard/aws/accounts",
  // AWS_CONNECT:   "/dashboard/aws/connect",
  AWS_CONNECT: "/dashboard/aws/accounts#connect",

  AWS_LIST: "/dashboard/aws/list",
  // AZURE_HOME:    "/dashboard/azure",
  AZURE_CONNECT: "/dashboard/azure/add",
  AZURE_LIST: "/dashboard/azure/list",
  SETTINGS: "/dashboard/settings",
  CHARTS: "/dashboard/charts",

  LINK_ACCOUNTS: "/dashboard/linkAccounts",
  AZURE_HOME: "/dashboard/azureHome",
  AZURESERVERLESS: "/dashboard/azureHome/Azureserverless",
  AWSSERVERLESS: "/dashboard/awsHome/AWSserverless",
  GCPSERVERLESS: "/dashboard/gcp/GCPserverless",
  TRIAL_EXPIRED: "/dashboard/disabled",
  // RECOMMENDATIONS: "/dashboard/recommendations"
};

export default RoutesList;
