import React, { useState, useEffect } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Azure_cosmosDB_icon from "../../images/newCloudTrakrIcons/AzureCosmosDB.png";
import Format from "components/NumberFormat";
import moment from "moment";
import { getAzureCosmosDBRequest, getAzureCosmosDBSubRequest,getAzureCosmosDBMetricsRequest, getAzureCosmosDBDetailsRequest, getAzureCosmosDBChartDataRequest, getAzureCosmosDBSub1YTableDataRequest } from "../../redux/actions/actions";
import TablePagination from "@material-ui/core/TablePagination";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import DetailsDialogBox from '../../components/DetailsDialogBox';
import { Table, TableBody, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress,CircularProgress } from "@material-ui/core";
import TablePaginationActions from "../../components/TablePaginationActions";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import aws_account from "../../images/aws_org.svg";
import TableSearch from "../../components/TableSearch";
import TableMonthlySpend from "../AllClouds/TableMonthlySpend";
import {getMonthsFromNow} from "../../util/DateFormatter";
import {isNumeric} from "../../util/Miscellaneous";
import NO_DATA_AVAILABLE from "../../components/NO_DATA_AVAILABLE";

export default function AzureCosmosDB1YTable({width, customerId,tenantId=null,subscriptionId=null,period, currency }) {
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [spendByMonthAccountData, setSpendByMonthAccountData] = useState(null);

    let yearTableData = useSelector((state) =>(subscriptionId && tenantId)?
        state?.azureCosmosDBSubReducer?.cosmosTableData:
        state?.azureCosmosDBReducer?.cosmosTableData
    );

    let loading = useSelector((state) => (subscriptionId && tenantId)?
        state?.azureCosmosDBSubReducer?.cosmosTableLoading:
        state?.azureCosmosDBReducer?.cosmosTableLoading
    );

    const [ytdSearch, setYTDSearch] = useState(null);

    useEffect(()=>{
        if(yearTableData){
            const accounts = {};
            const finalData = [];

            yearTableData?.data?.SpendByMonth?.forEach(monthWise=>{
                monthWise.resources.forEach((subWise)=>{
                    if(subWise.resourceId in accounts || subWise?.skuId in accounts){
                        accounts[subWise.cloudProvider != 'gcp'?subWise.resourceId:subWise?.skuId].list.push({month: monthWise.month, cost: subWise.cost});
                    } else {
                        accounts[subWise.cloudProvider != 'gcp'?subWise.resourceId:subWise?.skuId] = { cloud :subWise.cloudProvider, list: [], subscriptionDetails: {
                            id: subWise?.subscriptionId, name: subWise?.subscriptionName
                            }};
                        accounts[subWise.cloudProvider != 'gcp'?subWise.resourceId:subWise?.skuId].list.push({month: monthWise.month, cost: subWise.cost});
                    }

                })
            });

            Object.keys(accounts).forEach(key=>{
                const account = accounts[key].list;

                const newData = getMonthsFromNow().reverse().map((m, i) => {
                    const time = moment(m, "MMM YYYY");
                    const found = account.findIndex((d) => {
                        const int = moment(d?.month, "YYYYMM");
                        return time.isSame(int);
                    });
                    if (found > -1) {
                        return account?.[found];
                    } else {
                        return {month: parseInt(moment(m).format("YYYYMM")), cost: "_"};
                    }
                });

                finalData.push({subscriptionId: key, cloud: accounts[key].cloud, list: newData, subscriptionDetails: accounts?.[key]?.subscriptionDetails})
            });
            // const total = [];

            // [
            // [1, 2, 3],
            // [1, 2, 3],
            // [1, 2, 3],
            //     ]
            //
            // [3, 6, 9]

            let otherRow = [];
            if(finalData && finalData.length){
                const totalData = finalData.map(f=>f.list.map(l=>isNumeric(l.cost)?parseFloat(l.cost):'-'));
                const finalTotal = [null,null,null,null,null,null,null,null,null,null,null,null,null];
                for(var col = 0; col < (totalData?.[0]?.length) || 0; col++){
                    totalData.forEach(row=>{
                        if(isNumeric(String(row[col])))
                            finalTotal[col] = (finalTotal[col] || 0) + row[col];
                    })
                }

                otherRow.push({
                    subscriptionId: 'All CosmosDB Accounts',
                    cloud: 'all',
                    list: finalData?.[0]?.list?.map((f, i)=>({month: f.month, cost: ""+finalTotal[i]}))
                });
            }

            setSpendByMonthAccountData({list: finalData, other: otherRow});
        }
    }, [yearTableData])


    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            {/*<div style={{ overflow: "hidden", }}>*/}
                <ContainerCardWithDateRange title={"Spend by months"}
                                            titleIcon={Azure_cosmosDB_icon}
                                            showDateRange={false}
                                            collapsible={true}>
                    {
                        ()=>width?(
                            loading?
                                <></>:
                                (spendByMonthAccountData?.list)?
                                <>
                                <TableSearch onSearch={setYTDSearch} />
                                <TableMonthlySpend
                                    showCloud={false}
                                    subscriptionNameAsOverlay={false}
                                    tableLabel={"CosmosDB"}
                                    width={width}
                                    data={(spendByMonthAccountData?.list?.filter(s=>{
                                        if (ytdSearch && ytdSearch.toString().length) {
                                            return !!TableSearch.doesItIncludes(
                                                [
                                                    s.cloud,
                                                    s.subscriptionId
                                                ],
                                                ytdSearch
                                            );
                                        }
                                        return true;
                                    })) || []}
                                    subscriptionLevel={!!subscriptionId}
                                    staticRows={spendByMonthAccountData?.other}
                                    currency={currency}
                                />
                            </>:
                                    <NO_DATA_AVAILABLE height={'initial'}  />
                        ):null
                    }
                </ContainerCardWithDateRange>
            {/*</div>*/}
        </div>
    );
}
