import React, { useState, useRef, useEffect } from "react";
import GCP_ICON from "../../../images/GCP_logo.svg";
import axios from "../../../connection/axios";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../../components/BreadCrumbs";
import { Button, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import QuestionMark from "../../../images/questionmark.png";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import RoutesList from "../../../routes/RoutesList";
import { Alert, AlertTitle } from "@material-ui/lab";

const { REACT_APP_GCP_CONNECTION } = process.env;

export default function ConnectGCPAccount() {
    const classes1 = useStyles1();
    const [connectionLoading, setConnectionLoading] = useState(false);
    const [displayName, setDisplayName] = useState("");
    const [displayError, setDisplayError] = useState({
        status: false,
        msg: "",
    });

    const [tableId, setTableId] = useState("");
    const [tableIdError, setTableIdError] = useState({
        status: false,
        msg: "",
    });
    const [alertTitle, setAlertTitle] = useState("");
    const [alertMsg, setAlertMsg] = useState("");
    const [alertOpen, setalertOpen] = useState(false);

    let history = useHistory();
    const { customerId } = useSelector((state) => ({
        customerId: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    function onConnectionSuccess() {
        // props.onConnectionSuccess();
        setDisplayName("");
        setTableId("");
        history.push(RoutesList.LINK_ACCOUNTS + "#gcp");
    }

    async function onConnect(e) {
        e.preventDefault();
        var noErrors = true;
        if (!displayName?.trim()) {
            setDisplayError({
                status: true,
                msg: "Please enter display name",
            });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.\-_\s]{3,50}$/.test(displayName.trim())) {
            setDisplayError({
                status: true,
                msg: "Only contains alphanumerics and characters",
            });
            noErrors = false;
        }
        if (!tableId.trim()) {
            setTableIdError({ status: true, msg: "Please enter Table Id" });
            noErrors = false;
        } else if (!/^[a-zA-Z0-9.\-_:\s]{1,255}$/.test(tableId.trim())) {
            setTableIdError({
                status: true,
                msg: "Table Id must be in the format (ProjectId:BigqueryDatasetName.Tablename)",
            });
            noErrors = false;
        }

        if (noErrors) {
            setConnectionLoading(true);
            await axios
                .post(REACT_APP_GCP_CONNECTION, {
                    customerId,
                    tableId: tableId.trim(),
                    displayName: displayName.trim(),
                })
                .then((response) => response.data)
                .then((responseJson) => {
                    setalertOpen(true);
                    if ("statusCode" in responseJson) {
                        if (responseJson.statusCode == 200) {
                            setAlertTitle("success");
                            setAlertMsg("Added sucessfully");
                            setTimeout(function () {
                                onConnectionSuccess();
                            }, 2000);
                        } else if (responseJson?.statusCode == 1003 || responseJson?.statusCode == 4001) {
                            //--- Alredy exists || invalid table id----
                            setAlertTitle("error");
                            setAlertMsg(responseJson?.errorMessage);
                        } else {
                            setAlertTitle("error");
                            setAlertMsg(responseJson?.errorMessage);
                            setTimeout(function () {
                                onConnectionSuccess();
                            }, 2000);
                        }
                    }
                })
                .catch((e) => {})
                .finally(() => setConnectionLoading(false));
        }
        e.preventDefault();
    }

    return (
        <div>
            <Card.Header>
                <div>
                    <BreadCrumbs parent={"Home"} child={"GCP"} subChild={"Account"} parentLink={"/dashboard/charts"} subchildLink={"/dashboard/gcp"} hasChild={true} hasSubChild={true} />
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <div
                        style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img
                            src={GCP_ICON}
                            alt="GCP logo"
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        />
                        <span style={{ marginLeft: 10, fontFamily: "Poppins_SemiBold", fontSize: 20 }}>Connect GCP Account</span>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to open GCP list</Tooltip>}>
                        <Link to={RoutesList.LINK_ACCOUNTS + "#gcp"}>
                            <Button className={classes1.btnStyle}>List GCP Account</Button>
                        </Link>
                    </OverlayTrigger>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span style={{ color: "gray" }}>Pre-requisites to connect GCP account</span>
                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                        <a href="https://docs.cloudtrakr.com/SetupGcpConnection/" target="_blank">
                            <img src={QuestionMark} alt="QuestionMark" style={{ width: 20, height: 20, marginLeft: 12 }} />
                        </a>
                    </OverlayTrigger>
                </div>
            </Card.Header>

            <div
                style={{
                    padding: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                <div style={{ width: "50%" }}>
                    <div style={{ display: "flex" }}>
                        <Form style={{ flex: 1, marginBottom: 10 }} onSubmit={(e) => e.preventDefault()}>
                            <TextField
                                value={displayName}
                                label={"Display name *"}
                                // onBlur={() => onBlurHandle("displayName")}
                                hasError={displayError.status}
                                errorMessage={displayError.msg}
                                onInput={(v) => {
                                    setDisplayName(v);
                                    setDisplayError({ status: false, msg: "" });
                                }}
                                placeholder={"Enter display name"}
                            />
                            <TextField
                                value={tableId}
                                label={"Table Id *"}
                                // onBlur={() => onBlurHandle("tableId")}
                                hasError={tableIdError.status}
                                errorMessage={tableIdError.msg}
                                onInput={(v) => {
                                    setTableId(v);
                                    setTableIdError({ status: false, msg: "" });
                                }}
                                placeholder={"ProjectId:BigqueryDatasetName.Tablename"}
                            />
                            <br />

                            <Button onClick={onConnect} variant="primary" type="submit">
                                Connect
                            </Button>
                        </Form>
                    </div>
                </div>
                <div
                    style={{
                        width: "50%",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {connectionLoading ? <CircularProgress /> : null}
                    {alertOpen && <AlertBox title={alertTitle} msg={alertMsg} onclose={() => setalertOpen(false)} />}
                </div>
            </div>
        </div>
    );
}
function TextField({ onBlur, value, hasError, label, placeholder, onInput, errorMessage, disabled = false }) {
    return (
        <Form.Group controlId="formBasicEmail">
            <Form.Label className={disabled ? "disabled-field" : ""}>{label}</Form.Label>
            <Form.Control onBlur={onBlur} value={value} disabled={disabled} placeholder={placeholder} onChange={(event) => onInput(event.target.value)} />
            {hasError ? <Form.Text style={{ color: "red" }}>{errorMessage}</Form.Text> : null}
        </Form.Group>
    );
}

function AlertBox({ title = null, msg = null, onclose }) {
    return title == "success" ? (
        <Alert severity={title} style={{ width: "80%" }}>
            <AlertTitle>{"Success"}</AlertTitle>
            {msg}
        </Alert>
    ) : (
        <Alert onClose={onclose} severity={title} style={{ width: "60%" }}>
            <AlertTitle>{title == "error" ? "Error" : null}</AlertTitle>
            {msg}
        </Alert>
    );
}

const useStyles1 = makeStyles({
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
});
