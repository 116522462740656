import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import Budgets from "../pages/Budgets/Budgets";
import GetStarted from "../pages/GetStarted";
import LinearProgress from '../components/LineProgress';
import Dashboard from "../pages/Dashboard";
import AddAzureAccount from "../pages/Accounts/AddAzureAccount";
// import AddGcpAccount from "../pages/Accounts/AddGcpAccount";
import ConnectGCPAccount from "../pages/Accounts/GCP/ConnectGCPAccount";
import LinkAccounts from "../pages/Accounts/NewLinkAccount";
import ConnectAWSAccount from "../pages/Accounts/aws/ConnectAWSAccount";
import ListAWSAccount from "../pages/Accounts/aws/ListAWSAccount";
import DashboardChart from "../pages/Charts/DashboardChart";
import Settings from "../pages/Settings/Settings";
import AzureHome from "../pages/DashboardAccountsHome/AzureHome";
import GcpHome from "pages/DashboardAccountsHome/GcpHome";
import ListGCPAccount from "pages/Accounts/GCP/ListGCPAccount";
import RoutesList from "./RoutesList";
import ConenctAccountSkipPage from "../pages/ConnectAccountSkipPage";
import isUserAgentPhone from "../util/MobileCheck";
import CostUnify from "../images/app_logo.png";
import Google_Store from "../images/google_store.png";
import Apple_Store from "../images/apple_store.svg";
import Reports from "../pages/reports/Reports";
import Invoices from "pages/invoice/Invoices";
// import RecommendationsMain from "../pages/RecommendationsMain";
export default function Routes() {

    // if (isUserAgentPhone()) {
    //     return (
    //         <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
    //             <div
    //                 style={{
    //                     backgroundColor: "#098bed4a",
    //                     display: "flex",
    //                     paddingTop: 50,
    //                     paddingBottom: 50,
    //                     flexDirection: "column",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                 }}
    //             >
    //                 {/*<div>*/}
    //                 <div
    //                     style={{
    //                         display: "flex",
    //                         justifyContent: "center",
    //                         alignItems: "flex-start",
    //                     }}
    //                 >
    //                     <img src={CloudTrakr} height={30} style={{ marginTop: 4, marginRight: 5 }} />
    //                     <h2>CloudTrakr</h2>
    //                 </div>
    //                 <div
    //                     style={{
    //                         display: "flex",
    //                         justifyContent: "center",
    //                         alignItems: "center",
    //                     }}
    //                 >
    //                     {/*<div style={{border: '1px solid'}}>*/}
    //                     <h6 style={{ padding: 0, margin: 0 }}>from</h6>
    //                     {/*</div>*/}
    //                     <img src={CloudTrakr} height={25} style={{ marginLeft: 2 }} />
    //                 </div>
    //                 {/*</div>*/}
    //             </div>
    //             <div style={{ padding: 20, textAlign: "center" }}>
    //                 <span style={{ textAlign: "center" }}>For better experience on mobile please use our app available on these platforms</span>
    //             </div>
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                 }}
    //             >
    //                 <span style={{ fontWeight: "bold" }}>For Android</span>
    //                 <a href="https://play.google.com/store/apps/details?id=com.cloudcostmanagementapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
    //                     <img src={Google_Store} height={100} />
    //                 </a>
    //             </div>
    //             <div
    //                 style={{
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     flexDirection: "column",
    //                 }}
    //             >
    //                 <span style={{ marginBottom: 10, fontWeight: "bold" }}>For iOS</span>
    //                 <a href="https://apps.apple.com/app/id1519053095">
    //                     <img src={Apple_Store} height={75} />
    //                 </a>
    //             </div>
    //         </div>
    //     );
    // }
    return (
        <Switch>
            <Route path="/" exact component={GetStarted} />
            <Route path="/register" component={GetStarted} />
            <Route path="/dashboard" component={Dashboard} isPrivate />
            <Route path="/dashboard/gcp/connect" component={ConnectGCPAccount} isPrivate />
            <Route path="/dashboard/azure/add" component={AddAzureAccount} isPrivate />
            <Route path="/dashboard/Reports" component={Reports} />
            <Route path="/dashboard/aws/accounts#connect" component={ConnectAWSAccount} isPrivate />
            <Route path="/dashboard/aws/list" component={ListAWSAccount} isPrivate />
            <Route path="/dashboard/charts" component={DashboardChart} isPrivate />
            <Route path="/dashboard/settings" component={Settings} isPrivate />
            <Route path="/dashboard/linkAccounts" component={LinkAccounts} isPrivate />
            <Route path="/dashboard/azure/azureHome" component={AzureHome} isPrivate />
            <Route path="/dashboard/gcp/gcpHome" component={GcpHome} isPrivate />
            <Route path="/dashboard/budgets" component={Budgets} isPrivate />

            {/*<Route path={RoutesList.RECOMMENDATIONS} component={RecommendationsMain} isPrivate />*/}
            <Route path={RoutesList.GCP_LIST} component={ListGCPAccount} isPrivate />
            <Route path="/dashboard/invoice" component={Invoices} isPrivate />
            <Route component={GetStarted} />
        </Switch>
    );
}
