import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";

import { getAwsOrgSpendingRequest } from "../../../redux/actions/actions";
import { LinearProgress } from "@material-ui/core";
import Format from "components/NumberFormat";
import AwsOrgTable from "components/table/AwsOrgTable";
import NO_DATA_AVAILABLE from "../../../components/NO_DATA_AVAILABLE";
import ContainerCardWithDateRange from "../../../components/ContainerCardWithDateRange";
import aws_org from "../../../images/newCloudTrakrIcons/AWSOrganizations.png";

export default function AwsOrganizationView(props) {
    const dispat = useDispatch();
    const { period, selectedSubscription, currency, customerId } = props;
    const [progress, setProgress] = useState(0);
    const [root, setRoot] = useState({
        name: "",
        rootId: "",
        aname: "",
        total: "",
    });
    const { awsOrgSpending, awsOrgAccountSpending, loading } = useSelector((state) => ({
        awsOrgSpending: state.awsServerlessReducer.awsOrgSpending,
        awsOrgAccountSpending: state.awsServerlessReducer.awsOrgAccountSpending,
        loading: state.awsServerlessReducer.loading,
    }));
    useEffect(() => {
        let { title, tenantId } = selectedSubscription;
        if (title == "All") {
            let obj = { customerId, view: "organizations" };
            if (awsOrgSpending === null) {
                dispat(getAwsOrgSpendingRequest(obj));
            }
        } else {
            let obj1 = { customerId, tenantId, view: "account" };
            dispat(getAwsOrgSpendingRequest(obj1));
        }
    }, [customerId]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            {selectedSubscription.title == "All" ? (
                awsOrgSpending ? (
                    awsOrgSpending.map((i) => (
                        <div style={{ marginBottom: 10 }}>
                            <AwsOrgTable data={i} currency={currency} show={true} />
                        </div>
                    ))
                ) : (
                    <ContainerCardWithDateRange title={"AWS Organizations"} titleIcon={aws_org} showDateRange={false} collapsible={true} datePeriodEnabled={false}>
                        {() => <NO_DATA_AVAILABLE />}
                    </ContainerCardWithDateRange>
                )
            ) : (
                awsOrgAccountSpending &&
                awsOrgAccountSpending.map((i) => (
                    <div style={{ marginBottom: 10 }}>
                        <AwsOrgTable data={i} currency={currency} show={true} />
                    </div>
                ))
            )}
        </div>
    );
}
