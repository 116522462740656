import React, { useState, useEffect } from "react";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import Format from "components/NumberFormat";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import {CancelRounded, Help, RemoveCircle, RemoveCircleOutline} from "@material-ui/icons";
import ExpandRow from "./ExpandRow";
import {Backdrop, Divider, Fade, Modal, Typography} from "@material-ui/core";
import Cost from "components/Cost";
import "./style.css";
import MatricLevelChart from "./MatricLevelChart";
import MatricObjectData from "./MatricObjectData";
// import aws_recommendation from "../../images/aws_recommendation.svg";
import Ec2Icon from "../../images/EC2.svg";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import Badge from "@material-ui/core/Badge";
import {OverlayTrigger, Tooltip, Popover} from "react-bootstrap";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import TooltipMUI from '@material-ui/core/Tooltip';

import Button from "@material-ui/core/Button";
import color from "../../styles/color";
import LinkText from "../../components/LinkText";
import Checkbox from "components/Checkbox";

const AWS_TERMINATE_INSTANCE_HELP = "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html#new-console";
const useStyles = makeStyles((theme) => ({
    backgroundStyle: {border: "1px solid #338307",margin:2,padding:2,fontSize:14
    }
}));

export default function RecommendationsTable(props) {
    const { list: list2, currency, customerId, subscriptionId, type, onVolumeClick = ()=>{}, volumeLinkEnabled = false } = props;
    const classes = useRowStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showingDetails, setShowingDetails] = useState(null);
    const [search, setSearch] = useState(null);
    const [list, setList] = useState([]);

    const [selectionModel, setSelectionModel] = useState([]);
    const [approvedSavingsTotal, setApprovedSavingsTotal] = useState(0);

    const [approvedSavingsModal, setApprovedSavingsModal] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showS3Details = (detail) => {
        setShowingDetails(null);
        if (detail) {
            setShowingDetails(detail);
        }
    };

    const matricbg = useStyles();

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.ec2Name,
                f?.instanceId,
                f?.region,
                f?.accountId
                
            ], search);
        }
        return true;
    }

    useEffect(()=>{
        if(list2 && list2.length){
            const DAYS_PERIOD = 30;
            const list = list2.map(row=>{
                if(type=='stopped') {
                    row.totalSavingsMonths = row?.volumes?.map(r => r.avgCostPerDay || 0)?.reduce((a, b) => a + b, 0) * DAYS_PERIOD;
                    row.totalSavingsDaysString = (row?.numberOfDaysStopped)?(Format.formatNumber(Number(row?.volumes?.map(v => parseFloat(v.totalCost) || 0).reduce((a, b) => a + b, 0))) +  "/" +  row?.numberOfDaysStopped + ((parseInt(row?.numberOfDaysStopped) > 1 ? " days" : " day"))):null;
                } else {
                    row.totalSavingsMonths = (parseFloat(row.avgCost) + row?.volumes?.map(r=>parseFloat(r.avgCost)||0)?.reduce((a,b)=>a+b, 0)) * DAYS_PERIOD
                }
                return row;
            })
            setList(list);
        }
    }, [list2]);

    useEffect(()=>{
        if(!selectionModel.length) return
        const data = selectionModel.map(s=>list.find(g=>(g?.ec2Name || g?.instanceId)==s));
        const approvedSavingsTotal = data.map(d=>parseFloat(d.totalSavingsMonths)).reduce((a,b)=>a+b, 0);
        setApprovedSavingsTotal(approvedSavingsTotal);
    }, [selectionModel]);

    const onSelect = (id, all = false) => {
        if(all){
            if(selectionModel.length === list?.length) {
                setSelectionModel([]);
            } else {
                const selected = list.map(row => row?.instanceId || row?.ec2Name);
                setSelectionModel(selected);
            }
        } else {
            if(selectionModel.indexOf(id) != -1){
                setSelectionModel(selectionModel.filter(i=>i!==id));
            } else {
                const model = Object.assign([], selectionModel);
                model.push(id);
                setSelectionModel(model);
            }
        }
    }

    return (
        <div>
            <ApprovedSavings open={approvedSavingsModal} currency={currency} onClose={()=>setApprovedSavingsModal(false)}
                             data={selectionModel.map(s=>list.find(g=>(g?.ec2Name || g?.instanceId)==s))}
                             onCancel={()=>{
                                 setSelectionModel([]);
                                 setApprovedSavingsModal(false);
                             }}
                             type={type}
                             onRemove={(index, id)=>{
                                 const final = selectionModel.filter(s => s != id);
                                 if(final.length){
                                     setSelectionModel(final);
                                 } else {
                                     setSelectionModel([]);
                                     setApprovedSavingsModal(false);
                                 }
                             }}
                             total={approvedSavingsTotal}
                             currency={Format.formatCurrencySymbol(currency)}
            />
                <div className={classes.root}>
                    {list != null && list?.length ? (
                        <div style={{padding: 0, margin: 0}}>
                            <Paper elevation={3}>
                                <Box display={"flex"} flex={1}>
                                    <Box flex={showingDetails ? 0.3 : 1}>
                                        <div style={{display:'flex', alignItems:'center',justifyContent:'space-evenly'}}>
                                            <div style={{flex: 4,display:'flex', alignItems:'center'}}>
                                                <TableSearch onSearch={setSearch} filterWidth={true}/>
                                                {type==='idle' ?
                                                    <div style={{display:'flex',marginLeft: 160}}>
                                                        <b>Metrics:</b>

                                                        <td style={{marginLeft:4}}>
                                                            <span className={matricbg.backgroundStyle}> {" Avg. CPU Utilization <5% " || null}</span>{" "}Last 30 days
                                                        </td>
                                                    </div>
                                                    :null}
                                            </div>
                                            <Box flex={1} display={'flex'} justifyContent={'flex-end'}>
                                                <TooltipMUI disableHoverListener={selectionModel.length>0}
                                                            disableFocusListener={selectionModel.length>0}
                                                            disableTouchListener={selectionModel.length>0}
                                                            title="Please select any recommendations">
                    <span>
                        <Button onClick={()=>setApprovedSavingsModal(true)}
                                disabled={selectionModel.length==0}
                                variant="contained"
                                color="primary"
                                style={{textTransform: "none"}}
                            // style={{ backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial" }}>
                        >
                            {
                                selectionModel.length>0?
                                    `Approve Savings (${Format.formatCurrencySymbol(currency) + Format.formatNumber(approvedSavingsTotal)})`:
                                    "Approve Savings"
                            }
                        </Button>
                    </span>
                                                </TooltipMUI>
                                            </Box>
                                        </div>
                                        <TableContainer className={classes.container}>
                                            <Table className={classes.table} stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell align="left">
                                                            <Checkbox onClick={()=>onSelect(null, true)} color={"primary"} checked={selectionModel.length === list.length} />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            EC2 instance Id
                                                        </StyledTableCell>

                                                        {showingDetails ? null : (
                                                            <>

                                                                <StyledTableCell align="left">
                                                                    Location
                                                                </StyledTableCell>

                                                                <StyledTableCell align="left">
                                                                    Account
                                                                </StyledTableCell>

                                                                {type == 'stopped' && <StyledTableCell align="left">
                                                                    # days stopped
                                                                </StyledTableCell>}

                                                                <StyledTableCell align="left">
                                                                    # EBS vol. attached
                                                                </StyledTableCell>

                                                                {type == 'stopped' && <StyledTableCell align="left">
                                                                    Total EBS vol. size
                                                                </StyledTableCell>}

                                                                <StyledTableCell align="left">
                                                                    EBS vol. Savings
                                                                    {/* {type==='idle' && " (per month)"} */}
                                                                </StyledTableCell>

                                                                {type == 'idle' && <StyledTableCell align="left">
                                                                    EC2 savings{type === 'idle' ? <br/> : null}
                                                                    {/* {type==='idle' && " (per month)"} */}
                                                                </StyledTableCell>}

                                                                <StyledTableCell align="left">
                                                                    Total Savings{type === 'idle' ? <br/> : null}
                                                                    {/* {type==='idle' && " (per month)"} */}
                                                                </StyledTableCell>

                                                                <StyledTableCell align="center">

                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'center',
                                                                        alignItems: "center"
                                                                    }}>
                                                                        Action{" "}
                                                                        <OverlayTrigger placement="left"
                                                                                        overlay={<Tooltip
                                                                                            id="button-tooltip">How to
                                                                                            terminate EC2
                                                                                            instance?</Tooltip>}>
                                                                            <Badge badgeContent={0} color="secondary">
                                                                                <Help
                                                                                    onClick={() => window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")}
                                                                                    style={{
                                                                                        marginLeft: 5,
                                                                                        cursor: "pointer",
                                                                                        fontSize: 15,
                                                                                        color: "#303"
                                                                                    }}/>
                                                                            </Badge>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                </StyledTableCell>
                                                            </>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {(list || [])?.sort((a, b) => b.totalSavingsMonths - a.totalSavingsMonths)?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                        <ExpandRow volumeLinkEnabled={volumeLinkEnabled}
                                                                   onVolumeClick={onVolumeClick} type={type}
                                                                   subscriptionId={subscriptionId} row={r} index={i}
                                                                   currency={currency} showingDetails={showingDetails}
                                                                   setShowingDetails={showS3Details} icon={Ec2Icon}
                                                                   selectedInstances={selectionModel}
                                                                   onSelectInstance={onSelect}
                                                        />
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                    <Box display={showingDetails ? "flex" : "none"} flex={showingDetails ? 0.7 : 0}>
                                        <Paper style={{flex: 1, padding: 5}} elevation={3}>
                                            <Box display={"flex"} alignItems={"center"}>
                                                <Box display={"flex"} flex={1} justifyContent={"space-between"}
                                                     style={{paddingRight: 10}} alignItems={"center"}>
                                                    <Typography component={"h4"} variant={"h4"}>
                                                        <img src={Ec2Icon} height={30}/> {showingDetails?.ResourceId}
                                                    </Typography>
                                                    <Typography component={"h5"} variant={"h5"}>
                                                        {Format.formatCurrencySymbol(currency)}
                                                        {showingDetails?.cost ?
                                                            <Cost>{showingDetails?.cost}</Cost> : "__.__"}
                                                    </Typography>
                                                </Box>
                                                <CancelRounded onClick={() => setShowingDetails(null)}/>
                                            </Box>
                                            <Divider/>
                                            <Box padding={1}>
                                                <MatricObjectData customerId={customerId}
                                                                  bucketName={showingDetails?.ResourceId}
                                                                  tenantId={showingDetails?.UsageAccountId}
                                                                  bucketRegion={showingDetails?.Region}/>
                                            </Box>
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                padding: 10
                                            }}>
                                                <div style={{display: "flex", alignItems: "center"}}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginLeft: 20
                                                    }}>
                                                        <Typography>
                                                            <b>Linked account:</b>
                                                        </Typography>
                                                        <Typography className="typoMargin">
                                                            <b>PricingTerm:</b>
                                                        </Typography>
                                                        <Typography className="typoMargin">
                                                            <b>PricingUnit:</b>
                                                        </Typography>
                                                        <Typography className="typoMargin">
                                                            <b>LineItemType:</b>
                                                        </Typography>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginLeft: 20
                                                    }}>
                                                        <Typography>{showingDetails?.UsageAccountId || "-"}</Typography>
                                                        <Typography
                                                            className="typoMargin">{showingDetails?.PricingTerm || "-"}</Typography>
                                                        <Typography
                                                            className="typoMargin">{showingDetails?.PricingUnit || "-"}</Typography>
                                                        <Typography
                                                            className="typoMargin">{showingDetails?.LineItemType || "-"}</Typography>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", marginRight: 20}}>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <Typography>
                                                            <b>ResourceTags:</b>
                                                        </Typography>

                                                        <Typography className="typoMargin">
                                                            <b>Region:</b>
                                                        </Typography>

                                                        <Typography className="typoMargin">
                                                            <b>ProductName:</b>
                                                        </Typography>

                                                        <Typography className="typoMargin">
                                                            <b>BillType:</b>
                                                        </Typography>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginLeft: 20
                                                    }}>
                                                        <Typography>{showingDetails?.ResourceTags || "-"}</Typography>
                                                        <Typography
                                                            className="typoMargin">{showingDetails?.Region}</Typography>
                                                        <Typography
                                                            className="typoMargin">{showingDetails?.ProductName}</Typography>
                                                        <Typography
                                                            className="typoMargin">{showingDetails?.BillType || "-"}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider/>
                                            <Box padding={1}>{showingDetails?.meterLevel &&
                                                <Metertable data={showingDetails?.meterLevel}
                                                            currency={currency}/>}</Box>
                                            <Divider/>
                                            <Box padding={2}>
                                                <MatricLevelChart customerId={customerId}
                                                                  bucketName={showingDetails?.ResourceId}
                                                                  tenantId={showingDetails?.UsageAccountId}
                                                                  bucketRegion={showingDetails?.Region}/>
                                            </Box>
                                        </Paper>
                                    </Box>
                                </Box>
                                <TablePagination ActionsComponent={TablePaginationActions}
                                                 rowsPerPageOptions={[10, 25, 50, 75]} component="div"
                                                 count={list?.filter(filteredTable)?.length} rowsPerPage={rowsPerPage}
                                                 page={page} onChangePage={handleChangePage}
                                                 onChangeRowsPerPage={handleChangeRowsPerPage}/>
                            </Paper>
                        </div>
                    ) : (
                        <h6 style={{display: "flex", justifyContent: "center"}}>No data found</h6>
                    )}
                </div>
        </div>
    );
}

function Metertable({ data, currency }) {
    return (
        <div>
            <Typography style={{ fontWeight: "bold" }}>Meter data</Typography>
            <div style={{ maxHeight: 300 }} class="overflow-auto">
                <table id="customers">
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Cost</th>
                    </tr>

                    {data &&
                        data.meter.map((m) => (
                            <tr>
                                <td>{m.Operation}</td>
                                <td>{m.ProductFamily}</td>
                                <td>{m.LineItemUsageType}</td>
                                <td>{m.LineItemDescription}</td>
                                <td>{Format.formatCurrencySymbol(currency) + Format.formatNumber(Number(m["cost"]))}</td>
                            </tr>
                        ))}
                </table>
            </div>
        </div>
    );
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        whiteSpace:'nowrap',
        fontSize:12
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1),

    },
}))(TableCell);

const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 450,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export function ApprovedSavings({open, onClose, data, currency, onApprove, onCancel, onRemove, total, type = null}){
    const classes = useStyles();

    function getTotalVolumeSize(e, output_unit){
        function sizeInBytes(input_unit, size, to = null, new_size){
            if(!((size?.toString() || false) || (new_size?.toString() || false))) return null;
            var newSize = size;
            if(to){
                size = 1;
            }
            switch(to || input_unit){
                case "KB":  newSize = size*1000;
                    break;
                case "MB":  newSize = size*1000*1000;
                    break;
                case "GB":  newSize = size*1000*1000*1000;
                    break;
                case "TB":  newSize = size*1000*1000*1000*1000;
                    break;
                case "PB":  newSize = size*1000*1000*1000*1000*1000;
                    break;
                case "KiB": newSize = size*1024;
                    break;
                case "MiB": newSize = size*1024*1024;
                    break;
                case "GiB": newSize = size*1024*1024*1024;
                    break;
                case "TiB": newSize = size*1024*1024*1024*1024;
                    break;
                case "PiB": newSize = size*1024*1024*1024*1024*1024;
                    break;
            };
            return to != null?new_size/newSize:newSize;
        }
        function bytesToSize(bytes, decimals = 2) {
            if (bytes) {
                if (bytes === 0) return "0 Bytes";

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
            } else {
                return "-";
            }
        }
        var newSize = e?.volumes?.map(v=>sizeInBytes(v?.unit, v?.size, null))
            ?.filter(f=>f!=null)
            ?.reduce((a,b)=>a+b, null);
        newSize=bytesToSize(newSize);
        return newSize || "--";
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div
                        className={classes.paper3}
                        style={{
                            height: '100%',
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Paper elevation={3} style={{width: '70%'}}>
                            <br/>
                            <div style={{display: "flex", alignItems: "center",justifyContent: "center"}}>
                                <div style={{ padding: 10 }}>
                                    <Paper style={{width: 200,  background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 10, textAlign: "center" }} elevation={3}>
                                        <h5 className="carouselValue">{data.length}</h5>
                                        <span style={{fontSize:14}}>Instance selected</span>
                                    </Paper>
                                </div>
                                <div style={{ padding: 10 }}>
                                    <Paper style={{width: 200, background: `linear-gradient(to right, ${color.tableHeader}, #eef2f3)`, padding: 10, textAlign: "center" }} elevation={3}>
                                        <h5 className="carouselValue">{currency + Format.formatNumber(total)}</h5>
                                        <span style={{fontSize:14}}>Approved Savings</span>
                                    </Paper>
                                </div>
                            </div>
                            <br/>
                            {type==='idle' ?
                                <div style={{display:'flex', marginLeft: 10}}>
                                    <b>Metrics:</b>

                                    <td style={{marginLeft:4}}>
                                        <span style={{border: "1px solid #338307",margin:2,padding:2,fontSize:14}}> {" Avg. CPU Utilization <5% " || null}</span>{" "}Last 30 days
                                    </td>
                                    <br/>
                                    <br/>
                                </div>
                                :null}
                            <TableContainer className={classes.container} style={{height: 300}}>
                                <Table
                                    className={classes.table}
                                    stickyHeader
                                    aria-label="sticky table"
                                    style={{height: 'max-content'}}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell/>
                                            <TableCell>
                                                EC2 instance id
                                            </TableCell>
                                            {type === 'stopped' && <TableCell>
                                                Total EBS vol. size
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Curr. Instance Type
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Recomm. Instance Type
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Curr. CPU Util.
                                            </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                Projected CPU Util.
                                            </TableCell>}
                                            {type === 'stopped' && <TableCell>
                                                # of days stopped
                                            </TableCell>}
                                            <TableCell>
                                                Potential Savings
                                                </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((row,i)=>(
                                            <TableRow>
                                                <TableCell>
                                                    <RemoveCircleOutline onClick={()=>onRemove(i, row?.ec2Name || row?.instanceId)} color={'secondary'} style={{cursor: 'pointer'}} />
                                                </TableCell>
                                            <TableCell>
                                                    {row?.ec2Name || row?.instanceId || row?.instanceName}
                                                </TableCell>
                                                {type === 'stopped' && <TableCell>
                                                {getTotalVolumeSize(row)}
                                            </TableCell>}
                                                {type === 'stopped' && <TableCell>
                                                    {
                                                        ((row?.numberOfDaysStopped?.toString()) +
                                                            (parseInt(row?.numberOfDaysStopped) > 1? " days":" day" ))
                                                    }
                                                </TableCell>}
                                                 {type === 'rightsizing' && <TableCell>
                                                    {
                                                        row?.currentInstanceType
                                                    }
                                                </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                    {
                                                        row?.recommendedInstanceType
                                                    }
                                                </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                    {
                                                        Format.formatNumber(row.currentInstanceTypeCPUUtilInPercent) + " %"
                                                    }
                                                </TableCell>}
                                            {type === 'rightsizing' && <TableCell>
                                                    {
                                                        Format.formatNumber(row.projectedCPUUtilInPercent) + " %"
                                                    }
                                                </TableCell>}
                                            <TableCell>
                                                    {currency + Format.formatNumber(Number(row?.totalSavingsMonths || row?.potentialSavings).toFixed(2)) + " /mon"}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div style={{padding: 10, display: "flex", justifyContent: "flex-end"}}>
                                <Button onClick={onApprove} variant="contained" color="primary">
                                    Approve
                                </Button>

                                <Button onClick={onCancel} style={{marginLeft: 10}} variant="contained" color="secondary">
                                    Cancel
                                </Button>
                            </div>
                        </Paper>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}


/**
 *
 *             <div style={{ height: 0, width: '100%' }}>
 *             <DataGrid rows={
 *                 list
 *                     ?.sort((a,b)=>b.totalSavingsMonths - a.totalSavingsMonths)?.filter(filteredTable)
 *                     .map((r,i)=>expandRow22({
 *                     subscriptionId: subscriptionId,
 *                     row:r,
 *                     index:i,
 *                     currency:currency,
 *                     mode: 'data', type}))
 *             } pagination
 *                       // components={{Toolbar: GridToolbarExport}}
 *                       columns={[
 *                           {id: 'i',name:" ", width: 22,  renderCell: ()=><img src={Ec2Icon} height={20} />},
 *                           {id: 'id',name: "EC2 instance", },
 *                           {width: 150, id: 'totalSavings',name: "Potential Savings",
 *                               renderCell: (a,b,c)=>{
 *                                 let val = a.formattedValue.split('&');
 *                                 return (
 *                                     <span>
 *                                          {val[0]}
 *                                         <br/>
 *                                         <span style={{fontSize: 11, color: "#7f7f7f"}}>
 *                                         {val[1]}
 *                                         </span>
 *                                     </span>
 *                                 )
 *                               },
 *                               cellClassName: 'line-heighted-table-cell'
 *                           },
 *                           {width: 150, id: 'daysStopped',name: "# days stopped", hide: type === 'idle'},
 *                           {width: 180, id: 'ebsvol',name: "# EBS vol. attached",
 *                           renderCell: (r)=> {
 *                               return (
 *                                   <LinkText onClick={() => onVolumeClick(r.row?.volumes)}
 *                                             role={volumeLinkEnabled && (r.row?.volumes?.length > 1) ? 'Owner' : 'other'}>
 *                                       {
 *                                           (r.row?.volumes?.length?.toString()) +
 *                                           (r.row?.volumes?.length > 1 ? " volumes" : " volume")
 *                                           || "-"
 *                                       }
 *                                   </LinkText>
 *                               )
 *                           }
 *                           },
 *                           {width: 160, id: 'ebsSize',name: "Total EBS vol. size", hide: type === 'idle'},
 *                           {width: 150, id: 'ebsSavings',name: "EBS vol. Savings"},
 *                           {width: 150, id: 'ec2Savings',name: "EC2 Savings", hide: type === 'stopped'},
 *                           {width: 100, id: 'location',name: "Location"},
 *                           {width: 140, id: 'account',name: "Account"}
 *                       ].map(({id, name, width, ...rest})=>({
 *                           ...rest,
 *                           field: id,
 *                           headerName: name,
 *                           width: width || 200,
 *                           editable: false,
 *                           sortable: false,
 *                           filterable: false,
 *                           valueOptions: null,
 *                           hideSortIcons: true,
 *                           hidePreferences: true,
 *                           // headerClassName: 'data-grid-header'
 *                       }))}
 *                       disableColumnMenu={true}
 *                       pageSize={10}
 *                       checkboxSelection
 *                       disableSelectionOnClick
 *                       onSelectionModelChange={(newSelectionModel,y,u,i,h) => {
 *                           setSelectionModel(newSelectionModel);
 *                       }}
 *                       selectionModel={selectionModel}
 *             />
 *             </div>
 */
