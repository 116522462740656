import { all } from "redux-saga/effects";
import userSaga, {getConnectedTeamsList, getCustomerStatus, getWhiteLabel} from "./userSaga";
import allHomeSaga, { getAllSubscriptions } from "./allHomeSaga";
import accountStatus from "./accountStatus";
import azureRecommendationSaga from "./azureRecommendationSaga";
import azureServerlessSaga from "./azureServerlessSaga";
import awsServerlessSaga from "./awsServerlessSaga";
import gcpServerlessSaga from "./gcpServerlessSaga";
import { getMessagesSaga } from "./userSaga";
import getBudgetSaga from "./getBudgetSaga";
import reportsSaga from "./reportsSaga";
import getInvoiceSaga from "./invoiceSaga";
import getBudgetSummarySaga from "./getBudgetSummarySaga";
import azureManagementGroupSaga from "./azureManagementGroupSaga";
import awsReservationSaga from "./awsReservationSaga";
import connectionErrorSaga from "./connectionErrorSaga";
import azureReservationSaga from "./azureReservationSaga";
import azureCCKSaga from "./azureCCKSaga";
import awsCCKSaga from "./awsCCKSaga";
import azureCosmosDBSaga from "./azureCosmosDBSaga";
import azureCosmosDBMetricsSaga from "./azureCosmosDBMetricsSaga";
import azureCosmosDBSubSaga from "./azureCosmosDBSubSaga";
import awsDynamoDBSaga from "./awsDynamoDBSaga";
import awsLambdaSaga from "./awsLambdaSaga";
import gcpProjectSaga from "./gcpProjectSaga";
import appStateSaga from "./appStateSaga";
import gcpServicesSaga from "./gcpServicesSaga";
import gcpComputeSaga from "./gcpComputeSaga";
import gcpStorageSaga from "./gcpStorageSaga";
import allComputeSaga from "./allComputeSaga";
import azureFunctionSaga from "./azureFunctionSaga";
import allDatabaseSaga from "./allDatabaseSaga";
import allFunctionsSaga from "./allFunctionsSaga";
import allStorageSaga from "./allStorageSaga";
import awsRDSSaga from "./awsRDSSaga";
import awsComputeOptimizerSaga from "./awsComputeOptimizerSaga";
import azureStorageSaga from "./azureStorageSaga";
import allAccountSaga from "./allAccountSaga";
import allServicesSaga from "./allServicesSaga";
import allResourcesSaga from "./allResourcesSaga";
import awsRDSRecommendationSaga from "./awsRDSRecommendationSaga";
import awsEC2RecommendationSaga from "./awsEC2RecommendationSaga";
import azureTagsSaga from "./azureTagsSaga";
import awsRecommendationSummarySaga from "./awsRecommendationSummarySaga";
import azureServicesSaga from './azureServicesSaga';
import awsDynamoDBRecommendationSaga from "./awsDynamoDBRecommendationSaga";
import azureStoppedVmRecommendationSaga from "./azureStoppedVmRecommendationSaga";
import awsS3RecommendationSaga from "./awsS3RecommendationSaga";

export default function* rootSaga() {
    yield all([
        appStateSaga(),
        userSaga(),
        allHomeSaga(),
        accountStatus(),
        getMessagesSaga(),
        azureRecommendationSaga(),
        azureServerlessSaga(),
        awsServerlessSaga(),
        gcpServerlessSaga(),
        getAllSubscriptions(),
        getBudgetSaga(),
        reportsSaga(),
        getBudgetSummarySaga(),
        getInvoiceSaga(),
        azureManagementGroupSaga(),
        awsReservationSaga(),
        getWhiteLabel(),
        connectionErrorSaga(),
        azureReservationSaga(),
        azureCCKSaga(),
        awsCCKSaga(),
        azureCosmosDBSaga(),
        azureCosmosDBMetricsSaga(),
        azureCosmosDBSubSaga(),
        awsDynamoDBSaga(),
        awsLambdaSaga(),
        gcpProjectSaga(),
        gcpServicesSaga(),
        gcpComputeSaga(),
        gcpStorageSaga(),
        getCustomerStatus(),
        allComputeSaga(),
        getConnectedTeamsList(),
        azureFunctionSaga(),
        allDatabaseSaga(),
        allFunctionsSaga(),
        allStorageSaga(),
        awsRDSSaga(),
        awsComputeOptimizerSaga(),
        azureStorageSaga(),
        azureTagsSaga(),
        allAccountSaga(),
        allServicesSaga(),
        allResourcesSaga(),
        awsRDSRecommendationSaga(),
        awsEC2RecommendationSaga(),
        awsRecommendationSummarySaga(),
        azureServicesSaga(),
        awsDynamoDBRecommendationSaga(),
        azureStoppedVmRecommendationSaga(),
        awsS3RecommendationSaga()
    ]);
}
