import React, { useState, useEffect } from "react";
import Azure_logo from "../../images/Azure_logo.svg";
import active_directory from "../../images/active_directory.svg";
import key from "../../images/key.svg";
import QuestionMark from "../../images/questionmark.png";
import Refresh from "../../images/refresh.svg";
import axios from "../../connection/axios";
import { useSelector } from "react-redux";
import Switch from "react-switch";
import { Button, Link, Typography, LinearProgress, Paper } from "@material-ui/core";
import BreadCrumbs from "../../components/BreadCrumbs";
import { makeStyles } from "@material-ui/core/styles";
import AzureBillingExport from "./azure/AzureBillingExport";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Colors from "../../styles/color";
import VerfiedImage from "../../images/verified.svg";
import error from "../../images/error.svg";

import { Card, ListGroup, Modal, Toast, Table, Alert, Tooltip, OverlayTrigger, paper, Form, Popover } from "react-bootstrap";
const { REACT_APP_AZURE_CONNECTION, REACT_APP_MSAL_AZURE_CONNECTION, REACT_APP_LIVE_AZURE_SUBSCRIPTION, REACT_APP_ENABLE_DISABLE_SUBSCRIPTION, REACT_APP_ADD_AND_VERIFY, REACT_APP_AZURE_ACCOUNT_HELP } = process.env;

const DEFAULT_SUBSCRIPTION = {
    title: "Pay as you Go",
};
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
    },
}));

export default function AddAzureAccount() {
    const [accountListResponse, setAccountListResponse] = useState([]);
    const [modelData, setModelData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selectAccount, setSelectAccount] = useState(DEFAULT_SUBSCRIPTION);

    const connectionlist = [{ title: "Pay as you Go" }, { title: "Billing Export Connection" }];

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const classes = useStyles1();

    useEffect(() => {
        accountListApi();
    }, [userData.id]);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    function onSubscriptionChange(accountType) {
        setSelectAccount(accountType);
    }

    return (
        <div>
            {loading ? (
                <div className={classes.root}>
                    <LinearProgress variant="determinate" value={progress} />
                </div>
            ) : (
                <div>
                    <Card.Header>
                        <div>
                            <BreadCrumbs parent={"Home"} child={"Azure"} subChild={"Account"} parentLink={"/dashboard/charts"} subchildLink={"/dashboard/azureHome"} hasChild={true} hasSubChild={true} />
                        </div>
                        <div
                            style={{
                                alignItems: "center",
                                display: "flex",
                                justifyContent: "space-between",
                            }}
                        >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src={Azure_logo}
                                    alt="Azure logo"
                                    style={{
                                        width: 30,
                                        height: 30,
                                        marginRight: 12,
                                    }}
                                />
                                <h3 style={{ margin: 0 }}>Azure Accounts</h3>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {/* <Autocomplete
                  clearIndicator={true}
                  disableClearable={true}
                  value={selectAccount}
                  title={"Choose your account"}
                  style={{ width: 300, marginRight: 20 }}
                  size={"small"}
                  options={connectionlist}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, value) => onSubscriptionChange(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose your account"
                      variant="outlined"
                    />
                  )}
                /> */}

                                {selectAccount.title == "Pay as you Go" && (
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="button-tooltip">Click to add your Azure account</Tooltip>}>
                                        <Button className={classes.btnStyle} onClick={deviceFlowApi}>
                                            Connect Azure account
                                        </Button>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <span style={{ color: "gray" }}>Pre-requisites to connect Azure account</span>
                            <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Click to open help file</Tooltip>}>
                                <a href="https://docs.cloudtrakr.com/SetupAzureConnection/" target="_blank">
                                    <img src={QuestionMark} alt="QuestionMark" style={{ width: 20, height: 20, marginLeft: 12 }} />
                                </a>
                            </OverlayTrigger>
                        </div>
                    </Card.Header>

                    {selectAccount.title == "Pay as you Go" ? (
                        <ListAzureConnection accountListResponse={accountListResponse} addAndVerifySubscription={addAndVerifySubscription} setModelData={setModelData} setSwitchValue={setSwitchValue} handleShow={handleShow} />
                    ) : (
                        <AzureBillingExport />
                    )}
                </div>
            )}

            <LinkUnlink show={show} data={modelData} handleClose={handleClose} setSwitchValue={setSwitchValue} />
        </div>
    );

    function setSwitchValue(isEnabled, id) {
        isEnabled === true ? enableAndDisableSubscriptions(id, false) : enableAndDisableSubscriptions(id, true);
        handleClose();
    }

    function enableAndDisableSubscriptions(subID, status) {
        setLoading(true);
        axios
            .get(REACT_APP_ENABLE_DISABLE_SUBSCRIPTION, {
                params: {
                    customerID: userData.id,
                    subscriptionID: subID,
                    isEnabled: status,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("statusCode" in responseJson) {
                    if (responseJson.statusCode == 200) {
                        accountListApi();
                    } else {
                        // Toast.show(responseJson.msg);
                    }
                }
                return responseJson;
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }

    function deviceFlowApi() {
        setLoading(true);
        axios
            .get(REACT_APP_MSAL_AZURE_CONNECTION, {
                params: {
                    customerID: userData.id,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                // console.log("new azure connection", responseJson);
                if ("response" in responseJson) {
                    window.open(responseJson.response.url, "_self");
                }

                return responseJson;
            })
            .finally(() => setLoading(false))
            .catch((error) => {
                console.error(error);
            });
    }

    function accountListApi() {
        setLoading(true);
        axios
            .get(REACT_APP_LIVE_AZURE_SUBSCRIPTION + `getSubscriptions?customerID=${userData.id}`)
            .then((response) => response.data)
            .then((responseJson) => {
                if ("statusCode" in responseJson) {
                    if (responseJson.statusCode == 200) {
                        // console.log("list response", responseJson);
                        setAccountListResponse(responseJson.response.azure);
                    } else {
                        // Toast.show(responseJson.msg);
                    }
                }
            })
            .finally(() => setLoading(false))

            .catch((error) => {
                console.error(error);
            });
    }

    function addAndVerifySubscription(tenantID) {
        setLoading(true);
        axios
            .get(REACT_APP_ADD_AND_VERIFY, {
                params: {
                    customerID: userData.id,
                    tenantID: tenantID,
                },
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("statusCode" in responseJson) {
                    if (responseJson.statusCode == 200) {
                        // Toast.show("Your subscription updated successfully");
                        accountListApi();
                    } else {
                        //Toast.show(responseJson.msg);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }

    function connectionCode(code) {
        // console.log("connection code", code);
        setLoading(true);
        axios
            .post("https://costunify.azure-api.net/azure-connection-msal-dev/connectTenants", {
                code: code,
                state: userData.id,
            })
            .then((response) => response.data)
            .then((responseJson) => {
                if ("statusCode" in responseJson) {
                    if (responseJson.statusCode == 200) {
                        // Toast.show("Your subscription updated successfully");
                        // console.log("User code api response");
                    } else {
                        //Toast.show(responseJson.msg);
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }
}

function ListAzureConnection({ accountListResponse, addAndVerifySubscription, setModelData, setSwitchValue, handleShow }) {
    return (
        <div>
            {accountListResponse && accountListResponse.length ? (
                accountListResponse.map((item, index) => (
                    <Paper elevation={3} style={{ margin: 8 }}>
                        <div>
                            <div
                                style={{
                                    padding: 8,
                                    backgroundColor: Colors.tableHeader,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                {" "}
                                <div>
                                    <img
                                        src={active_directory}
                                        alt="Active Directory"
                                        style={{
                                            width: 25,
                                            height: 25,
                                            marginRight: 4,
                                            padding: 3,
                                        }}
                                    />
                                    {item.tenantName}
                                    {"  "} ({item.tenantId}){"  "}
                                </div>
                                {/* <OverlayTrigger placement="left" overlay={<Tooltip id="button-tooltip">Click to update your subscriptions</Tooltip>}>
                                    <div>
                                        <span style={{ color: "blue", marginRight: 8 }}>Update subscriptions</span>
                                        <img
                                            onClick={() => addAndVerifySubscription(item.tenantId)}
                                            src={Refresh}
                                            alt="refresh"
                                            style={{
                                                cursor: "pointer",
                                                width: 20,
                                                height: 20,
                                                marginRight: 12,
                                            }}
                                        />
                                    </div>
                                </OverlayTrigger> */}
                            </div>

                            <Table>
                                {item.subscriptions && item.subscriptions.length ? (
                                    item.subscriptions?.map((item) => (
                                        <tbody>
                                            <tr
                                                style={{
                                                    fontSize: 14,
                                                    backgroundColor: item.status == "unauthorized" ? "#ffcccc" : null,
                                                }}
                                            >
                                                <td>
                                                    <img
                                                        src={key}
                                                        alt="key"
                                                        style={{
                                                            width: 25,
                                                            height: 25,
                                                            marginRight: 4,
                                                            padding: 3,
                                                        }}
                                                    />
                                                    {item.subName}
                                                </td>
                                                <td>{item.subscriptionId}</td>
                                                <td align="right">
                                                    {item.status == "active" ? (
                                                        <div>
                                                            <span>Active</span>
                                                            <img
                                                                src={VerfiedImage}
                                                                alt="VerfiedImage"
                                                                style={{
                                                                    width: 26,
                                                                    height: 26,
                                                                    marginRight: 4,
                                                                    padding: 3,
                                                                }}
                                                            />
                                                        </div>
                                                    ) : item.status == "unauthorized" ? (
                                                        <OverlayTrigger
                                                            placement="bottom"
                                                            overlay={
                                                                <Popover id="popover-contained">
                                                                    <Popover.Title as="h3">
                                                                        <img
                                                                            src={error}
                                                                            alt="error"
                                                                            style={{
                                                                                width: 26,
                                                                                height: 26,
                                                                                marginRight: 4,
                                                                                padding: 3,
                                                                            }}
                                                                        />
                                                                        {item?.status}
                                                                    </Popover.Title>
                                                                    <Popover.Content>{typeof item?.error_msg == "object" && item?.error_msg?.error_desc}</Popover.Content>
                                                                </Popover>
                                                            }
                                                        >
                                                            <div>
                                                                <span>{item.status}</span>
                                                                <img
                                                                    src={error}
                                                                    alt="error"
                                                                    style={{
                                                                        width: 26,
                                                                        height: 26,
                                                                        marginRight: 4,
                                                                        padding: 3,
                                                                    }}
                                                                />{" "}
                                                            </div>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <div>
                                                            <span>{item.status}</span>
                                                            <img
                                                                src={error}
                                                                alt="error"
                                                                style={{
                                                                    width: 26,
                                                                    height: 26,
                                                                    marginRight: 4,
                                                                    padding: 3,
                                                                }}
                                                            />{" "}
                                                        </div>
                                                    )}
                                                </td>
                                                {item.status !== "unauthorized" ? (
                                                    <td>
                                                        <Switch
                                                            height={26}
                                                            checked={item.isEnabled === true}
                                                            onChange={() => {
                                                                if (item.isEnabled === true) {
                                                                    setModelData({
                                                                        isEnabled: item.isEnabled,
                                                                        subscriptionId: item.subscriptionId,
                                                                        subName: item.subName,
                                                                    });
                                                                    handleShow();
                                                                } else {
                                                                    setSwitchValue(item.isEnabled, item.subscriptionId);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                ) : (
                                                    <td>
                                                        <Switch height={26} disabled />
                                                    </td>
                                                )}
                                            </tr>
                                        </tbody>
                                    ))
                                ) : (
                                    <span>No Subscription found</span>
                                )}
                            </Table>
                            {/* </Box> */}
                            {/* </Collapse> */}
                        </div>
                    </Paper>
                ))
            ) : (
                <h5 style={{display:'flex',justifyContent:'center',marginTop:'10%'}}>No account found</h5>
            )}
        </div>
    );
}

function LinkUnlink({ data, show, handleClose, setSwitchValue }) {
    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Unlink Azure Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to Unlink the your Azure "{data?.subName}" subscription?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    No
                </Button>
                <Button onClick={() => setSwitchValue(data.isEnabled, data.subscriptionId)} variant="primary">
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const useStyles1 = makeStyles({
    btnStyle: {
        background: "linear-gradient(45deg, #3f51b5 50%, #3f51b5 100%)",
        border: 0,
        borderRadius: 3,
        boxShadow: "0 3px 5px 2px rgba(63, 81, 181, .3)",
        color: "white",
        height: 40,
        padding: "0 40px",
        textTransform: "none",
    },
});
