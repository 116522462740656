import React, { useEffect } from "react";
import Format from "../../components/NumberFormat";
import { makeStyles, Paper, Grid, Divider } from "@material-ui/core";
import Azure_logo from "../../images/Azure_logo.svg";
import active_directory from "../../images/active_directory.svg";
import resource_group from "../../images/resource_group.svg";
import key from "../../images/key.svg";
import { Form } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 0,
  },
}));

export default function AzureResourceGroupList({
  tenantLogo,
  currency,
  accountList,
}) {
  const classes = useStyles();

  const { label, totalCost, dataSet } = accountList;
  return (
    <div>
      {accountList ? (
        <Paper className={classes.paper} elevation={3}>
          <div
            style={{
              padding: 12,
              backgroundColor: "#cfdac8",
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={tenantLogo}
                  alt="tenantLogo"
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 8,
                  }}
                />
                <span style={{ fontWeight: "bold" }}>{"Resource Groups"}</span>
              </div>
              <div style={{ fontWeight: "bold" }}>
                {currency}
                {Format.formatNumber(totalCost)}
              </div>
            </div>
          </div>
          <div
            class="overflow-auto"
            style={{
              padding: 12,

              maxHeight: 450,
            }}
          >
            {dataSet?.map((item) => (
              <div style={{ padding: 2 }}>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display: "flex",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <img
                      src={resource_group}
                      alt="resource_group"
                      style={{
                        width: 20,
                        height: 20,
                        marginRight: 8,
                      }}
                    />
                    <span>{item.label}</span>
                  </div>
                  <div>
                    {" "}
                    {currency}
                    {Format.formatNumber(item.value)}
                  </div>
                </div>
                <Divider light style={{ marginTop: 8 }} />
              </div>
            ))}
          </div>
        </Paper>
      ) : null}
    </div>
  );
}
