import React, { useState, useEffect } from "react";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import Format from "components/NumberFormat";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Table, TableBody,TablePagination, TableCell as TCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Divider, LinearProgress } from "@material-ui/core";

import S3Icon from "../../images/newCloudTrakrIcons/AWSS3.png";
import { CancelRounded } from "@material-ui/icons";
import ExpandRow from "./ExpandRow";
import Cost from "components/Cost";
import "./style.css";
import MatricLevelChart from "./MatricLevelChart";
import MatricObjectData from "./MatricObjectData";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";

import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 5 }}>
            {props.children}
        </TCell>
    );
}


export default function S3BucketTable(props) {
    const { list, currency, customerId, data } = props;
    const classes = useRowStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showingDetails, setShowingDetails] = useState(null);
    const [expandlist, setExpandList] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const showS3Details = (detail) => {
        setShowingDetails(null);
        if (detail) {
            setShowingDetails(detail);
        }
    };

    const [search, setSearch] = useState(null);

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.resourceId,
                f?.location,
                f?.usageAccountId
            ], search);
        }
        return true;
    }

    return (
        <div>
            <div className={classes.root}>
                {list != null && list?.length ? (
                    <div>
                        <Paper elevation={0}>
                            <Box flex={1}>
                                <Box>
                                <TableSearch onSearch={setSearch} />
                                <TableContainer style={{maxHeight:450}}component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                                    <TableHead style={{ backgroundColor: "#cfdac8" }}>
                                                <TableRow>
                                                    <TableCell/>
                                                    <TableCell>
                                                       <b>Bucket name</b>
                                                    </TableCell>

                                                            <TableCell>
                                                              <b>Location</b>
                                                            </TableCell>

                                                            <TableCell>
                                                               <b>AWS Account</b>
                                                            </TableCell>

                                                            <TableCell align="right">
                                                                <b>Cost</b>
                                                            </TableCell>
                                                       
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {(list || [])
                                                    ?.filter(filteredTable)
                                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((r, i) => (
                                                    <ExpandRow row={r} index={i} list={expandlist} setExpandList={setExpandList} currency={currency} data={data} showingDetails={showingDetails} setShowingDetails={showS3Details} icon={S3Icon} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box display={showingDetails ? "flex" : "none"} flex={showingDetails ? 0.7 : 0}>
                                    <Paper style={{ flex: 1, padding: 5 }} elevation={3}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            <Box display={"flex"} flex={1} justifyContent={"space-between"} style={{ paddingRight: 10 }} alignItems={"center"}>
                                                <Typography component={"h4"} variant={"h4"}>
                                                    <img src={S3Icon} height={30} /> {showingDetails?.ResourceId}
                                                </Typography>
                                                <Typography component={"h5"} variant={"h5"}>
                                                    {Format.formatCurrencySymbol(currency)}
                                                    {showingDetails?.cost ? <Cost>{showingDetails?.cost}</Cost> : "__.__"}
                                                </Typography>
                                            </Box>
                                            <CancelRounded onClick={() => setShowingDetails(null)} />
                                        </Box>
                                        <Divider />
                                        <Box padding={1}>
                                            <MatricObjectData customerId={customerId} bucketName={showingDetails?.ResourceId} tenantId={showingDetails?.UsageAccountId} bucketRegion={showingDetails?.Region} />
                                        </Box>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: 10 }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                                                    <Typography>
                                                        <b>Linked account:</b>
                                                    </Typography>
                                                    <Typography className="typoMargin">
                                                        <b>PricingTerm:</b>
                                                    </Typography>
                                                    <Typography className="typoMargin">
                                                        <b>PricingUnit:</b>
                                                    </Typography>
                                                    <Typography className="typoMargin">
                                                        <b>LineItemType:</b>
                                                    </Typography>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                                                    <Typography>{showingDetails?.UsageAccountId || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.PricingTerm || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.PricingUnit || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.LineItemType || "-"}</Typography>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", marginRight: 20 }}>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <Typography>
                                                        <b>ResourceTags:</b>
                                                    </Typography>

                                                    <Typography className="typoMargin">
                                                        <b>Region:</b>
                                                    </Typography>

                                                    <Typography className="typoMargin">
                                                        <b>ProductName:</b>
                                                    </Typography>

                                                    <Typography className="typoMargin">
                                                        <b>BillType:</b>
                                                    </Typography>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
                                                    <Typography>{showingDetails?.ResourceTags || "-"}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.Region}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.ProductName}</Typography>
                                                    <Typography className="typoMargin">{showingDetails?.BillType || "-"}</Typography>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider />
                                        <Box padding={1}>{showingDetails?.meterLevel && <Metertable data={showingDetails?.meterLevel} currency={currency} />}</Box>
                                        <Divider />
                                        <Box padding={2}>
                                            <MatricLevelChart customerId={customerId} bucketName={showingDetails?.ResourceId} tenantId={showingDetails?.UsageAccountId} bucketRegion={showingDetails?.Region} />
                                        </Box>
                                    </Paper>
                                </Box>
                            </Box>
                            <TablePagination ActionsComponent={TablePaginationActions} rowsPerPageOptions={[10, 25, 50, 75]} component="div" count={list?.filter(filteredTable)?.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
                        </Paper>
                    </div>
                ) : (
                    <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                )}
            </div>
        </div>
    );
}

function Metertable({ data, currency }) {
    return (
        <div>
            <Typography style={{ fontWeight: "bold" }}>Meter data</Typography>
            <div style={{ maxHeight: 300 }} class="overflow-auto">
                <table id="customers">
                    <tr>
                        <th>Operation</th>
                        <th>Product family</th>
                        <th>Usage type</th>
                        <th>Description</th>
                        <th>Cost</th>
                    </tr>

                    {data &&
                        data.meter.map((m) => (
                            <tr>
                                <td>{m.Operation}</td>
                                <td>{m.ProductFamily}</td>
                                <td>{m.LineItemUsageType}</td>
                                <td>{m.LineItemDescription}</td>
                                <td>{Format.formatCurrencySymbol(currency) + Format.formatNumber(Number(m["cost"]))}</td>
                            </tr>
                        ))}
                </table>
            </div>
        </div>
    );
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
    root: {
        padding: theme.spacing(1.5),
    },
}))(TableCell);

const useRowStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            padding: theme.spacing(1.5),
        },
    },
    table: {
        // minWidth: 700,
    },
    container: {
        maxHeight: 600,
    },
    link: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));
