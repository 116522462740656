import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import Calendar_Spend_Icon from "../../images/calendar_months.svg";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {PauseCircleFilled, PlayCircleFilled} from "@material-ui/icons";
import CalendarLoader from "../../components/loaders/CalendarLoader";
import YearTableData from "../../components/YearTableData";
import Format from "../../components/NumberFormat";
import YearCalendarData, {CalendarDataProcessor, Months} from "../../components/YearCalendarData";
import {OverlayComponent} from "../../components/tab/AzureHomeTab";
import aws_account from "../../images/aws_org.svg";
import azure_subscription from "../../images/key.svg";
import gcp_billing_account from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import aws_region from "../../images/aws_region.svg";
import azure_location from "../../images/azure_location.svg";
import cloud_location from "../../images/cloud_location.png";
import resource_group from "../../images/resource_group.svg";
import gcp_compute from "../../images/gcp_compute.svg";
import azure_resource_group from "../../images/azure_resource_group.svg";
import eye_icon from "../../images/eye_icon.svg";
import GroupedBarChart from "../../components/D3Charts/GroupedBarChart";
import {Box} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {TIMERS} from "../../util/AppConstants";
import {getMonthsFromNow} from "../../util/DateFormatter";
import {arraySaveToCSV} from "../../util/Miscellaneous";
const $ = window.$;

export default function AllCloudBillingTab({somerefs, cloudCount, calendarLoading, yearTableData, monthWiseData, currency, yearWiseData, getAll1YearMonthlyData = ()=>{}, cloudIcons: CLOUD_ICONS}){
    const [overlayData, setOverlayData] = useState(null);
    const [currentCloud, setCurrentCloud] = useState("total");
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [monthInterval, setMonthInterval] = useState(null);
    const [yearToDateBillingCost, setYearToDateBillingCost] = useState(null);
    const [yearToDateBillingChart, setYearToDateBillingChart] = useState([]);
    const [parentWidth, setParentWidth] = useState(null);

    const billingComponentRef = useRef();

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    useEffect(()=>{
        const legends = {
            'all': 'All cloud',
            'azure': 'Azure',
            'aws': 'AWS',
            'gcp': 'GCP',
        }
        if(yearToDateBillingCost) {
            setYearToDateBillingChart([
                {
                    dataLabel: "YTD 2023",
                    values: yearToDateBillingCost?.ytd?.list?.map(({cost: value, cloud: legend}) => ({value, legend: legend != 'all'?legends[legend]:null}))
                        .filter(l=>l.legend!=null)
                },
                // {
                //     dataLabel: "2021",
                //     values: yearToDateBillingCost?.otherYear?.list?.map(({cost: value, cloud: legend}) => ({value, legend: legend != 'all'?legends[legend]:null}))
                //         .filter(l=>l.legend!=null)
                // },
                ...Object.keys(yearToDateBillingCost?.quarter).map(quarter=>({
                    dataLabel: "Q" + quarter + " " + yearToDateBillingCost?.quarter[quarter]?.year,
                    values: yearToDateBillingCost?.quarter[quarter]?.list?.map(({cost: value, cloud: legend}) => ({value, legend: legend != 'all'?legends[legend]:null}))
                        .filter(l=>l.legend!=null)
                }))
            ])
        }
    }, [yearToDateBillingCost])

    useEffect(()=>{
        getYearToDateCost();
    }, [yearTableData]);

    useEffect(()=>{
        onResume();
    }, [])

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;
            // console.log(awsAccountList);
            // debugger
            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear, currentCloud]);

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData]);

    function getMonths() {
        // const months = new Array();
        const azureMonths = yearTableData?.azure?.SpendByMonth?.map((m) => m.month) || [];
        const awsMonths = yearTableData?.aws?.SpendByMonth?.map((m) => m.month) || [];
        const gcpMonths = yearTableData?.gcp?.SpendByMonth?.map((m) => m.month) || [];
        // azureMonths.length
        // if (azureMonths.length >= 10) {
        //     return azureMonths;
        // } else if (awsMonths.length >= 10) {
        //     return awsMonths;
        // } else if (gcpMonths.length >= 10) {
        //     return gcpMonths;
        // } else {
        //     return [];
        // }
        return new Array(13).fill(null);
        // Object.keys(yearTableData).forEach(key=>{
        //   months.push(yearTableData?.[key]?.SpendByMonth?.map(m=>m.month));
        // })
        // console.log(months)
    }

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByDaily?.find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByMonth?.find((data) => data.month == y.toString() + m.toString());
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
            if (currentCloud == "total") {
                const current = moment(currentYear + currentMonth || 0, "YYYYMMMM");
                date = moment(current).isValid() ? current.format("MMMM YYYY") : null;
            }
        }
        return {data, date};
    }

    function onResume() {
        setCurrentCloud("total");
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    async function onMonthSelect(m, y = null, cloud) {
        onPause();
        if (cloud) setCurrentCloud(cloud);
        if (monthWiseData == null || !monthWiseData) {
            await getAll1YearMonthlyData("daily")
                .then(() => setCurrentMonth(m))
                .finally(() => false);
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }
        if (y) setCurrentYear(y);
    }

    const onPrint = useReactToPrint({
        content: () => billingComponentRef.current,
    });

    function getYearToDateCost() {
        const allCost = {
            ytd: {
                list: [],
            },
            otherYear: {
                list: [],
            },
            quarter: {
                1: {
                    list: [],
                },
                2: {
                    list: [],
                },
                3: {
                    list: [],
                },
                4: {
                    list: [],
                },
            },
        };
        const clouds = Object.keys(yearTableData);
        const currentYear = new Date().getFullYear();
        // console.log({quarter: yearTableData})
        clouds.forEach((cloud) => {
            let cost = yearTableData?.[cloud]?.SpendByMonth?.filter((s) => s.month.toString().startsWith(currentYear));

            // let temp = Object.assign(
            //     [],
            //     cost?.sort((a, b) => parseInt(a) - parseInt(b))
            // );
            // let temp = JSON.parse(JSON.stringify(cost));
            let costOth = yearTableData?.[cloud]?.SpendByMonth?.filter((s) => !s.month.toString().startsWith(currentYear));
            const otherYearMonths = getMonthsFromNow(months, false,true).filter((s) =>s.year != currentYear);
            costOth = otherYearMonths.map(m=>{
                return costOth.find(c=>moment(c?.month, "YYYYMM").isSame(moment(m.month + m.year, "MMMYYYY")))
            }).filter(f=>f!=null && f!=undefined)?.sort((a,b)=>a.month-b.month)
            const start = moment(cost?.[0]?.month, "YYYYMM").format("MMM");
            const end = moment(cost?.[cost?.length - 1]?.month, "YYYYMM").format("MMM");

            const startOth = moment(costOth?.[0]?.month, "YYYYMM").format("MMM");
            const endOth = moment(costOth?.[costOth?.length - 1]?.month, "YYYYMM").format("MMM");


            // 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11

            var quarters = {};
            const quarterRange = {
                0: [1, 3], 1: [4, 6], 2: [7, 9], 3: [10, 12]
            }
            let quarterlyCost = yearTableData?.[cloud]?.SpendByMonth
            // ?.filter((s) => s.month.toString().startsWith(currentYear));
            var currentQuarter = parseInt(moment().month() / 3);
            var lastQuarter = currentQuarter;
            var selectedYear = new Date().getFullYear();

            // quarterlyCost.filter(s=>s.month.toString().startsWith(selectedYear));
            var availableCurrentYear = quarterlyCost.filter(s=>s.month.toString().startsWith(selectedYear));
            let currentYearQuarters = 0;
            while(currentQuarter>-1){
                currentYearQuarters++;
                const range = quarterRange[currentQuarter];
                quarters[currentQuarter] = availableCurrentYear.filter(s=>parseInt(s.month.toString().substr(4,5))>=range[0] && parseInt(s.month.toString().substr(4,5))<=range[1]);
                currentQuarter--;
            }
            currentQuarter = currentYearQuarters;

            if(currentQuarter>-1){
                availableCurrentYear = quarterlyCost.filter(s=>s.month.toString().startsWith(selectedYear-1));
                while(currentQuarter<4){
                    if(!(currentQuarter in quarters)){
                        const range = quarterRange[currentQuarter];
                        quarters[currentQuarter] = availableCurrentYear.filter(s=>parseInt(s.month.toString().substr(4,5))>=range[0] && parseInt(s.month.toString().substr(4,5))<=range[1]);
                    }
                    currentQuarter++;

                }
            }

            // let temp = Object.assign(
            //     [],
            //     quarterlyCost?.sort((a, b) => parseInt(a) - parseInt(b))
            // );

            // const quarter1 = temp.reverse()?.splice(0, 3);
            // const quarter2 = temp?.splice(0, 3);
            // const quarter3 = temp?.splice(0, 3);
            // const quarter4 = temp;

            allCost.quarter["1"].year = allCost?.quarter["1"]?.year || quarters?.[0]?.[0]?.month.toString().substr(0,4) || currentYear - 1;
            allCost.quarter["1"].start = 0;
            allCost.quarter["1"].end = 0;
            allCost.quarter["1"].list.push({
                cost: quarters?.[0]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[0]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[0]?.[quarters?.[0].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.quarter["2"].year = allCost?.quarter["2"]?.year || quarters?.[1]?.[0]?.month.toString().substr(0,4) || currentYear - 1;
            allCost.quarter["2"].start = start;
            allCost.quarter["2"].end = end;
            allCost.quarter["2"].list.push({
                cost: quarters?.[1]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[1]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[1]?.[quarters?.[1].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.quarter["3"].year = allCost?.quarter["3"]?.year || quarters?.[2]?.[0]?.month.toString().substr(0,4)  || currentYear - 1;
            allCost.quarter["3"].start = start;
            allCost.quarter["3"].end = end;
            allCost.quarter["3"].list.push({
                cost: quarters?.[2]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[2]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[2]?.[quarters?.[2].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.quarter["4"].year = allCost?.quarter["4"]?.year || quarters?.[3]?.[0]?.month.toString().substr(0,4) || currentYear - 1;
            allCost.quarter["4"].start = start;
            allCost.quarter["4"].end = end;
            allCost.quarter["4"].list.push({
                cost: quarters?.[3]?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                start: moment(quarters?.[3]?.[0]?.month, "YYYYMM").format("MMM"),
                end: moment(quarters?.[3]?.[quarters?.[3].length - 1]?.month, "YYYYMM").format("MMM"),
                cloud,
            });

            allCost.ytd.year = currentYear;
            allCost.ytd.start = start;
            allCost.ytd.end = end;
            allCost.ytd.list.push({
                cost: cost?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                cloud,
            });

            const filteredOther = costOth?.filter((f) => moment(f.month, "YYYYMM").isValid());

            allCost.otherYear.start = startOth;
            allCost.otherYear.end = endOth;
            allCost.otherYear.list.push({
                cost: costOth?.map((a) => parseFloat(a?.cost)).reduce((a, b) => a + b, 0),
                cloud,
            });
            if (filteredOther && filteredOther.length) allCost.otherYear.year = moment(filteredOther?.[0]?.month, "YYYYMM").year();
        });

        setYearToDateBillingCost(allCost);
        return allCost;
    }

    window.$(document).ready(rootLoaded);

    function rootLoaded() {
        if (parentWidth) return;
        const e = window.$("#root_dashboard");
        try {
            setParentWidth(e.width());
        } catch (e) {}
    }

    useEffect(() => {
        window.$(document).ready(() => {
            rootLoaded();
            // console.log((document.querySelector('#root_dashboard')));
        });
    }, []);

    function onCSVSave(){
        const csvData = $('#allcloudbillingtable1').table2csv('json', {
            separator: "###",
            appendHeaders: ['Cloud', 'azure', 'aws', 'gcp', 'all cloud'],
            removeColumns: 2
        });
        arraySaveToCSV(csvData, 'all_cloud_billing.csv');
    }

    useEffect(()=>{
        const $ = window.$;
        $(document).ready(()=>{
            const final = $('#final-spend-by-quarter');
            const prev = $('#spend-by-quarters-all-cloud');
            prev.parent().find('br').remove();
            final.append('<br/>');
            prev.appendTo(final);
        })
    }, [])

    return (
        <div>
            <div onLoad={rootLoaded} id={"root_dashboard"}><span></span></div>
            <Box ref={billingComponentRef}>
            <ContainerCardWithDateRange showDateRange={false} title={'Spend by months'} titleIcon={Calendar_Spend_Icon}
                                        someref={somerefs[0]}
                                        HeaderTitleComponent={(e,setCollapseClickable)=>
                                            <>
                                                <div style={{display: 'flex', alignItems: 'center',
                                                    justifyContent: 'center', marginLeft: 10}}>
                                                    <span>Spend by months</span>
                                                    <div style={{marginLeft: 5}} onMouseOver={()=>setCollapseClickable(false)} onMouseOut={()=>setCollapseClickable(true)}>
                                                        {yearTableData && (yearTableData?.aws || yearTableData?.azure || yearTableData?.gcp)?
                                                            (monthInterval ?
                                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                                    <PauseCircleFilled style={{color: "#0000008a"}} onClick={() => onPause()}/>
                                                                </OverlayTrigger>:
                                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                                    <PlayCircleFilled style={{color: "#0000008a"}}  onClick={() => onResume()}/>
                                                                </OverlayTrigger>):
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </>
                                        }
                saveAsCSV={true}
                onSaveAsCSV={onCSVSave}
            >
                {()=><div style={{}}>
                    {calendarLoading ?
                        <CalendarLoader/> :
                        <>
                            <div style={{}}>
                                <hr style={{padding: 0, margin: 0}}/>
                                <div style={{overflow: "hidden", zIndex: 20}}>
                                    {parentWidth && (
                                        <YearTableData
                                            width={parentWidth}
                                            showTotal={true}
                                            data={{
                                                // azure: yearTableData?.azure?.SpendByMonth?.map(c=>c.cost),
                                                // aws: yearTableData?.aws?.SpendByMonth?.map(c=>c.cost),
                                                // gcp: yearTableData?.gcp?.SpendByMonth?.map(c=>c.cost),
                                                azure: yearTableData?.azure?.SpendByMonth,
                                                aws: yearTableData?.aws?.SpendByMonth,
                                                gcp: yearTableData?.gcp?.SpendByMonth,
                                                total: yearTableData?.all?.SpendByMonth,
                                                // aws: [3000, 500,100,12,9011,982,1,23,11,2,3,1,2329],
                                                // gcp: [3000, 500,100,132,981,342,1764,231,761,3422,3,1, 900]
                                            }}
                                            selectedCloud={currentCloud}
                                            onMonthPress={onMonthSelect}
                                            months={getMonths()}
                                            selectedMonth={currentMonth}
                                            selectedYear={currentYear}
                                            currency={Format.formatCurrencySymbol(currency || "USD")}
                                            tableId={'allcloudbillingtable1'}
                                            // getAsCSV={(e)=>setAll1YCSVData(e)}
                                            // data={yearTableData}
                                        />
                                    )}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    marginTop: 8,
                                    flexDirection: "row",
                                    justifyContent: "center",
                                }}
                            >
                                <YearCalendarData
                                    style={{flex: 1}}
                                    currentCloud={currentCloud}
                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                    year={currentYear}
                                    onNext={() => setCurrentYear(currentYear + 1)}
                                    onPrev={() => setCurrentYear(currentYear - 1)}
                                    yearData={CalendarDataProcessor(yearWiseData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByMonth, "year")}
                                    monthData={CalendarDataProcessor(monthWiseData?.[currentCloud == "total" ? "all" : currentCloud]?.SpendByDaily, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                    onMonthSelect={onMonthSelect}
                                    month={currentMonth}
                                    renderOverlay={(d, m, y, cost) => {
                                        const {data, date} = getOverlayData(d, m, y);

                                        if (data) return setOverlayData({data, date, cost: data?.cost});
                                        return null;
                                    }}
                                />
                                <div
                                    style={{
                                        marginLeft: 10,
                                        width: 500,
                                        height: 400,
                                        display: "flex",
                                    }}
                                >
                                    {overlayData && overlayData.data && (
                                        <OverlayComponent
                                            showArrows={false}
                                            cost={overlayData?.cost}
                                            data={overlayData?.data}
                                            date={overlayData?.date}
                                            headerIcon={CLOUD_ICONS[currentCloud]}
                                            dataKeys={[
                                                {
                                                    key: currentCloud == "gcp" ? "billingAccounts" : "subscriptions",
                                                    title: {
                                                        total: "Top Accounts",
                                                        azure: "Top Azure Subscriptions",
                                                        aws: "Top AWS Accounts",
                                                        gcp: "Top GCP Billing Accounts",
                                                    }[currentCloud],
                                                    subKey: currentCloud == "gcp" ? "billingAccountName" : "subscriptionName",
                                                    subKey2: currentCloud == "gcp" ? "billingAccountId" : "subscriptionId",
                                                    onMouseOverSet: {
                                                        all: "subscriptionId",
                                                        azure: "subscriptionId",
                                                        aws: "subscriptionId",
                                                        // gcp: "subscriptionName",
                                                        // currentCloud == 'gcp' ? "billingAccountId" : null
                                                    },
                                                    iconSet: {
                                                        all: aws_account,
                                                        azure: azure_subscription,
                                                        aws: aws_account,
                                                        gcp: gcp_billing_account,
                                                    },
                                                    icon:
                                                        currentCloud != "total"
                                                            ? {
                                                                all: aws_account,
                                                                azure: azure_subscription,
                                                                aws: aws_account,
                                                                gcp: gcp_billing_account,
                                                            }[currentCloud]
                                                            : null,
                                                },
                                                currentCloud == "gcp"
                                                    ? {
                                                        key: "projects",
                                                        title: "Top Projects",
                                                        subKey: "projectId",
                                                        icon: gcp_project,
                                                    }
                                                    : null,
                                                {
                                                    key: "locations",
                                                    title: {
                                                        total: "Top Locations",
                                                        azure: "Top Azure Locations",
                                                        aws: "Top AWS Locations",
                                                        gcp: "Top GCP Locations",
                                                    }[currentCloud],
                                                    subKey: "location",
                                                    iconSet: {
                                                        all: aws_region,
                                                        azure: azure_location,
                                                        aws: aws_region,
                                                        gcp: cloud_location,
                                                    },
                                                    icon:
                                                        currentCloud != "total"
                                                            ? {
                                                                all: aws_region,
                                                                azure: azure_location,
                                                                aws: aws_region,
                                                                gcp: cloud_location,
                                                            }[currentCloud]
                                                            : null,
                                                },
                                                {
                                                    key: "services",
                                                    title: {
                                                        total: "Top Services",
                                                        azure: "Top Azure Services",
                                                        aws: "Top AWS Services",
                                                        gcp: "Top GCP Services",
                                                    }[currentCloud],
                                                    subKey: "service",
                                                    subKey2: "serviceCode",
                                                    iconSet: {
                                                        all: resource_group,
                                                        azure: resource_group,
                                                        aws: resource_group,
                                                        gcp: gcp_compute,
                                                    },
                                                    icon:
                                                        currentCloud != "total"
                                                            ? {
                                                                all: resource_group,
                                                                azure: resource_group,
                                                                aws: resource_group,
                                                                gcp: gcp_compute,
                                                            }[currentCloud]
                                                            : null,
                                                },
                                                currentCloud == "azure"
                                                    ? {
                                                        key: "resourceGroups",
                                                        title: "Top Azure Resource groups",
                                                        subKey: "resourceGroupName",
                                                        icon: azure_resource_group,
                                                    }
                                                    : null,
                                                {
                                                    key: "resources",
                                                    title: {
                                                        total: "Top Resources",
                                                        azure: "Top Azure Resources",
                                                        aws: "Top AWS Resources",
                                                        gcp: "Top GCP Resources",
                                                    }[currentCloud],
                                                    subKey: currentCloud == "gcp" ? "skuId" : "resourceId",
                                                    // onMouseOver: currentCloud=='gcp'?"billingAccountId":null,
                                                    iconSet: {
                                                        all: resource_group,
                                                        azure: resource_group,
                                                        aws: resource_group,
                                                        gcp: resource_group,
                                                    },
                                                    icon:
                                                        currentCloud != "total"
                                                            ? {
                                                                all: resource_group,
                                                                azure: resource_group,
                                                                aws: resource_group,
                                                                gcp: resource_group,
                                                            }[currentCloud]
                                                            : null,
                                                },
                                            ]}
                                            cloud={currentCloud}
                                            currency={Format.formatCurrencySymbol(currency)}
                                            // onClick={()=>{
                                            //   setValue(TAB_SET.SERVICES);
                                            //   props?.setActiveTab(TAB_SET.SERVICES);
                                            // }}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    }
                </div>}
            </ContainerCardWithDateRange>
            <br/>
            <ContainerCardWithDateRange showDateRange={false}
                                        someref={somerefs[1]}
                                        title={'Spend by quarters'}
                                        titleIcon={eye_icon}
                                        id={'spend-by-quarters-all-cloud'}
                // saveAsCSV={true}
                // onSaveAsCSV={onSaveAsCSV}
            >
                {()=>yearToDateBillingCost && yearToDateBillingCost?.ytd?.list?.length ?
                    <>
                        <GroupedBarChart
                            maxWidth={null}
                            currency={Format.formatCurrencySymbol(currency)}
                            totalCost={null}
                            // mouseEffect={true}
                            data={yearToDateBillingChart}
                            cloudCount={3 || cloudCount}
                            largeFont = {true}
                        />
                        {/*<YearToDateBilling data={yearToDateBillingCost}/>*/}
                    </>: null}
            </ContainerCardWithDateRange>
        </Box>
        </div>
    )
}
