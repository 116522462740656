import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    Fade,
    Paper,
    Popper,
    Table,
    TableBody,
    TableCell as TCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMonthsFromNow } from "../../util/DateFormatter";
import AZURE_LOGO from "../../images/Azure_logo.svg";
import AWS_LOGO from "../../images/aws_dark.svg";
import GCP_LOGO from "../../images/GCP_logo.svg";
import COMBINED_CLOUD_LOGO from '../../images/allCloudLogo.svg';
import moment from "moment";

import Cost, { Currency } from "../../components/Cost";
import {ALPHA_NUM_SORTER, isNumeric} from "../../util/Miscellaneous";
import TableSortHeader from "../../components/TableSortHeader";
import {TABLE_SORT} from "../../util/AppConstants";
import Format from "../../components/NumberFormat";
import color from "../../styles/color";
import {OverlayTrigger, Popover, Tooltip} from "react-bootstrap";
import CalendarLoader from "../../components/loaders/CalendarLoader";
import MonthlySpendTableLoader from "../../components/loaders/MonthlySpendTableLoader";


import ApiManagement_logo from "../../images/api-management-services.png";
import AzureCosmosDB_logo from "../../images/azure-cosmos-db.png";
import FunctionApps_logo from "../../images/function-apps.png";
import LogAnalytics_logo from "../../images/Log-Analytics-Workspaces.svg";
import StorageAccounts_logo from "../../images/Storage-Accounts.svg";
import ApplicationInsights_logo from "../../images/application-insights.png";
import AppService from "../../images/App-Services.svg";
import Function_logo from "../../images/function.png";
import big_query from "../../images/gcpResources/gcp_bigquery.svg";
import ComputeIcon from "../../images/Virtual-Machine.svg";



function TableCell(props) {
    const {extras = {}} = props;
    return (
        <TCell {...props} style={{ ...(props.style?props.style:{}), padding: 5, ...extras }}>
            {props.children}
        </TCell>
    );
}

const CLOUD_ICONS = {
    azure: AZURE_LOGO,
    aws: AWS_LOGO,
    gcp: GCP_LOGO,
    all: COMBINED_CLOUD_LOGO,
};
const useStyles2 = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    head: {
        backgroundColor: "",
        minWidth: "50px",
    },
    tableContainer: {
        maxHeight: "400px",
    },
    cell: {
        minWidth: "100px",
    },
}));

const StickyTableCell = withStyles((theme) => ({
    head: {
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 2,
    },
    body: {
        // backgroundColor: "#ddd",
        backgroundColor: "",
        minWidth: "50px",
        left: 0,
        position: "sticky",
        zIndex: theme.zIndex.appBar + 1,
    },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
    head: {
        border: "1px solid #98989833",
        borderTopWidth: "0px",
        borderLeftWidth: "0px",
    },
    body: {
        border: "1px solid #98989833",
        borderTopWidth: "0px",
        borderBottomWidth: "0px",
        borderLeftWidth: "0px",
        fontSize: 14,
    },
}))(TableCell);

const TABLE_SORT_DEFAULT_COLUMN = 'month 0';

export default function TableMonthlySpend({
                                          hideLegend = false,
                                          width = '100%',
                                          data = null,
                                          staticRows = null,
                                          months = [],
                                          currency,
                                          showTotal = false,
                                          onMouseOver = () => {},
                                          renderOverlay = () => null,
                                          onMonthPress = () => null,
                                          selectedYear = "",
                                          selectedMonth = "",
                                          selectedCloud = "",
                                          tableLabel ='',
                                          subscriptionNameAsOverlay = false,
                                          showCloud = true,
                                          subscriptionLevel=false,
                                              loading = false,
                                              cols = 1
                                      }) {
    const [monthsList, setMonthsList] = useState([]);
    const classes = useStyles2();
    const [allCloudMonthWiseTotal, setAllCloudMonthWiseTotal] = useState([]);
    let [yearWiseTotal, setYearWiseTotal] = useState(null);
    let [finalData, setFinalData] = useState({});

    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_DESC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    const cloudColumn = showCloud?{ numeric:0, id:'cloud', label: 'Cloud'}:'null_column';

    // if (width ) {
        return (
            <TableContainer component={Paper} style={{ width }}>
                <Table size={'small'}
                       bordered
                       stickyHeader
                       className={classes.table}
                       aria-label="simple table"
                >
                    <>
                        <TableRow style={{backgroundColor: color.tableHeader,}}>
                            {!hideLegend && (
                                <>
                                    <TableSortHeader
                                        classes={classes}
                                        numSelected={0}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={data?.length}
                                        align={'center'}
                                        headCells={[
                                            cloudColumn,
                                            { numeric:0, id:'account', label: tableLabel},
                                            ...getMonthsFromNow(months).reverse().map((o, i) => ({
                                                numeric:1, id:'month '+i, label: o
                                            }))
                                        ].filter(f=>f!='null_column')}
                                    />
                                {/*<StickyTableCell>*/}
                                {/*    <TableCell*/}
                                {/*        style={{*/}
                                {/*            borderBottomWidth: 0,*/}
                                {/*            padding: 4,*/}
                                {/*            fontWeight: "bold",*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Cloud*/}
                                {/*    </TableCell>*/}
                                {/*</StickyTableCell>*/}
                                {/*<StickyTableCell>*/}
                                {/*    <TableCell*/}
                                {/*        style={{*/}
                                {/*            borderBottomWidth: 0,*/}
                                {/*            padding: 4,*/}
                                {/*            fontWeight: "bold",*/}
                                {/*        }}*/}
                                {/*    >*/}
                                {/*        Account*/}
                                {/*    </TableCell>*/}
                                {/*</StickyTableCell>*/}
                                </>
                            )}
                            {/*{getMonthsFromNow(months)*/}
                            {/*    .reverse()*/}
                            {/*    .map((o, i) => (*/}
                            {/*        <TableCell*/}
                            {/*            align={"center"}*/}
                            {/*            className={classes.head}*/}
                            {/*            numeric*/}
                            {/*            style={{*/}
                            {/*                padding: 0,*/}
                            {/*                backgroundColor: i % 2 ? "" : "",*/}
                            {/*                borderRightWidth: 1,*/}
                            {/*                fontWeight: "bold",*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            {o}*/}
                            {/*        </TableCell>*/}
                            {/*    ))}*/}
                        </TableRow>
                    </>
                    {loading?
                        <TableBody>
                            <TableRow>
                                <td colSpan={cols}>
                                <MonthlySpendTableLoader width={width} height={.45 * window.innerHeight }/>
                                </td>
                            </TableRow>
                        </TableBody>
                        :<TableBody>
                    {staticRows && staticRows.map((row, index)=>
                        (<TableRow key={row.subscriptionId + index} style={{backgroundColor: '#73e96bb8'}}>
                    {!hideLegend && (
                        <>
                    {showCloud && <StickyTableCell bordered border
                        extras={{
                        padding: 0,
                        // border: '1px 1px 1px 1px solid black'
                    }}
                        style={{
                        padding: 0,
                    }}>
                        <StyledTableCell
                        component="th"
                        numeric
                        style={{
                        // borderRight: ,
                        display: "flex",
                        alignItems: "center",

                        justifyContent: "center",
                        // backgroundColor: row === "total" ? "" : "",
                        color: row === "total" ? "" : "",
                    }}
                        extras={{padding: 0}}
                        >
                        <div>
                        <img
                        height={20}
                        src={CLOUD_ICONS[row.cloud]}
                        //   style={{ marginLeft: 16 }}
                        />
                        </div>
                        </StyledTableCell>
                        </StickyTableCell>}
                        <StickyTableCell bordered border
                        extras={{padding: 0, border: '1px 1px 1px 1px solid black'}}
                        style={{
                        padding: 0,
                    }}>
                        <StyledTableCell
                        component="th"
                        numeric
                        style={{
                        // borderRight: ,
                        display: "flex",
                        alignItems: "center",

                        justifyContent: "left",
                        backgroundColor: row === "total" ? "" : "",
                        color: row === "total" ? "" : "",
                    }}
                        extras={{padding: 0, paddingLeft: 5}}
                        >
                        <span style={{whiteSpace: 'nowrap'}}>
                    {row.subscriptionId?.replace(/\//g,"")?.toString().substr(0, 21) + (row.subscriptionId?.replace(/\//g,"")?.toString().length > 21 ? "..." : "")}
                        </span>
                        </StyledTableCell>
                        </StickyTableCell>
                        </>
                        )}
                    {row.list.map((o, i) => (
                        <>
                        <CostCell
                        style={classes.head}
                        year={monthsList?.[i]?.year}
                        month={monthsList?.[i]?.month}
                        onMonthPress={onMonthPress}
                        renderOverlay={renderOverlay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        row={row}
                        cloud={row.cloud?.toLowerCase() || ""}
                        selectedCloud={selectedCloud}
                        >
                    {
                        isNumeric(o?.cost) ?
                        (0.01 > parseFloat(o.cost) && parseFloat(o.cost) > 0) ?
                        "<" + (currency) + "0.01" :
                        currency + Format.formatNumber(o.cost) : "-"
                    }
                        </CostCell>
                        </>
                        ))}
                        </TableRow>))}
                    {data
                        ?.sort((r1, r2) => {
                        let fi =  ({
                        cloud:  ALPHA_NUM_SORTER.compare(r1?.cloud,r2?.cloud),
                        account:  ALPHA_NUM_SORTER.compare(r1?.subscriptionId,r2?.subscriptionId)
                    });
                        getMonthsFromNow(months).reverse().forEach((o, i) =>{
                        fi['month '+ i] = (isNumeric(r1.list[i]?.cost)?r1.list[i]?.cost:0) - (isNumeric(r2.list[i]?.cost)?r2.list[i]?.cost:0);
                    })
                        return fi[orderBy] * order;
                    })
                        ?.map((row, index) =>
                        data?.length ? (
                        <TableRow key={row.subscriptionId + index} style={{backgroundColor: index %2!=0?"#cfdac875":""}}>
                    {!hideLegend && (
                        <>
                    {showCloud && <StickyTableCell bordered border extras={{
                        padding: 0,
                        border: '1px 1px 1px 1px solid black'
                    }} style={{
                        padding: 0,
                    }}>
                        <StyledTableCell
                        component="th"
                        numeric
                        style={{
                        // borderRight: ,
                        display: "flex",
                        alignItems: "center",

                        justifyContent: "center",
                        backgroundColor: row === "total" ? "" : "",
                        color: row === "total" ? "" : "",
                    }}
                        extras={{padding: 0}}
                        >
                        <div>
                        <img
                        height={20}
                        src={CLOUD_ICONS[row.cloud]}
                        //   style={{ marginLeft: 16 }}
                        />
                        </div>
                        </StyledTableCell>
                        </StickyTableCell>}
                        <StickyTableCell bordered border extras={{padding: 0, border: '1px 1px 1px 1px solid black'}} style={{padding: 0,
                    }}>
                        <StyledTableCell
                        component="th"
                        numeric
                        style={{
                        // borderRight: ,
                        display: "flex",
                        alignItems: "center",

                        justifyContent: "left",
                        backgroundColor: row === "total" ? "" : "",
                        color: row === "total" ? "" : "",
                    }}
                        extras={{padding: 0, paddingLeft: 5}}
                        >
                    {
                        (row?.cloud == 'gcp' && subscriptionNameAsOverlay)?
                        <span style={{whiteSpace: 'nowrap'}}>
                    {
                        (row?.subscriptionId?.replace(/\//g,""))?
                        row.subscriptionId?.replace(/\//g,"")?.toString().substr(0, 20) + (row.subscriptionId?.replace(/\//g,"")?.toString().length > 20 ? "..." : ""):
                        <span>unassigned</span>
                    }
                        </span>:
                        subscriptionNameAsOverlay?
                        <OverlayTrigger placement="right"
                        overlay={
                        <Popover id="popover-contained">
                        <Popover.Content style={{fontSize: 11}}>
                    {row?.name}
                        </Popover.Content>
                        </Popover>
                    }
                        >
                        <span style={{whiteSpace: 'nowrap'}}>
                    {
                        (row?.subscriptionId?.replace(/\//g,""))?
                        (row.subscriptionId?.replace(/\//g,"")?.toString().substr(0, 20) + (row.subscriptionId?.replace(/\//g,"")?.toString().length > 20 ? "..." : "")):
                        <span>unassigned</span>
                    }
                        </span>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="right"
                        overlay={
                        <Popover id="popover-contained" hidden={subscriptionLevel && row.subscriptionId?.replace(/\//g,"")?.toString().length <= 20}>
                        <Popover.Content style={{fontSize: 11}}>
                    {
                        (row.subscriptionId?.replace(/\//g,"")?.toString().length > 20)?
                        subscriptionLevel?
                        <span>
                    {row?.subscriptionId?.replace(/\//g,"")}
                        </span>:
                        <span>
                    {row?.subscriptionId?.replace(/\//g,"")}
                        <br/>
                        <b>Subscription</b>
                        <br/>
                    {row?.subscriptionDetails?.name}
                        <br/>
                    {row?.subscriptionDetails?.id}
                        </span>:
                        <span>
                        <b>Subscription</b>
                        <br/>
                    {row?.subscriptionDetails?.name}
                        <br/>
                    {row?.subscriptionDetails?.id}
                        <br/>
                        </span>
                    }
                        </Popover.Content>
                        </Popover>
                    }
                        >
                        <span style={{whiteSpace: 'nowrap'}}>
                    {
                        (row?.subscriptionId?.replace(/\//g,""))?
                        (row.subscriptionId?.replace(/\//g,"")?.toString().substr(0, 20) + (row.subscriptionId?.replace(/\//g,"")?.toString().length > 20 ? "..." : "")):
                        <span>unassigned</span>
                    }
                        </span>
                        </OverlayTrigger>
                    }
                        </StyledTableCell>
                        </StickyTableCell>
                        </>
                        )}
                    {row.list.map((o, i) => (
                        <>
                        <CostCell
                        style={classes.head}
                        year={monthsList?.[i]?.year}
                        month={monthsList?.[i]?.month}
                        onMonthPress={onMonthPress}
                        renderOverlay={renderOverlay}
                        selectedMonth={selectedMonth}
                        selectedYear={selectedYear}
                        row={row}
                        cloud={row.cloud?.toLowerCase() || ""}
                        selectedCloud={selectedCloud}
                        >
                    {
                        isNumeric(o?.cost) ?
                            (0.01 > parseFloat(o.cost) && parseFloat(o.cost) > 0) ?
                                "<" + (currency) + "0.01" :
                                currency + Format.formatNumber(o.cost) : "-"
                    }
                        </CostCell>
                        </>
                        ))}
                        </TableRow>
                        ) : null
                        )}
                        </TableBody>}
                </Table>
            </TableContainer>
        );
    // }
    return null;
}

function CostCell({
                      row = "",
                      children,
                      style,
                  }) {

    return (
        <>
            <StyledTableCell
                style={{
                    border: '0.5px solid #e5e5e57a',
                    backgroundColor: row === "total" ? "" : "",
                    padding: 0, fontSize: 14, margin: 0
                }}
                align="center"
                className={style}
                numeric
            >
                    {children}
            </StyledTableCell>
        </>
    );
}
