import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Typography, IconButton, Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress, Divider } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import Format from "components/NumberFormat";
import TablePaginationActions from "../TablePaginationActions";
import TableSearch from "../../components/TableSearch";

import colors from "../../styles/color";
import {OverlayTrigger, Popover} from "react-bootstrap";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            borderBottom: "unset",
            padding: 3.5,
        },
    },
    table: {
        minWidth: 700,
    },
    container: {
        // maxHeight: 440,
    },
    container1: {
        maxHeight: 400,
    },
});

export default function AzureTagsTable({ period, data, currency, headerData, loading, progress, setTotalParent = ()=>{}, selectedTag, subscriptionLevel }) {
    const classes = useRowStyles();
    const [total, setTotalCost] = useState();
    const [totalSubscription, setTotalSubscription] = useState();
    // const [managementData, setManagementData] = useState(data);
    const [open, setOpen] = useState(false);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState(null);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {

    }, []);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=>{
        handleChangeRowsPerPage({
            target: {
                value: 10
            }
        })
    }, [period, selectedTag])

    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.tagValue,
                f?.subscriptionName,
                f?.cost,
                // f?.monthlySavings,
                
            ], search);
        }
        return true;
    }

    return loading ? (
        <div className={classes.root}>
            <LinearProgress variant="determinate" value={progress} />
        </div>
    ) : (
        <Paper>
            
            <TableSearch onSearch={setSearch} />
            <TableContainer className={classes.container}>
                <Table style={{alignItems: 'center', justifyContent: 'center'}} className={classes.table}
                       stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead style={{backgroundColor: "#cfdac7",}}>
                        <TableRow style={{fontWeight: ''}}>
                            <TableCell style={{fontWeight: 'bold'}}>
                                <h5>{selectedTag == 'untagged-tag'? 'Untagged':selectedTag}{""+'\t'}</h5>
                            </TableCell>
                            {!subscriptionLevel &&
                                <TableCell>
                                    <h5>Subscription</h5>
                                </TableCell>
                            }
                            <TableCell>
                                <h5>Cost</h5>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {data
                ?.sort((a, b) => parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0))?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((m) => (
                    <Tags data={m} classes={classes} currency={currency} period={period} collapseControl={open} subscriptionLevel={subscriptionLevel} />
                ))}
                </Table>
            </TableContainer>
                  <TablePagination
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={data?.filter(filteredTable)?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
        </Paper>
    );
}
function Tags({ data, classes, period, currency, subscriptionLevel, collapseControl }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(collapseControl);
    }, [collapseControl]);

    return (
        <>
            <TableRow>
                <TableCell>
                    {/*<IconButton*/}
                    {/*    aria-label="expand row"*/}
                    {/*    size="small"*/}
                    {/*    onClick={() => {*/}
                    {/*        setOpen(!open);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
                    {/*</IconButton>*/}
                    {data.tagValue || <span style={{color: colors.gray}}>No value set</span>}
                </TableCell>
                {!subscriptionLevel &&
                    <TableCell>
                        <OverlayTrigger placement="right"
                                        overlay={
                                            <Popover id="popover-contained">
                                                <Popover.Content style={{fontSize: 11}}>
                                                    {data?.subscriptionId}
                                                </Popover.Content>
                                            </Popover>
                                        }
                        >
                            <span style={{whiteSpace: 'nowrap'}}>
                                {data?.subscriptionName}
                            </span>
                        </OverlayTrigger>
                    </TableCell>
                }
                <TableCell>{currency + Format.formatNumber(Number(data?.cost))}</TableCell>
            </TableRow>
            <div style={{
                // border: '1px solid red'
            }}>
                    <Collapse in={open}>
                        <TableRow colSpan={3} style={{}}>
                            <TableCell >
                            <div style={{padding : 10, width: '100%', border: '1px solid'}}>

                            {/*<TableBody style={{}}>*/}
                        {
                            data.tagValues?.map(r=>(<TagValues currency={currency} collapseControl={false} data={r} />))
                        }
                        {/*</TableBody>*/}
                        {/*</TableRow>*/}
                            </div>
                            </TableCell>
                        </TableRow>
                    </Collapse>
            </div>
        </>
    )
}

function TagValues({ data, classes, period, currency, collapseControl }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(collapseControl);
    }, [collapseControl]);

    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => {setOpen(!open);}}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {data.tagValue}
                </TableCell>
                <TableCell>{currency + Format.formatNumber(Number(data?.cost))}</TableCell>
            </TableRow>
            <TableRow style={{}}>
                <div style={{padding : 10, width: '100%'}}>
                    <Collapse in={open}>

                        {/*<TableBody style={{}}>*/}
                        {
                            data.resources?.map(r=>(<Resources currency={currency} data={r} />))
                        }
                        {/*</TableBody>*/}
                        {/*</TableRow>*/}
                    </Collapse>
                </div>
            </TableRow>
            {/*</TableRow>*/}
            {/*<Collapse in={open}>*/}
            {/**/}
            {/*</Collapse>*/}
        </>
    )
}

function Resources({ data, classes, period, currency, collapseControl }) {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(collapseControl);
    }, [collapseControl]);

    return (
        <>
            {/*<TableRow>*/}
            <TableRow style={{padding: 10, border: '1px solid'}}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => {setOpen(!open);}}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                    {data.resourceId}
                </TableCell>
                <TableCell>{data.resourceType}</TableCell>
                <TableCell>{data.resourceGroupName}</TableCell>
            </TableRow>
            {/*</TableRow>*/}
            {/*<Collapse in={open}>*/}
            {/**/}
            {/*</Collapse>*/}
        </>
    )
}
