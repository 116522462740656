import React, {useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes/index";
import { Worker } from "@react-pdf-viewer/core";
import {
  createMuiTheme,
  MuiThemeProvider as ThemeProvider,
} from "@material-ui/core";

const {REACT_APP_CUSTS} = process.env;

const theme = createMuiTheme({
  typography: {
    fontFamily: "Poppins_Regular",
  },
});

function App() {
    useEffect(()=>{
        const customer = REACT_APP_CUSTS.split(';');
        window.$(document).ready(()=>{
            const select = window.$('#customer_id_selector_main');
            customer.map(c=>{
                select.append(`<option value="${c.split('=')[1]}" >${c.split('=')[0]}(${c.split('=')[1].slice(0,4)}...)</option>`)
            })
        })
    }, []);

  return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <ThemeProvider theme={theme}>
              <Provider store={store}>
                  <Router forceRefresh={true}>
                      <Routes />
                  </Router>
              </Provider>
          </ThemeProvider>
      </Worker>
  );
}

export default App;
