import React, { useEffect, useState } from "react";
import Format from "../../components/NumberFormat";
import { makeStyles, Paper, Grid, Divider, Card, IconButton, Collapse } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow, ThumbUp } from "@material-ui/icons";
import { Table } from "react-bootstrap";
import gcp_billing from "../../images/gcp_billing.svg";
import gcp_project from "../../images/gcp_project.svg";
import gcp_org from "../../images/gcp_org.svg";
import Azure_logo from "../../images/Azure_logo.svg";
import active_directory from "../../images/active_directory.svg";
import key from "../../images/key.svg";
import DateRange from "../DateRange";
import ContainerCardWithDateRange from "../ContainerCardWithDateRange";

export default function GCPSubscription({ tenantLogo, currency, accountList = [] }) {
    const [period, setPeriod] = useState("1M");
    const [orgView, setOrgView] = useState(true);

    return (
        <div style={{marginTop: 10}}>
            <ContainerCardWithDateRange title={'Spend by Organization'} defaultPeriod={"1M"} titleIcon={gcp_org}
                                        MiddleComponent={({period})=>(
                                            <span style={{display: 'flex', justifyContent: "flex-end", }}>
                                                Total: {currency}{accountList?.[period]?.dataSet? Format.formatNumber((accountList?.[period]?.dataSet?.map(d=>parseFloat(d.value))?.reduce((a,b)=>a+b, 0)) || 0) : "__.__"}
                                            </span>
                                        )}>
                {({period})=>(
                    accountList?.[period]?.dataSet && accountList?.[period]?.dataSet.length ? (
                        accountList?.[period]?.dataSet
                            .sort((a, b) => b.value - a.value)
                            .map((item, index) => (
                                <div>
                                    <div
                                        style={{
                                            padding: 16,
                                            backgroundColor: "#cfdac8",
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                        }}
                                    >
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                display: "flex",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={gcp_org}
                                                    alt="gcp_org"
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                        marginRight: 8,
                                                    }}
                                                />
                                                <span>{item.organizationName}</span>
                                            </div>
                                            <div>
                                                {" "}
                                                {currency}
                                                {Format.formatNumber(item.value)}
                                            </div>
                                        </div>
                                        <span style={{ marginLeft: 28 }}>{item.organizationId}</span>
                                    </div>

                                    {item.billingAccounts && item.billingAccounts.length ? (
                                        item.billingAccounts
                                            ?.sort((a, b) => b.value - a.value)
                                            .map((item) => (
                                                <div style={{ padding: 16 }}>
                                                    <div
                                                        style={{
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        <div style={{ display: "flex" }}>
                                                            <img
                                                                src={gcp_billing}
                                                                alt="gcp_billing"
                                                                style={{
                                                                    width: 20,
                                                                    height: 20,
                                                                    marginRight: 8,
                                                                }}
                                                            />
                                                            <span>{item.billingAccountName}</span>
                                                        </div>
                                                        <div>
                                                            {" "}
                                                            {currency}
                                                            {Format.formatNumber(item.value)}
                                                        </div>
                                                    </div>
                                                    <span style={{ marginLeft: 28 }}>{item.billingAccountId}</span>
                                                    <Divider style={{ marginTop: 8 }} />

                                                    {item.projects && item.projects.length ? (
                                                        item.projects?.map((item) => (
                                                            <div
                                                                style={{
                                                                    paddingLeft: 16,
                                                                    paddingTop: 8,
                                                                    paddingBottom: 8,
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        flexDirection: "row",
                                                                        justifyContent: "space-between",
                                                                        display: "flex",
                                                                    }}
                                                                >
                                                                    <div style={{ display: "flex" }}>
                                                                        <img
                                                                            src={gcp_project}
                                                                            alt="gcp_project"
                                                                            style={{
                                                                                width: 20,
                                                                                height: 20,
                                                                                marginRight: 8,
                                                                            }}
                                                                        />
                                                                        <span>{item.label}</span>
                                                                    </div>
                                                                    <div>
                                                                        {" "}
                                                                        {currency}
                                                                        {Format.formatNumber(item.value)}
                                                                    </div>
                                                                </div>
                                                                <Divider style={{ marginTop: 8 }} />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <span>No Projects found</span>
                                                    )}
                                                </div>
                                            ))
                                    ) : (
                                        <span>No billingAccount found found</span>
                                    )}
                                </div>
                            ))
                    ) : (
                        <div style={{flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                            <span style={{padding: 10}}>No data found</span>
                        </div>
                    )
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}
