/**
 * How to use:
 *
 * const [monthWiseData, setMonthWiseData] = useState(null);
 * const [yearWiseData, setYearWiseData] = useState(null);
 * const [currentYear, setCurrentYear] = useState(2021);
 * const [currentMonth, setCurrentMonth] = useState(null);
 *
 *
 * async function onMonthSelect(m,y){
    if(monthWiseData==null || !monthWiseData){
      await get1YearTableDataDaily()
          .then(()=> {
            setCurrentMonth(m)
          })
      setCurrentMonth(m);
    } else {
      setCurrentMonth(m);
    }
  }

 * function onNext(){
 *    setCurrentYear(currentYear + 1);
 * }
 * function onPrev(){
 *    setCurrentYear(currentYear - 1);
 * }
 *
 * <YearCalendarData year={currentYear} onNext={onNext} onPrev={onPrev}
 *                   yearData={CalendarDataProcessor(yearWiseData, 'year')}
 *                   monthData={(CalendarDataProcessor(monthWiseData, 'month')?.[currentYear]?.[currentMonth?.toString()?.substring(0,3)]) || null}
 *                   onMonthSelect={onMonthSelect}
 *                   month={currentMonth}
 * />
 */

import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Paper, Popper, Fade } from "@material-ui/core";

import moment from "moment";
import Dots from "./AnimatedDots/Dots";
import Cost, { Currency } from "./Cost";

import Azure_logo from "../images/Azure_logo.svg";
import AWS_LOGO from "../images/aws_dark.svg";
import GCP_ICON from "../images/GCP_logo.svg";
import All_CLOUD from "../images/allCloudLogo_white.svg";

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wed",
  "Thursday",
  "Friday",
  "Saturday",
];

/**
 * This calendar expects data to be in below format:
 *
 * --For Year Calendar--
 * const yearData = {
 *     2020: {
 *         "Jan": 90,
 *         "Feb": 10,
 *         "Mar": 30,
 *     },
 *     1997: {
 *         "Jan": 90,
 *         "Feb": 10,
 *         "Mar": 30,
 *     },
 * }
 * Here above year data contains object of all years with months also in Object
 * and you can either pass secondary data against month or just leave it blank
 * like "Jan" : ""
 *
 *--For Month Wise Calendar--
 * const yearData = {
 *     2020: {
 *         "Jan": [67, 12, 45, 200],
 *         "Feb": [467, 212, 905, 400],,
 *         "Mar": [212, 905, 1000, 243],
 *     },
 *     1997: {
 *         "Jan": [67, 12, 45, 200],
 *         "Feb": [467, 212, 905, 400],,
 *         "Mar": [212, 905, 1000, 243],
 *     },
 * }
 * Here above year data contains object of all years with months also in Object
 * and you have to pass date wise data in array format, it is not mandatory to
 * pass data in dates array. You can leave the dates array inside month as blank
 * like "Jan" : []
 *
 * */

const showOverlays = true;

export default function YearCalendarData({
  currentCloud = "",
  onNext,
  onPrev,
  year,
  yearData,
  monthData,
  month,
  onMonthSelect,
  loading = false,
  currency,
  disableNavigation = false,
  instanceName = "common",
  renderOverlay = () => {},
    calendarWidth = 500,
    calendarHeight = 400
}) {
  const [months, setMonths] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [yearTotal, setYearTotal] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [sortedCostData, setSortedCostData] = useState([]);
  const [medium, setMedium] = useState(null);
  const [low, setLow] = useState(null);
  const [high, setHigh] = useState([]);

  useEffect(() => {
    if (selectedMonth) return;
    getMonthsTable(Months);
  }, [Months, yearData, year]);

  function getMonthsTable(set) {
    let yearTotal = 0;
    const rem = set.length % 4;
    const rows = rem === 0 ? set.length / 4 : set.length;
    const months = [];
    for (let i = 1; i <= rows; i++) {
      months.push(
        <div style={{ display: "flex", flex: 1 }}>{getMonths(i, year)}</div>
      );
      setMonths(months);
    }
    function getMonths(i, year) {
      const months = [];
      for (let j = (i - 1) * 4; j < i * 4; j++) {
        const month = Months[j];
        yearTotal += yearData
          ? yearData?.[year]?.[month?.toString()?.substring(0, 3)] || 0
          : 0;
        months.push(
          <Block
            loading={loading}
            instanceName={instanceName}
            textClass={"month-calendar-text"}
            cost={
              yearData
                ? yearData?.[year]?.[month?.toString()?.substring(0, 3)] || ""
                : "-"
            }
            // low={low} medium={medium}
            costClass={"month-calendar-cost"}
            year={year || ""}
            month={month || ""}
            onClick={whenMonthSelect}
            currency={currency}
            renderOverlay={renderOverlay}
          >
            {month?.toString()?.substring(0, 3)}
          </Block>
        );
      }
      return months;
    }
    setYearTotal(yearTotal.toFixed(2));
  }

  function whenMonthSelect(month, year) {
    onMonthSelect(month, year);
    // onMonthSelect();
    // setSelectedMonth(month);
  }

  function onDaySelect() {}

  useEffect(() => {
    setSelectedMonth(month);
  }, [month]);

  useEffect(() => {
    if (!selectedMonth) return;
    if (!yearData) return;
    getDaysTable();
  }, [selectedMonth, year, monthData]);

  useEffect(() => {
    setLow(null);
    setMedium(null);
    setSortedCostData(
      monthData
        ?.filter((d) => {
          if (d != null && d != undefined && d != "") return d;
        })
        .sort((a, b) => a - b) || []
    );
    if (sortedCostData.length > 3) {
      var low = sortedCostData[Math.round(sortedCostData.length / 3)];
      var medium = sortedCostData[Math.round((sortedCostData.length / 3) * 2)];
      // var high = sortedCostData[Math.round((sortedCostData.length / 3)*2)];
      setLow(low);
      setMedium(medium);
    }
  }, [monthData, yearData]);
  function getDaysTable() {
    let weeks = [];
    const month = moment(year + selectedMonth, "YYYYMMMM");
    const totalDays = month.daysInMonth();
    let day = 0;
    let counter = month.toDate().getDay();
    let monthTotal = 0;

    weeks.push(
      <div className={"calendar-week-container"}>
        {Days.map((d) => (
          <Block
            loading={loading}
            instanceName={instanceName}
            currency={currency}
            // year={year || ""}
            // month={selectedMonth || ""}
            dashNeeded={false}
            onClick={() => null}
          >
            {d?.toString()?.substring(0, 3)}
          </Block>
        ))}
      </div>
    );

    for (let i = 1; i < 6; i++) {
      weeks.push(getDays(i));
    }

    function getDays(row) {
      const days = Days.map((d, j) => {
        const dateValue =
          j == counter &&
          (++day || true) &&
          day <= totalDays &&
          (counter++ || true)
            ? day
            : null;
        monthTotal += monthData?.[dateValue] || 0;
        return (
          <Block
            loading={loading}
            instanceName={instanceName}
            currency={currency}
            textClass={"day-calendar-text"}
            costClass={"day-calendar-cost"}
            year={year || ""}
            month={selectedMonth || ""}
            dashNeeded={true}
            cost={monthData?.[dateValue]}
            low={low}
            medium={medium}
            onClick={() => null}
            renderOverlay={renderOverlay}
            alterBackground= {((row % 2 == 0)?!(j%2 == 0):(j%2 == 0))}
          >
            {dateValue}
          </Block>
        );
      });
      counter = 0;
      return days;
    }

    if (totalDays > day) {
      const left = totalDays - day;
      let startDay = ++day;
      const week = weeks[1];
      for (let i = 0; i < left; i++) {
        monthTotal += monthData?.[startDay] || 0;
        week[i] = (
          <Block
            loading={loading}
            instanceName={instanceName}
            currency={currency}
            textClass={"day-calendar-text"}
            costClass={"day-calendar-cost"}
            year={year || ""}
            month={selectedMonth || ""}
            dashNeeded={true}
            low={low}
            medium={medium}
            cost={monthData?.[startDay]}
            onClick={() => null}
            renderOverlay={renderOverlay}
            alterBackground= {((1 % 2 == 0)?!(i%2 == 0):(i%2 == 0))}
          >
            {startDay}
          </Block>
        );
        startDay++;
      }
    }

    weeks = weeks.map((w) => (
      <div style={{ display: "flex", flex: 1.8 }}>{w}</div>
    ));
    setDays(weeks);
    setMonthTotal(monthTotal?.toFixed(2) || 0);
  }

  function onBeforePrev(props) {
    if (selectedMonth) {
      const month = moment(year + selectedMonth, "YYYYMMMM").month() - 1;
      if (month === -1) {
        onPrev(props);
        onMonthSelect(Months[11], year - 1);
        // setSelectedMonth(Months[11]);
      } else {
        onMonthSelect(Months[month], year);
        // setSelectedMonth(Months[month]);
      }
    } else {
      onPrev(props);
    }
  }

  function onBeforeNext(props) {
    if (selectedMonth) {
      const month = moment(year + selectedMonth, "YYYYMMMM").month() + 1;
      if (month === 12) {
        onNext(props);
        onMonthSelect(Months[0], year + 1);
        // setSelectedMonth(Months[0]);
      } else {
        onMonthSelect(Months[month]);
        // setSelectedMonth(Months[month]);
      }
    } else {
      onNext(props);
    }
  }

  if (!yearData) return <></>;

  return (
    <Paper elevation={3} style={{ width: calendarWidth, height: calendarHeight }}>
      <div
        style={{
          height: "20%",
          display: "flex",
          backgroundColor: "#3f51b5",
          color: "white",
          alignItems: "center",
          justifyContent: "space-evenly",
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      >
        <div
          onClick={onBeforePrev}
          align="left"
          style={{
            flex: 0.1,
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!disableNavigation && <ChevronLeft style={{cursor: "pointer"}} />}
          {
            {
              azure: (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 4,
                    marginTops: 4,
                  }}
                  src={Azure_logo}
                />
              ),
              aws: (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 4,
                    marginTops: 4,
                  }}
                  src={AWS_LOGO}
                />
              ),
              gcp: (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 4,
                    marginTops: 4,
                  }}
                  src={GCP_ICON}
                />
              ),
              total: (
                <img
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 4,
                    marginTops: 4,
                  }}
                  src={All_CLOUD}
                />
              ),
            }[currentCloud]
          }
        </div>

        <div
          onClick={() => setSelectedMonth(null)}
          align="center"
          style={{
            flex: 0.75,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <span style={{ fontSize: 20 }}>
            <span style={{ fontSize: 30, fontWeight: "bold" }}>
              {currency || <Currency />}
              <Cost>{selectedMonth ? monthTotal : yearTotal}}</Cost>
            </span>
            <br />
            {(selectedMonth || "") + " " + year}
            <br />
          </span>
        </div>
        <div
          onClick={onBeforeNext}
          align="right"
          style={{
            flex: 0.15,
            alignItems: "center",
            justifyContent: "center",
            padding: 10,
          }}
        >
          {!disableNavigation && <ChevronRight style={{cursor: "pointer"}} />}
        </div>
      </div>
      <div
        style={{
          height: "80%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ebeeff",
        }}
      >
        {selectedMonth ? days : months}
      </div>
    </Paper>
  );
}

function Block({
  textClass = "",
  costClass = "",
  children,
  backgroundColor = null,
  onClick,
  month,
  year,
  dashNeeded = true,
  cost,
  currency,
  loading,
  instanceName,
  renderOverlay = () => {},
  low,
  medium,
    alterBackground = null
}) {
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const showingPopover = Boolean(popoverAnchorEl);
  const popover_id = showingPopover ? "messages-popup" + children : undefined;
  const [shouldShowBorder, showBorder] = useState(false);

  let overlay_day = typeof children === "number" ? children : null;
  let overlay_month = Months.indexOf(month) + 1;
  const overlay_year = year;

  overlay_day =
    overlay_day && overlay_day < 10 ? "0" + overlay_day : overlay_day;
  overlay_month =
    overlay_month && overlay_month < 10 ? "0" + overlay_month : overlay_month;
  const overlay_cost = parseFloat(cost)?.toFixed(2);

  const backgroundStyle = {};
  // const backgroundStyle =
  //   low && medium && cost
  //     ? {
  //         backgroundColor:
  //           cost <= low
  //             ? "#9fff9f"
  //             : cost > low && cost <= medium
  //             ? "#ffff91"
  //             : "#ff000061",
  //       }
  //     : {};
  return (
    <div
      onMouseOver={(event) => (cost && showOverlays ? showBorder(true) : null)}
      onMouseOut={() => (cost && showOverlays ? showBorder(false) : null)}
      onClick={() => {
        onClick(month, year);
        renderOverlay(overlay_day, overlay_month, overlay_year, overlay_cost);
      }}
      style={{
        cursor: "pointer",
        flex: 1,
        border: "1px solid " + (shouldShowBorder ? "rgb(63, 81, 181)" : "#ddd"),
        borderTopWidth: 0,
        borderLeftWidth: 0,
        display: "flex",
      }}
      className={alterBackground != null?alterBackground?"calendar-date-bg-1":"calendar-date-bg-2":""}
    >
      <div
        style={{
          cursor: "pointer",
          flex: 1,
          // color: !backgroundColor ? "#435540" : "#c8c6a7",
          borderTopWidth: 0,
          borderLeftWidth: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className={textClass}>{children}</span>
        <span className={costClass}>
          {loading && year != null && month != null && !!children ? (
            <Dots width={5} height={2} dotType={"square"} count={3} />
          ) : !cost ? (
            dashNeeded && children && "-"
          ) : children ? (
            (
              <>
                {currency || <Currency />}
                <Cost>{cost}</Cost>
              </>
            ) || ""
          ) : (
            ""
          )}
        </span>
      </div>
      {/*<Popper style={{zIndex: 100, bottom: 10}}*/}
      {/*        // placement="right-start"*/}
      {/*        id={popover_id}*/}
      {/*        open={showingPopover} anchorEl={popoverAnchorEl} transition>*/}
      {/*  {({ TransitionProps }) => (*/}
      {/*      <Fade {...TransitionProps} timeout={500}>*/}
      {/*        <Paper elevation={3}>*/}
      {/*          {renderOverlay(overlay_day, overlay_month, overlay_year, overlay_cost)}*/}
      {/*          /!*<div style={{ padding: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>*!/*/}
      {/*          /!*  <span>{month} {children + ","} {year}</span>*!/*/}
      {/*          /!*  <span style={{fontWeight: 'bold'}}>{currency + parseFloat(cost)?.toFixed(2)}</span>*!/*/}
      {/*          /!*</div>*!/*/}
      {/*        </Paper>*/}
      {/*      </Fade>*/}
      {/*  )}*/}
      {/*</Popper>*/}
    </div>
  );
}

export function CalendarDataProcessor(data, period, filterObjects = []) {
  if (!data || !(data?.length)) return null;
  const final = {};
  if (period == "year") {
    data.map((d) => {
      const rawYear = d.month.toString().substring(0, 4);
      const rawMonth = d.month.toString().substring(4);
      const month = moment(d.month, "YYYYMM");
      const monthCost = {};
      var finalSubObj = {};
      filterObjects.forEach(object=>{
        finalSubObj[object] = d[object];
      });
      if(filterObjects.length){
        monthCost[Months[month.month()]?.toString().substring(0, 3)] = {...finalSubObj};
      } else {
        monthCost[Months[month.month()]?.toString().substring(0, 3)] = parseFloat(
            d.cost
        );
      }
      final[month.year()] = {
        ...(final?.[month.year()] || null),
        ...monthCost,
      };
    });
  } else if (period == "month") {
    const getMonths = function (months) {
      const m = {};
      months.forEach((month) => {
        const finalDates = [];
        getDates(month.dates, finalDates);
        m[Months[parseInt(month.month) - 1]?.toString()?.substring(0, 3)] =
          finalDates;
      });
      return m;
    };
    const getDates = function (dates, final) {
      return dates.map((date) => {
        const d = date.date.toString();
        const i = {};
        const day = moment(d, "YYYYMMDD").date();
        final[day.toString()] = parseFloat(date.cost);
        return i;
      });
    };
    data.forEach((year) => {
      const months = getMonths(year.months);
      final[year.year] = months;
    });
  }

  return final;
}
