import React, {useState, useEffect, useRef} from "react";
import "./style.css";
import Format from "components/NumberFormat";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import moment from "moment";
// import {similarObject} from "../listS3bucket/ConversionFunc";
import RecommendationsTable, {ApprovedSavings} from "./RecommendationTable";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import aws_recommendation from "../../images/aws_recommendation.svg";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AmazonRDS from "../../images/awsAccounts/Amazon-RDS.svg";
import RDSIcon from "../../images/RDS.svg";
import AwsLogo from "../../images/aws_dark.svg";
import downarrow from '../../images/downarrow.png'
import info_icon from '../../images/info_icon.svg'
import DynamoDBIcon from "../../images/DynamoDB.svg";
import Button from "@material-ui/core/Button";
import recommendation_icon from "../../images/recommended.png";
import {OverlayTrigger, Tooltip,Popover} from "react-bootstrap";
import Badge from "@material-ui/core/Badge";
import {CancelRounded, Help, Info} from "@material-ui/icons";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TablePaginationActions from "../../components/TablePaginationActions";
import TableSearch from "../../components/TableSearch";
import Color from "../../styles/color";
import Box from "@material-ui/core/Box";
import {bytesToSize}  from "../../util/Miscellaneous";
import Ec2Icon from "../../images/EC2.svg";
import axios from "../../connection/axios";
import LinkText from "../../components/LinkText";
import TooltipMUI from "@material-ui/core/Tooltip";
import Checkbox from "components/Checkbox";
const AWS_TERMINATE_INSTANCE_HELP = "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html#new-console";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        //marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,

    },
}));

function generateURL(data){
    // https://us-east-1


    // https://us-east-1.console.aws.amazon.com/cost-management/home?region=
        // us-east-1#/rightsizing/
        // i-039dd7f401c552edf?REGION=US%20West%20(Oregon)&RIGHTSIZING_TYPE=Modify&LINKED_ACCOUNT=099864119031&target=SAME_INSTANCE_FAMILY&benefitsConsidered=true





    if(data){
        const BASE_PATH = ".console.aws.amazon.com/cost-management/home?region=";
        const AWS_REGION = data?.regionId;
        const AWS_REGION_2 = data?.Region;
        const AWS_RIGHTSIZING = "#/rightsizing/";
        const INSTANCE = data?.instanceId + "?REGION=";
        const INSTANCE_FAMILY = "&target=SAME_INSTANCE_FAMILY&benefitsConsidered=true";
        const LINKED_ACCOUNT = data?.account;
        const RIGHTSIZING_TYPE = "&RIGHTSIZING_TYPE=Modify&LINKED_ACCOUNT="

        const FINAL_URL = "https://" + AWS_REGION + BASE_PATH + AWS_REGION + AWS_RIGHTSIZING + INSTANCE + AWS_REGION_2 +
            RIGHTSIZING_TYPE + LINKED_ACCOUNT+ INSTANCE_FAMILY;
        // console.log(FINAL_URL)
        window.open(FINAL_URL, "_blank");
    }
}

export default function AWSRightsizingEC2Recommendation({customerId, cards, data, title, view, subscriptionId, lastUpdated, currency, collapseRef, loading }) {
    const classes = useStyles();
    const [totalExpand, setTotalExpand] = useState(true);
    const [selected, setSelected] = React.useState([]);
    const [progress, setProgress] = useState(0);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [list, setList] = useState(null);
    const [search, setSearch] = useState(null);

    const [selectionModel, setSelectionModel] = useState([]);
    const [approvedSavingsTotal, setApprovedSavingsTotal] = useState(0);

    const [approvedSavingsModal, setApprovedSavingsModal] = useState(false);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);
    const filteredTable = (f)=> {
        if(search && search.toString().length){
            return !!TableSearch.doesItIncludes([
                f?.instanceId,
                f?.instanceName,
                f?.currentInstanceType,
                f?.recommendedInstanceType,
                f?.Region,
                f?.account,
            ], search);
        }
        return true;
    }

    useEffect(()=>{
        if(!selectionModel.length) return
        const data2 = selectionModel.map(s=>data?.resizingRecommendations.find(g=>(g.instanceId || g.instanceName)==s));
        const approvedSavingsTotal = data2.map(d=>parseFloat(d?.potentialSavings || 0)).reduce((a,b)=>a+b, 0);
        setApprovedSavingsTotal(approvedSavingsTotal);
    }, [selectionModel]);
    // const data2 = data;

    const onSelect = (id, all = false) => {
        if(all){
            if(selectionModel.length === data?.resizingRecommendations?.length) {
                setSelectionModel([]);
            } else {
                const selected = data?.resizingRecommendations?.map(row => row?.instanceId || row?.ec2Name);
                setSelectionModel(selected);
            }
        } else {
            if(selectionModel.indexOf(id) != -1){
                setSelectionModel(selectionModel.filter(i=>i!==id));
            } else {
                const model = Object.assign([], selectionModel);
                model.push(id);
                setSelectionModel(model);
            }
        }
    }

    return(
        <div>
            <ApprovedSavings open={approvedSavingsModal} currency={currency} onClose={()=>setApprovedSavingsModal(false)}
                             data={selectionModel?.map(s=>data?.resizingRecommendations?.find(g=>(g.instanceId || g.instanceName)==s))}
                             onCancel={()=>{
                                 setSelectionModel([]);
                                 setApprovedSavingsModal(false);
                             }}
                             type={'rightsizing'}
                             onRemove={(index, id)=>{
                                 const final = selectionModel.filter(s => s != id);
                                 if(final.length){
                                     setSelectionModel(final);
                                 } else {
                                     setSelectionModel([]);
                                     setApprovedSavingsModal(false);
                                 }
                             }}
                             total={approvedSavingsTotal}
                             currency={currency}
            />
            <ContainerCardWithDateRange someref={collapseRef}
                title={title}
                titleIcon={AwsLogo}
                showDateRange={false}
                collapsible={true}
                HeaderTitleComponent={()=>(
                    <div>
                        <img src={Ec2Icon} height={25} style={{marginLeft: 8, marginRight: 8}} />
                        <span>
                            {title}
                        </span>
                    </div>
                )}
                ExtraHeaderComponent={({ period }) =>
                    data != null && Object.keys(data)?.length ? (
                        <div style={{ marginTop: 4 }}>
                            <span style={{ fontSize: 14 }}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
                        </div>
                    ) : null
                }
                CenterComponent={()=>(
                    <div>
                     {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
                  </div>
                    )}
                 >
                {({ period }) => (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {!loading ?(
                    <div style={{flex:1}}>
                        {cards && <RecommendationsSummaryCards summary={cards} />}
                        <div className={classes.root}>
                                {data?.resizingRecommendations?.length ? (
                                    <Paper className={classes.paper}>
                                        <Box flex={1} display={'flex'} justifyContent={'space-between'}>
                                            <TableSearch onSearch={setSearch} filterWidth={true}/>
                                            <TooltipMUI disableHoverListener={selectionModel.length>0}
                                                        disableFocusListener={selectionModel.length>0}
                                                        disableTouchListener={selectionModel.length>0}
                                                        title="Please select any recommendations">
                    <span>
                        <Button onClick={()=>setApprovedSavingsModal(true)}
                                disabled={selectionModel.length==0}
                                variant="contained"
                                color="primary"
                                style={{textTransform: "none"}}
                            // style={{ backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial" }}>
                        >
                            {
                                selectionModel.length>0?
                                    `Approve Savings (${currency + Format.formatNumber(approvedSavingsTotal)})`:
                                    "Approve Savings"
                            }
                        </Button>
                    </span>
                                            </TooltipMUI>
                                        </Box>
                                        <TableContainer style={{maxHeight:450}}>
                                            <Table className={classes.table} stickyHeader aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                                <AwsRDSRecommendationHead view={view} subscriptionId={subscriptionId} classes={classes} numSelected={selected.length} rowCount={data?.resizingRecommendations?.length}
                                                                          onSelect={()=>onSelect(null, true)} checkedAll={selectionModel?.length === data?.resizingRecommendations?.length} />
                                                <TableBody>
                                                    {data?.resizingRecommendations
                                                     ?.filter(filteredTable)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                        ?.sort((a,b)=>(b?.potentialSavings || 0)-(a?.potentialSavings || 0))
                                                        ?.map((row, index) => {
                                                        return <MyTableRow account={data?.managedAWSAccountId} date={data} view={view} subscriptionId={subscriptionId} row={row} index={index} list={list} setList={setList} currency={currency}
                                                                           onSelectInstance={onSelect} selectedInstances={selectionModel} />;
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <TablePagination
                                            ActionsComponent={TablePaginationActions}
                                            rowsPerPageOptions={[10, 25, 50, 75]}
                                            component="div"
                                            count={data?.resizingRecommendations?.filter(filteredTable)?.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                        />
                                    </Paper>
                                ) : (null
                                    // <h6 style={{ textAlign: "center" }}>No data found</h6>
                                )}
                        </div>
                    </div>
                    ):<CircularProgress thickness={2} size={60} />
                     }
                     </div>
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

const useStyles22 = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        whiteSpace: "nowrap",
        fontWeight: "bold",
        backgroundColor: Color.tableHeader,
        fontSize:12
    },
}));

function AwsRDSRecommendationHead({ view, onSelect, checkedAll }) {

    const classes = useStyles22();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow} align="left">
                    <Checkbox onClick={onSelect} color={"primary"} checked={checkedAll} />
                </TableCell>
                <TableCell className={classes.tableRow}>Instance Id</TableCell>
                <TableCell className={classes.tableRow}>Curr. Instance Type</TableCell>
                <TableCell className={classes.tableRow}>Recomm. Instance Type</TableCell>
                <TableCell className={classes.tableRow}>Curr. CPU Util.</TableCell>
                <TableCell className={classes.tableRow}>Projected CPU Util.</TableCell>
                <TableCell className={classes.tableRow}>Region</TableCell>
                {view == "all" ? <TableCell className={classes.tableRow}>AWS Account</TableCell> : null}
                <TableCell className={classes.tableRow}>Potential Savings</TableCell>
                <TableCell align={"center"} className={classes.tableRow}>
                    {" "}
                        Action
                        {/*<div>*/}
                        {/*    <OverlayTrigger placement="left"*/}
                        {/*                    overlay={<Tooltip id="button-tooltip">How to terminate Prev.Gen. EC2 instance?</Tooltip>}>*/}
                        {/*        <Badge badgeContent={0} color="secondary">*/}
                        {/*            <Help*/}
                        {/*                onClick={()=>window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")}*/}
                        {/*                style={{cursor: "pointer", fontSize:20, color: "#303" }} />*/}
                        {/*        </Badge>*/}
                        {/*    </OverlayTrigger>*/}
                        {/*</div>*/}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ date, row, view, index, list, setList, currency, account, onSelectInstance, selectedInstances }) {
    const [open, setOpen] = useState(true);

    return (
        <React.Fragment>
            <TableRow>
                <TableCell align="left">
                    <Checkbox onClick={()=>onSelectInstance(row?.instanceId || row?.instanceName)} color={"primary"} checked={selectedInstances.indexOf(row?.instanceId || row?.ec2Name) !== -1} />
                </TableCell>

                <TableCell style={{whiteSpace: "nowrap"}}>
                    {row?.instanceName || row?.instanceId}
                    <br/>
                    {(row?.instanceName)?<span className={'secondary-table-item'}>
                        {row?.instanceId}
                    </span>:null}
                </TableCell>
                <TableCell>
                    {row?.currentInstanceType}
                    <OverlayTrigger placement="bottom"
                                                              overlay={
                                                                  <Tooltip id="button-tooltip">
                                                <span style={{}}>
                                                    OS: {row?.OS}
                                                </span>
                                                                  </Tooltip>
                                                              }
                    // <Tooltip id="button-tooltip">{row?.instanceType}</Tooltip>

                >
                    <Info aria-label={'disabled'} style={{cursor: "pointer", fontSize: 15, color: "#adadad" }} />
                </OverlayTrigger>
                    <br/>
                    <span style={{whiteSpace: "nowrap", fontSize: 11, color: "#7f7f7f"}}>
                        vCPU: {row?.currentInstanceTypeCPU} Memory: {row?.currentInstanceTypeMemory} {row?.memoryUnits}
                    </span>
                </TableCell>
                <TableCell>
                    {row?.recommendedInstanceType}
                    <br/>
                    <span style={{fontSize: 11, color: "#7f7f7f"}}>
                        vCPU: {row?.recommendedInstanceTypeCPU} Memory: {row?.recommendedInstanceTypeMemory} {row?.memoryUnits}
                    </span>
                </TableCell>
                <TableCell>
                    {Format.formatNumber(row?.currentInstanceTypeCPUUtilInPercent)} %
                </TableCell>
                <TableCell>
                    {Format.formatNumber(row?.projectedCPUUtilInPercent)} %
                </TableCell>
                <TableCell>
                    {row?.Region}
                </TableCell>
                <TableCell>
                    {account}
                </TableCell>
                <TableCell align={"right"} style={{whiteSpace: "nowrap"}}>
                    {(currency) + Format.formatNumber(row?.potentialSavings)} /mon
                </TableCell>
                <TableCell>
                    <Button
                        onClick={()=>{generateURL({...row, account})}}
                        variant="contained" style={{ fontSize: 11,
                         borderRadius: 15,
                          backgroundColor: "#3f50b5",
                           color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                        Fix in AWS
                    </Button>
                </TableCell>
            </TableRow>
            {/*<TableRow hover key={row.date}>*/}
            {/*    <TableCell>*/}
            {/*        <>*/}
            {/*            <IconButton*/}
            {/*                style={{ cursor: "pointer" }}*/}
            {/*                aria-label="expand row"*/}
            {/*                size="small"*/}
            {/*                onClick={() => {*/}
            {/*                    setOpen(!open);*/}
            {/*                    setList(index);*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
            {/*            </IconButton>{" "}*/}
            {/*            {row?.resource}*/}
            {/*        </>*/}
            {/*    </TableCell>*/}
            {/*    <TableCell>{row?.currentDBInstanceType}</TableCell>*/}
            {/*    {view == "all" ? <TableCell>{row?.linkedAWSAccountId}</TableCell> : null}*/}
            {/*    <TableCell>{row?.Engine}</TableCell>*/}
            {/*    /!* /!* <TableCell>{row?.vCPU}</TableCell> *!/*/}
            {/*    <TableCell>{row?.multiAZ ?'Multi':'Single'}</TableCell>*/}
            {/*    <TableCell>{row?.region}</TableCell>*/}
            {/*    <TableCell>{row?.DBInstanceStatus}</TableCell>*/}
            {/*    <TableCell>*/}
            {/*    <div style={{border: "1px solid #338307",padding:2}}>*/}

            {/*        {Format.formatCurrencySymbol(currency)}*/}
            {/*        {Format.formatNumber(Math.max(...row?.recommendations?.map((m)=>(*/}
            {/*           (parseFloat(m?.yearlyPotentialSavings)/12)*/}
            {/*        ))))+ " /mon"}*/}
            {/*        <br />*/}
            {/*         {Format.formatCurrencySymbol(currency)}*/}
            {/*        {Format.formatNumber(Math.max(...row?.recommendations?.map((m)=>(*/}
            {/*            m?.yearlyPotentialSavings*/}
            {/*        ))))+ " /yr"}*/}

            {/*        </div>*/}
            {/*    </TableCell>*/}
            {/*</TableRow>*/}
            {/*<ExpandLavelData date={date} data={row} open={open} select={list} currency={currency} />*/}
        </React.Fragment>
    );
}

export function ExpandLavelData({ date, data, open, select, set, currency }) {
    return (
        <React.Fragment>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2.5}>
                            <Htmltable date={date} data={data} currency={currency} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles33 = makeStyles((theme) => ({
    backgroundStyle: {border: "1px solid #338307",margin:2,padding:2
    }
}));

export function Htmltable({date, data, currency }) {


    const classes = useStyles33();
    return (
        <>
            <div class="overflow-auto">
                {"reservations" in data && data?.reservations?.length ? (
                    <>
                        <b>Reservations</b>
                        <table id="customers">
                            <tr>
                                <th>Current price</th>
                                <th>Offering type</th>
                                <th>Duration</th>
                                <th>Start date</th>
                                <th>End date</th>
                            </tr>

                            {data &&
                                data?.reservations
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.fixedPrice || 0) - parseFloat(a?.fixedPrice || 0);
                                    })
                                    .map((m) => (
                                        <tr>
                                            <td>
                                                {Format.formatCurrencySymbol(m?.currencyCode)}
                                                {Format.formatNumber(m?.fixedPrice)}
                                            </td>
                                            <td>{m?.offeringType}</td>
                                            <td>{m?.duration}</td>

                                            <td>{moment(m?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</td>
                                            <td>{m?.duration == "1yr" ? moment(m?.startDate, "YYYYMMDD").add(1, "y").subtract(1, "d").format("MMM DD, YYYY") : moment(m?.startDate, "YYYYMMDD").add(3, "y").subtract(1, "d").format("MMM DD, YYYY")}</td>
                                        </tr>
                                    ))}
                        </table>

                        <br />
                    </>
                ) : null}
                {"recommendations" in data && data?.recommendations?.length ? (
                    <>
                       <div style={{display: "flex", flexdirection:'row',marginTop:4,alignItems:'center'}}>
                        <b>Recommendations</b>

                        <td className={classes.backgroundStyle} style={{marginLeft:4}}>
                        <img
                        src={downarrow}
                        style={{
                            width: 20,
                            height: 20,
                            marginRight: 4,
                        }} />
                            <span>{data?.recommendations?.[0]?.recommendationShortDescription} </span>
                        </td>
                       </div>
                       <div style={{display: "flex", flexdirection:'row',marginTop:8}}>
                          <div style={{display: "flex", flexdirection:'row',marginTop:8}}>
                        <b>Metrics</b>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Popover id="popover-contained">
                                    <Popover.Content><b>Based on the last 30 days ({moment(date?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}-{moment(date?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}) usage</b></Popover.Content>
                                </Popover>
                            }
                        >
                            <img
                            src={info_icon}
                            style={{
                                width: 20,
                                height: 20,
                                marginLeft: 4,
                                marginRight: 4,
                            }} />
                        </OverlayTrigger>
                        </div>

                       <table id="customers">
                            <tr>
                                <th>Avg. CPU Utilization</th>
                                <th>Avg. Freeable Memory</th>
                                <th>Avg. Free Storage Space</th>
                                <th>Avg. Read IOPS</th>
                                <th>Avg. Write IOPS</th>
                                <th>Avg. Database Connections</th>
                            </tr>

                                        <tr>
                                            <td>
                                                {Format.formatNumber(data?.metrics?.[0].cpuUtilizationAverage)+'%' || null}
                                            </td>
                                            <td>{bytesToSize(data?.metrics?.[1]?.freeableMemoryAverage)||null}</td>
                                            <td>{bytesToSize(data?.metrics?.[2]?.freeStorageSpaceAverage||null)}</td>

                                            <td>{Format.formatNumberInt(data?.metrics?.[4]?.readIOPSAverage||null)}</td>
                                            <td>{Format.formatNumberInt(data?.metrics?.[5]?.writeIOPSAverage||null)}</td>
                                            <td>{Format.formatNumberInt(data?.metrics?.[3]?.databaseConnectionsAverage||null)}</td>
                                        </tr>

                        </table>

                       </div>
                       <br/>
                        <table id="customers">
                            <tr>
                                <th>From instance</th>
                                <th>To instance</th>
                                {/* <th style={{ whiteSpace: "nowrap" }}>To configuration</th> */}
                                <th>Current price</th>
                                {/* <th>Reserved price</th> */}
                                <th style={{ whiteSpace: "nowrap" }}>On-Demand price</th>
                                <th>Potential savings</th>
                                {/* <th>
                                    <span style={{ whiteSpace: "nowrap" }}>
                                        Savings over <br />
                                        On-Demand
                                    </span>
                                </th> */}
                                <th>
                                    {" "}
                                    Action
                                    {/* <>
                                      <OverlayTrigger placement="left"
                                        overlay={<Tooltip id="button-tooltip">How to terminate RDS instance?</Tooltip>}>
                                        <Badge badgeContent={0} color="secondary">
                                        <Help
                                        onClick={()=>window.open(AWS_TERMINATE_INSTANCE_HELP, "_blank")}
                                        style={{cursor: "pointer", fontSize:20, color: "#303" }} />
                                        </Badge>
                                        </OverlayTrigger>
                                    </> */}
                                </th>
                            </tr>

                            {data &&
                                data?.recommendations
                                    ?.sort((a, b) => {
                                        return parseFloat(b?.yearlyPotentialSavings || 0) - parseFloat(a?.yearlyPotentialSavings || 0);
                                    })
                                    .map((m) => (
                                        <tr style={{ borderColor: m?.isBestRecommendation == true ? Color?.lightGreen : null, border: m?.isBestRecommendation == true ? "2px solid #338307" : null }}>
                                            <td>

                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {" "}
                                                        {data?.currentDBInstanceType} <br />
                                                        {m?.fromInstanceType=='reserved'?'Reserved':m?.fromInstanceType}
                                                    </span>
                                             <br />
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                    {data?.vCPU} CPU, {data?.memory} mem
                                                    </span>

                                            </td>
                                            <td style={{ alignItems: "center",whiteSpace: "nowrap" }}>
                                                {" "}
                                                {/* {m?.isBestRecommendation == true ? <img style={{ height: 16, marginRight: 4 }} src={recommendation_icon} /> : null} */}
                                                {m?.toInstanceType == "On-Demand" ? (

                                            <span style={{ whiteSpace: "nowrap" }}>
                                                        {m?.dbInstance}
                                                        <br />
                                                        On-Demand
                                                    </span>
                                                ) : (
                                                    <span style={{ whiteSpace: "nowrap" }}>
                                                        {m?.dbInstance}
                                                        <br />
                                                        Reserved
                                                    </span>
                                                )}
                                                <br />
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {m?.vCPU} CPU, {m?.memory} mem
                                                </span>
                                            </td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.vCPU} CPU, <br /> {m?.memory} mem
                                            </td> */}

                                            <td>
                                                <div>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {Format.formatCurrencySymbol(currency)}
                                                    {Format.formatNumber(
                                                        (data?.reservations?.[0]?.duration == "3yr")?
                                                            parseFloat(m?.currentDBInstancePrice / 3 / 12):
                                                            parseFloat(m?.currentDBInstancePrice / 1 / 12)
                                                    ) + " /mon"}
                                                </span>
                                                </div>
                                                <span style={{ whiteSpace: "nowrap" }}>
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.currentDBInstancePrice)}
                                                {data?.reservations?.[0]?.duration == "3yr" ? " /3yrs" : " /yr"}
                                                </span>
                                            </td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.toInstanceType == "reservedInstance" && m?.termLeaseLength == "3yr" ? (
                                                    Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.threeYearlyReservedInstancePrice) + " /" + m?.termLeaseLength
                                                ) : m?.toInstanceType == "reservedInstance" && m?.termLeaseLength == "1yr" ? (
                                                    Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.yearlyReservedInstancePrice) + " /" + m?.termLeaseLength
                                                ) : (
                                                    <span style={{ color: "gray", fontSize: 12 }}>N/A</span>
                                                )}
                                            </td> */}
                                            <td style={{ whiteSpace: "nowrap" }}>
                                                {m?.toInstanceType == "reservedInstance" ?
                                                    <span style={{ color: "gray", fontSize: 12 }}>N/A</span> :
                                                    <>
                                                        <div>
                                                            {Format.formatCurrencySymbol(currency) + Format.formatNumber(parseFloat(m?.yearlyOnDemandPrice)/12) + " /mon"}
                                                        </div>
                                                        {Format.formatCurrencySymbol(currency) + Format.formatNumber(m?.yearlyOnDemandPrice) + " /yr"}
                                                    </>}
                                            </td>
                                            <td style={{ whiteSpace: "nowrap" }}>

                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(parseFloat(m?.yearlyPotentialSavings)/12) + " /mon"}
                                                <br />
                                                {Format.formatCurrencySymbol(currency)}
                                                {Format.formatNumber(m?.yearlyPotentialSavings) + " /yr"}

                                            </td>
                                            {/* <td style={{ whiteSpace: "nowrap" }}>{m?.toInstanceType == "reservedInstance" ? m?.calculatedPrice?.savingsOverOnDemandPercent?.USD?.toFixed(2) + "%" : <span style={{ color: "gray", fontSize: 12 }}>N/A</span>}</td> */}
                                            <td align="left">
                                                <Button
                                                onClick={()=>{generateURL(data)}}
                                                 variant="contained" style={{ fontSize: 11, borderRadius: 15, backgroundColor: "#3f50b5", color: "#FFF", textTransform: "initial", whiteSpace: "nowrap" }}>
                                                    Fix in AWS
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                        </table>
                        <br />

                    </>
                ) : null}
            </div>
        </>
    );
}

/**
 * <div style={{ height: 0, width: '100%' }}>
 *                         <DataGrid rows={
 *                             (data?.resizingRecommendations || [])
 *                                 ?.sort((a,b)=>(b?.potentialSavings || 0)-(a?.potentialSavings || 0))?.filter(filteredTable)
 *                                 .map((r,i)=>{
 *                                     return {
 *                                         id: r.instanceId,
 *                                         name: r.instanceName,
 *                                         potentialSavings: currency + Format.formatNumber(r.potentialSavings) + " /mon",
 *                                         currInstType: r.currentInstanceType,
 *                                         recomInstType: r.recommendedInstanceType,
 *                                         cpuCurrUtil:Format.formatNumber(r.currentInstanceTypeCPUUtilInPercent) + " %",
 *                                         projCPUUtil:Format.formatNumber(r.projectedCPUUtilInPercent) + " %",
 *                                         region:r.regionId,
 *                                         account:r.linkedAWSAccountId,
 *                                         currvCPU:r.currentInstanceTypeCPU,
 *                                         currMem: r.currentInstanceTypeMemory,
 *                                         recomvCPU:r.recommendedInstanceTypeCPU,
 *                                         recomMem: r.recommendedInstanceTypeMemory,
 *
 *                                     };
 *                                 })
 *                         } pagination
 *                                   columns={[
 *                                       {id: 'id',name: "EC2 Instance",
 *                                           renderCell: (a,b,c)=>{
 *                                             return (
 *                                                 <span>{a?.row?.name}<br/>
 *                                                     <span style={{fontSize: 11, color: "#7f7f7f"}}>
 *                                                         {a?.row?.id}
 *                                                     </span>
 *                                                 </span>
 *                                             )
 *                                           },
 *                                           cellClassName: 'line-heighted-tc'
 *                                       },
 *                                       {width: 150, id: 'potentialSavings',name: "Potential Savings",
 *                                           cellClassName: 'highlighted-table-cell'
 *                                       },
 *                                       {width: 150, id: 'currInstType',name: "Curr. Instance Type",
 *                                           renderCell: (a,b,c)=>{
 *                                               return (
 *                                                   <span>{a?.formattedValue}<br/>
 *                                                     <span style={{fontSize: 11, color: "#7f7f7f"}}>
 *                                                         vCPU: {a?.row?.currvCPU} Memory: {a?.row?.currMem} GiB
 *                                                     </span>
 *                                                 </span>
 *                                               )
 *                                           },
 *                                           cellClassName: 'line-heighted-tc'
 *                                       },
 *                                       {width: 180, id: 'recomInstType',name: "Recomm. Instance Type",
 *                                           renderCell: (a,b,c)=>{
 *                                               return (
 *                                                   <span>{a?.formattedValue}<br/>
 *                                                     <span style={{fontSize: 11, color: "#7f7f7f"}}>
 *                                                         vCPU: {a?.row?.recomvCPU} Memory: {a?.row?.recomMem} GiB
 *                                                     </span>
 *                                                 </span>
 *                                               )
 *                                           },
 *                                           cellClassName: 'line-heighted-tc'
 *                                       },
 *                                       {width: 160, id: 'cpuCurrUtil',name: "Curr. CPU Util."},
 *                                       {width: 150, id: 'projCPUUtil',name: "Projected CPU Util."},
 *                                       {width: 150, id: 'region',name: "Region"},
 *                                       {width: 140, id: 'account',name: "Account"}
 *                                   ].map(({id, name, width, ...rest})=>({
 *                                       ...rest,
 *                                       field: id,
 *                                       headerName: name,
 *                                       width: width || 200,
 *                                       editable: false,
 *                                       sortable: false,
 *                                       filterable: false,
 *                                       valueOptions: null,
 *                                       hideSortIcons: true,
 *                                       hidePreferences: true,
 *                                   }))}
 *                                   disableColumnMenu={true}
 *                                   pageSize={10}
 *                                   checkboxSelection
 *                                   disableSelectionOnClick
 *                                   onSelectionModelChange={(newSelectionModel,y,u,i,h) => {
 *                                       setSelectionModel(newSelectionModel);
 *                                   }}
 *                                   selectionModel={selectionModel}
 *                         />
 *                         </div>
 */
