import React, {useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    CircularProgress,
    LinearProgress,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow
} from "@material-ui/core";
import NO_DATA_AVAILABLE from "components/NO_DATA_AVAILABLE";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import Format from "../../components/NumberFormat";
import ArrowDown from '../../images/double-chevron-down.svg'
import axios from "../../connection/axios";
import AwsLogo from "../../images/aws_dark.svg";
import RecommendationsSummaryCards from "./RecommendationSummaryCards";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import TablePaginationActions from "../../components/TablePaginationActions";
import TablePagination from "@material-ui/core/TablePagination";
import ArrowUp from "../../images/double-chevron-up.svg";
import MountUnmount from "../../components/MountUnmount";

const { REACT_APP_GET_AWS_RIGHTSIZING_EC2_RECOMMENDATION, REACT_APP_AWS_TRUST_ADVISOR_RECOMMENDATIONS} = process.env;

const DAYS_PERIOD = 30;


const useStyles = makeStyles((theme) => ({
    root1: {
        flexGrow: 1,
        marginBottom: 10,
    },
    paper1: {
        height: 250,
        width: 262,
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#eeeeee",
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper3: {
        //width: "99%",
        // height: 680,
        //  backgroundColor: "#eee",
        //  boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
    },
}));

export default function AWSTrustedAdvisor(props) {
    const dispatch = useDispatch();

    const [trustedData, setTrustedData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    const { customerId, currency, extraRef = null, linkedAccounts } = props;

    const classes = useStyles();

    function getTrustedAdvisorData(tenantId){
         setLoading(true);
        axios.get(REACT_APP_AWS_TRUST_ADVISOR_RECOMMENDATIONS, {
            params: {
                customerId, tenantId
            }
        })
            .then(response=>response.data)
            .then(response=> {
                if(response?.data && response?.data?.awsTrustedAdvisorChecks) {
                    processTrustedData(response?.data);
                }
            })
            .finally(()=>setLoading(false));
    }

    useEffect(()=>{
        if(linkedAccounts?.aws && linkedAccounts?.aws.length && linkedAccounts?.aws?.[0]?.tenantId){
            getTrustedAdvisorData(linkedAccounts?.aws?.[0]?.tenantId);
        }
    }, [linkedAccounts]);

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    function processTrustedData(testing){
        if(testing.awsTrustedAdvisorChecks){
            const order = {
                "Low Utilization Amazon EC2 Instances":{
                    index: 0,
                    headers: [
                        "Instance",
                        "Estimated savings /month",
                        "Instance type",
                        "Region",
                        "Avg. CPU util.",
                        "Avg. Network I/O"
                    ],
                    value: LowUtilAmazEC2Row,
                    func: (d,i)=>{
                        let total = 0;
                        d.forEach(d=>{
                            d.cost = parseFloat(d.metadata[4].substring(1).split(',').join(''));
                            total += d.cost;
                        });
                        i.totalCostSavings = total;
                        return d.sort((a,b)=>b.cost-a.cost)
                    },
                    cards: (d)=>{
                        const sumCards = [];
                        sumCards.push({ name: d?.checkResult?.flaggedResources?.length>1 ? "EC2 instances":"EC2 instance", value: d?.checkResult?.flaggedResources?.length });
                        sumCards.push({
                            name: "Potential Savings /month",
                            value: Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(d?.checkResult?.totalCostSavings)
                        });
                        d.cards = sumCards;
                    }
                },
                "Amazon EC2 Reserved Instances Optimization": {
                    index: 1,
                    headers: [
                        "Instance type",
                        "Estimated savings /month",
                        "Platform",
                        "# RIs to purchase",
                        "Upfront RI cost",
                        "Breakeven months",
                        "Term"
                    ],
                    value: EC2RIO,
                    func: (d, i)=>{
                        let total = 0;
                        d.forEach(d=>{
                            d.cost = parseFloat(d.metadata[5].substring(1).split(',').join(''))
                            total += d.cost;
                        });
                        i.totalCostSavings = total;
                        return d.sort((a,b)=>b.cost-a.cost)
                    },
                    cards: (d)=>{
                        const sumCards = [];
                        sumCards.push({ name: d?.checkResult?.flaggedResources?.length>1 ? "EC2 instances":"EC2 instance", value: d?.checkResult?.flaggedResources?.length });
                        sumCards.push({
                            name: "Potential Savings /month",
                            value: Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(d?.checkResult?.totalCostSavings)
                        });
                        d.cards = sumCards;
                    }
                },
                "Amazon EC2 instances over-provisioned for Microsoft SQL Server":{
                    index: 2,
                    headers: null
                },
                "Amazon EC2 instances consolidation for Microsoft SQL Server":{
                    index: 3,
                    headers: null
                },
                "Amazon EC2 Reserved Instance Lease Expiration":{
                    index: 4,
                    headers: null
                },
                "Underutilized Amazon EBS Volumes":{
                    index: 5,
                    headers: [
                        "Volume",
                        "Monthly Storage Cost",
                        "Volume Type",
                        "Volume Size",
                        "Region"
                    ],
                    value: UnderUtilEBSVol,
                    func: (d,i)=>{
                        let total = 0;
                        d.forEach(d=>{
                            d.cost = parseFloat(d.metadata[5].substring(1).split(',').join(''))
                            total += d.cost;
                        });
                        i.totalCostSavings = total;
                        return d.sort((a,b)=>b.cost-a.cost)
                    },
                    cards: (d)=>{
                        const sumCards = [];
                        sumCards.push({ name: d?.checkResult?.flaggedResources?.length>1 ? "EBS volumes":"EBS volume", value: d?.checkResult?.flaggedResources?.length });
                        sumCards.push({
                            name: "Potential Savings /month",
                            value: Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(d?.checkResult?.totalCostSavings)
                        });
                        d.cards = sumCards;
                    }
                },
                "Amazon EBS over-provisioned volumes":{
                    index: 6,
                    headers: [
                        "Volume Id",
                        "Estimated monthly savings",
                        "Current Vol. Type",
                        "Recommended Vol. Type",
                        "Current Size (GB)",
                        "Recommended Size (GB)",
                        "Current Baseline/\nBurst IOPS",
                        "Current Baseline/\nBurst Throughput",
                        "Recommended Baseline/\nBurst IOPS",
                        "Recommended Baseline/\nBurst Throughput",
                        "Region"
                    ],
                    value: EBSOverProVol,
                    func: (d, i)=>{
                        let total = 0;
                        d.forEach(d=>{
                            d.cost = parseFloat(d.metadata[17].split(',').join(''))
                            total += d.cost;
                        });
                        i.totalCostSavings = total;
                        return d.sort((a,b)=>b.cost-a.cost)
                    },
                    cards: (d)=>{
                        const sumCards = [];
                        sumCards.push({ name: d?.checkResult?.flaggedResources?.length>1 ? "EBS volumes":"EBS volume", value: d?.checkResult?.flaggedResources?.length });
                        sumCards.push({
                            name: "Potential Savings /month",
                            value: Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(d?.checkResult?.totalCostSavings)
                        });
                        d.cards = sumCards;
                    }
                },
                "Idle Load Balancers":{
                    index: 7,
                    headers: null
                },
                "Unassociated Elastic IP Addresses":{
                    index: 8,
                    headers: null
                },
                "Amazon Route 53 Latency Resource Record Sets":{
                    index: 9,
                    headers: null
                },
                "Amazon RDS Idle DB Instances":{
                    index: 10,
                    headers: null
                },
                "Amazon Relational Database Service (RDS) Reserved Instance Optimization":{
                    index: 11,
                    headers: null
                },
                "Amazon Redshift Reserved Node Optimization":{
                    index: 12,
                    headers: null
                },
                "Underutilized Amazon Redshift Clusters":{
                    index: 13,
                    headers: null
                },
                "AWS Lambda over-provisioned functions for memory size":{
                    index: 14,
                    headers: null
                },
                "AWS Lambda Functions with High Error Rates":{
                    index: 15,
                    headers: null
                },
                "AWS Lambda Functions with Excessive Timeouts":{
                    index: 16,
                    headers: null
                },
                "Amazon Comprehend Underutilized Endpoints":{
                    index: 17,
                    headers: null
                },
                "Amazon OpenSearch Service Reserved Instance Optimization":{
                    index: 18,
                    headers: null
                },
                "Amazon ElastiCache Reserved Node Optimization":{
                    index: 19,
                    headers: null
                },
                "AWS Well-Architected high risk issues for cost optimization":{
                    index: 20,
                    headers: null
                },
                "Savings Plan":{
                    index: 21,
                    headers: [
                        "Savings Plan type",
                        "Estimated Monthly Savings",
                        "Payment option",
                        "Upfront cost",
                        "Hourly commitment to purchase",
                        "Estimated average utilization",
                        "Term (years)",
                    ],
                    value: SavPlan,
                    func: (d, i)=>{
                        let total = 0;
                        d.forEach(d=>{
                            d.cost = parseFloat(d.metadata[5].substring(1).split(',').join(''));
                            total += d.cost;
                        });
                        i.totalCostSavings = total;
                        return d.sort((a,b)=>b.cost-a.cost)
                    },
                    cards: (d)=>{
                        const sumCards = [];
                        sumCards.push({ name: d?.checkResult?.flaggedResources?.length>1 ? "Saving Plans":"Saving Plan", value: d?.checkResult?.flaggedResources?.length });
                        sumCards.push({
                            name: "Potential Savings /month",
                            value: Format.formatCurrencySymbol(currency) +
                                Format.formatNumber(d?.checkResult?.totalCostSavings)
                        });
                        d.cards = sumCards;
                    }
                }
            }
            const final = [];
            const h = testing.awsTrustedAdvisorChecks;
            h.forEach(t=>{
                final[order[t.name].index] = t;
                final[order[t.name].index].metadata = order[t.name].headers || t.metadata;
                final[order[t.name].index].printer = order[t.name]?.value;
                if(order[t.name].func){
                    final[order[t.name].index].checkResult.flaggedResources = order[t.name].func(
                        final[order[t.name].index].checkResult.flaggedResources, final[order[t.name].index].checkResult
                    )
                }
                if(order[t.name].cards){
                    order[t.name].cards(final[order[t.name].index]);
                }
            });
            setTrustedData(final);
        }
    }

    return (
        <div>
            <MountUnmount onUnmount={()=> {
                setDashboardComponentsRef({});
                setDashboardComponentsCollapsed(false);
            }} />
            {trustedData?.length || 0?
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                        <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                </div>:null
            }
            <div>
                {
                    loading ?
                        <LinearProgress thickness={2} size={60}/>:
                        trustedData?
                trustedData?.map(t=>(
                    <TrustedAdvisorContainer loading={loading} classes={classes} t={t}
                                             dashboardComponentsRef={dashboardComponentsRef}
                                             setDashboardComponentsRef={setDashboardComponentsRef} currency={currency} />
                )):
                null
                }
            </div>

        </div>
    );
}

function TrustedAdvisorContainer({setDashboardComponentsRef, dashboardComponentsRef, t, loading, classes, currency}){
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <><ContainerCardWithDateRange
            someref={{how: setDashboardComponentsRef, what:t.name, where: dashboardComponentsRef}}
            title={t.name}
            titleIcon={AwsLogo}
            showDateRange={false}
            collapsible={true}
            // onlyTotal={t?.checkResult?.flaggedResources?.length!=0}
            // totalCost={()=>(Format.formatCurrencySymbol(currency) + Format.formatNumber(t?.checkResult?.totalCostSavings))}
            // HeaderTitleComponent={() => (
            //     <div>
            //         <img src={Ec2Icon} height={25} style={{marginLeft: 8, marginRight: 8}}/>
            //         <span>
            //                 {t.name}
            //             </span>
            //     </div>
            // )}
            // CenterComponent={()=>(
            //     <div>
            //         {cards != null && cards ?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>{cards?.[1]?.value} {cards?.[1]?.name} </h6>:null}
            //     </div>
            // )}
            // ExtraHeaderComponent={({ period }) =>
            //     data != null && data?.length ? (
            //         <div style={{ marginTop: 4 }}>
            //             <span style={{fontSize: 14}}>Last updated {moment(lastUpdated).fromNow(true)} ago</span>
            //         </div>
            //     ) : null
            // }
            CenterComponent={()=>(
                <div>
                    {(t?.checkResult?.flaggedResources?.length &&  t?.checkResult?.totalCostSavings)?<h6 style={{ fontWeight: "bold", marginTop: 6, paddingLeft: 20}}>Estimated monthly savings {(t?.checkResult?.flaggedResources?.length)?
                        (Format.formatCurrencySymbol(currency) + Format.formatNumber(t?.checkResult?.totalCostSavings))
                        : null} </h6>:null}
                </div>
            )}
        >
            {({period}) => (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    {!loading ?
                        t?.checkResult?.flaggedResources?.length?
                        <div style={{flex: 1}}>
                            {t?.cards && <RecommendationsSummaryCards summary={t?.cards} />}
                            <TableContainer className={classes.container}>
                                <Table
                                    className={classes.table}
                                    stickyHeader
                                    aria-label="sticky table"
                                    size={"small"}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {
                                                t.metadata.map(c=>(
                                                    <StyledTableCell
                                                        align="left"
                                                        style={{ fontSize: 16, fontWeight: "bold" }}
                                                    >
                                                        {c}
                                                    </StyledTableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {t?.checkResult?.flaggedResources
                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map(r=> {
                                                const Row = t.printer;
                                                if(Row){
                                                    return <Row currency={currency} data={r.metadata} />
                                                }
                                                return <StyledTableRow>
                                                    {r?.metadata?.map(m => (
                                                        <StyledTableCell align="left">
                                                            {m}
                                                        </StyledTableCell>
                                                    ))}
                                                </StyledTableRow>
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 75]}
                                component="div"
                                count={t?.checkResult?.flaggedResources?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </div>:
                            <NO_DATA_AVAILABLE height={'auto'} />
                        :
                        <CircularProgress thickness={2} size={60}/>
                    }
                </div>
            )}
        </ContainerCardWithDateRange>
            <br/>
        </>
    )
}

function LowUtilAmazEC2Row({data, currency}){
    return (
        <StyledTableRow>
            <TableCell style={{whiteSpace: "nowrap"}}>
                {data[2] || data[1]}
                <br/>
                {(data[2])?<span className={'secondary-table-item'}>
                        {data[1]}
                    </span>:null}
            </TableCell>
        <TableCell className={'highlighted-table-cell'} style={{whiteSpace: "nowrap"}}>{data[4]} /month</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[3]}</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[0]}</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[19]}</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[20]}</TableCell>
        </StyledTableRow>
    )
}

function EC2RIO({data, currency}){
    return (
        <StyledTableRow>
            {/*<TableCell style={{whiteSpace: "nowrap"}}>*/}
            {/*    {data[2] || data[1]}*/}
            {/*    <br/>*/}
            {/*    {(data[2])?<span className={'secondary-table-item'}>*/}
            {/*            {data[1]}*/}
            {/*        </span>:null}*/}
            {/*</TableCell>*/}
        {/*<TableCell style={{whiteSpace: "nowrap"}}>{data[4]} /month</TableCell>*/}
        <TableCell style={{whiteSpace: "nowrap"}}>{data[1]}</TableCell>
        <TableCell className={'highlighted-table-cell'} style={{whiteSpace: "nowrap"}}>{data[5]} /month</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[2]}</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[3]}</TableCell>

        <TableCell style={{whiteSpace: "nowrap"}}>
            {data[6]}
            <br/>
            <span className={'secondary-table-item'}>{data[7]} /mon</span>
        </TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{data[9]} months</TableCell>
        <TableCell style={{whiteSpace: "nowrap"}}>{TERM[data[11]]}</TableCell>
        </StyledTableRow>
    )
}

function UnderUtilEBSVol({data, currency}){
    return (
        <StyledTableRow>
            {/*<TableCell style={{whiteSpace: "nowrap"}}>*/}
            {/*    {data[2] || data[1]}*/}
            {/*    <br/>*/}
            {/*    {(data[2])?<span className={'secondary-table-item'}>*/}
            {/*            {data[1]}*/}
            {/*        </span>:null}*/}
            {/*</TableCell>*/}
        {/*<TableCell style={{whiteSpace: "nowrap"}}>{data[4]} /month</TableCell>*/}
            <TableCell style={{whiteSpace: "nowrap"}}>
                {data[2] || data[1]}
                <br/>
                {data[2] ? <span className={'secondary-table-item'}>{data[1]}</span> : null}
            </TableCell>
            <TableCell className={'highlighted-table-cell'} style={{whiteSpace: "nowrap"}}>{data[5]} /month</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{data[3]}</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{Format.formatNumberInt(data[4])} GB</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{data[0]}</TableCell>
        </StyledTableRow>
    )
}

function EBSOverProVol({data, currency}){
    return (
        <StyledTableRow>
            {/*<TableCell style={{whiteSpace: "nowrap"}}>*/}
            {/*    {data[2] || data[1]}*/}
            {/*    <br/>*/}
            {/*    {(data[2])?<span className={'secondary-table-item'}>*/}
            {/*            {data[1]}*/}
            {/*        </span>:null}*/}
            {/*</TableCell>*/}
        {/*<TableCell style={{whiteSpace: "nowrap"}}>{data[4]} /month</TableCell>*/}
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[2]}</TableCell>
            <TableCell className={'highlighted-table-cell'} style={{ whiteSpace: "nowrap"}}>{Format.formatCurrencySymbol(data[18])}{Format.formatNumber(data[17])} /month</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{data[3]}</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{data[9]}</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{Format.formatNumberInt(data[4])} GB</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{Format.formatNumberInt(data[10])} GB</TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>
                {Format.formatNumberInt(data[5])}
                <br/>
                {Format.formatNumberInt(data[6])}
            </TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>
                {Format.formatNumberInt(data[7])}
                <br/>
                {Format.formatNumberInt(data[8])}
            </TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>
                {Format.formatNumberInt(data[11])}
                <br/>
                {Format.formatNumberInt(data[12])}
            </TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>
                {Format.formatNumberInt(data[13])}
                <br/>
                {Format.formatNumberInt(data[14])}
            </TableCell>
            <TableCell style={{whiteSpace: "nowrap"}}>{data[1]}</TableCell>
        </StyledTableRow>
    )
}

function SavPlan({data, currency}){
    return (
        <StyledTableRow>
            {/*<TableCell style={{whiteSpace: "nowrap"}}>*/}
            {/*    {data[2] || data[1]}*/}
            {/*    <br/>*/}
            {/*    {(data[2])?<span className={'secondary-table-item'}>*/}
            {/*            {data[1]}*/}
            {/*        </span>:null}*/}
            {/*</TableCell>*/}
        {/*<TableCell style={{whiteSpace: "nowrap"}}>{data[4]} /month</TableCell>*/}
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[0]}</TableCell>
            <TableCell className={'highlighted-table-cell'} style={{  whiteSpace: "nowrap"}}>{data[5]} /month</TableCell>
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[1]}</TableCell>
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[2]}</TableCell>
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[3]}</TableCell>
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[4]}</TableCell>
            <TableCell style={{  whiteSpace: "nowrap"}}>{data[8]}</TableCell>
        </StyledTableRow>
    )
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
        color: theme.palette.common.black,
        padding: theme.spacing(1.2),
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const TERM = {
    "ONE_YEAR": "1 year",
    "TWO_YEARS": "2 years",
    "THREE_YEARS": "3 years",
    "FOUR_YEARS": "4 years",
    "FIVE_YEARS": "5 years",
    "SIX_YEARS": "6 years",
    "SEVEN_YEARS": "7 years",
    "EIGHT_YEARS": "8 years",
    "NINE_YEARS": "9 years",
    "TEN_YEARS": "10 years",
    "ELEVEN_YEARS": "11 years",
    "TWELVE_YEARS": "12 years",
}
