import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import { Fade } from "pages/reports/ReportList";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#cfdac8", //theme.palette.common.black, //"#cfdac8",
    color: theme.palette.common.black,
    padding: theme.spacing(1.2),
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function RecommendationDetailPage({ data, selectedRecommendation }) {
  const classes = useStyles();
  const [list, setList] = useState(null);
  const [highProblem, setHighProblem] = useState(null);
  const [mediumProblem, setMediumProblem] = useState(null);
  const [lowProblem, setLowProblem] = useState(null);
  const [IRData, setIRData] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const IRTypesCal = (val, d) => {
    let count = 0;
    let actualData = [];
    for (var i = 0; i < d.length; i++) {
      if (d[i].field === val) {
        count++;
        actualData.push(d[i]);
      }
    }
    setIRData({ AD: actualData, TR: count });
    return count;
  };

  useEffect(() => {
    setHighProblem(null);
    setMediumProblem(null);
    setLowProblem(null);

    const { details } = data;
    let highrecdata = [];
    let mediumrecdata = [];
    let lowrecdata = [];

    let highIR = [];
    let mediumIR = [];
    let lowIR = [];

    switch (selectedRecommendation) {
      case "Cost":
        const { costHighData, costLowData, costMediumData, CostIR } = details;
        CostIR.map((i) => {
          if (i.impact == "High") {
            highIR.push({ field: i.impactedField, value: i.impactedValue });
          } else if (i.impact == "Medium") {
            mediumIR.push({ field: i.impactedField, value: i.impactedValue });
          } else {
            lowIR.push({ field: i.impactedField, value: i.impactedValue });
          }
        });
        if (costHighData !== undefined) {
          costHighData.map((item, index) => {
            highrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, highIR),
            });
          });

          setHighProblem({ data: highrecdata, IR: highIR });
        }
        if (costMediumData != undefined) {
          costMediumData.map((item, index) => {
            mediumrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, mediumIR),
            });
          });
          setMediumProblem({ data: mediumrecdata, IR: mediumIR });
        }
        if (costLowData != undefined) {
          costLowData.map((item, index) => {
            lowrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, lowIR),
            });
          });
          setLowProblem({ data: lowrecdata, IR: lowIR });
        }
        break;
      case "Security":
        const {
          securityHighData,
          securityMediumData,
          securityLowData,
          SecurityIR,
        } = details;

        SecurityIR.map((i) => {
          if (i.impact == "High") {
            highIR.push({ field: i.impactedField, value: i.impactedValue });
          } else if (i.impact == "Medium") {
            mediumIR.push({ field: i.impactedField, value: i.impactedValue });
          } else {
            lowIR.push({ field: i.impactedField, value: i.impactedValue });
          }
        });
        if (securityHighData !== undefined) {
          securityHighData.map((item, index) => {
            highrecdata.push({
              impact: item.impact,
              category: item.category,
              value: IRTypesCal(item.impactedField, highIR),
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
            });
          });

          setHighProblem({ data: highrecdata, IR: highIR });
        }
        if (securityMediumData != undefined) {
          securityMediumData.map((item) =>
            mediumrecdata.push({
              impact: item.impact,
              category: item.category,
              value: IRTypesCal(item.impactedField, mediumIR),
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
            })
          );

          setMediumProblem({ data: mediumrecdata, IR: mediumIR });
        }
        if (securityLowData != undefined) {
          securityLowData.map((item) =>
            lowrecdata.push({
              impact: item.impact,
              category: item.category,
              value: IRTypesCal(item.impactedField, lowIR),
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
            })
          );

          setLowProblem({ data: lowrecdata, IR: lowIR });
        }
        break;
      case "HighAvailability":
        const {
          availabilityHighData,
          availabilityMediumData,
          availabilityLowData,
          AvailabilityIR,
        } = details;
        AvailabilityIR.map((i) => {
          if (i.impact == "High") {
            highIR.push({ field: i.impactedField, value: i.impactedValue });
          } else if (i.impact == "Medium") {
            mediumIR.push({ field: i.impactedField, value: i.impactedValue });
          } else {
            lowIR.push({ field: i.impactedField, value: i.impactedValue });
          }
        });
        if (availabilityHighData !== undefined) {
          availabilityHighData.map((item) =>
            highrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, highIR),
            })
          );

          setHighProblem({ data: highrecdata, IR: highIR });
        }
        if (availabilityMediumData != undefined) {
          availabilityMediumData.map((item) =>
            mediumrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, mediumIR),
            })
          );

          setMediumProblem({ data: mediumrecdata, IR: mediumIR });
        }
        if (availabilityLowData != undefined) {
          availabilityLowData.map((item) =>
            lowrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, lowIR),
            })
          );

          setLowProblem({ data: lowrecdata, IR: lowIR });
        }
        break;
      case "Performance":
        const {
          performHighData,
          performMediumData,
          performLowData,
          PerformanceIR,
        } = details;
        PerformanceIR.map((i) => {
          if (i.impact == "High") {
            highIR.push({ field: i.impactedField, value: i.impactedValue });
          } else if (i.impact == "Medium") {
            mediumIR.push({ field: i.impactedField, value: i.impactedValue });
          } else {
            lowIR.push({ field: i.impactedField, value: i.impactedValue });
          }
        });
        if (performHighData !== undefined) {
          performHighData.map((item) =>
            highrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, highIR),
            })
          );

          setHighProblem({ data: highrecdata, IR: highIR });
        }
        if (performMediumData != undefined) {
          performMediumData.map((item) =>
            mediumrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, mediumIR),
            })
          );

          setMediumProblem({ data: mediumrecdata, IR: mediumIR });
        }
        if (performLowData != undefined) {
          performLowData.map((item) =>
            lowrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, lowIR),
            })
          );

          setLowProblem({ data: lowrecdata, IR: lowIR });
        }
        break;
      case "OperationalExcellence":
        const {
          excellenceHighData,
          excellenceMediumData,
          excellenceLowData,
          ExcellenceIR,
        } = details;

        ExcellenceIR.map((i) => {
          if (i.impact == "High") {
            highIR.push({ field: i.impactedField, value: i.impactedValue });
          } else if (i.impact == "Medium") {
            mediumIR.push({ field: i.impactedField, value: i.impactedValue });
          } else {
            lowIR.push({ field: i.impactedField, value: i.impactedValue });
          }
        });
        if (excellenceHighData !== undefined) {
          excellenceHighData.map((item) =>
            highrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, highIR),
            })
          );

          setHighProblem({ data: highrecdata, IR: highIR });
        }
        if (excellenceMediumData !== undefined) {
          excellenceMediumData.map((item) =>
            mediumrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, mediumIR),
            })
          );

          setMediumProblem({ data: mediumrecdata, IR: mediumIR });
        }
        if (excellenceLowData !== undefined) {
          excellenceLowData.map((item) =>
            lowrecdata.push({
              impact: item.impact,
              category: item.category,
              impactedField: item.impactedField,
              impactedValue: item.impactedValue,
              lastUpdated: item.lastUpdated,
              problem: item?.shortDescription.problem,
              value: IRTypesCal(item.impactedField, lowIR),
            })
          );

          setLowProblem({ data: lowrecdata, IR: lowIR });
        }
        break;

      default:
        break;
    }
  }, [data, selectedRecommendation]);
  return (
    <div>
      <Paper elevation={3}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: 16, fontWeight: "bold" }}
                >
                  Impact
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{ fontSize: 16, fontWeight: "bold" }}
                >
                  Description
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: 16, fontWeight: "bold" }}
                >
                  Category
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{ fontSize: 16, fontWeight: "bold" }}
                >
                  Impacted resource
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  style={{ fontSize: 16, fontWeight: "bold" }}
                >
                  Last updated
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {highProblem &&
                highProblem?.data?.map((row, i) => (
                  <StyledTableRow key={row.problem}>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#C85250", fontWeight: "bold" }}
                    >
                      {row.impact}
                    </StyledTableCell>

                    <StyledTableCell align="justify">
                      {"problem" in row ? row.problem : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.category}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      onClick={
                        highProblem.IR.length != 0
                          ? () => {
                              handleOpen();
                              IRTypesCal(row.impactedField, highProblem.IR);
                            }
                          : null
                      }
                      className={highProblem.IR.length == 0 ? null : "accounts"}
                    >
                      {"impactedField" in row
                        ? row.value + " " + row.impactedField.split("/")[1]
                        : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row.lastUpdated, "YYYY-MM-DD , h:mm:ss a").format(
                        "YYYY-MM-DD , h:mm:ss a"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableBody>
              {mediumProblem &&
                mediumProblem?.data?.map((row) => (
                  <StyledTableRow key={row.problem}>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#FAD02C", fontWeight: "bold" }}
                    >
                      {row.impact}
                    </StyledTableCell>
                    <StyledTableCell align="justify">
                      {"problem" in row ? row.problem : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.category}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      onClick={
                        mediumProblem.IR.length != 0
                          ? () => {
                              handleOpen();
                              IRTypesCal(row.impactedField, mediumProblem.IR);
                            }
                          : null
                      }
                      className={
                        mediumProblem.IR.length == 0 ? null : "accounts"
                      }
                    >
                      {"impactedField" in row
                        ? row.value + " " + row.impactedField.split("/")[1]
                        : null}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.lastUpdated, "YYYY-MM-DD , h:mm:ss a").format(
                        "YYYY-MM-DD , h:mm:ss a"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
            <TableBody>
              {lowProblem &&
                lowProblem?.data?.map((row) => (
                  <StyledTableRow key={row.problem}>
                    <StyledTableCell
                      align="left"
                      style={{ color: "#41729F", fontWeight: "bold" }}
                    >
                      {row.impact}
                    </StyledTableCell>
                    <StyledTableCell align="justify">
                      {"problem" in row ? row.problem : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.category}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      onClick={
                        lowProblem.IR.length != 0
                          ? () => {
                              handleOpen();
                              IRTypesCal(row.impactedField, lowProblem.IR);
                            }
                          : null
                      }
                      className={lowProblem.IR.length == 0 ? null : "accounts"}
                    >
                      {"impactedField" in row
                        ? row.value + " " + row.impactedField.split("/")[1]
                        : null}
                    </StyledTableCell>
                    <StyledTableCell>
                      {moment(row.lastUpdated, "YYYY-MM-DD , h:mm:ss a").format(
                        "YYYY-MM-DD , h:mm:ss a"
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper>
            <div style={{ maxHeight: 650 }} class="overflow-auto">
              <table id="customers">
                <tr>
                  <th>Resource type</th>
                  <th>Resource{IRData && `(${IRData.TR})`}</th>
                </tr>
                {IRData &&
                  IRData.AD.map((i) => (
                    <tr>
                      <td> {i.field.split("/")[1]}</td>
                      <td> {i.value}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </Paper>
        </Fade>
      </Modal>
    </div>
  );
}

export default RecommendationDetailPage;
