export const USER_API_LOADING = "USER_API_LOADING";
export const USER_API_SUCCESS = "USER_API_SUCCESS";
export const USER_API_FAIL = "USER_API_FAIL";

export const ADB2C_TOKENS = "ADB2C_TOKENS";
export const SIGNIN_SIGNUP = "SIGNIN_SIGNUP";
export const SWITCH_TEAM = "SWITCH_TEAM";

export const ALL_HOME_LOADING = "ALL_HOME_LOADING";
export const ALL_HOME_SUCCESS = "ALL_HOME_SUCCESS";
export const ALL_HOME_FAIL = "ALL_HOME_FAIL";

export const ALL_GCP_LOADING = "ALL_GCP_LOADING";
export const ALL_GCP_SUCCESS = "ALL_GCP_SUCCESS";
export const ALL_GCP_FAIL = "ALL_GCP_FAIL";

export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE";

export const GET_CUSTOMER_STATUS_REQUEST = "GET_CUSTOMER_STATUS_REQUEST";
export const GET_CUSTOMER_STATUS_SUCCESS = "GET_CUSTOMER_STATUS_SUCCESS";
export const GET_CUSTOMER_STATUS_FAILURE = "GET_CUSTOMER_STATUS_FAILURE";

export const GET_WHITE_LABEL_REQUEST = "GET_WHITE_LABEL_REQUEST";
export const GET_WHITE_LABEL_SUCCESS = "GET_WHITE_LABEL_SUCCESS";
export const GET_WHITE_LABEL_FAILURE = "GET_WHITE_LABEL_FAILURE";

export const CLOUD_ACCOUNT_REQUEST = "CLOUD_ACCOUNT_REQUEST";
export const CLOUD_ACCOUNT_SUCCESS = "CLOUD_ACCOUNT_SUCCESS";
export const CLOUD_ACCOUNT_FAIL = "CLOUD_ACCOUNT_FAIL";

export const AZURE_RECOMMENDATION_REQUEST = "AZURE_RECOMMENDATION_REQUEST";
export const AZURE_RECOMMENDATION_SUCCESS = "AZURE_RECOMMENDATION_SUCCESS";
export const AZURE_RECOMMENDATION_FAIL = "AZURE_RECOMMENDATION_FAIL";

export const AZURE_SUB_STOPPED_VM_RECOMMENDATION_REQUEST = "AZURE_SUB_STOPPED_VM_RECOMMENDATION_REQUEST";
export const AZURE_SUB_STOPPED_VM_RECOMMENDATION_SUCCESS = "AZURE_SUB_STOPPED_VM_RECOMMENDATION_SUCCESS";
export const AZURE_SUB_STOPPED_VM_RECOMMENDATION_FAILED  = "AZURE_SUB_STOPPED_VM_RECOMMENDATION_FAILED";

export const AZURE_HOME_RECOMMENDATION_REQUEST = "AZURE_HOME_RECOMMENDATION_REQUEST";
export const AZURE_HOME_RECOMMENDATION_SUCCESS = "AZURE_HOME_RECOMMENDATION_SUCCESS";
export const AZURE_HOME_RECOMMENDATION_FAILED = "AZURE_HOME_RECOMMENDATION_FAILED";

export const AZURE_SERVERLESS_REQUEST = "AZURE_SERVERLESS_REQUEST";
export const AZURE_SERVERLESS_SUCCESS = "AZURE_SERVERLESS_SUCCESS";
export const AZURE_SERVERLESS_FAIL = "AZURE_SERVERLESS_FAIL";

export const AZURE_SERVERLESS_HOME_REQUEST = "AZURE_SERVERLESS_HOME_REQUEST";
export const AZURE_SERVERLESS_HOME_SUCCESS = "AZURE_SERVERLESS_HOME_SUCCESS";
export const AZURE_SERVERLESS_HOME_FAIL = "AZURE_SERVERLESS_HOME_FAIL";

export const AZURE_SERVERLESS_TENANT_REQUEST = "AZURE_SERVERLESS_TENANT_REQUEST";
export const AZURE_SERVERLESS_TENANT_SUCCESS = "AZURE_SERVERLESS_TENANT_SUCCESS";
export const AZURE_SERVERLESS_TENANT_FAIL = "AZURE_SERVERLESS_TENANT_FAIL";

export const AZURE_RESOURCES_REQUEST = "AZURE_RESOURCES_REQUEST";
export const AZURE_RESOURCES_SUCCESS = "AZURE_RESOURCES_SUCCESS";
export const AZURE_RESOURCES_FAIL = "AZURE_RESOURCES_FAIL";

export const AZURE_TENANT_RESOURCES_REQUEST = "AZURE_TENANT_RESOURCES_REQUEST";
export const AZURE_TENANT_RESOURCES_SUCCESS = "AZURE_TENANT_RESOURCES_SUCCESS";
export const AZURE_TENANT_RESOURCES_FAIL = "AZURE_TENANT_RESOURCES_FAIL";

export const AZURE_RESOURCE_GROUPS_REQUEST = "AZURE_RESOURCE_GROUPS_REQUEST";
export const AZURE_RESOURCE_GROUPS_SUCCESS = "AZURE_RESOURCE_GROUPS_SUCCESS";
export const AZURE_RESOURCE_GROUPS_FAIL = "AZURE_RESOURCE_GROUPS_FAIL";

export const AZURE_TENANT_RESOURCE_GROUPS_REQUEST = "AZURE_TENANT_RESOURCE_GROUPS_REQUEST";
export const AZURE_TENANT_RESOURCE_GROUPS_SUCCESS = "AZURE_TENANT_RESOURCE_GROUPS_SUCCESS";
export const AZURE_TENANT_RESOURCE_GROUPS_FAIL = "AZURE_TENANT_RESOURCE_GROUPS_FAIL";

export const AWS_SERVERLESS_REQUEST = "AWS_SERVERLESS_REQUEST";
export const AWS_SERVERLESS_SUCCESS = "AWS_SERVERLESS_SUCCESS";
export const AWS_SERVERLESS_FAIL = "AWS_SERVERLESS_FAIL";

export const GCP_SERVERLESS_REQUEST = "GCP_SERVERLESS_REQUEST";
export const GCP_SERVERLESS_SUCCESS = "GCP_SERVERLESS_SUCCESS";
export const GCP_SERVERLESS_FAIL = "GCP_SERVERLESS_FAIL";

export const GET_SUBSCRIPTIONS_REQUEST = "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAILURE = "GET_SUBSCRIPTIONS_FAILURE";

export const GET_BUDGETS_REQUEST = "GET_BUDGETS_REQUEST";
export const GET_BUDGETS_SUCCESS = "GET_BUDGETS_SUCCESS";
export const GET_BUDGETS_FAILURE = "GET_BUDGETS_FAILURE";

export const GET_BUDGET_SUMMARY_REQUEST = "GET_BUDGET_SUMMARY_REQUEST";
export const GET_BUDGET_SUMMARY_SUCCESS = "GET_BUDGET_SUMMARY_SUCCESS";
export const GET_BUDGET_SUMMARY_FAILURE = "GET_BUDGET_SUMMARY_FAILURE";

export const GET_REPORTS_NAME_REQUEST = "GET_REPORTS_NAME_REQUEST";
export const GET_REPORTS_NAME_SUCCESS = "GET_REPORTS_NAME_SUCCESS";
export const GET_REPORTS_NAME_FAILURE = "GET_REPORTS_NAME_FAILURE";

export const GET_REPORTS_ARRAY_REQUEST = "GET_REPORTS_ARRAY_REQUEST";
export const GET_REPORTS_ARRAY_SUCCESS = "GET_REPORTS_ARRAY_SUCCESS";
export const GET_REPORTS_ARRAY_FAILURE = "GET_REPORTS_ARRAY_FAILURE";

export const GET_AWS_ACCOUNT_SPENDING_REQUEST = "GET_AWS_ACCOUNT_SPENDING_REQUEST";
export const GET_AWS_ACCOUNT_SPENDING_SUCCESS = "GET_AWS_ACCOUNT_SPENDING_SUCCESS";
export const GET_AWS_ACCOUNT_SPENDING_FAILURE = "GET_AWS_ACCOUNT_SPENDING_FAILURE";

export const GET_AWS_SUB_SPENDING_REQUEST = "GET_AWS_SUB_SPENDING_REQUEST";
export const GET_AWS_SUB_SPENDING_SUCCESS = "GET_AWS_SUB_SPENDING_SUCCESS";
export const GET_AWS_SUB_SPENDING_FAILURE = "GET_AWS_SUB_SPENDING_FAILURE";

export const GET_AWS_ORG_SPENDING_REQUEST = "GET_AWS_ORG_SPENDING_REQUEST";
export const GET_AWS_ORG_SPENDING_SUCCESS = "GET_AWS_ORG_SPENDING_SUCCESS";
export const GET_AWS_ORG_SPENDING_FAILURE = "GET_AWS_ORG_SPENDING_FAILURE";

export const GET_INVOICE_JSON_REQUEST = "GET_INVOICE_JSON_REQUEST";
export const GET_INVOICE_JSON_SUCCESS = "GET_INVOICE_JSON_SUCCESS";
export const GET_INVOICE_JSON_FAILURE = "GET_INVOICE_JSON_FAILURE";

export const GET_AZURE_MANAGEMENT_REQUEST = "GET_AZURE_MANAGEMENT_REQUEST";
export const GET_AZURE_MANAGEMENT_SUCCESS = "GET_AZURE_MANAGEMENT_SUCCESS";
export const GET_AZURE_MANAGEMENT_FAILURE = "GET_AZURE_MANAGEMENT_FAILURE";

export const GET_AZURE_TENANT_MANAGEMENT_REQUEST = "GET_AZURE_TENANT_MANAGEMENT_REQUEST";
export const GET_AZURE_TENANT_MANAGEMENT_SUCCESS = "GET_AZURE_TENANT_MANAGEMENT_SUCCESS";
export const GET_AZURE_TENANT_MANAGEMENT_FAILURE = "GET_AZURE_TENANT_MANAGEMENT_FAILURE";


export const GET_AWS_RESERVATION_REQUEST = "GET_AWS_RESERVATION_REQUEST";
export const GET_AWS_RESERVATION_SUCCESS = "GET_AWS_RESERVATION_SUCCESS";
export const AWS_RESERVATION_FAILURE = "GET_AWS_RESERVATION_FAILURE";

export const GET_AWS_ACCOUNT_RESERVATION_REQUEST = "GET_AWS_ACCOUNT_RESERVATION_REQUEST";
export const GET_AWS_ACCOUNT_RESERVATION_SUCCESS = "GET_AWS_ACCOUNT_RESERVATION_SUCCESS";
export const AWS_ACCOUNT_RESERVATION_FAILURE = "GET_AWS_ACCOUNT_RESERVATION_FAILURE";

export const GET_CONNECTIONERROR_REQUEST = "GET_CONNECTIONERROR_REQUEST";
export const GET_CONNECTIONERROR_SUCCESS = "GET_CONNECTIONERROR_SUCCESS";
export const GET_CONNECTIONERROR_FAILURE = "GET_CONNECTIONERROR_FAILURE";
export const SET_DELETED_ERROR = "SET_DELETED_ERROR";

export const AZURE_RESERVATION_REQUEST = "AZURE_RESERVATION_REQUEST";
export const AZURE_RESERVATION_SUCCESS = "AZURE_RESERVATION_SUCCESS";
export const AZURE_RESERVATION_FAILURE = "AZURE_RESERVATION_FAILURE";

export const AZURE_RESERVATION_SUB_REQUEST = "AZURE_RESERVATION_SUB_REQUEST";
export const AZURE_RESERVATION_SUB_SUCCESS = "AZURE_RESERVATION_SUB_SUCCESS";
export const AZURE_RESERVATION_SUB_FAILURE = "AZURE_RESERVATION_SUB_FAILURE";

export const AZURE_CCK_REQUEST = "AZURE_CCK_REQUEST";
export const AZURE_CCK_SUCCESS = "AZURE_CCK_SUCCESS";
export const AZURE_CCK_FAILURE = "AZURE_CCK_FAILURE";

export const AZURE_CCK_TENANT_REQUEST = "AZURE_CCK_TENANT_REQUEST";
export const AZURE_CCK_TENANT_SUCCESS = "AZURE_CCK_TENANT_SUCCESS";
export const AZURE_CCK_TENANT_FAILURE = "AZURE_CCK_TENANT_FAILURE";

export const AZURE_CCK_SUB_REQUEST = "AZURE_CCK_SUB_REQUEST";
export const AZURE_CCK_SUB_SUCCESS = "AZURE_CCK_SUB_SUCCESS";
export const AZURE_CCK_SUB_FAILURE = "AZURE_CCK_SUB_FAILURE";
//-----------------------------------AWS CCK-------------------------------------------

export const AWS_CCK_REQUEST = "AWS_CCK_REQUEST";
export const AWS_CCK_SUCCESS = "AWS_CCK_SUCCESS";
export const AWS_CCK_FAILURE = "AWS_CCK_FAILURE";

export const AWS_CCK_SUB_REQUEST = "AWS_CCK_SUB_REQUEST";
export const AWS_CCK_SUB_SUCCESS = "AWS_CCK_SUB_SUCCESS";
export const AWS_CCK_SUB_FAILURE = "AWS_CCK_SUB_FAILURE";

export const AWS_COMPUTE_OPTIMIZER_REQUEST = "AWS_COMPUTE_OPTIMIZER_REQUEST";
export const AWS_COMPUTE_OPTIMIZER_SUCCESS = "AWS_COMPUTE_OPTIMIZER_SUCCESS";
export const AWS_COMPUTE_OPTIMIZER_FAILURE = "AWS_COMPUTE_OPTIMIZER_FAILURE";

//---------------------------------------------------------------------------------------

export const AZURE_COSMOSDB_REQUEST = "AZURE_COSMOSDB_REQUEST";
export const AZURE_COSMOSDB_SUCCESS = "AZURE_COSMOSDB_SUCCESS";
export const AZURE_COSMOSDB_FAILURE = "AZURE_COSMOSDB_FAILURE";

export const AZURE_COSMOSDB_METRICS_REQUEST = "AZURE_COSMOSDB_METRICS_REQUEST";
export const AZURE_COSMOSDB_METRICS_SUCCESS = "AZURE_COSMOSDB_METRICS_SUCCESS";
export const AZURE_COSMOSDB_METRICS_FAILURE = "AZURE_COSMOSDB_METRICS_FAILURE";

export const AZURE_COSMOSDB_DETAILS_REQUEST = "AZURE_COSMOSDB_DETAILS_REQUEST";
export const AZURE_COSMOSDB_DETAILS_SUCCESS = "AZURE_COSMOSDB_DETAILS_SUCCESS";
export const AZURE_COSMOSDB_DETAILS_FAILURE = "AZURE_COSMOSDB_DETAILS_FAILURE";

export const AZURE_COSMOSDB_CHARTDATA_REQUEST = "AZURE_COSMOSDB_CHARTDATA_REQUEST";
export const AZURE_COSMOSDB_CHARTDATA_SUCCESS = "AZURE_COSMOSDB_CHARTDATA_SUCCESS";
export const AZURE_COSMOSDB_CHARTDATA_FAILURE = "AZURE_COSMOSDB_CHARTDATA_FAILURE";

//-----------------------------------COSMOSDB Tenant LEVEL---------------------
export const AZURE_COSMOSDB_TENANT_REQUEST = "AZURE_COSMOSDB_TENANT_REQUEST";
export const AZURE_COSMOSDB_TENANT_SUCCESS = "AZURE_COSMOSDB_TENANT_SUCCESS";
export const AZURE_COSMOSDB_TENANT_FAILURE = "AZURE_COSMOSDB_TENANT_FAILURE";

//-----------------------------------COSMOSDB SUBSCRIPTION LEVEL---------------------
export const AZURE_COSMOSDB_SUB_REQUEST = "AZURE_COSMOSDB_SUB_REQUEST";
export const AZURE_COSMOSDB_SUB_SUCCESS = "AZURE_COSMOSDB_SUB_SUCCESS";
export const AZURE_COSMOSDB_SUB_FAILURE = "AZURE_COSMOSDB_SUB_FAILURE";

//-----------------------------------COSMOSDB SUBSCRIPTION LEVEL---------------------
export const AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_REQUEST = "AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_REQUEST";
export const AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_SUCCESS = "AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_SUCCESS";
export const AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_FAILURE = "AZURE_COSMOSDB_SUB_1Y_TABLE_DATA_FAILURE";

//-----------------------------------COSMOSDB SUBSCRIPTION LEVEL 3M METRIC---------------------
export const AZURE_COSMOSDB_SUB_3M_METRIC_REQUEST = "AZURE_COSMOSDB_SUB_3M_METRIC_REQUEST";
export const AZURE_COSMOSDB_SUB_3M_METRIC_SUCCESS = "AZURE_COSMOSDB_SUB_3M_METRIC_SUCCESS";
export const AZURE_COSMOSDB_SUB_3M_METRIC_FAILURE = "AZURE_COSMOSDB_SUB_3M_METRIC_FAILURE";

//-----------------------------------COSMOSDB AZURE LEVEL 3M METRIC---------------------
export const AZURE_COSMOSDB_3M_METRIC_REQUEST = "AZURE_COSMOSDB_3M_METRIC_REQUEST";
export const AZURE_COSMOSDB_3M_METRIC_SUCCESS = "AZURE_COSMOSDB_3M_METRIC_SUCCESS";
export const AZURE_COSMOSDB_3M_METRIC_FAILURE = "AZURE_COSMOSDB_3M_METRIC_FAILURE";

//-----------------------------------COSMOSDB SUBSCRIPTION LEVEL---------------------
export const AZURE_COSMOSDB_1Y_TABLE_DATA_REQUEST = "AZURE_COSMOSDB_1Y_TABLE_DATA_REQUEST";
export const AZURE_COSMOSDB_1Y_TABLE_DATA_SUCCESS = "AZURE_COSMOSDB_1Y_TABLE_DATA_SUCCESS";
export const AZURE_COSMOSDB_1Y_TABLE_DATA_FAILURE = "AZURE_COSMOSDB_1Y_TABLE_DATA_FAILURE";

//------------------------------------AWS DYNAMODB-----------------------------------

export const AWS_DYNAMODB_REQUEST = "AWS_DYNAMODB_REQUEST";
export const AWS_DYNAMODB_SUCCESS = "AWS_DYNAMODB_SUCCESS";
export const AWS_DYNAMODB_FAILURE = "AWS_DYNAMODB_FAILURE";

export const AWS_DYNAMODB_ACC_REQUEST = "AWS_DYNAMODB_ACC_REQUEST";
export const AWS_DYNAMODB_ACC_SUCCESS = "AWS_DYNAMODB_ACC_SUCCESS";
export const AWS_DYNAMODB_ACC_FAILURE = "AWS_DYNAMODB_ACC_FAILURE";
//-----------------------------------AWS lambda function------------------------------

export const AWS_LAMBDA_REQUEST = "AWS_LAMBDA_REQUEST";
export const AWS_LAMBDA_SUCCESS = "AWS_LAMBDA_SUCCESS";
export const AWS_LAMBDA_FAILURE = "AWS_LAMBDA_FAILURE";

export const AWS_LAMBDA_ACCOUNT_REQUEST = "AWS_LAMBDA_ACCOUNT_REQUEST";
export const AWS_LAMBDA_ACCOUNT_SUCCESS = "AWS_LAMBDA_ACCOUNT_SUCCESS";
export const AWS_LAMBDA_ACCOUNT_FAILURE = "AWS_LAMBDA_ACCOUNT_FAILURE";

//------------------------------ AWS S3 Recommendation ----------------------------------------
export const AWS_RECOMMENDATIONS_REQUEST = "AWS_RECOMMENDATIONS_REQUEST";
export const AWS_RECOMMENDATIONS_SUCCESS = "AWS_RECOMMENDATIONS_SUCCESS";
export const AWS_RECOMMENDATIONS_FAILURE = "AWS_RECOMMENDATIONS_FAILURE";

//------------------------------ AWS S3 Bucket ----------------------------------------
export const AWS_S3BUCKET_REQUEST = "AWS_S3BUCKET_REQUEST";
export const AWS_S3BUCKET_SUCCESS = "AWS_S3BUCKET_SUCCESS";
export const AWS_S3BUCKET_FAILURE = "AWS_S3BUCKET_FAILURE";

export const AWS_S3METRIC_DATA_REQUEST = "AWS_S3METRIC_DATA_REQUEST";
export const AWS_S3METRIC_DATA_SUCCESS = "AWS_S3METRIC_DATA_SUCCESS";
export const AWS_S3METRIC_DATA_FAILURE = "AWS_S3METRIC_DATA_FAILURE";

export const AWS_S3BUCKET_ACCOUNT_REQUEST = "AWS_S3BUCKET_ACCOUNT_REQUEST";
export const AWS_S3BUCKET_ACCOUNT_SUCCESS = "AWS_S3BUCKET_ACCOUNT_SUCCESS";
export const AWS_S3BUCKET_ACCOUNT_FAILURE = "AWS_S3BUCKET_ACCOUNT_FAILURE";

//------------------------------------GCP PROJECT Account------------------------------

export const GCP_PROJECT_REQUEST = "GCP_PROJECT_REQUEST";
export const GCP_PROJECT_SUCCESS = "GCP_PROJECT_SUCCESS";
export const GCP_PROJECT_FAILURE = "GCP_PROJECT_FAILURE";

export const GCP_PROJECT_ACCOUNT_REQUEST = "GCP_PROJECT_ACCOUNT_REQUEST";
export const GCP_PROJECT_ACCOUNT_SUCCESS = "GCP_PROJECT_ACCOUNT_SUCCESS";
export const GCP_PROJECT_ACCOUNT_FAILURE = "GCP_PROJECT_ACCOUNT_FAILURE";

//------------------------------------GCP SERVICES -------------------------------------

export const GCP_SERVICES_REQUEST = "GCP_SERVICES_REQUEST";
export const GCP_SERVICES_SUCCESS = "GCP_SERVICES_SUCCESS";
export const GCP_SERVICES_FAILURE = "GCP_SERVICES_FAILURE";

export const GCP_SERVICES_ACCOUNT_REQUEST = "GCP_SERVICES_ACCOUNT_REQUEST";
export const GCP_SERVICES_ACCOUNT_SUCCESS = "GCP_SERVICES_ACCOUNT_SUCCESS";
export const GCP_SERVICES_ACCOUNT_FAILURE = "GCP_SERVICES_ACCOUNT_FAILURE";

export const GCP_SERVICES_PROJECT_ACCOUNT_REQUEST = "GCP_SERVICES_PROJECT_ACCOUNT_REQUEST";
export const GCP_SERVICES_PROJECT_ACCOUNT_SUCCESS = "GCP_SERVICES_PROJECT_ACCOUNT_SUCCESS";
export const GCP_SERVICES_PROJECT_ACCOUNT_FAILURE = "GCP_SERVICES_PROJECT_ACCOUNT_FAILURE";

//-------------------------------------GCP COMPUTE---------------------------------------

export const GCP_COMPUTE_REQUEST = "GCP_COMPUTE_REQUEST";
export const GCP_COMPUTE_SUCCESS = "GCP_COMPUTE_SUCCESS";
export const GCP_COMPUTE_FAILURE = "GCP_COMPUTE_FAILURE";

export const GCP_COMPUTE_ACCOUNT_REQUEST = "GCP_COMPUTE_ACCOUNT_REQUEST";
export const GCP_COMPUTE_ACCOUNT_SUCCESS = "GCP_COMPUTE_ACCOUNT_SUCCESS";
export const GCP_COMPUTE_ACCOUNT_FAILURE = "GCP_COMPUTE_ACCOUNT_FAILURE";

export const GCP_COMPUTE_PROJECT_ACCOUNT_REQUEST = "GCP_COMPUTE_PROJECT_ACCOUNT_REQUEST";
export const GCP_COMPUTE_PROJECT_ACCOUNT_SUCCESS = "GCP_COMPUTE_PROJECT_ACCOUNT_SUCCESS";
export const GCP_COMPUTE_PROJECT_ACCOUNT_FAILURE = "GCP_COMPUTE_PROJECT_ACCOUNT_FAILURE";

export const SET_CLOUD_ACTIVE_TAB = "SET_CLOUD_ACTIVE_TAB";
export const SET_CLOUD_ACTIVE_TAB_REQUEST = "SET_CLOUD_ACTIVE_TAB_REQUEST";

//-------------------------------------GCP STORAGE---------------------------------------

export const GCP_STORAGE_REQUEST = "GCP_STORAGE_REQUEST";
export const GCP_STORAGE_SUCCESS = "GCP_STORAGE_SUCCESS";
export const GCP_STORAGE_FAILURE = "GCP_STORAGE_FAILURE";

export const GCP_STORAGE_ACCOUNT_REQUEST = "GCP_STORAGE_ACCOUNT_REQUEST";
export const GCP_STORAGE_ACCOUNT_SUCCESS = "GCP_STORAGE_ACCOUNT_SUCCESS";
export const GCP_STORAGE_ACCOUNT_FAILURE = "GCP_STORAGE_ACCOUNT_FAILURE";

export const GCP_STORAGE_PROJECT_ACCOUNT_REQUEST = "GCP_STORAGE_PROJECT_ACCOUNT_REQUEST";
export const GCP_STORAGE_PROJECT_ACCOUNT_SUCCESS = "GCP_STORAGE_PROJECT_ACCOUNT_SUCCESS";
export const GCP_STORAGE_PROJECT_ACCOUNT_FAILURE = "GCP_STORAGE_PROJECT_ACCOUNT_FAILURE";

//---------------------------------------ALL cloud compute--------------------------------

export const ALL_COMPUTE_REQUEST = "ALL_COMPUTE_REQUEST";
export const ALL_COMPUTE_SUCCESS = "ALL_COMPUTE_SUCCESS";
export const ALL_COMPUTE_FAILURE = "ALL_COMPUTE_FAILURE";

//---------------------------------------ALL Database --------------------------------

export const ALL_DATABASE_REQUEST = "ALL_DATABASE_REQUEST";
export const ALL_DATABASE_SUCCESS = "ALL_DATABASE_SUCCESS";
export const ALL_DATABASE_FAILURE = "ALL_DATABASE_FAILURE";

//---------------------------------------ALL Functions --------------------------------

export const ALL_FUNCTIONS_REQUEST = "ALL_FUNCTIONS_REQUEST";
export const ALL_FUNCTIONS_SUCCESS = "ALL_FUNCTIONS_SUCCESS";
export const ALL_FUNCTIONS_FAILURE = "ALL_FUNCTIONS_FAILURE";

//---------------------------------------ALL Storage --------------------------------

export const ALL_STORAGE_REQUEST = "ALL_STORAGE_REQUEST";
export const ALL_STORAGE_SUCCESS = "ALL_STORAGE_SUCCESS";
export const ALL_STORAGE_FAILURE = "ALL_STORAGE_FAILURE";

//---------------------------------------ALL ACCOUNT --------------------------------

export const ALL_ACCOUNT_REQUEST = "ALL_ACCOUNT_REQUEST";
export const ALL_ACCOUNT_SUCCESS = "ALL_ACCOUNT_SUCCESS";
export const ALL_ACCOUNT_FAILURE = "ALL_ACCOUNT_FAILURE";

//---------------------------------------ALL Services --------------------------------

export const ALL_SERVICES_REQUEST = "ALL_SERVICES_REQUEST";
export const ALL_SERVICES_SUCCESS = "ALL_SERVICES_SUCCESS";
export const ALL_SERVICES_FAILURE = "ALL_SERVICES_FAILURE";

//---------------------------------------ALL Resources --------------------------------

export const ALL_RESOURCES_REQUEST = "ALL_RESOURCES_REQUEST";
export const ALL_RESOURCES_SUCCESS = "ALL_RESOURCES_SUCCESS";
export const ALL_RESOURCES_FAILURE = "ALL_RESOURCES_FAILURE";

//------------------------------------------------------------------------------------

export const GET_CONNECTED_TEAMS_LIST_REQUEST = "GET_CONNECTED_TEAMS_LIST_REQUEST";
export const GET_CONNECTED_TEAMS_LIST_SUCCESS = "GET_CONNECTED_TEAMS_LIST_SUCCESS";
export const GET_CONNECTED_TEAMS_LIST_FAILURE = "GET_CONNECTED_TEAMS_LIST_FAILURE";
//---------------------------------------Azure Functions----------------------------------

export const AZURE_FUNCTION_REQUEST = "AZURE_FUNCTION_REQUEST";
export const AZURE_FUNCTION_SUCCESS = "AZURE_FUNCTION_SUCCESS";
export const AZURE_FUNCTION_FAILURE = "AZURE_FUNCTION_FAILURE";

export const AZURE_FUNCTION_TENANT_REQUEST = "AZURE_FUNCTION_TENANT_REQUEST";
export const AZURE_FUNCTION_TENANT_SUCCESS = "AZURE_FUNCTION_TENANT_SUCCESS";
export const AZURE_FUNCTION_TENANT_FAILURE = "AZURE_FUNCTION_TENANT_FAILURE";


export const AZURE_FUNCTION_ACCOUNT_REQUEST = "AZURE_FUNCTION_ACCOUNT_REQUEST";
export const AZURE_FUNCTION_ACCOUNT_SUCCESS = "AZURE_FUNCTION_ACCOUNT_SUCCESS";
export const AZURE_FUNCTION_ACCOUNT_FAILURE = "AZURE_FUNCTION_ACCOUNT_FAILURE";


//---------------------------------------Azure Storage----------------------------------

export const AZURE_STORAGE_REQUEST = "AZURE_STORAGE_REQUEST";
export const AZURE_STORAGE_SUCCESS = "AZURE_STORAGE_SUCCESS";
export const AZURE_STORAGE_FAILURE = "AZURE_STORAGE_FAILURE";

export const AZURE_STORAGE_TENANT_REQUEST = "AZURE_STORAGE_TENANT_REQUEST";
export const AZURE_STORAGE_TENANT_SUCCESS = "AZURE_STORAGE_TENANT_SUCCESS";
export const AZURE_STORAGE_TENANT_FAILURE = "AZURE_STORAGE_TENANT_FAILURE";

export const AZURE_STORAGE_ACCOUNT_REQUEST = "AZURE_STORAGE_ACCOUNT_REQUEST";
export const AZURE_STORAGE_ACCOUNT_SUCCESS = "AZURE_STORAGE_ACCOUNT_SUCCESS";
export const AZURE_STORAGE_ACCOUNT_FAILURE = "AZURE_STORAGE_ACCOUNT_FAILURE";

//--------------------------------------AWS RDS-------------------------------------------

export const AWS_RDS_REQUEST = "AWS_RDS_REQUEST";
export const AWS_RDS_SUCCESS = "AWS_RDS_SUCCESS";
export const AWS_RDS_FAILURE = "AWS_RDS_FAILURE";

export const AWS_RDS_ACCOUNT_REQUEST = "AWS_RDS_ACCOUNT_REQUEST";
export const AWS_RDS_ACCOUNT_SUCCESS = "AWS_RDS_ACCOUNT_SUCCESS";
export const AWS_RDS_ACCOUNT_FAILURE = "AWS_RDS_ACCOUNT_FAILURE";

export const AWS_RDS_RECOMMENDATION_REQUEST = "AWS_RDS_RECOMMENDATION_REQUEST";
export const AWS_RDS_RECOMMENDATION_SUCCESS = "AWS_RDS_RECOMMENDATION_SUCCESS";
export const AWS_RDS_RECOMMENDATION_FAILURE = "AWS_RDS_RECOMMENDATION_FAILURE"

export const AWS_RDS_ACCOUNT_RECOMMENDATION_REQUEST = "AWS_RDS_ACCOUNT_RECOMMENDATION_REQUEST";
export const AWS_RDS_ACCOUNT_RECOMMENDATION_SUCCESS = "AWS_RDS_ACCOUNT_RECOMMENDATION_SUCCESS";
export const AWS_RDS_ACCOUNT_RECOMMENDATION_FAILURE = "AWS_RDS_ACCOUNT_RECOMMENDATION_FAILURE"


export const AWS_EC2_RECOMMENDATION_REQUEST = "AWS_EC2_RECOMMENDATION_REQUEST";
export const AWS_EC2_RECOMMENDATION_SUCCESS = "AWS_EC2_RECOMMENDATION_SUCCESS";
export const AWS_EC2_RECOMMENDATION_FAILURE = "AWS_EC2_RECOMMENDATION_FAILURE";

export const AWS_EC2_ACCOUNT_RECOMMENDATION_REQUEST = "AWS_EC2_ACCOUNT_RECOMMENDATION_REQUEST";
export const AWS_EC2_ACCOUNT_RECOMMENDATION_SUCCESS = "AWS_EC2_ACCOUNT_RECOMMENDATION_SUCCESS";
export const AWS_EC2_ACCOUNT_RECOMMENDATION_FAILURE = "AWS_EC2_ACCOUNT_RECOMMENDATION_FAILURE"

export const AZURE_TAGS_REQUEST = "AZURE_TAGS_REQUEST";
export const AZURE_TAGS_SUCCESS = "AZURE_TAGS_SUCCESS";
export const AZURE_TAGS_FAILURE = "AZURE_TAGS_FAILURE";

export const AWS_RECOMMENDATION_SUMMARY_REQUEST = "AWS_RECOMMENDATION_SUMMARY_REQUEST";
export const AWS_RECOMMENDATION_SUMMARY_SUCCESS = "AWS_RECOMMENDATION_SUMMARY_SUCCESS";
export const AWS_RECOMMENDATION_SUMMARY_FAILURE = "AWS_RECOMMENDATION_SUMMARY_FAILURE";

export const AWS_ACCOUNT_RECOMMENDATION_SUMMARY_REQUEST = "AWS_ACCOUNT_RECOMMENDATION_SUMMARY_REQUEST";
export const AWS_ACCOUNT_RECOMMENDATION_SUMMARY_SUCCESS = "AWS_ACCOUNT_RECOMMENDATION_SUMMARY_SUCCESS";
export const AWS_ACCOUNT_RECOMMENDATION_SUMMARY_FAILURE = "AWS_ACCOUNT_RECOMMENDATION_SUMMARY_FAILURE";

export const AZURE_SERVICES_REQUEST = "AZURE_SERVICES_REQUEST";
export const AZURE_SERVICES_SUCCESS = "AZURE_SERVICES_SUCCESS";
export const AZURE_SERVICES_FAILURE = "AZURE_SERVICES_FAILURE";

export const AZURE_TENANT_SERVICES_REQUEST = "AZURE_TENANT_SERVICES_REQUEST";
export const AZURE_TENANT_SERVICES_SUCCESS = "AZURE_TENANT_SERVICES_SUCCESS";
export const AZURE_TENANT_SERVICES_FAILURE = "AZURE_TENANT_SERVICES_FAILURE";

export const AZURE_SUBSCRIPTION_SERVICES_REQUEST = "AZURE_SUBSCRIPTION_SERVICES_REQUEST";
export const AZURE_SUBSCRIPTION_SERVICES_SUCCESS = "AZURE_SUBSCRIPTION_SERVICES_SUCCESS";
export const AZURE_SUBSCRIPTION_SERVICES_FAILURE = "AZURE_SUBSCRIPTION_SERVICES_FAILURE";

export const AWS_RECOMMENDATION_DYNAMODB_REQUEST = "AWS_RECOMMENDATION_DYNAMODB_REQUEST";
export const AWS_RECOMMENDATION_DYNAMODB_SUCCESS = "AWS_RECOMMENDATION_DYNAMODB_SUCCESS";
export const AWS_RECOMMENDATION_DYNAMODB_FAILURE = "AWS_RECOMMENDATION_DYNAMODB_FAILURE";

export const AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_REQUEST = "AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_REQUEST";
export const AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_SUCCESS = "AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_SUCCESS";
export const AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_FAILURE = "AWS_ACCOUNT_RECOMMENDATION_DYNAMODB_FAILURE";

export const AZURE_STOPPED_VM_RECOMMENDATION_REQUEST = "AZURE_STOPPED_VM_RECOMMENDATION_REQUEST";
export const AZURE_STOPPED_VM_RECOMMENDATION_SUCCESS = "AZURE_STOPPED_VM_RECOMMENDATION_SUCCESS";
export const AZURE_STOPPED_VM_RECOMMENDATION_FAIL = "AZURE_STOPPED_VM_RECOMMENDATION_FAIL";

export const AZURE_STOPPED_VM_RECOMMENDATION_TENANT_REQUEST = "AZURE_STOPPED_VM_RECOMMENDATION_TENANT_REQUEST";
export const AZURE_STOPPED_VM_RECOMMENDATION_TENANT_SUCCESS = "AZURE_STOPPED_VM_RECOMMENDATION_TENANT_SUCCESS";
export const AZURE_STOPPED_VM_RECOMMENDATION_TENANT_FAIL = "AZURE_STOPPED_VM_RECOMMENDATION_TENANT_FAIL";

export const AWS_ACCOUNT_S3RECOMMENDATION_REQUEST = "AWS_ACCOUNT_S3RECOMMENDATION_REQUEST";
export const AWS_ACCOUNT_S3RECOMMENDATION_SUCCESS = "AWS_ACCOUNT_S3RECOMMENDATION_SUCCESS";
export const AWS_ACCOUNT_S3RECOMMENDATION_FAILURE = "AWS_ACCOUNT_S3RECOMMENDATION_FAILURE";

export const AWS_CONNECTION_UUID = "AWS_CONNECTION_UUID";

export const AZURE_COSMOSDB_RECOMMENDATION_REQUEST = "AZURE_COSMOSDB_RECOMMENDATION_REQUEST";
export const AZURE_COSMOSDB_RECOMMENDATION_SUCCESS = "AZURE_COSMOSDB_RECOMMENDATION_SUCCESS";
export const AZURE_COSMOSDB_RECOMMENDATION_FAIL = "AZURE_COSMOSDB_RECOMMENDATION_FAIL";

export const AZURE_DBSERVER_RECOMMENDATION_REQUEST = "AZURE_DBSERVER_RECOMMENDATION_REQUEST";
export const AZURE_DBSERVER_RECOMMENDATION_SUCCESS = "AZURE_DBSERVER_RECOMMENDATION_SUCCESS";
export const AZURE_DBSERVER_RECOMMENDATION_FAIL = "AZURE_DBSERVER_RECOMMENDATION_FAIL";

export const AZURE_FUNCTION_APP_RECOMMENDATION_REQUEST = "AZURE_FUNCTION_APP_RECOMMENDATION_REQUEST";
export const AZURE_FUNCTION_APP_RECOMMENDATION_SUCCESS = "AZURE_FUNCTION_APP_RECOMMENDATION_SUCCESS";
export const AZURE_FUNCTION_APP_RECOMMENDATION_FAIL = "AZURE_FUNCTION_APP_RECOMMENDATION_FAIL";

export const AZURE_IDLE_VM_RECOMMENDATION_REQUEST = "AZURE_IDLE_VM_RECOMMENDATION_REQUEST";
export const AZURE_IDLE_VM_RECOMMENDATION_SUCCESS = "AZURE_IDLE_VM_RECOMMENDATION_SUCCESS";
export const AZURE_IDLE_VM_RECOMMENDATION_FAIL = "AZURE_IDLE_VM_RECOMMENDATION_FAIL";

export const AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_REQUEST = "AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_REQUEST";
export const AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_SUCCESS = "AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_SUCCESS";
export const AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_FAIL = "AZURE_SQL_MANAGED_INSTANCE_RECOMMENDATION_FAIL";

export const AZURE_STOPPED_VM_RECOMMENDATION_ALL_REQUEST = "AZURE_STOPPED_VM_RECOMMENDATION_ALL_REQUEST";
export const AZURE_STOPPED_VM_RECOMMENDATION_ALL_SUCCESS = "AZURE_STOPPED_VM_RECOMMENDATION_ALL_SUCCESS";
export const AZURE_STOPPED_VM_RECOMMENDATION_ALL_FAIL = "AZURE_STOPPED_VM_RECOMMENDATION_ALL_FAIL";

export const AZURE_STORAGE_RECOMMENDATION_REQUEST = "AZURE_STORAGE_RECOMMENDATION_REQUEST";
export const AZURE_STORAGE_RECOMMENDATION_SUCCESS = "AZURE_STORAGE_RECOMMENDATION_SUCCESS";
export const AZURE_STORAGE_RECOMMENDATION_FAIL = "AZURE_STORAGE_RECOMMENDATION_FAIL";

export const AZURE_VM_MIGRATE_RECOMMENDATION_REQUEST = "AZURE_VM_MIGRATE_RECOMMENDATION_REQUEST";
export const AZURE_VM_MIGRATE_RECOMMENDATION_SUCCESS = "AZURE_VM_MIGRATE_RECOMMENDATION_SUCCESS";
export const AZURE_VM_MIGRATE_RECOMMENDATION_FAIL = "AZURE_VM_MIGRATE_RECOMMENDATION_FAIL";

export const AZURE_VM_RIGHTSIZING_RECOMMENDATION_REQUEST = "AZURE_VM_RIGHTSIZING_RECOMMENDATION_REQUEST";
export const AZURE_VM_RIGHTSIZING_RECOMMENDATION_SUCCESS = "AZURE_VM_RIGHTSIZING_RECOMMENDATION_SUCCESS";
export const AZURE_VM_RIGHTSIZING_RECOMMENDATION_FAIL = "AZURE_VM_RIGHTSIZING_RECOMMENDATION_FAIL";
