import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ExpandableTable from "components/ExpandableTable";
import { Dropdown } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HtmlECTable from "../../components/table/HtmlECTable";
import { getAzureResourcesRequest, getAzureResourceGroupsRequest, getAzureTenantResourcesRequest, getAzureTenantResourceGroupsRequest } from "../../redux/actions/actions";
import Format from "components/NumberFormat";
import { LinearProgress, Paper, Collapse, IconButton } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, PlayArrow } from "@material-ui/icons";
import DateRange from "components/DateRange";
import resource_group from "../../images/resource_group.svg";
import moment from "moment";
import StartEndDate from "components/StartEndDate";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

function AzureResources({somerefs, showData, currency, tab, customerId }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [dropdown, setDropDown] = useState("Resources");
    const [totalExpand, setTotalExpand] = useState(true);
    const [period, setPeriod] = useState("1M");
    const loading = useSelector((state) => state.azureRecommendationReducer.loading);
    let resources = useSelector((state) => state.azureRecommendationReducer.resources);
    let tenantResources = useSelector((state) => state.azureRecommendationReducer.tenantResources);
    let resourceGroups = useSelector((state) => state.azureRecommendationReducer.resourceGroups);
    let tenantResourceGroups = useSelector((state) => state.azureRecommendationReducer.tenantResourceGroups);

    let subresources = useSelector((state) => state.azureRecommendationReducer.subresources);
    let subresourceGroups = useSelector((state) => state.azureRecommendationReducer.subresourceGroups);

    useEffect(() => {
        if (tab) {
            setDropDown("Resource groups");
        }
    }, [tab]);

    useEffect(() => {
        if (tab) {
            if ("all" in showData) {
                if (showData.all != null) {
                    if (dropdown == "Resources") {
                        if (resources == null) {
                            dispatch(getAzureResourcesRequest(customerId));
                        }
                    } else {
                        if (resourceGroups == null) {
                            dispatch(getAzureResourceGroupsRequest(customerId));
                        }
                    }
                } else {
                    const { tenantId, subscriptionId } = showData;
                    if (dropdown == "Resources") {
                        dispatch(
                            getAzureResourcesRequest({
                                customerId,
                                tenantId,
                                subscriptionId,
                            })
                        );
                    } else {
                        dispatch(
                            getAzureResourceGroupsRequest({
                                customerId,
                                tenantId,
                                subscriptionId,
                            })
                        );
                    }
                }
            } else {
                const { tenantId } = showData;
                if (dropdown == "Resources") {
                    dispatch(
                        getAzureTenantResourcesRequest({
                            customerId,
                            tenantId,
                        })
                    );
                } else {
                    dispatch(
                        getAzureTenantResourceGroupsRequest({
                            customerId,
                            tenantId,
                        })
                    );
                }
            }
        } else {
            if ("all" in showData) {
                if (showData.all != null) {
                    if (dropdown == "Resources") {
                        if (resources == null) {
                            dispatch(getAzureResourcesRequest(customerId));
                        }
                    } else {
                        if (resourceGroups == null) {
                            dispatch(getAzureResourceGroupsRequest(customerId));
                        }
                    }
                } else {
                    const { tenantId, subscriptionId } = showData;
                    if (dropdown == "Resources") {
                        dispatch(getAzureResourcesRequest({ customerId, tenantId, subscriptionId }));
                    } else {
                        dispatch(
                            getAzureResourceGroupsRequest({
                                customerId,
                                tenantId,
                                subscriptionId,
                            })
                        );
                    }
                }
            } else {
                const { tenantId } = showData;
                if (dropdown == "Resources") {
                    dispatch(getAzureTenantResourcesRequest({ customerId, tenantId }));
                } else {
                    dispatch(
                        getAzureTenantResourceGroupsRequest({
                            customerId,
                            tenantId,
                        })
                    );
                }
            }
        }
    }, [customerId, showData, dropdown, tab]);

    return loading ? (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    ) : (
        <div>
            <Paper style={{ padding: 10 }} elevation={10}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <img style={{ width: 30, height: 30, marginRight: 6, marginBottom: 8 }} src={resource_group} />
                        {dropdown == "Resources" ? <h5 style={{ fontWeight: "bold" }}> Spend by Resources</h5> : <h5 style={{ fontWeight: "bold" }}> Spend by Resource groups</h5>}
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        {totalExpand && (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {"all" in showData
                                    ? showData.all != null
                                        ? dropdown == "Resources"
                                            ? resources != null
                                                ? resources && "resources" in resources?.SpendByResources[period]
                                                    ? moment(resources?.SpendByResources[period]?.startDate, "YYYYMMDD").isValid() && (
                                                          <StartEndDate startDate={resources?.SpendByResources[period]?.startDate} endDate={resources?.SpendByResources[period]?.endDate} />
                                                      )
                                                    : null
                                                : null
                                            : resourceGroups != null
                                            ? resourceGroups && "resourceGroup" in resourceGroups?.SpendByResourceGroup[period]
                                                ? moment(resourceGroups?.SpendByResourceGroup[period]?.startDate, "YYYYMMDD").isValid() && (
                                                      <StartEndDate
                                                          startDate={resourceGroups?.SpendByResourceGroup[period]?.startDate}
                                                          endDate={resourceGroups?.SpendByResourceGroup[period]?.endDate}
                                                      />
                                                  )
                                                : null
                                            : null
                                        : dropdown === "Resources"
                                        ? subresources != null
                                            ? subresources && "resources" in subresources?.SpendByResources[period]
                                                ? moment(subresources?.SpendByResources[period]?.startDate, "YYYYMMDD").isValid() && (
                                                      <StartEndDate startDate={subresources?.SpendByResources[period]?.startDate} endDate={subresources?.SpendByResources[period]?.endDate} />
                                                  )
                                                : null
                                            : null
                                        : subresourceGroups != null
                                        ? subresourceGroups && "resourceGroup" in subresourceGroups?.SpendByResourceGroup[period]
                                            ? moment(subresourceGroups?.SpendByResourceGroup[period]?.startDate, "YYYYMMDD").isValid() && (
                                                  <StartEndDate
                                                      startDate={subresourceGroups?.SpendByResourceGroup[period]?.startDate}
                                                      endDate={subresourceGroups?.SpendByResourceGroup[period]?.endDate}
                                                  />
                                              )
                                            : null
                                        : null
                                    : dropdown == "Resources" // tenant level data resource data
                                    ? tenantResources != null
                                        ? tenantResources && "resources" in tenantResources?.SpendByResources[period]
                                            ? moment(tenantResources?.SpendByResources[period]?.startDate, "YYYYMMDD").isValid() && (
                                                  <StartEndDate startDate={tenantResources?.SpendByResources[period]?.startDate} endDate={tenantResources?.SpendByResources[period]?.endDate} />
                                              )
                                            : null
                                        : null
                                    : tenantResourceGroups != null
                                    ? tenantResourceGroups && "resourceGroup" in tenantResourceGroups?.SpendByResourceGroup[period]
                                        ? moment(tenantResourceGroups?.SpendByResourceGroup[period]?.startDate, "YYYYMMDD").isValid() && (
                                              <StartEndDate
                                                  startDate={tenantResourceGroups?.SpendByResourceGroup[period]?.startDate}
                                                  endDate={tenantResourceGroups?.SpendByResourceGroup[period]?.endDate}
                                              />
                                          )
                                        : null
                                    : null}

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 8 }}>
                                    <span
                                        style={{
                                            marginRight: 4,
                                            fontSize: 12,
                                            color: "#616161",
                                        }}
                                    >
                                        Last
                                    </span>
                                    <DateRange height={22} padding={1} fontSize={12} onSelectionChanged={setPeriod} defaultSelected={period} />
                                </div>
                            </div>
                        )}
                        {tab == false ? (
                            <IconButton onClick={() => setTotalExpand(!totalExpand)} style={{ outline: "none" }}>
                                {totalExpand ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                        ) : null}
                    </div>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <div style={{ marginBottom: 8, marginLeft: 25 }}>
                        <Dropdown>
                            <Dropdown.Toggle variant="none" id="dropdown-basic">
                                {dropdown}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setDropDown(dropdown == "Resources" ? "Resource groups" : "Resources")}>
                                    {dropdown === "Resources" ? "Resource groups" : "Resources"}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <div style={{ marginRight: 10 }}>
                        {"all" in showData ? (
                            showData.all != null ? (
                                dropdown == "Resources" ? (
                                    resources != null ? (
                                        resources && "resources" in resources?.SpendByResources[period] ? (
                                            <h6>
                                                {"Total: "}
                                                <span style={{ fontWeight: "bold" }}> {currency + Format.formatNumber(resources?.SpendByResources[period]?.totalCost || currency + 0.0)}</span>
                                            </h6>
                                        ) : null
                                    ) : null
                                ) : resourceGroups != null ? (
                                    resourceGroups && "resourceGroup" in resourceGroups?.SpendByResourceGroup[period] ? (
                                        <h6>
                                            {"Total: "}
                                            <span style={{ fontWeight: "bold" }}>{currency + Format.formatNumber(resourceGroups?.SpendByResourceGroup[period]?.totalCost || currency + 0.0)}</span>
                                        </h6>
                                    ) : null
                                ) : null
                            ) : dropdown === "Resources" ? (
                                subresources != null ? (
                                    subresources && "resources" in subresources?.SpendByResources[period] ? (
                                        <h6>
                                            {"Total: "}
                                            <span style={{ fontWeight: "bold" }}> {currency + Format.formatNumber(subresources?.SpendByResources[period]?.totalCost || currency + 0.0)}</span>
                                        </h6>
                                    ) : null
                                ) : null
                            ) : subresourceGroups != null ? (
                                subresourceGroups && "resourceGroup" in subresourceGroups?.SpendByResourceGroup[period] ? (
                                    <h6>
                                        {"Total: "}
                                        <span style={{ fontWeight: "bold" }}> {currency + Format.formatNumber(subresourceGroups?.SpendByResourceGroup[period]?.totalCost || currency + 0.0)}</span>
                                    </h6>
                                ) : null
                            ) : null
                        ) : // tennat level data
                        dropdown == "Resources" ? (
                            tenantResources != null ? (
                                tenantResources && "resources" in tenantResources?.SpendByResources[period] ? (
                                    <h6>
                                        {"Total: "}
                                        <span style={{ fontWeight: "bold" }}> {currency + Format.formatNumber(tenantResources?.SpendByResources[period]?.totalCost || currency + 0.0)}</span>
                                    </h6>
                                ) : null
                            ) : null
                        ) : tenantResourceGroups != null ? (
                            tenantResourceGroups && "resourceGroup" in tenantResourceGroups?.SpendByResourceGroup[period] ? (
                                <h6>
                                    {"Total: "}
                                    <span style={{ fontWeight: "bold" }}>{currency + Format.formatNumber(tenantResourceGroups?.SpendByResourceGroup[period]?.totalCost || currency + 0.0)}</span>
                                </h6>
                            ) : null
                        ) : null}
                    </div>
                </div>
                <Collapse in={totalExpand}>
                    <div style={{}}>
                        {"all" in showData ? (
                            showData.all != null ? (
                                dropdown == "Resources" ? (
                                    <div>
                                        {resources != null ? (
                                            resources && "resources" in resources?.SpendByResources[period] ? (
                                                <ExpandableTable
                                                    tableRow={resources?.SpendByResources[period]?.resources}
                                                    data={resources?.SpendByResources[period]}
                                                    currency={currency}
                                                    dropdown={dropdown}
                                                />
                                            ) : (
                                                <div style={{ textAlign: "center" }}>No data found</div>
                                            )
                                        ) : null}
                                    </div>
                                ) : (
                                    <div>
                                        {resourceGroups != null ? (
                                            resourceGroups && "resourceGroup" in resourceGroups?.SpendByResourceGroup[period] ? (
                                                <HtmlECTable
                                                    tableRow={resourceGroups?.SpendByResourceGroup[period]?.resourceGroup}
                                                    data={resourceGroups?.SpendByResourceGroup[period]}
                                                    currency={currency}
                                                    dropdown={dropdown}
                                                />
                                            ) : (
                                                <div style={{ textAlign: "center" }}>No data found</div>
                                            )
                                        ) : null}
                                    </div>
                                )
                            ) : dropdown == "Resources" ? (
                                <div>
                                    {subresources != null ? (
                                        subresources && "resources" in subresources?.SpendByResources[period] ? (
                                            <ExpandableTable
                                                tableRow={subresources?.SpendByResources[period]?.resources}
                                                data={subresources?.SpendByResources[period]}
                                                currency={currency}
                                                dropdown={dropdown}
                                            />
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No data found</div>
                                        )
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    {subresourceGroups != null ? (
                                        subresourceGroups && "resourceGroup" in subresourceGroups?.SpendByResourceGroup[period] ? (
                                            <HtmlECTable
                                                tableRow={subresourceGroups?.SpendByResourceGroup[period]?.resourceGroup}
                                                data={subresourceGroups?.SpendByResourceGroup[period]}
                                                currency={currency}
                                                dropdown={dropdown}
                                            />
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No data found</div>
                                        )
                                    ) : null}
                                </div>
                            )
                        ) : // tenant level data
                        dropdown == "Resources" ? (
                            <div>
                                {tenantResources != null ? (
                                    tenantResources && "resources" in tenantResources?.SpendByResources[period] ? (
                                        <ExpandableTable
                                            tableRow={tenantResources?.SpendByResources[period]?.resources}
                                            data={tenantResources?.SpendByResources[period]}
                                            currency={currency}
                                            dropdown={dropdown}
                                        />
                                    ) : (
                                        <div style={{ textAlign: "center" }}>No data found</div>
                                    )
                                ) : null}
                            </div>
                        ) : (
                            <div>
                                {tenantResourceGroups != null ? (
                                    tenantResourceGroups && "resourceGroup" in tenantResourceGroups?.SpendByResourceGroup[period] ? (
                                        <HtmlECTable
                                            tableRow={tenantResourceGroups?.SpendByResourceGroup[period]?.resourceGroup}
                                            data={tenantResourceGroups?.SpendByResourceGroup[period]}
                                            currency={currency}
                                            dropdown={dropdown}
                                        />
                                    ) : (
                                        <div style={{ textAlign: "center" }}>No data found</div>
                                    )
                                ) : null}
                            </div>
                        )}
                    </div>
                </Collapse>
            </Paper>
        </div>
    );
}

export default AzureResources;
