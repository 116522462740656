import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Colors from "../styles/color";
import subscritpion_icon from "../images/key.svg";
import aws_account from "../images/aws_org.svg";
import gcp_billing from "../images/gcp_billing.svg";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
      padding: theme.spacing(1.2),
      backgroundColor: Colors.tableHeader,
      fontWeight: "bold",
    },
    tableStyle: { padding: theme.spacing(1.5) },
  },
}));

function Row(props) {
  const {
    cloud,
    row,
    keyElement,
    mainRowHeader = [],
    subRowHeader = [],
    mainRowKeys = [],
    subRowKeys = [],
    subKeyElement,
  } = props;
  const { spreadFurtherKey = null, spreadKeyData = () => "" } = props;
  const { subKeyOverride = null, subKeyOverrideData = () => "" } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow hover className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {(mainRowKeys || []).map((k) => (
          <TableCell component="th" scope="row">
            {row?.[k]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell align="center" style={{ padding: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases">
              {cloud == "Azure" ? (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    padding: 4,
                  }}
                >
                  <img
                    style={{ width: 20, height: 20, marginRight: 4 }}
                    src={subscritpion_icon}
                  />{" "}
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    Subscriptions{" "}
                  </Typography>
                </div>
              ) : cloud == "AWS" ? (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    padding: 4,
                  }}
                >
                  <img
                    style={{ width: 20, height: 20, marginRight: 4 }}
                    src={aws_account}
                  />{" "}
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    Accounts{" "}
                  </Typography>
                </div>
              ) : (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    padding: 4,
                  }}
                >
                  <img
                    style={{ width: 20, height: 20, marginRight: 4 }}
                    src={gcp_billing}
                  />{" "}
                  <Typography style={{ fontWeight: "bold" }}>
                    {" "}
                    Billing Account{" "}
                  </Typography>
                </div>
              )}

              <TableHead>
                <TableRow
                  className={classes.tableStyle}
                  style={{ backgroundColor: Colors.tableHeader }}
                >
                  {(subRowHeader || []).map((rH) => (
                    <TableCell
                      align="center"
                      style={{
                        fontWeight: "bold",
                        padding: 10,
                      }}
                    >
                      {rH}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {row?.[subKeyElement]?.map((subRow) => (
                  <TableRow hover key={subRow?.[0]}>
                    {(subRowKeys || []).map((rK) => (
                      <TableCell
                        align="center"
                        style={{ padding: 10 }}
                        component="th"
                        scope="row"
                      >
                        {spreadFurtherKey === rK &&
                        subRow?.[spreadFurtherKey] &&
                        subRow?.[spreadFurtherKey]?.constructor.name == "Array"
                          ? spreadKeyData(subRow?.[spreadFurtherKey])
                          : rK == subKeyOverride
                          ? subKeyOverrideData(subRow?.[rK])
                          : subRow?.[rK]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function SubRowTable({
  cloud = null,
  list = [],
  keyElement = null,
  mainRowHeader,
  subRowHeader,
  mainRowKeys,
  subRowKeys,
  subKeyElement = null,
  spreadFurtherKey = null,
  spreadKeyData,
  subKeyOverride = null,
  subKeyOverrideData = () => null,
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead style={{ backgroundColor: Colors.tableHeader }}>
          <TableRow>
            {/* <TableCell />
            {(mainRowHeader || []).map((h) => (
              <TableCell style={{ fontWeight: "bold", padding: 12 }}>
                {h}
              </TableCell>
            ))} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {(list || []).map((row) => (
            <Row
              cloud={cloud}
              key={row?.name}
              row={row}
              keyElement={keyElement}
              subKeyElement={subKeyElement}
              mainRowHeader={mainRowHeader}
              subRowHeader={subRowHeader}
              subRowKeys={subRowKeys}
              mainRowKeys={mainRowKeys}
              spreadFurtherKey={spreadFurtherKey}
              spreadKeyData={spreadKeyData}
              subKeyOverride={subKeyOverride}
              subKeyOverrideData={subKeyOverrideData}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
