import {useTheme} from "styled-components";
import Box from "@material-ui/core/Box";
import IconButton2 from "@material-ui/core/IconButton";
import {
  FirstPage as FirstPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage as LastPageIcon
} from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage :onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
      <Box style={{ flexShrink: 0, ml: 2.5,
        // display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <IconButton2
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton2>
        <IconButton2
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton2>
        <IconButton2
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton2>
        <IconButton2
            onClick={handleLastPageButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="last page"
        >
          <LastPageIcon />
        </IconButton2>
      </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default TablePaginationActions;
