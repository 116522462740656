import React, { useState } from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import $ from 'jquery';
import './styles.css';

export default function DateRange({
  onSelectionChanged,
  defaultSelected,
  shouldShow = true,
    useClass = null,
    height = 'initial',
    padding: paddingRequired = null,
    fontSize = 'initial'
}) {
  const [selected, setSelected] = useState(defaultSelected);
  const padding = paddingRequired?{padding: paddingRequired}:{};
  function onClick(period) {
    setSelected(period);
    onSelectionChanged(period);
  }

  $(document).ready(function () {
    const popoverOptions = {
      content: function (){
        return "Last " + {
          '1D': "1 day",
          '1W': "7 days",
          '1M': "30 days",
          '3M': "90 days",
          '6M': "180 days",
          '1Y': "365 days"
        }[this.dataset.period]
      },
      trigger: 'hover',
      animation: false,
      placement: 'top',
    };
    $('.date-range-button').popover(popoverOptions);
  });

  if (!shouldShow) return null;
  return (
    <div
      style={{  display: "flex" }}
      className={useClass || "sticky-inner"}
    >
      {/*<Card elevation={3}>*/}
      <div>
        <ButtonGroup
          style={{ background: "white",
            height
          }}
          size="large"
          color="primary"
          aria-label="large outlined primary button group"
        >
          {["1D", "1W", "1M", "3M", "6M", "1Y"].map((period) => (
            <Button
              style={{
                ...padding,
                lineHeight: 1.5,
                outline: "none",
                backgroundColor: selected === period ? "#3f51b5db" : "",
                color: selected === period ? "white" : "",
                fontSize
              }}
              onClick={() => onClick(period)}
              className={'date-range-button'}
              data-period={period}
            >
              {period}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </div>
  );
}
