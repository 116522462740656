import styled, { css } from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const RecHeader = styled.div`
  padding: 14px;
  display: flex;
  width: auto;
  height: 55px;
  background-color: #cfdac8;
`;
export const HeaderText = styled.h5`
  font-family: "Poppins-SemiBold";
  margin-left: 7px;
`;
export const RecTotalText = styled.h6`
  font-family: "Poppins-SemiBold";
  margin-left: 10px;
`;
export const HeaderIcon = styled.img`
  margin-left: 5px;
  width: ${(props) => (props.availability ? "80px" : "80px")};
  height: ${(props) => (props.availability ? "80px" : "80px")};
  margin-top: ${(props) => (props.availability ? "-25px" : "-25px")};
  margin-inline: ${(props) => (props.availability ? "-15px" : "-15px")};
`;
export const RecTotal = styled.div`
  margin-left: ${(props) => (props.IR ? "0PX" : "10px")};
  padding: 14px;
  display: flex;
  justify-content: ${(props) => (props.IR ? "center" : null)};
  align-items: ${(props) => (props.IR ? "center" : null)};
`;

export const NoRecommend = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 195px;
  padding: 15px;
`;
