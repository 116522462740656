import React, { useState, useEffect } from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@material-ui/core/Icon";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Format from "components/NumberFormat";
import moment from "moment";
import Color from "../../styles/color";
import { LinearProgress } from "@material-ui/core";
import Cost from "../../components/Cost";
import gcp_billing from "../../images/gcp_billing.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import DateRange from "../../components/DateRange";
import gcp_project from "../../images/gcp_project.svg";
import ContainerCardWithDateRange from "../../components/ContainerCardWithDateRange";
import TablePaginationActions from "../../components/TablePaginationActions";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
}));

export default function GCPBillingAccount({ currency, billingAccount, loading, orgData }) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    const [selected, setSelected] = React.useState([]);
    const [list, setList] = useState(null);
    const [total, setTotal] = useState("");
    const [period, setPeriod] = useState("1M");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const userData = useSelector((state) => ({
        id: state.userDetailsReducer.customerId == null ? state.userDetailsReducer.id : state.userDetailsReducer.customerId,
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <div className={classes.root}>
                <ContainerCardWithDateRange title={'GCP billing accounts'} defaultPeriod={"1M"} titleIcon={gcp_billing}
                                            ExtraHeaderComponent={({period})=>(
                                                billingAccount != null && billingAccount?.SpendByProjects?.[period]?.dataSet?.length ? (
                                                    <div style={{ marginTop: 4 }}>
                                                        <span>{moment(billingAccount?.SpendByProjects?.[period]?.startDate, "YYYYMMDD").format("MMM DD, YYYY")}</span> -{" "}
                                                        <span>{moment(billingAccount?.SpendByProjects?.[period]?.endDate, "YYYYMMDD").format("MMM DD, YYYY")}</span>
                                                    </div>
                                                ) : null
                                            )}
                                            MiddleComponent={({period})=>(
                                                <span style={{display: "flex", justifyContent: "flex-end"}}>
                                               Total: {currency}{!isNaN(Format.formatNumber(Number((billingAccount?.SpendByProjects?.[period]?.totalCost)).toFixed(2)))?
                                                    Format.formatNumber(Number(billingAccount?.SpendByProjects?.[period]?.totalCost).toFixed(2)):
                                                    "__.__"}
                                            </span>
                                            )}
                                            collapsible={false}>
                    {({period})=>(
                        billingAccount != null && billingAccount?.SpendByProjects?.[period]?.dataSet?.length ? (
                            <Paper elevation={1} className={classes.paper}>
                                <TableContainer>
                                    <Table className={classes.table} aria-labelledby="tableTitle" size={"small"} aria-label="enhanced table">
                                        <GCPBillingHead classes={classes} numSelected={selected.length} rowCount={billingAccount.length} totalCost={billingAccount?.SpendByProjects?.[period]?.totalCost} currency={currency} />
                                        <TableBody>
                                            {(billingAccount?.SpendByProjects?.[period]?.dataSet).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                                                return <MyTableRow row={row} index={index} list={list} setList={setList} currency={currency} orgData={orgData} />;
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    ActionsComponent={TablePaginationActions}
                                        rowsPerPageOptions={[10, 25, 50, 75]}
                                        component="div"
                                        count={billingAccount?.SpendByProjects?.[period]?.dataSet?.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                            </Paper>
                        ) : (
                            <span style={{ display: "flex", justifyContent: "center", fontSize: 20 }}>No data found</span>
                        )
                    )}
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}

const useStyles2 = makeStyles((theme) => ({
    root: {
        backgroundColor: Color.tableHeader,
        padding: theme.spacing(0.5),
    },

    tableRow: {
        fontWeight: "bold",
    },
}));

function GCPBillingHead({ totalCost, currency = "" }) {

    const classes = useStyles2();

    return (
        <TableHead className={classes.root}>
            <TableRow>
                <TableCell className={classes.tableRow}>Billing account Id</TableCell>
                <TableCell className={classes.tableRow}>Billing account name</TableCell>
                <TableCell className={classes.tableRow}>Project Id</TableCell>

                <TableCell className={classes.tableRow}>
                    Cost
                    {/*{currency}*/}
                    {/*<Cost>{totalCost}</Cost>*/}
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

function MyTableRow({ row, currency, orgData }) {
    return (
        <React.Fragment>
            <TableRow hover key={row.date}>
                <TableCell>
                    {" "}
                    <img src={gcp_billing} height={26} style={{ marginRight: 4 }} />
                    {orgData[0]?.billingAccountId}
                </TableCell>
                <TableCell>{orgData[0]?.billingAccountName}</TableCell>
                <TableCell>{row?.label}</TableCell>

                <TableCell>
                    {currency}
                    {Format.formatNumber(Number(row.value))}
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}
