import React, { useEffect, useState, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import "./style.css";
import aws_org from "../../images/newCloudTrakrIcons/AWSOrganizations.png";
import aws_account from "../../images/newCloudTrakrIcons/AWSAccounts.png";
import Overview from "../../images/overview.svg";
import serverless_icon from "../../images/light.svg";
import resource_group from "../../images/newCloudTrakrIcons/AWSServices.png";
import all_resource from "../../images/newCloudTrakrIcons/AWSResources.png";
import billing_icon from "../../images/newCloudTrakrIcons/AWSBilling.png";
import aws_region from "../../images/aws_region.svg";

import calendar_icon from "../../images/calendar_icon.png";
import reservation from "../../images/Reserved-Instance-Reporting.svg";
import { GridChart } from "../../pages/DashboardAccountsHome/AWSHome";
import ComputeIcon from "../../images/newCloudTrakrIcons/AWSCompute.png";
import S3Icon from "../../images/newCloudTrakrIcons/AWSS3.png";

import { Paper, Grid, Divider, ButtonGroup, Button, Hidden, CircularProgress, IconButton, Collapse } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import Format from "components/NumberFormat";
import YearTableData from "../YearTableData";
import axios from "../../connection/axios";
import AWSOrgList from "components/AWS/AWSOrgList";
import AWSAccountList from "components/AWS/AWSAccountList";
import AWSServerlessHome from "components/Serverless/AWSServerlessHome";
import AwsReservationTable from "../../components/table/AwsReservation";
// import {CalendarToday, Menu} from "@material-ui/icons";
import YearCalendarData, { CalendarDataProcessor, Months } from "../YearCalendarData";
import AwsAccountData from "pages/DashboardAccountsHome/AwsAccountData/AwsAccountData";
import { CalendarToday, KeyboardArrowDown, KeyboardArrowUp, Menu, PauseCircleFilled, PlayArrow, PlayCircleFilled } from "@material-ui/icons";
import AwsOrganizationView from "pages/DashboardAccountsHome/awsOrgData/AwsOrganizationView";
import moment from "moment";
import { OverlayComponent } from "./AzureHomeTab";
import SlideShow from "components/CustomSlider/SlideShow";
import ReactD3PieChart from "components/D3Charts/ReactD3GridPieChart";
import ReactD3BarChart from "components/D3Charts/ReactD3GridChart";
import { getAWSReservationRequest, getAWSAccountReservationRequest } from "../../redux/actions/actions";
import { AwsOrgTotal, IndividualAwsOrg } from "components/table/AwsOrgTable";
import { LinearProgress } from "@material-ui/core";
import DashboardIcon from "../../images/newCloudTrakrIcons/MultiCloudIcon.png";
import DynamoDBIcon from "../../images/newCloudTrakrIcons/AWSDynamoDB.png";
import RDSIcon from "../../images/newCloudTrakrIcons/AWSRDS.png";
import reservation_icon from "../../images/newCloudTrakrIcons/AWSReservations.png";
import aws_recommendation from "../../images/newCloudTrakrIcons/AWSRecommendations.png";

import Calendar_Spend_Icon from "../../images/newCloudTrakrIcons/AWSBilling.png";
import GroupedBarChart from "../D3Charts/GroupedBarChart";
import StackedBarChart2 from "../../pages/Charts/StackedBarChart2";
import { generateGroupedChartData } from "../../pages/Charts/DashboardTabs";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Total_Spend_Icon from "../../images/sigma_icon.svg";
import AWSCompute from "pages/DashboardAccountsHome/AWSCompute";
import AWSAccountCompute from "pages/DashboardAccountsHome/AWSAccountCompute";
import AWSDynamoDB from "pages/DashboardAccountsHome/AWSDynamoDB";
import AWSRDS from "pages/DashboardAccountsHome/AWSRDS";
import AWSServices from "pages/DashboardAccountsHome/AwsAccountData/AWSServices";
import AWSLambdaFunction from "pages/DashboardAccountsHome/AWSLambdaFunction";
import lambdaFunctionIcon from "../../images/newCloudTrakrIcons/AWSLambda.png";
import NO_DATA_AVAILABLE from "../NO_DATA_AVAILABLE";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import ListS3Bucket from "pages/listS3bucket/ListS3Bucket";
import AWSRecommendations from "../../pages/recommendations/AWSRecommendations";
import { TIMERS } from "../../util/AppConstants";
import PauseIcon from "@material-ui/icons/Pause";
import { getMonthsFromNow } from "../../util/DateFormatter";
import CalendarLoader from "../loaders/CalendarLoader";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Total_Spend_Icon_New from "../../images/spend_icon.png";
import { arraySaveToCSV } from "../../util/Miscellaneous";
import ArrowDown from "../../images/double-chevron-down.svg";
import MountUnmount from "../MountUnmount";
import ArrowUp from "../../images/double-chevron-up.svg";

const { REACT_APP_AWS_1Y_TABLE_DATA, REACT_APP_AWS_SUBSCRIPTION_1Y_TABLE_DATA, REACT_APP_AWS_SUBSCRIPTION_KEY } = process.env;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: "#ff990021",
        //padding: theme.spacing(-20),
    },
    paper: {
        padding: theme.spacing(2),
    },
    tab: {
        fontWeight: "bold",
        textTransform: "none",
        outline: "none",
    },
    tabPanel: {
        padding: theme.spacing(-10),
    },
}));

export default function AwsHomeTab(props) {
    const dispatch = useDispatch();

    const { charts, currency, customerId, TAB_SET, activeTab } = props;
    const { selectedSubscription, accountList, spendBySubscription } = props;
    const { awsOrgSpending, orgloading } = props;
    const classes = useStyles();
    // const [value, setValue] = React.useState(TAB_SET.DASHBOARD);
    const [parentWidth, setParentWidth] = useState(props.parentWidth);
    const [yearTableData, setYearTableData] = useState([]);
    const [loadingTableData, setLoadingTableData] = useState(false);
    const [loadingCalendarTableData, setLoadingCalendarTableData] = useState(false);
    const [monthWiseData, setMonthWiseData] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [currentMonth, setCurrentMonth] = useState(null);
    const [showDataBy, setShowDataBy] = useState("table");
    const [overlayData, setOverlayData] = useState(null);
    const [orgBuUnit, setorgBuUnit] = useState(null);
    const [progress, setProgress] = useState(0);
    const [spendByMonthsChartView, setSpendByMonthsChartView] = useState(true);
    const [awsMonthWiseChartData, setAwsMonthWiseChartData] = useState([]);
    const [othersMonthWiseChartData, setOthersMonthWiseChartData] = useState([]);
    const [chartShowingIndex, setChartShowingIndex] = useState(0);
    const [chartTop5ShowingIndex, setChartTop5ShowingIndex] = useState(0);
    const [calendarLoading, setCalendarLoading] = useState(false);

    const [totalSpendChartView, setTotalSpendChartView] = useState(true);
    const [chart2ShowingIndex, setChart2ShowingIndex] = useState(0);
    const [monthInterval, setMonthInterval] = useState(null);

    const [dashboardComponentsRef, setDashboardComponentsRef] = useState({});
    const [dashboardComponentsCollapsed, setDashboardComponentsCollapsed] = useState(false);

    // for (let i = 0; i < awsOrgSpending?.length; i++) {
    //   setorgBuUnit(awsOrgSpending[i]);
    // }

    let awsReservation = useSelector((state) => state?.awsReservationReducer?.spendByReservations?.spendByReservations);

    let awsAccountReservation = useSelector((state) => state?.awsReservationReducer?.spendByAccountReservation?.SpendByReservations);

    const AwsReservationloading = useSelector((state) => state.azureManagementGroupReducer.loading);

    const handleChange = (event, newValue) => {
        props?.setActiveTab(newValue);
    };

    async function get1YearTableData(tenantId, subscriptionId, period = "monthly") {
        setCalendarLoading(true)

        setLoadingCalendarTableData(true);
        return await axios
            .get(REACT_APP_AWS_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                    subscriptionId,
                    tenantId,
                },
                headers: {
                    "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                // console.log({ response });
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data?.SpendByDaily);
                } else {
                    setYearTableData(response?.data?.data?.SpendByMonth);
                }
            })
            .catch((e) => {})
            .finally(() => {setLoadingCalendarTableData(false);
                setCalendarLoading(false);
            });
    }

    async function get1YearAccountTableData(tenantId, subscriptionId, period = "monthly") {
        setLoadingCalendarTableData(true);
        setCalendarLoading(true)
        return await axios
            .get(REACT_APP_AWS_SUBSCRIPTION_1Y_TABLE_DATA, {
                params: {
                    customerId,
                    view: period,
                    subscriptionId,
                    tenantId,
                },
                headers: {
                    "Ocp-Apim-Subscription-Key": REACT_APP_AWS_SUBSCRIPTION_KEY,
                },
            })
            .then((response) => response.data)
            .then((response) => {
                // console.log({ response });
                if (period === "daily") {
                    setMonthWiseData(response?.data?.data?.SpendByDaily);
                } else {
                    setYearTableData(response?.data?.data?.SpendByMonth);
                }
            })
            .catch((e) => {})
            .finally(() => {setLoadingCalendarTableData(false);
                setCalendarLoading(false);});
    }

    useEffect(() => {
        props?.setTabSelect("homeTab");

        load1YearTableData("monthly", selectedSubscription?.tenantId, selectedSubscription?.value)
            .then(() => load1YearTableData("daily", selectedSubscription?.tenantId, selectedSubscription?.value))
            .finally(() => {
                setLoadingTableData(false);
                onResume();
            });
    }, [customerId, selectedSubscription]);

    const [buffer, setBuffer] = React.useState(10);
    const progressRef = React.useRef(() => {});
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    async function load1YearTableData(period = "monthly", tenantId = null, subscriptionId = null) {
        if (customerId) {
            setLoadingTableData(true);
            if (selectedSubscription.title === "All") {
                dispatch(getAWSReservationRequest({ customerId }));

                return await get1YearTableData(null, null, period);
                // .then(onResume);
            } else if (tenantId || selectedSubscription?.tenantId) {
                dispatch(
                    getAWSAccountReservationRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                    })
                );

                return await get1YearAccountTableData(tenantId || selectedSubscription?.tenantId, subscriptionId || selectedSubscription?.value, period);
            } else {
                return null;
            }
        }
    }

    async function onMonthSelect(m, y = null) {
        if (monthWiseData == null || !monthWiseData) {
            await load1YearTableData("daily")
                .then(() => setCurrentMonth(m))
                .finally(() => setLoadingTableData(false));
            setCurrentMonth(m);
        } else {
            setCurrentMonth(m);
        }
        if (y) setCurrentYear(y);
    }

    function rootLoaded() {
        const e = window.$("#root_dashboard");
        try {
            props?.setParentWidth(e.width());
            setParentWidth(e.width());
        } catch (e) {}
    }

    let { title } = selectedSubscription;

    useEffect(() => {
        if (!monthWiseData) return;
        setCurrentYear(new Date().getFullYear());
        setCurrentMonth(Months[new Date().getMonth()]);
    }, [monthWiseData, activeTab, selectedSubscription]);

    function getOverlayData(d, m, y) {
        let data = null;
        let date = null;
        if (d && m && y) {
            data = monthWiseData
                ?.find((data) => data.year == y)
                ?.months?.find((data) => data.month == m)
                ?.dates?.find((data) => data.date == y.toString() + m.toString() + d.toString());
            date = moment(data?.date || 0, "YYYYMMDD").format("MMM D, YYYY");
        } else if (m && y) {
            data = yearTableData?.find((data) => data.month == y.toString() + m.toString());
            // debugger
            date = moment(data?.startDate || 0, "YYYYMMDD").format("MMM D, YYYY") + " - " + moment(data?.endDate || 0, "YYYYMMDD").format("MMM D, YYYY");
        }
        return { data, date };
    }

    useEffect(() => {
        if (currentMonth) {
            let month = Months.indexOf(currentMonth) + 1;
            month = month < 10 ? "0" + month : month;
            // console.log(awsAccountList);
            // debugger
            const { data, date } = getOverlayData(null, month, currentYear);
            setOverlayData({ cost: data?.cost, data, date });
        }
    }, [currentMonth, currentYear]);

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ aws: { SpendByMonth: yearTableData } }, setAwsMonthWiseChartData, () => {});
    }, [yearTableData]);

    useEffect(() => {
        if (yearTableData) generateGroupedChartData({ aws: { SpendByMonth: yearTableData } }, setOthersMonthWiseChartData, () => {}, ["resources", "services"]);
    }, [yearTableData]);

    var loopIndex = 0;
    const months = getMonthsFromNow([], true)?.reverse();

    function onResume() {
        setMonthInterval(
            setInterval(() => {
                if (loopIndex == months.length) {
                    loopIndex = 0;
                    onMonthNext(months[0]);
                } else {
                    onMonthNext(months[loopIndex]);
                }
                loopIndex++;
            }, TIMERS.CALENDAR_INTERVAL)
        );
    }

    function onPause() {
        clearInterval(monthInterval);
        setMonthInterval(null);
    }

    function onMonthNext(monthYear = "") {
        if (monthYear) {
            const month = monthYear?.split(" ")[0];
            const year = monthYear?.split(" ")[1];
            setCurrentMonth(month);
            setCurrentYear(parseInt(year));
        }
    }

    const extraOverlayKeys = !(selectedSubscription.tenantId && selectedSubscription.value)
        ? [
              {
                  key: "subscriptions",
                  title: "Top AWS Accounts",
                  subKey: "subscriptionName",
                  subKey2: "subscriptionId",
                  icon: aws_account,
                  onMouseOver: "subscriptionId",
              },
          ]
        : [];
    const pausePlaySpendRef = useRef(null);
    const pausePlayTotalRef = useRef(null);
    const pausePlayTop5Ref = useRef(null);

    function onCSVSave() {
        const csvData = window.$(selectedSubscription?.tenantId ? "#awssubscriptionbillingtable1" : "#awsbillingtable1").table2csv("json", {
            separator: "###",
            appendHeaders: ["Cloud", "aws"],
            removeColumns: 0,
        });
        arraySaveToCSV(csvData, selectedSubscription?.tenantId ? "aws_subscription_billing.csv" : "aws_billing.csv");
    }

    function collapseCards() {
        const h = dashboardComponentsRef;
        for(const rg in h){
            h[rg](dashboardComponentsCollapsed);
        }
        setDashboardComponentsCollapsed(!dashboardComponentsCollapsed);
    }

    return (
        <div className={classes.root} style={{ marginTop: -10 }}>
            <TabContext value={activeTab}>
                <br />
                {title && title == "All" ? (
                    <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" style={{ maxWidth: "calc(100vw - 80px)" }} aria-label="simple tabs example" textColor="primary" indicatorColor="primary">
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={billing_icon} />
                                    Dashboard
                                </div>
                            }
                            value={TAB_SET.BILLING}
                            className={classes.tab}
                        />
                        {/*<Tab*/}
                        {/*    style={styles.tab}*/}
                        {/*    label={*/}
                        {/*        <div style={styles.labelContainer}>*/}
                        {/*            <img style={styles.icon} src={DashboardIcon} />*/}
                        {/*            Dashboard*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*    value={TAB_SET.DASHBOARD}*/}
                        {/*    className={classes.tab}*/}
                        {/*    //onClick={() => setresTab(false)}*/}
                        {/*/>*/}
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={aws_recommendation} />
                                    Recommendations
                                </div>
                            }
                            value={TAB_SET.RECOMMENDATIONS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={aws_org} />
                                    Organizations
                                </div>
                            }
                            value={TAB_SET.ORGANIZATIONS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={aws_account} />
                                    Accounts
                                </div>
                            }
                            value={TAB_SET.ACCOUNTS}
                            className={classes.tab}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={resource_group} />
                                    Services
                                </div>
                            }
                            value={TAB_SET.SERVICES}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={all_resource} />
                                    Resources
                                </div>
                            }
                            value={TAB_SET.RESOURCES}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        {/* <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={serverless_icon} />
                                    Serverless
                                </div>
                            }
                            value={TAB_SET.SERVERLESS}
                            className={classes.tab}
                        /> */}
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={ComputeIcon} />
                                    Compute
                                </div>
                            }
                            value={TAB_SET.COMPUTE}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={S3Icon} />
                                    S3
                                </div>
                            }
                            value={TAB_SET.S3BUCKET}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={RDSIcon} />
                                    RDS
                                </div>
                            }
                            value={TAB_SET.RDS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={DynamoDBIcon} />
                                    DynamoDB
                                </div>
                            }
                            value={TAB_SET.DYNAMO_DB}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={lambdaFunctionIcon} />
                                    Lambda
                                </div>
                            }
                            value={TAB_SET.LAMBDA}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={reservation_icon} />
                                    Reservations
                                </div>
                            }
                            value={TAB_SET.RESERVATIONS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        {/* <Divider orientation="vertical" flexItem />
              <Tab label="Serverless" value={TAB_SET.BILLING} className={classes.tab} /> */}
                    </TabList>
                ) : (
                    <TabList onChange={handleChange} variant="scrollable" scrollButtons="auto" style={{ maxWidth: "calc(100vw - 80px)" }} aria-label="simple tabs example" textColor="primary" indicatorColor="primary">
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={billing_icon} />
                                    Dashboard
                                </div>
                            }
                            value={TAB_SET.BILLING}
                            className={classes.tab}
                        />
                        {/*<Tab*/}
                        {/*    style={styles.tab}*/}
                        {/*    label={*/}
                        {/*        <div style={styles.labelContainer}>*/}
                        {/*            <img style={styles.icon} src={DashboardIcon} />*/}
                        {/*            Dashboard*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*    value={TAB_SET.DASHBOARD}*/}
                        {/*    className={classes.tab}*/}
                        {/*    //onClick={() => setresTab(false)}*/}
                        {/*/>*/}
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={aws_recommendation} />
                                    Recommendations
                                </div>
                            }
                            value={TAB_SET.RECOMMENDATIONS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={aws_account} />
                                    Accounts
                                </div>
                            }
                            value={TAB_SET.ACCOUNTS}
                            className={classes.tab}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={resource_group} />
                                    Services
                                </div>
                            }
                            value={TAB_SET.SERVICES}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={all_resource} />
                                    Resources
                                </div>
                            }
                            value={TAB_SET.RESOURCES}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={serverless_icon} />
                                    Serverless
                                </div>
                            }
                            value={TAB_SET.SERVERLESS}
                            className={classes.tab}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={ComputeIcon} />
                                    Compute
                                </div>
                            }
                            value={TAB_SET.COMPUTE}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={S3Icon} />
                                    S3
                                </div>
                            }
                            value={TAB_SET.S3BUCKET}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={RDSIcon} />
                                    RDS
                                </div>
                            }
                            value={TAB_SET.RDS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={DynamoDBIcon} />
                                    DynamoDB
                                </div>
                            }
                            value={TAB_SET.DYNAMO_DB}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={lambdaFunctionIcon} />
                                    Lambda
                                </div>
                            }
                            value={TAB_SET.LAMBDA}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />
                        <Tab
                            style={styles.tab}
                            label={
                                <div style={styles.labelContainer}>
                                    <img style={styles.icon} src={reservation_icon} />
                                    Reservations
                                </div>
                            }
                            value={TAB_SET.RESERVATIONS}
                            className={classes.tab}
                            // onClick={() => setresTab(true)}
                        />

                        {/* <Divider orientation="vertical" flexItem />
              <Tab label="Serverless" value=TAB_SET.BILLING className={classes.tab} /> */}
                    </TabList>
                )}
                <Divider />
                <TabPanel onLoad={rootLoaded} id={"root_dashboard"} value={"dep"+TAB_SET.DASHBOARD}>
                    <div>
                        {title == "All" ? (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange
                                        title={"Total spend"}
                                        titleIcon={Total_Spend_Icon_New}
                                        showDateRange={true}
                                        collapsible={true}
                                        HeaderTitleComponent={(e, setCollapseClickable) => (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                    <span>Total spend</span>
                                                    <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                            <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                                {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    >
                                        {({ period }) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", zIndex: 1 }}>
                                                        <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                            <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                                <div style={{ flex: 1 }}>
                                                    <div>
                                                        <SlideShow pausePlayRef={pausePlayTotalRef} names={["Spend by AWS", "Spend by Accounts", "Spend by Business units"]} showingIndex={chartShowingIndex} arrowsHandled={true}>
                                                            <div>
                                                                {charts?.AWSCloudSpending?.[period]?.dataSet ? (
                                                                    <GridChart
                                                                        currency={currency}
                                                                        title={"Spend by AWS"}
                                                                        charts={charts}
                                                                        classes={classes}
                                                                        period={period}
                                                                        data={charts?.AWSCloudSpending?.[period]}
                                                                        ChartCategory={ReactD3PieChart}
                                                                        chartData={charts?.AWSCloudSpending?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                    />
                                                                ) : (
                                                                    <NO_DATA_AVAILABLE>No data found for Spend By AWS</NO_DATA_AVAILABLE>
                                                                )}
                                                            </div>

                                                            <div>
                                                                {charts?.SpendByAccounts?.[period]?.dataSet ? (
                                                                    <GridChart
                                                                        currency={currency || "USD"}
                                                                        title={"Spend by Accounts"}
                                                                        charts={charts}
                                                                        classes={classes}
                                                                        period={period}
                                                                        data={charts?.SpendByAccounts?.[period]}
                                                                        ChartCategory={ReactD3BarChart}
                                                                        chartData={charts?.SpendByAccounts?.[period]?.dataSet?.sort((a, b) => b.value - a.value) || null}
                                                                    />
                                                                ) : (
                                                                    <NO_DATA_AVAILABLE>No data found for Spend By Accounts</NO_DATA_AVAILABLE>
                                                                )}
                                                            </div>

                                                            <div>
                                                                {orgloading ? (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <CircularProgress thickness={2} size={80} />
                                                                    </div>
                                                                ) : awsOrgSpending && awsOrgSpending !== null ? (
                                                                    <BussinessUnitAws data={awsOrgSpending} period={period} currency={currency} />
                                                                ) : (
                                                                    <NO_DATA_AVAILABLE>No data found for AWS Organizations</NO_DATA_AVAILABLE>
                                                                )}
                                                            </div>
                                                        </SlideShow>
                                                    </div>
                                                </div>
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", right: 1 }}>
                                                        <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                            <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>

                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange
                                        title={"Total spend"}
                                        titleIcon={Total_Spend_Icon_New}
                                        showDateRange={true}
                                        collapsible={true}
                                        HeaderTitleComponent={(e, setCollapseClickable) => (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                    <span>Total spend</span>
                                                    <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                            <div onClick={() => pausePlayTop5Ref?.current?.click()}>
                                                                {pausePlayTop5Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    >
                                        {({ period }) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", zIndex: 1 }}>
                                                        <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                            <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                                <div style={{ flex: 1 }}>
                                                    <div>
                                                        <SlideShow pausePlayRef={pausePlayTop5Ref} names={["Top 5 Services", "Top 5 Locations", "Spend by Category"]} showingIndex={chartTop5ShowingIndex} arrowsHandled={true}>
                                                            <div>
                                                                {charts?.TopFiveServices?.[period]?.dataSet ? (
                                                                    <GridChart
                                                                        currency={currency}
                                                                        title={"Top 5 Services"}
                                                                        charts={charts}
                                                                        classes={classes}
                                                                        period={period}
                                                                        data={charts?.TopFiveServices?.[period]}
                                                                        ChartCategory={ReactD3BarChart}
                                                                        chartData={charts?.TopFiveServices?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                    />
                                                                ) : (
                                                                    <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                                )}
                                                            </div>
                                                            <div>
                                                                {charts?.TopFiveLocations?.[period]?.dataSet ? (
                                                                    <GridChart
                                                                        currency={currency}
                                                                        title={"Top 5 Locations"}
                                                                        charts={charts}
                                                                        classes={classes}
                                                                        period={period}
                                                                        data={charts?.TopFiveLocations?.[period]}
                                                                        ChartCategory={ReactD3BarChart}
                                                                        chartData={charts?.TopFiveLocations?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                    />
                                                                ) : (
                                                                    <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                                )}
                                                            </div>
                                                            <div>
                                                                {charts?.SpendByCategory?.[period]?.dataSet ? (
                                                                    <GridChart
                                                                        currency={currency}
                                                                        title={"Spend by Category"}
                                                                        charts={charts}
                                                                        classes={classes}
                                                                        period={period}
                                                                        data={charts?.SpendByCategory?.[period]}
                                                                        ChartCategory={ReactD3BarChart}
                                                                        chartData={charts?.SpendByCategory?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                    />
                                                                ) : (
                                                                    <NO_DATA_AVAILABLE>No data found for Spend By Category</NO_DATA_AVAILABLE>
                                                                )}
                                                            </div>
                                                        </SlideShow>
                                                    </div>
                                                </div>
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", right: 1 }}>
                                                        <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                            <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange
                                        title={"Total spend"}
                                        titleIcon={Total_Spend_Icon_New}
                                        showDateRange={true}
                                        collapsible={true}
                                        HeaderTitleComponent={(e, setCollapseClickable) => (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                    <span>Total spend</span>
                                                    <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                            <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                                {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    >
                                        {({ period }) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", zIndex: 1 }}>
                                                        <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                            <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                                <SlideShow names={["Spend by AWS", "Spend by Category"]} showingIndex={chartShowingIndex} arrowsHandled={true} pausePlayRef={pausePlayTotalRef}>
                                                    <div>
                                                        {charts?.AWSCloudSpending?.[period]?.dataSet ? (
                                                            <GridChart
                                                                currency={currency}
                                                                title={"Spend by AWS"}
                                                                charts={charts}
                                                                classes={classes}
                                                                period={period}
                                                                data={charts?.AWSCloudSpending?.[period]}
                                                                ChartCategory={ReactD3PieChart}
                                                                chartData={charts?.AWSCloudSpending?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Spend By AWS</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>

                                                    <div>
                                                        {charts?.SpendByCategory?.[period]?.dataSet ? (
                                                            <GridChart
                                                                currency={currency}
                                                                title={"Spend by Category"}
                                                                charts={charts}
                                                                classes={classes}
                                                                period={period}
                                                                data={charts?.SpendByCategory?.[period]}
                                                                ChartCategory={ReactD3BarChart}
                                                                chartData={charts?.SpendByCategory?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Spend by Category</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", right: 1 }}>
                                                        <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                            <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange
                                        title={"Total spend"}
                                        titleIcon={Total_Spend_Icon_New}
                                        showDateRange={true}
                                        collapsible={true}
                                        HeaderTitleComponent={(e, setCollapseClickable) => (
                                            <>
                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                    <span>Total spend</span>
                                                    <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                        <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                            <div onClick={() => pausePlayTop5Ref?.current?.click()}>
                                                                {pausePlayTop5Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    >
                                        {({ period }) => (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    position: "relative",
                                                }}
                                            >
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", zIndex: 1 }}>
                                                        <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                            <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                                <SlideShow names={["Top 5 Services", "Top 5 Locations"]} showingIndex={chartTop5ShowingIndex} arrowsHandled={true} pausePlayRef={pausePlayTop5Ref}>
                                                    <div>
                                                        {charts?.TopFiveServices?.[period]?.dataSet ? (
                                                            <GridChart
                                                                currency={currency}
                                                                title={"Top 5 Services"}
                                                                charts={charts}
                                                                classes={classes}
                                                                period={period}
                                                                data={charts?.TopFiveServices?.[period]}
                                                                ChartCategory={ReactD3BarChart}
                                                                chartData={charts?.TopFiveServices?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {charts?.TopFiveLocations?.[period]?.dataSet ? (
                                                            <GridChart
                                                                currency={currency}
                                                                title={"Top 5 Locations"}
                                                                charts={charts}
                                                                classes={classes}
                                                                period={period}
                                                                data={charts?.TopFiveLocations?.[period]}
                                                                ChartCategory={ReactD3BarChart}
                                                                chartData={charts?.TopFiveLocations?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                            />
                                                        ) : (
                                                            <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                        )}
                                                    </div>
                                                </SlideShow>
                                                {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                    <div style={{ position: "absolute", right: 1 }}>
                                                        <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                            <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                        </IconButton>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>
                            </Grid>
                        )}
                        <br />
                       
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ContainerCardWithDateRange showDateRange={false} title={"Calendar"} titleIcon={calendar_icon}>
                                        {() => (
                                              <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                            {loadingCalendarTableData ? (
                                                <CircularProgress justifyContent={'center'} thickness={2} size={60} />
                                                 ) : (
                                            <YearCalendarData
                                                style={{ flex: 1 }}
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                year={currentYear}
                                                onNext={() => setCurrentYear(currentYear + 1)}
                                                onPrev={() => setCurrentYear(currentYear - 1)}
                                                yearData={CalendarDataProcessor(yearTableData, "year")}
                                                monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                                onMonthSelect={onMonthSelect}
                                                month={currentMonth}
                                                renderOverlay={(d, m, y, cost) => {
                                                    const { data, date } = getOverlayData(d, m, y);

                                                    if (data) return setOverlayData({ data, date, cost: data?.cost });
                                                    return null;
                                                }}
                                                calendarWidth={"100%"}
                                            />
                                         )}
                                            </div>
                                        )}
                                    </ContainerCardWithDateRange>
                                </Grid>

                                <Grid item xs={6}>                     
                                        <ContainerCardWithDateRange showDateRange={false} title={"Calendar details"} titleIcon={calendar_icon}>
                                            {() => (
                                                 <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                                                 {!!!overlayData ? (
                                                    <CircularProgress thickness={2} size={60} />
                                                     ) : (
                                                        overlayData && overlayData.data && (
                                                <OverlayComponent
                                                    showArrows={false}
                                                    cost={overlayData?.cost}
                                                    data={overlayData?.data}
                                                    date={overlayData?.date}
                                                    dataKeys={[
                                                        ...extraOverlayKeys,
                                                        {
                                                            key: "locations",
                                                            title: "Top Locations",
                                                            subKey: "location",
                                                            icon: aws_region,
                                                        },
                                                        {
                                                            key: "services",
                                                            title: "Top Services",
                                                            subKey: "serviceName",
                                                            subKey2: "serviceCode",
                                                            icon: resource_group,
                                                        },
                                                        {
                                                            key: "resources",
                                                            title: "Top Resources",
                                                            subKey: "resourceId",
                                                            icon: resource_group,
                                                        },
                                                    ]}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    maxWidth={"100%"}
                                                    maxHeight={700}
                                                />
                                                ))}
                                                </div>
                                            )}
                                        </ContainerCardWithDateRange>                         
                                </Grid>
                            </Grid>
                        <br />

                        <paper style={{ overflow: "hidden" }}>
                            {parentWidth && (
                                <YearTableData
                                    width={parentWidth}
                                    data={{
                                        aws: yearTableData,
                                    }}
                                    months={yearTableData?.map((m) => m.month)}
                                    currency={Format.formatCurrencySymbol(currency || "USD")}
                                />
                            )}
                        </paper>

                        <div style={{ marginTop: 10 }}>
                            <ContainerCardWithDateRange
                                showDateRange={false}
                                title={"Spend by months"}
                                titleIcon={calendar_icon}
                                HeaderTitleComponent={(e, setCollapseClickable) => (
                                    <>
                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                            <span>Spend by months</span>
                                            <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                        {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                    </div>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </>
                                )}
                            >
                                {() => (
                                    <div style={{}}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                    <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                <SlideShow pausePlayRef={pausePlaySpendRef} delay={TIMERS.LOWER_CHART_INTERVAL} showingIndex={chartShowingIndex} names={["Spend by AWS", "Top 5 Resources", "Top 5 Services"]} arrowsHandled={true}>
                                                    <div>
                                                        {loadingCalendarTableData ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                }}
                                                            >
                                                                <CircularProgress thickness={2} size={80} />
                                                            </div>
                                                        ) : (
                                                            <GroupedBarChart maxWidth={null} currency={Format.formatCurrencySymbol(currency)} totalCost={null} data={awsMonthWiseChartData} cloudCount={1} />
                                                        )}
                                                    </div>

                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"resources"}
                                                        keyField={"resourceId"}
                                                        valueKey={"cost"}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                    <StackedBarChart2
                                                        maxWidth={null}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        totalCost={null}
                                                        keyStack={"services"}
                                                        keyField={"serviceName"}
                                                        keyField2={"serviceCode"}
                                                        valueKey={"cost"}
                                                        data={othersMonthWiseChartData}
                                                        cloudCount={1}
                                                    />
                                                </SlideShow>
                                            </div>
                                            <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                                <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                    <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </div>
                        <br />
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange title={"AWS Accounts"} titleIcon={aws_account} showDateRange={true} collapsible={true}>
                                    {({ period }) => (
                                        <div style={{ marginTop: 10 }}>
                                            <div>
                                                {selectedSubscription.title == "All" ? (
                                                    accountList?.[period]?.dataSet?.length > 0 ? (
                                                        <div>
                                                            <AWSOrgList tenantLogo={aws_account} currency={Format.formatCurrencySymbol(currency)} accountList={accountList?.[period]?.dataSet} />
                                                        </div>
                                                    ) : (
                                                        <h6 style={{ textAlign: "center" }}>No data found</h6>
                                                    )
                                                ) : (
                                                    <div>
                                                        {spendBySubscription?.[period] != null && Object.keys(spendBySubscription?.[period]).length ? (
                                                            <div>
                                                                <AWSAccountList tenantLogo={aws_org} currency={Format.formatCurrencySymbol(currency)} accountList={spendBySubscription?.[period]} />
                                                            </div>
                                                        ) : (
                                                            <h6 style={{ textAlign: "center" }}>No data found</h6>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.ACCOUNTS}>
                    <AwsAccountData
                        yearTableData={yearTableData}
                        yearTableDataLoading={loadingCalendarTableData}
                        selectedSubscription={selectedSubscription}
                        currency={Format.formatCurrencySymbol(currency)}
                        view={"accounts"}
                        customerId={customerId}
                    />
                </TabPanel>
                <TabPanel value={TAB_SET.SERVICES}>
                    <AWSServices yearTableData={yearTableData} yearTableDataLoading={loadingCalendarTableData} selectedSubscription={selectedSubscription} currency={Format.formatCurrencySymbol(currency)} view={"services"} customerId={customerId} />
                </TabPanel>
                <TabPanel value={TAB_SET.RESOURCES}>
                    <AwsAccountData
                        yearTableData={yearTableData}
                        yearTableDataLoading={loadingCalendarTableData}
                        selectedSubscription={selectedSubscription}
                        currency={Format.formatCurrencySymbol(currency)}
                        view={"services"}
                        customerId={customerId}
                    />
                </TabPanel>
                <TabPanel value={TAB_SET.SERVERLESS}>
                    <Grid>
                        <Grid item xs={12}>
                            {/* {selectedSubscription.title == "All" ? (
                                serverlessList != null ? (
                                    <AWSServerless
                                        tenantLogo={serverless_icon}
                                        subscriptionName={"Serverless"}
                                        selectedSubscription={true}
                                        serverlessTotalCost={serverlessTotalValue}
                                        tenantID={""}
                                        currency={Format.formatCurrencySymbol(currency)}
                                        serverlessList={serverlessList.dataSet || 0}
                                    />
                                ) : null
                            ) : subscriptionServerless != null ? (
                                <AWSServerless
                                    tenantLogo={serverless_icon}
                                    subscriptionName={"Serverless"}
                                    //serverlessTotalCost={subscriptionServerless?.SpendByServerlessServices?.[period]?.totalCost || 0}
                                    tenantID={""}
                                    currency={Format.formatCurrencySymbol(currency)}
                                    // serverlessList={subscriptionServerless?.SpendByServerlessServices?.[period]?.dataSet || 0}
                                    subscriptionID={selectedSubscription.value}
                                />
                            ) : null} */}
                            {selectedSubscription.title != "All" ? <AWSServerlessHome selectedSubscription={selectedSubscription} currency={Format.formatCurrencySymbol(currency)} /> : null}
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel style={{ marginTop: -10 }} value={TAB_SET.BILLING}>
                    <MountUnmount onUnmount={()=> {
                            setDashboardComponentsRef({});
                            setDashboardComponentsCollapsed(false);
                        }} />
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <span onClick={collapseCards} className={'link-button'}>{dashboardComponentsCollapsed?"Expand All":"Collapse All"}
                            <img src={!dashboardComponentsCollapsed?ArrowUp:ArrowDown} height={20} />
                            </span>
                    </div>

                    <ContainerCardWithDateRange
                        someref={{how: setDashboardComponentsRef, what:'billing1', where: dashboardComponentsRef}}
                        showDateRange={false}
                        title={"Spend by months"}
                        titleIcon={Calendar_Spend_Icon}
                        HeaderTitleComponent={(e, setCollapseClickable) => (
                            <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                    <span>Spend by months</span>
                                    <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                        {yearTableData ? (
                                            monthInterval ? (
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <PauseCircleFilled style={{ color: "#0000008a" }} onClick={() => onPause()} />
                                                </OverlayTrigger>
                                            ) : (
                                                <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                    <PlayCircleFilled style={{ color: "#0000008a" }} onClick={() => onResume()} />
                                                </OverlayTrigger>
                                            )
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        )}
                        saveAsCSV={true}
                        onSaveAsCSV={onCSVSave}
                    >
                        {() => (
                            <div>
                                {loadingCalendarTableData ? (
                                    <CalendarLoader />
                                ) : (
                                    <div style={{}}>
                                        <hr style={{ padding: 0, margin: 0 }} />
                                        <YearTableData
                                            selectedCloud={"aws"}
                                            hideLegend={true}
                                            width={parentWidth}
                                            data={{
                                                // aws: yearTableData?.map((c) => c.cost),
                                                aws: yearTableData,
                                            }}
                                            months={yearTableData?.map((m) => m.month)}
                                            currency={Format.formatCurrencySymbol(currency || "USD")}
                                            onMonthPress={onMonthSelect}
                                            selectedMonth={currentMonth}
                                            selectedYear={currentYear}
                                            tableId={selectedSubscription?.tenantId ? "awssubscriptionbillingtable1" : "awsbillingtable1"}
                                        />
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                marginTop: 12,
                                            }}
                                        >
                                            {/*<div style={{ flex: 0.5 }}>{monthInterval ? <PauseIcon onClick={() => onPause()} /> : <PlayArrow onClick={() => onResume()} />}</div>*/}
                                            <YearCalendarData
                                                currency={Format.formatCurrencySymbol(currency || "USD")}
                                                year={currentYear}
                                                onNext={() => setCurrentYear(currentYear + 1)}
                                                onPrev={() => setCurrentYear(currentYear - 1)}
                                                yearData={CalendarDataProcessor(yearTableData, "year")}
                                                monthData={CalendarDataProcessor(monthWiseData, "month")?.[currentYear]?.[currentMonth?.toString()?.substring(0, 3)] || null}
                                                onMonthSelect={onMonthSelect}
                                                month={currentMonth}
                                                renderOverlay={(d, m, y, cost) => {
                                                    const { data, date } = getOverlayData(d, m, y);

                                                    if (data) return setOverlayData({ data, date, cost: data?.cost });
                                                    return null;
                                                }}
                                            />
                                            <div
                                                style={{
                                                    marginLeft: 10,
                                                    width: 500,
                                                    height: 400,
                                                    display: "flex",
                                                }}
                                            >
                                                {overlayData && overlayData.data && (
                                                    <OverlayComponent
                                                        showArrows={false}
                                                        cost={overlayData?.cost}
                                                        data={overlayData?.data}
                                                        date={overlayData?.date}
                                                        dataKeys={[
                                                            ...extraOverlayKeys,
                                                            {
                                                                key: "locations",
                                                                title: "Top Locations",
                                                                subKey: "location",
                                                                icon: aws_region,
                                                            },
                                                            {
                                                                key: "services",
                                                                title: "Top Services",
                                                                subKey: "serviceName",
                                                                subKey2: "serviceCode",
                                                                icon: resource_group,
                                                            },
                                                            {
                                                                key: "resources",
                                                                title: "Top Resources",
                                                                subKey: "resourceId",
                                                                icon: resource_group,
                                                            },
                                                        ]}
                                                        currency={Format.formatCurrencySymbol(currency)}
                                                        // onClick={()=>{
                                                        //   setValue(TAB_SET.SERVICES);
                                                        //   props?.setActiveTab(TAB_SET.SERVICES);
                                                        // }}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </ContainerCardWithDateRange>
                    <br/>
                    {title == "All" ? (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    someref={{how: setDashboardComponentsRef, what:'billing2', where: dashboardComponentsRef}}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    showDateRange={true}
                                    collapsible={true}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                            {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                >
                                    {({ period }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", zIndex: 1 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                            <div style={{ flex: 1 }}>
                                                <div>
                                                    <SlideShow pausePlayRef={pausePlayTotalRef} names={["Spend by AWS", "Spend by Accounts", "Spend by Business units"]} showingIndex={chartShowingIndex} arrowsHandled={true}>
                                                        <div>
                                                            {charts?.AWSCloudSpending?.[period]?.dataSet ? (
                                                                <GridChart
                                                                    currency={currency}
                                                                    title={"Spend by AWS"}
                                                                    charts={charts}
                                                                    classes={classes}
                                                                    period={period}
                                                                    data={charts?.AWSCloudSpending?.[period]}
                                                                    ChartCategory={ReactD3PieChart}
                                                                    chartData={charts?.AWSCloudSpending?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                />
                                                            ) : (
                                                                <NO_DATA_AVAILABLE>No data found for Spend By AWS</NO_DATA_AVAILABLE>
                                                            )}
                                                        </div>

                                                        <div>
                                                            {charts?.SpendByAccounts?.[period]?.dataSet ? (
                                                                <GridChart
                                                                    currency={currency || "USD"}
                                                                    title={"Spend by Accounts"}
                                                                    charts={charts}
                                                                    classes={classes}
                                                                    period={period}
                                                                    data={charts?.SpendByAccounts?.[period]}
                                                                    ChartCategory={ReactD3BarChart}
                                                                    chartData={charts?.SpendByAccounts?.[period]?.dataSet?.sort((a, b) => b.value - a.value) || null}
                                                                />
                                                            ) : (
                                                                <NO_DATA_AVAILABLE>No data found for Spend By Accounts</NO_DATA_AVAILABLE>
                                                            )}
                                                        </div>

                                                        <div>
                                                            {orgloading ? (
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    <CircularProgress thickness={2} size={80} />
                                                                </div>
                                                            ) : awsOrgSpending && awsOrgSpending !== null ? (
                                                                <BussinessUnitAws data={awsOrgSpending} period={period} currency={currency} />
                                                            ) : (
                                                                <NO_DATA_AVAILABLE>No data found for AWS Organizations</NO_DATA_AVAILABLE>
                                                            )}
                                                        </div>
                                                    </SlideShow>
                                                </div>
                                            </div>
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", right: 1 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>

                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    someref={{how: setDashboardComponentsRef, what:'billing3', where: dashboardComponentsRef}}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    showDateRange={true}
                                    collapsible={true}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTop5Ref?.current?.click()}>
                                                            {pausePlayTop5Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                >
                                    {({ period }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", zIndex: 1 }}>
                                                    <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                            <div style={{ flex: 1 }}>
                                                <div>
                                                    <SlideShow pausePlayRef={pausePlayTop5Ref} names={["Top 5 Services", "Top 5 Locations", "Spend by Category"]} showingIndex={chartTop5ShowingIndex} arrowsHandled={true}>
                                                        <div>
                                                            {charts?.TopFiveServices?.[period]?.dataSet ? (
                                                                <GridChart
                                                                    currency={currency}
                                                                    title={"Top 5 Services"}
                                                                    charts={charts}
                                                                    classes={classes}
                                                                    period={period}
                                                                    data={charts?.TopFiveServices?.[period]}
                                                                    ChartCategory={ReactD3BarChart}
                                                                    chartData={charts?.TopFiveServices?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                />
                                                            ) : (
                                                                <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {charts?.TopFiveLocations?.[period]?.dataSet ? (
                                                                <GridChart
                                                                    currency={currency}
                                                                    title={"Top 5 Locations"}
                                                                    charts={charts}
                                                                    classes={classes}
                                                                    period={period}
                                                                    data={charts?.TopFiveLocations?.[period]}
                                                                    ChartCategory={ReactD3BarChart}
                                                                    chartData={charts?.TopFiveLocations?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                />
                                                            ) : (
                                                                <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                            )}
                                                        </div>
                                                        <div>
                                                            {charts?.SpendByCategory?.[period]?.dataSet ? (
                                                                <GridChart
                                                                    currency={currency}
                                                                    title={"Spend by Category"}
                                                                    charts={charts}
                                                                    classes={classes}
                                                                    period={period}
                                                                    data={charts?.SpendByCategory?.[period]}
                                                                    ChartCategory={ReactD3BarChart}
                                                                    chartData={charts?.SpendByCategory?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                                />
                                                            ) : (
                                                                <NO_DATA_AVAILABLE>No data found for Spend By Category</NO_DATA_AVAILABLE>
                                                            )}
                                                        </div>
                                                    </SlideShow>
                                                </div>
                                            </div>
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", right: 1 }}>
                                                    <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    someref={{how: setDashboardComponentsRef, what:'billing4', where: dashboardComponentsRef}}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    showDateRange={true}
                                    collapsible={true}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTotalRef?.current?.click()}>
                                                            {pausePlayTotalRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                >
                                    {({ period }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", zIndex: 1 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                            <SlideShow names={["Spend by AWS", "Spend by Category"]} showingIndex={chartShowingIndex} arrowsHandled={true} pausePlayRef={pausePlayTotalRef}>
                                                <div>
                                                    {charts?.AWSCloudSpending?.[period]?.dataSet ? (
                                                        <GridChart
                                                            currency={currency}
                                                            title={"Spend by AWS"}
                                                            charts={charts}
                                                            classes={classes}
                                                            period={period}
                                                            data={charts?.AWSCloudSpending?.[period]}
                                                            ChartCategory={ReactD3PieChart}
                                                            chartData={charts?.AWSCloudSpending?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Spend By AWS</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>

                                                <div>
                                                    {charts?.SpendByCategory?.[period]?.dataSet ? (
                                                        <GridChart
                                                            currency={currency}
                                                            title={"Spend by Category"}
                                                            charts={charts}
                                                            classes={classes}
                                                            period={period}
                                                            data={charts?.SpendByCategory?.[period]}
                                                            ChartCategory={ReactD3BarChart}
                                                            chartData={charts?.SpendByCategory?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Spend by Category</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", right: 1 }}>
                                                    <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                            <Grid item xs={6}>
                                <ContainerCardWithDateRange
                                    someref={{how: setDashboardComponentsRef, what:'billing5', where: dashboardComponentsRef}}
                                    title={"Total spend"}
                                    titleIcon={Total_Spend_Icon_New}
                                    showDateRange={true}
                                    collapsible={true}
                                    HeaderTitleComponent={(e, setCollapseClickable) => (
                                        <>
                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                                <span>Total spend</span>
                                                <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                                    <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                        <div onClick={() => pausePlayTop5Ref?.current?.click()}>
                                                            {pausePlayTop5Ref?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                >
                                    {({ period }) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", zIndex: 1 }}>
                                                    <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                        <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                            <SlideShow names={["Top 5 Services", "Top 5 Locations"]} showingIndex={chartTop5ShowingIndex} arrowsHandled={true} pausePlayRef={pausePlayTop5Ref}>
                                                <div>
                                                    {charts?.TopFiveServices?.[period]?.dataSet ? (
                                                        <GridChart
                                                            currency={currency}
                                                            title={"Top 5 Services"}
                                                            charts={charts}
                                                            classes={classes}
                                                            period={period}
                                                            data={charts?.TopFiveServices?.[period]}
                                                            ChartCategory={ReactD3BarChart}
                                                            chartData={charts?.TopFiveServices?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 Services</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                                <div>
                                                    {charts?.TopFiveLocations?.[period]?.dataSet ? (
                                                        <GridChart
                                                            currency={currency}
                                                            title={"Top 5 Locations"}
                                                            charts={charts}
                                                            classes={classes}
                                                            period={period}
                                                            data={charts?.TopFiveLocations?.[period]}
                                                            ChartCategory={ReactD3BarChart}
                                                            chartData={charts?.TopFiveLocations?.[period]?.dataSet?.sort((a, b) => b.value - a.value).slice(0, 5) || null}
                                                        />
                                                    ) : (
                                                        <NO_DATA_AVAILABLE>No data found for Top 5 Locations</NO_DATA_AVAILABLE>
                                                    )}
                                                </div>
                                            </SlideShow>
                                            {charts?.AWSCloudSpending?.[period]?.dataSet && (
                                                <div style={{ position: "absolute", right: 1 }}>
                                                    <IconButton onClick={() => setChartTop5ShowingIndex(chartTop5ShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                        <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                                    </IconButton>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </ContainerCardWithDateRange>
                            </Grid>
                        </Grid>
                    )}
                    <br />
                    <div style={{ marginTop: 10 }}>
                        <ContainerCardWithDateRange
                            someref={{how: setDashboardComponentsRef, what:'billing6', where: dashboardComponentsRef}}
                            showDateRange={false}
                            title={"Spend by months"}
                            titleIcon={calendar_icon}
                            HeaderTitleComponent={(e, setCollapseClickable) => (
                                <>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: 10 }}>
                                        <span>Spend by months</span>
                                        <div style={{ marginLeft: 5 }} onMouseOver={() => setCollapseClickable(false)} onMouseOut={() => setCollapseClickable(true)}>
                                            <OverlayTrigger placement="right" overlay={<Tooltip id="button-tooltip">Play / Pause</Tooltip>}>
                                                <div onClick={() => pausePlaySpendRef?.current?.click()}>
                                                    {pausePlaySpendRef?.current?.dataset?.playstate == "playing" ? <PauseCircleFilled style={{ color: "#0000008a" }} /> : <PlayCircleFilled style={{ color: "#0000008a" }} />}
                                                </div>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </>
                            )}
                        >
                            {() => (
                                <div style={{}}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div style={{ position: "absolute", zIndex: 10, marginLeft: 5 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex - 1)} style={{ padding: 0, margin: 0, outline: "none" }}>
                                                <ArrowBackIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <SlideShow pausePlayRef={pausePlaySpendRef} delay={TIMERS.LOWER_CHART_INTERVAL} showingIndex={chartShowingIndex} names={["Spend by AWS", "Top 5 Resources", "Top 5 Services"]} arrowsHandled={true}>
                                                <div>
                                                    {loadingCalendarTableData ? (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <CircularProgress thickness={2} size={80} />
                                                        </div>
                                                    ) : (
                                                        <GroupedBarChart maxWidth={null} currency={Format.formatCurrencySymbol(currency)} totalCost={null} data={awsMonthWiseChartData} cloudCount={1} />
                                                    )}
                                                </div>

                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"resources"}
                                                    keyField={"resourceId"}
                                                    valueKey={"cost"}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                                <StackedBarChart2
                                                    maxWidth={null}
                                                    currency={Format.formatCurrencySymbol(currency)}
                                                    totalCost={null}
                                                    keyStack={"services"}
                                                    keyField={"serviceName"}
                                                    keyField2={"serviceCode"}
                                                    valueKey={"cost"}
                                                    data={othersMonthWiseChartData}
                                                    cloudCount={1}
                                                />
                                            </SlideShow>
                                        </div>
                                        <div style={{ position: "absolute", right: 30, zIndex: 10 }}>
                                            <IconButton onClick={() => setChartShowingIndex(chartShowingIndex + 1)} style={{ margin: 0, padding: 0, outline: "none" }}>
                                                <ArrowForwardIosIcon style={{ margin: 0, padding: 0 }} fontSize="large" color="primary" />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </ContainerCardWithDateRange>
                    </div>
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <ContainerCardWithDateRange someref={{how: setDashboardComponentsRef, what:'billing7', where: dashboardComponentsRef}} title={"AWS Accounts"} titleIcon={aws_account} showDateRange={true} collapsible={true}>
                                {({ period }) => (
                                    <div style={{ marginTop: 10 }}>
                                        <div>
                                            {selectedSubscription.title == "All" ? (
                                                accountList?.[period]?.dataSet?.length > 0 ? (
                                                    <div>
                                                        <AWSOrgList tenantLogo={aws_account} currency={Format.formatCurrencySymbol(currency)} accountList={accountList?.[period]?.dataSet} />
                                                    </div>
                                                ) : (
                                                    <h6 style={{ textAlign: "center" }}>No data found</h6>
                                                )
                                            ) : (
                                                <div>
                                                    {spendBySubscription?.[period] != null && Object.keys(spendBySubscription?.[period]).length ? (
                                                        <div>
                                                            <AWSAccountList tenantLogo={aws_org} currency={Format.formatCurrencySymbol(currency)} accountList={spendBySubscription?.[period]} />
                                                        </div>
                                                    ) : (
                                                        <h6 style={{ textAlign: "center" }}>No data found</h6>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </ContainerCardWithDateRange>
                        </Grid>
                    </Grid>

                </TabPanel>
                <TabPanel value={TAB_SET.ORGANIZATIONS}>
                    <div>
                        <AwsOrganizationView selectedSubscription={selectedSubscription} currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                    </div>
                </TabPanel>
                <TabPanel value={TAB_SET.RESERVATIONS}>
                    <ContainerCardWithDateRange title={"AWS Reservations"} titleIcon={reservation_icon} showDateRange={false} collapsible={false}>
                        {({ period }) => (
                            <Grid>
                                {!AwsReservationloading ? (
                                    <Grid item xs={12}>
                                        {selectedSubscription.title == "All" ? (
                                            awsReservation && awsReservation.length ? (
                                                <AwsReservationTable title={"AWS Reservations"} currency={Format.formatCurrencySymbol(currency)} data={awsReservation || 0} loading={AwsReservationloading} progress={progress} />
                                            ) : (
                                                <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                                            )
                                        ) : awsAccountReservation && awsAccountReservation.length ? (
                                            <AwsReservationTable title={"AWSAccountReservation"} currency={Format.formatCurrencySymbol(currency)} data={awsAccountReservation || 0} loading={AwsReservationloading} progress={progress} />
                                        ) : (
                                            <h6 style={{ display: "flex", justifyContent: "center" }}>No data found</h6>
                                        )}
                                    </Grid>
                                ) : (
                                    <LinearProgress variant="determinate" value={progress} />
                                )}
                            </Grid>
                        )}
                    </ContainerCardWithDateRange>
                </TabPanel>
                <TabPanel value={TAB_SET.RECOMMENDATIONS}>
                    <AWSRecommendations currency={Format.formatCurrencySymbol(currency)} subscriptionId={selectedSubscription.value} tenantId={selectedSubscription?.tenantId} customerId={customerId} />
                </TabPanel>
                <TabPanel value={TAB_SET.COMPUTE}>
                    <Grid>
                        <Grid item xs={12}>
                            {selectedSubscription.title == "All" ? (
                                <AWSCompute currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                            ) : (
                                <AWSAccountCompute subscriptionId={selectedSubscription.value} tenantId={selectedSubscription?.tenantId} currency={Format.formatCurrencySymbol(currency)} customerId={customerId} />
                            )}
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={TAB_SET.DYNAMO_DB}>
                    <Grid>
                        <Grid item xs={12}>
                            <AWSDynamoDB currency={Format.formatCurrencySymbol(currency)} subscriptionId={selectedSubscription.value} tenantId={selectedSubscription?.tenantId} customerId={customerId} />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={TAB_SET.LAMBDA}>
                    <Grid>
                        <Grid item xs={12}>
                            <AWSLambdaFunction currency={Format.formatCurrencySymbol(currency)} subscriptionId={selectedSubscription.value} tenantId={selectedSubscription?.tenantId} customerId={customerId} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={TAB_SET.S3BUCKET}>
                    <Grid>
                        <Grid item xs={12}>
                            <ListS3Bucket currency={Format.formatCurrencySymbol(currency)} subscriptionId={selectedSubscription.value} tenantId={selectedSubscription?.tenantId} customerId={customerId} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={TAB_SET.RDS}>
                    <Grid>
                        <Grid item xs={12}>
                            <AWSRDS currency={Format.formatCurrencySymbol(currency)} subscriptionId={selectedSubscription.value} tenantId={selectedSubscription?.tenantId} customerId={customerId} />
                        </Grid>
                    </Grid>
                </TabPanel>
            </TabContext>
        </div>
    );
}

function BussinessUnitAws({ data, period, currency }) {
    const [orgChartData, setorgChartData] = useState(null);

    function gettingOrgChartData(data) {
        let CD = [];
        let Org = [];
        let DataChart = [];
        for (let i = 0; i < data.length; i++) {
            let getData = AwsOrgTotal(data[i], period);
            CD.push(getData.rootTotal.reduce((a, b) => Number(a) + Number(b), 0));
            getData.org.map((i) => {
                Org.push(i);
            });
        }

        for (let i = 0; i < Org.length; i++) {
            let chart = IndividualAwsOrg(Org[i], period);
            DataChart.push(chart.cData);
        }

        setorgChartData({
            totalCost: CD.reduce((a, b) => a + b, 0).toFixed(2),
            data: DataChart,
        });
    }

    useEffect(() => {
        gettingOrgChartData(data);
    }, [data, period]);

    return (
        <>
            {orgChartData && orgChartData.totalCost > 0 ? (
                <ReactD3BarChart title={"Spend by Business units"} cost={orgChartData?.totalCost} currency={currency} mouseEffect={false} data={orgChartData.data.sort((a, b) => b.value - a.value).slice(0, 8)} />
            ) : (
                <NO_DATA_AVAILABLE />
            )}
        </>
    );
}

const styles = {
    tab: {
        outline: "none",
    },
    labelContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        marginBottom: -10,
        marginLeft: -10,
    },

    icon: { width: 18, height: 18, marginRight: 4 },
};
