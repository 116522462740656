import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { LinearProgress, Paper, Collapse, IconButton, CircularProgress } from "@material-ui/core";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import resource_group from "../../images/newCloudTrakrIcons/AzureResources.png";
import Format from "components/NumberFormat";
import moment from "moment";
import { getAzureResourcesRequest, getAzureTenantResourcesRequest } from "redux/actions/actions";
import ExpandableTable from "components/ExpandableTable";
import azure_service from "../../images/resource_group.svg";
import TableSearch from "../../components/TableSearch";
import TableMonthlySpend from "../AllClouds/TableMonthlySpend";
import NO_DATA_AVAILABLE from "../../components/NO_DATA_AVAILABLE";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        //marginBottom: 5,
    },
}));

export default function Resources({ showData, currency, customerId, spendByMonthResourcesData, loadingCalendarTableData, parentWidth }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [ytdSearch, setYTDSearch] = useState(null);
    const loading = useSelector((state) => state.azureRecommendationReducer.loading);
    let resources = useSelector((state) => state.azureRecommendationReducer.resources);
    let tenantResources = useSelector((state) => state.azureRecommendationReducer.tenantResources);
    let subresources = useSelector((state) => state.azureRecommendationReducer.subresources);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        if ("all" in showData) {
            if (showData.all != null) {
                if (resources == null) {
                    dispatch(getAzureResourcesRequest(customerId));
                }
            } else {
                const { tenantId, subscriptionId } = showData;
                dispatch(
                    getAzureResourcesRequest({
                        customerId,
                        tenantId,
                        subscriptionId,
                    })
                );
            }
        } else {
            let obj = { customerId: customerId, tenantId: showData.tenantId };
            dispatch(getAzureTenantResourcesRequest(obj));
        }
    }, [customerId, showData]);
    return loading ? (
        <div className={classes.root}>
            <LinearProgress />
        </div>
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"Spend by Resources"}
                titleIcon={resource_group}
                defaultPeriod={"1M"}
                showDateRange={true}
                collapsible={true}
                datePeriod={({ period }) => ({
                    start: "__",
                    end: "__",
                    rawStart:
                        "all" in showData
                            ? showData.all != null
                                ? moment(resources?.SpendByResources[period]?.startDate, "YYYYMMDD")
                                : moment(subresources?.SpendByResources[period]?.startDate, "YYYYMMDD")
                            : moment(tenantResources?.SpendByResources[period]?.startDate, "YYYYMMDD"),
                    rawEnd:
                        "all" in showData
                            ? showData.all != null
                                ? moment(resources?.SpendByResources[period]?.endDate, "YYYYMMDD")
                                : moment(subresources?.SpendByResources[period]?.endDate, "YYYYMMDD")
                            : moment(tenantResources?.SpendByResources[period]?.endDate, "YYYYMMDD"),
                })}
                totalCost={({ period }) =>
                    currency +
                    Format.formatNumber(
                        "all" in showData
                            ? showData.all != null
                                ? resources?.SpendByResources[period]?.totalCost
                                : subresources?.SpendByResources[period]?.totalCost
                            : tenantResources?.SpendByResources[period]?.totalCost
                    )
                }
                datePeriodEnabled={true}
                showDatePeriod={(period) =>
                    "all" in showData
                        ? showData.all != null
                            ? !!resources != null && resources?.SpendByResources[period]?.resources?.length
                            : !!subresources != null && subresources?.SpendByResources[period]?.resources?.length
                        : !!tenantResources != null && tenantResources?.SpendByResources[period]?.resources?.length
                }
                onPeriodChange={() => handleChangeRowsPerPage({ target: { value: 10 } })}
            >
                {({ period }) => (
                    <>
                        {"all" in showData ? (
                            showData.all != null ? (
                                <div>
                                    {resources != null ? (
                                        resources && "resources" in resources?.SpendByResources[period] ? (
                                            <ExpandableTable tableRow={resources?.SpendByResources[period]?.resources} data={resources?.SpendByResources[period]} currency={currency} />
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No data found</div>
                                        )
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    {subresources != null ? (
                                        subresources && "resources" in subresources?.SpendByResources[period] ? (
                                            <ExpandableTable tableRow={subresources?.SpendByResources[period]?.resources} data={subresources?.SpendByResources[period]} currency={currency} />
                                        ) : (
                                            <div style={{ textAlign: "center" }}>No data found</div>
                                        )
                                    ) : null}
                                </div>
                            )
                        ) : (
                            <div>
                                {tenantResources != null ? (
                                    tenantResources && "resources" in tenantResources?.SpendByResources[period] ? (
                                        <ExpandableTable tableRow={tenantResources?.SpendByResources[period]?.resources} data={tenantResources?.SpendByResources[period]} currency={currency} />
                                    ) : (
                                        <div style={{ textAlign: "center" }}>No data found</div>
                                    )
                                ) : null}
                            </div>
                        )}
                    </>
                )}
            </ContainerCardWithDateRange>

            <br />
            <div style={{}}>
                <ContainerCardWithDateRange title={"Spend by months"} titleIcon={resource_group} showDateRange={false} collapsible={true}>
                    {() => (
                        <>
                            {loadingCalendarTableData || spendByMonthResourcesData ? <TableSearch onSearch={setYTDSearch} /> : null}
                            {loadingCalendarTableData || spendByMonthResourcesData ? (
                                <TableMonthlySpend
                                    loading={loadingCalendarTableData}
                                    cols={14}
                                    subscriptionNameAsOverlay={false}
                                    subscriptionLevel={true}
                                    tableLabel={"Resource"}
                                    width={parentWidth}
                                    data={spendByMonthResourcesData?.list?.filter((s) => {
                                        if (ytdSearch && ytdSearch.toString().length) {
                                            return !!TableSearch.doesItIncludes([s.cloud, s.subscriptionId, s.name], ytdSearch);
                                        }
                                        return true;
                                    })}
                                    staticRows={spendByMonthResourcesData?.other}
                                    currency={currency}
                                    showCloud={false}
                                />
                            ) : (
                                <NO_DATA_AVAILABLE height={"auto"} />
                            )}
                        </>
                    )}
                </ContainerCardWithDateRange>
            </div>
        </div>
    );
}
