import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Azure_cosmosDB_icon from "../../images/newCloudTrakrIcons/AzureCosmosDB.png";
import Format from "components/NumberFormat";
import moment from "moment";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";
import {
    Table,
    TableBody,
    TableCell as TCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Divider,
    LinearProgress,
    CircularProgress,
    IconButton, Collapse
} from "@material-ui/core";
import ContainerCardWithDateRange from "components/ContainerCardWithDateRange";
import TableSearch from "../../components/TableSearch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../components/TablePaginationActions";
import NO_DATA_AVAILABLE from "../../components/NO_DATA_AVAILABLE";
import {TABLE_SORT} from "../../util/AppConstants";
import TableSortHeader from "../../components/TableSortHeader";
import {ALPHA_NUM_SORTER, convertToInternationalCurrencySystem} from "../../util/Miscellaneous";
import color from "../../styles/color";
const TABLE_SORT_DEFAULT_COLUMN = 'cost';

export default function AzureCosmosDB3MMetricTable({width, classes, customerId,tenantId=null,subscriptionId=null,period, currency }) {
    const dispatch = useDispatch();
    const [progress, setProgress] = useState(0);
    const [availableMonths, setAvailableMonths] = React.useState([]);
    const [selectedCosmosMonth, setSelectedCosmosMonth] = React.useState(null);
    const [visibleCosmos3MList, setVisibleCosmos3MList] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [order, setOrder] = React.useState(TABLE_SORT.ORDER_ASC);
    const [orderBy, setOrderBy] = React.useState(TABLE_SORT_DEFAULT_COLUMN);

    let data = useSelector((state) => subscriptionId ? state?.azureCosmosDBSubReducer?.cosmos3MMetric : state?.azureCosmosDBReducer?.cosmos3MMetric);
    let loading = useSelector((state) => subscriptionId ? state?.azureCosmosDBSubReducer?.cosmos3MMetricLoading : state?.azureCosmosDBReducer?.cosmos3MMetricLoading);

    const [search, setSearch] = useState(null);

    useEffect(()=>{
        if(data){
            const months = Array.from(
                new Set(
                    data?.cosmosDbAccounts?.map(d=>moment(d.startDate, "YYYY-MM-DD").format("YYYYMM")) || []
                )
            ).sort();

            if(months.length) {
                const available = months.map(m=>moment(m, "YYYYMM").format("MMM YYYY"));
                setAvailableMonths(available);
                onCosmosMonthSelect(available[available.length - 1]);
            }
        }
    }, [data]);

    function onCosmosMonthSelect(period){
        setSelectedCosmosMonth(period);
        if(data?.cosmosDbAccounts){
            setVisibleCosmos3MList(
                data?.cosmosDbAccounts
                    ?.filter(c=>moment(c.startDate, "YYYY-MM-DD")?.format("MMM YYYY")== period)
            );
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === TABLE_SORT.ORDER_ASC;
        setOrder(isAsc ? TABLE_SORT.ORDER_DESC : TABLE_SORT.ORDER_ASC);
        setOrderBy(property);
    };

    const filterTable = (f) => {
        if (search && search.toString().length) {
            return !!TableSearch.doesItIncludes(
                [
                    f?.databaseAccount,
                    f?.location,
                    f?.resourceGroup,
                    f?.kind,
                    f?.type
                ],
                search
            );
        }
        return true;
    }

    const sortTable = (r1, r2) => {
        const fi = {
            id: ALPHA_NUM_SORTER.compare(r1?.databaseAccount,r2?.databaseAccount),
            provisioned: Math.round(r2?.provisionedThroughput || 0) - Math.round(r1?.provisionedThroughput || 0),
            consumed: Math.round(r2?.totalRequestUnit || 0) - Math.round(r1?.totalRequestUnit || 0),
            requests: Math.round(r2?.totalRequests || 0) - Math.round(r1?.totalRequests || 0),
            databases: Math.round(r2?.databases?.length || 0) - Math.round(r1?.databases?.length || 0),
            containers: Math.round(
                r1?.databases?.map(d=>d.totalContainers)?.reduce((a,b)=>a+b, 0) || 0
            ) - Math.round(
                r2?.databases?.map(d=>d.totalContainers)?.reduce((a,b)=>a+b, 0) || 0
            ),
            resourceGroup: ALPHA_NUM_SORTER.compare(r1?.resourceGroup,r2?.resourceGroup),
            location: ALPHA_NUM_SORTER.compare(r1?.location,r2?.location),
            cost: parseFloat(r2.cost || 0) - parseFloat(r1.cost || 0),
            kind: ALPHA_NUM_SORTER.compare(r1?.kind,r2?.kind),
            capacity: ALPHA_NUM_SORTER.compare(r1?.capacity,r2?.capacity)
        }[orderBy];
        return fi * order;
    }

    return loading ? (
        <LinearProgress variant="determinate" value={progress} />
    ) : (
        <div>
            <ContainerCardWithDateRange
                title={"CosmosDB Insights"}
                titleIcon={Azure_cosmosDB_icon}
                showDateRange={false}
                collapsible={true}
                // totalCost={({ period }) => currency + Format.formatNumber(azureCosmosDB?.[period]?.resources?.map((t) => parseFloat(t.cost)).reduce((a, b) => a + b, 0))}
                datePeriodEnabled={true}
            >
                {()=>(
                    visibleCosmos3MList?.length?
                        <>
                        <TableSearch onSearch={(e)=>{
                            handleChangeRowsPerPage({ target: { value: 10 } });
                            setSearch(e)
                        }}
                                     RightSideComponent={()=>(
                                         <ButtonGroup
                                             style={{ background: "white",
                                                 height: 30
                                             }}
                                             size="large"
                                             color="primary"
                                             aria-label="large outlined primary button group"
                                         >
                                             {availableMonths.map((period) => (
                                                 <Button
                                                     style={{
                                                         lineHeight: 1.5,
                                                         outline: "none",
                                                         backgroundColor: selectedCosmosMonth === period ? "#3f51b5db" : "",
                                                         color: selectedCosmosMonth === period ? "white" : "",
                                                         fontSize: 12
                                                     }}
                                                     onClick={() =>onCosmosMonthSelect(period)}
                                                 >
                                                     {period}
                                                 </Button>
                                             ))}
                                         </ButtonGroup>
                                     )}
                                     containerStyle={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                        />
                            <Box>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead style={{backgroundColor: color.tableHeader}}>
                                    <TableSortHeader
                                        classes={classes}
                                        numSelected={1
                                            // ||
                                            //selected.length
                                        }
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        rowCount={visibleCosmos3MList?.filter(filterTable)?.length}
                                        headCells={[
                                            { numeric:0, id:'id', label: 'CosmosDB Account'},
                                            { numeric:0, id:'provisioned', label: 'RUs Provisioned'},
                                            { numeric:0, id:'consumed', label: 'RUs Consumed'},
                                            { numeric:0, id:'requests', label: 'Total Requests'},
                                            { numeric:0, id:'kind', label: 'Capacity mode'},
                                            { numeric:0, id:'type', label: 'Type'},
                                            { numeric:0, id:'databases', label: 'Total Databases'},
                                            { numeric:0, id:'containers', label: 'Total Containers'},
                                            { numeric:0, id:'resourceGroup', label: 'Resource Group'},
                                            { numeric:0, id:'location', label: 'Location'},
                                            { numeric:1, id:'cost', label: 'Cost'},
                                        ]}
                                    />
                                    </TableHead>
                                    <TableBody>
                                        {visibleCosmos3MList
                                            ?.filter(filterTable)
                                            ?.sort(sortTable)
                                            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            ?.map((row, index) => (
                                                <Cosmos3MItem row={row} index={index} currency={currency}/>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 75]}
                                component="div"
                                count={visibleCosmos3MList?.filter(filterTable)?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </Box>
                    </>:
                    <NO_DATA_AVAILABLE height={'inherit'} />
                )}
            </ContainerCardWithDateRange>
        </div>
    );
}

function TableCell(props) {
    return (
        <TCell {...props} style={{ padding: 5 }}>
            {props.children}
        </TCell>
    );
}

function Cosmos3MItem({row, index, currency}){
    const [open, setOpen] = useState(false);

    return (
        <>
        <TableRow key={row?.resourceId + index}>
            <TableCell align="left">
                {/*<IconButton*/}
                {/*    aria-label="expand row"*/}
                {/*    size="small"*/}
                {/*    onClick={() => {*/}
                {/*        setOpen(!open);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}*/}
                {/*</IconButton>*/}
                {row?.databaseAccount}
            </TableCell>
            <TableCell align="left">{
                row?.provisionedThroughput?
                    ((row?.provisionedThroughput > 999999)?
                        convertToInternationalCurrencySystem(
                            row?.provisionedThroughput
                        ) + " RUs":
                    (Format.formatNumberInt(Math.round(
                        row?.provisionedThroughput
                    )) + " RUs")):
                    "-"
            }</TableCell>
            <TableCell align="left">{
                row?.totalRequestUnit?
                    (((row?.totalRequestUnit > 999999)?
                        (convertToInternationalCurrencySystem(row?.totalRequestUnit) + " RUs"):
                        (Format.formatNumberInt(Math.round(row?.totalRequestUnit)) + " RUs"))):
                    "-"
            } </TableCell>
            <TableCell align="left">{
                row?.totalRequests?(
                    (row?.totalRequests > 999999)?convertToInternationalCurrencySystem(row?.totalRequests):
                        Format.formatNumberInt(row?.totalRequests)
                    ):"-"}</TableCell>
            <TableCell align="left">{row?.kind || "-"}</TableCell>
            <TableCell align="left">{row?.type || "-"}</TableCell>
            <TableCell align="left">{row?.databases?.length || "-"}</TableCell>
            <TableCell align="left">{row?.databases?.map(d=>d.totalContainers)?.reduce((a,b)=>a+b, 0) || "-"}</TableCell>
            <TableCell align="left">{row?.resourceGroup || "-"}</TableCell>
            <TableCell align="left">{row?.location || "-"}</TableCell>
            <TableCell align="right">
                {currency}
                {Format.formatNumber(Number(row.cost))}
            </TableCell>
        </TableRow>
            {/*<Collapse in={open}>*/}
            {/*    <div>*/}
            {/*        <div style={{maxHeight: 300}} className="overflow-auto">*/}
            {/*            <table id="customers">*/}
            {/*                <tr>*/}
            {/*                    <th>Database name</th>*/}
            {/*                    <th>Total Containers</th>*/}
            {/*                </tr>*/}

            {/*                {row?.databases*/}
            {/*                        ?.sort((a, b) => {*/}
            {/*                            return 1 || parseFloat(b?.cost || 0) - parseFloat(a?.cost || 0);*/}
            {/*                        })*/}
            {/*                        .map((m) => (*/}
            {/*                            <tr>*/}
            {/*                                <td>{m?.serviceName}</td>*/}
            {/*                                <td>{m.meterName}</td>*/}

            {/*                                <td>{currency + Format.formatNumber(Number(m.cost))}</td>*/}
            {/*                            </tr>*/}
            {/*                        ))*/}
            {/*                }*/}
            {/*            </table>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Collapse>*/}
        </>
    )
}
