import React, { useEffect, useRef, useState } from "react";
import error from "../images/error.svg";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Button, Link, Box, Paper } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "@material-ui/core";
import { CloseButton } from "react-bootstrap";
import moment from "moment";

import Azure_logo from "../images/Azure_logo.svg";
import AWS_LOGO from "../images/aws_dark.svg";
import GCP_ICON from "../images/GCP_logo.svg";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import colors from "../styles/color";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: 2,
    },
    heading: {
        fontWeight: theme.typography.fontWeightRegular,
    },
    table: {
        minWidth: 650,
    },
    link: {
        color: colors.blue,
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function ConnectionError({ account, row, date, cloud, tenantName, subscription, status, errorDetail }) {
    const dispatch = useDispatch();

    const classes = useStyles();
    const [errorModalState, seterrorModalState] = useState(false);
    const [errorData, setErrorData] = useState([]);

    function onErrorClose() {
        seterrorModalState(false);
        setErrorData([]);
    }

    return (
        <div className={classes.root}>
            <div
                style={{
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "#ffcccc",
                    padding: 8,
                    justifyContent: "space-between",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={error}
                        alt="error"
                        style={{
                            width: 18,
                            height: 18,
                            marginRight: 20,
                        }}
                    />

                    {
                        {
                            azure: (
                                <img
                                    style={{
                                        width: 18,
                                        height: 18,
                                        marginRight: 4,
                                        marginTops: 4,
                                    }}
                                    src={Azure_logo}
                                />
                            ),
                            aws: (
                                <img
                                    style={{
                                        width: 18,
                                        height: 18,
                                        marginRight: 4,
                                        marginTops: 4,
                                    }}
                                    src={AWS_LOGO}
                                />
                            ),
                            GCP: (
                                <img
                                    style={{
                                        width: 40,
                                        height: 40,
                                        marginRight: 4,
                                        marginTops: 4,
                                    }}
                                    src={GCP_ICON}
                                />
                            ),
                        }[cloud]
                    }
                    <Typography className={classes.heading}>
                        {tenantName} {cloud == "azure" ? "tenant" : "Account"} {subscription} has connection errors.
                    </Typography>

                    {cloud == "azure" ? (
                        <div
                            style={{
                                marginLeft: 4,
                            }}
                        >
                            <Typography
                                className={classes.link}
                                style={{ color: colors.blue }}
                                onClick={() => {
                                    setErrorData({ errorDetail: errorDetail, cloud: cloud });
                                    seterrorModalState(true);
                                }}
                            >
                                Click for details
                            </Typography>
                        </div>
                    ) : (
                        <div
                            style={{
                                marginLeft: 4,
                            }}
                        >
                            <Typography>
                                <Link
                                    onClick={() => {
                                        seterrorModalState(true);
                                        setErrorData({ errorDetail: errorDetail, cloud: cloud });
                                    }}
                                >
                                    Click for details
                                </Link>{" "}
                            </Typography>
                        </div>
                    )}
                </div>
                {account != "linked" && (
                    <CloseButton onClick={() => (cloud == "azure" ? dispatch({ type: "AZURE_DELETE_ERROR" }) : cloud == "aws" ? dispatch({ type: "AWS_DELETE_ERROR" }) : dispatch({ type: "GCP_DELETE_ERROR" }))} style={{ outline: "none" }} />
                )}
            </div>

            <Modal
                open={errorModalState}
                onClose={onErrorClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    outline: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ width: "70%", maxHeight: "80%", outline: "none", backgroundColor: "#cfdac7", overflow: "auto" }}>
                    <Box
                        style={{
                            padding: 10,
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                            }}
                        >
                            <img
                                src={error}
                                alt="error"
                                style={{
                                    width: 18,
                                    height: 18,
                                    marginRight: 20,
                                }}
                            />
                            <Typography variant={"h6"}>
                                <b>Error details</b>
                            </Typography>
                        </div>

                        <CloseButton onClick={onErrorClose} style={{ outline: "none" }} />
                    </Box>

                    <Box style={{ padding: 10, backgroundColor: "white" }}>
                        {errorData.cloud == "azure" ? (
                            errorData?.errorDetail?.subscriptions.length > 0 ? (
                                <TableContainer>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Subscription Name</TableCell>
                                                <TableCell>Subscription Id</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Error</TableCell>
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {errorData?.errorDetail?.subscriptions &&
                                                errorData?.errorDetail?.subscriptions.map((r, i) => (
                                                    <TableRow key={r?.subscriptionId}>
                                                        <TableCell component="th" scope="row">
                                                            {r?.subscriptionName}
                                                        </TableCell>
                                                        <TableCell>{r?.subscriptionId}</TableCell>
                                                        <TableCell>{r?.status}</TableCell>
                                                        <TableCell>
                                                            {typeof r?.errors?.error_desc == "object" ? r?.errors?.error_desc?.message : r?.errors?.error_desc}

                                                        </TableCell>
                                                        <TableCell>{moment(r?.lastModified).format("DD MMM yyyy")}</TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            ) : (
                                <TableContainer>
                                    <Table className={classes.table} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Tenant Name</TableCell>
                                                <TableCell>Tenant Id</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Error</TableCell>
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow key={errorData?.errorDetail?.tenantId}>
                                                <TableCell component="th" scope="row">
                                                    {errorData?.errorDetail?.tenantName}
                                                </TableCell>
                                                <TableCell>{errorData?.errorDetail?.tenantId}</TableCell>
                                                <TableCell>{errorData?.errorDetail?.status}</TableCell>
                                                <TableCell>{typeof errorData?.errorDetail?.errors?.error_desc == "object" ? errorData?.errorDetail?.errors?.error_desc?.message : errorData?.errorDetail?.errors?.error_desc}</TableCell>
                                                <TableCell>{moment(errorData?.errorDetail?.lastModified).format("DD MMM yyyy")}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        ) : (
                            <TableContainer>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>AWS AccountID</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Error</TableCell>
                                            <TableCell>Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow key={errorDetail}>
                                            <TableCell>{subscription}</TableCell>
                                            <TableCell>{status}</TableCell>
                                            <TableCell>{errorDetail}</TableCell>
                                            <TableCell>{moment(date).format("DD MMM yyyy")}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                </div>
            </Modal>
        </div>
    );
}
